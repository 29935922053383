import React, { memo } from 'react';
import { useReactFlow } from 'reactflow';
import useFitView from '@components/MainStage/hooks/useFitView';
import {
  MAX_ZOOM,
  MIN_ZOOM,
  ZOOM_TRANSITION_TIME,
} from '@constants/canvas/general';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import { Typography } from '@mui/material';

import {
  ButtonWrapper,
  CustomControlsStyled,
  FullViewWrapper,
  StyledFullViewIcon,
  StyledMinusIcon,
  StyledPlusIcon,
  StyledZoom,
  ZoomControls,
} from './CustomControls.styled';

const formatZoom = (zoom: number) => {
  return `${(zoom * 100).toFixed(0)}%`;
};

const steps = [0.5, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2];

const getNextZoom = (currentZoom: number, direction: 'in' | 'out') => {
  const currentIndex = steps.findIndex((step) => step >= currentZoom);

  if (direction === 'in') {
    return steps[Math.min(currentIndex + 1, steps.length - 1)];
  }
  return steps[Math.max(currentIndex - 1, 0)];
};

const normalizeZoom = (zoom: number) => {
  return steps.reduce((prev, curr) =>
    Math.abs(curr - zoom) <= Math.abs(prev - zoom) ? curr : prev,
  );
};

export const CustomControls = memo(function CustomControls() {
  const { zoomTo } = useReactFlow();
  const handleFitView = useFitView();
  const { zoom, setZoom } = useCanvasZoom();

  return (
    <CustomControlsStyled>
      <ZoomControls>
        <ButtonWrapper
          onClick={() => {
            const normalizedZoom = normalizeZoom(zoom);
            const nextZoom = getNextZoom(normalizedZoom, 'out');
            if (nextZoom < MIN_ZOOM) return;

            zoomTo(nextZoom, { duration: ZOOM_TRANSITION_TIME });
            setZoom(nextZoom);
          }}
        >
          <StyledMinusIcon />
        </ButtonWrapper>
        <StyledZoom>
          <Typography variant="body2" color="grey.800">
            {formatZoom(zoom)}
          </Typography>
        </StyledZoom>
        <ButtonWrapper
          onClick={() => {
            const normalizedZoom = normalizeZoom(zoom);
            const nextZoom = getNextZoom(normalizedZoom, 'in');
            if (nextZoom > MAX_ZOOM) return;

            zoomTo(nextZoom, { duration: ZOOM_TRANSITION_TIME });
            setZoom(nextZoom);
          }}
        >
          <StyledPlusIcon />
        </ButtonWrapper>
      </ZoomControls>

      <FullViewWrapper onClick={handleFitView}>
        <StyledFullViewIcon />
      </FullViewWrapper>
    </CustomControlsStyled>
  );
});
