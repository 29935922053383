import React from 'react';
import { Modes } from '@constants/canvas/layers';
import { ISelectOption } from '@constants/entities/ui';
import { CaseIcon, ControlsIcon, RiskIcon } from '@utils/iconsDefs';

export const modeOptions: ISelectOption<Modes>[] = [
  {
    label: 'Business alignment',
    value: Modes.BusinessAlignment,
    icon: CaseIcon,
  },
  { label: 'Controls', value: Modes.Controls, icon: ControlsIcon },
  { label: 'Risk management', value: Modes.RiskManagement, icon: RiskIcon },
];

export const modeTooltips = {
  [Modes.BusinessAlignment]: {
    icon: <CaseIcon fill="white" width={20} height={20} />,
    title: 'Business alignment mode',
    subtitle: 'Enables understanding the business context of an enterprise.',
  },
  [Modes.Controls]: {
    icon: <ControlsIcon fill="white" width={20} height={20} />,
    title: 'Controls mode',
    subtitle:
      'Models the security posture of the organisation and develops a logical construct to evaluate its efficacy.',
  },
  [Modes.RiskManagement]: {
    icon: <RiskIcon fill="white" width={20} height={20} />,

    title: 'Risk management mode',
    subtitle:
      'Identifies and manages the cyber risk exposure of the organisation.',
  },
};
