import React, { FC } from 'react';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

const StewardInput: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextField
      name={RiskKeys.Steward}
      label="Risk steward"
      placeholder="Risk steward"
      disabled={viewOnly}
    />
  );
};

export default StewardInput;
