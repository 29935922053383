import React, { FC, useEffect, useMemo } from 'react';
import { CANVAS_EXPORT_WIDTH } from '@constants/canvas/general';
import { ReactFlowId } from '@constants/entities/reports';
import { useProject } from '@context/Project/ProjectProvider';
import useCurrentAsset from '@hooks/useCurrentAsset';
import useExportHTMLImage from '@hooks/useExportHTMLImage';
import { themePalette } from '@theme/muiTheme';
import { getTextFileSize } from '@utils/helpers';
import ExportAsContent from '@views/ReportsModel/components/ExportAsContent/ExportAsContent';
import { ModalWrapper } from '@views/ReportsModel/components/ExportAsContent/ExportAsContent.styled';
import { ModalProps } from '@views/ReportsModel/constants/types';
import {
  getCanvasHeight,
  getModelImageFileName,
} from '@views/ReportsModel/helpers';
import usePrepareModelToExport from '@views/ReportsModel/hooks/usePrepareModelToExport';

export const ExportAsImageModal: FC<ModalProps> = (props) => {
  const { open, onClose } = props;
  const {
    insightsTab,
    toolbox: { mode },
  } = useProject();

  const { dto: asset } = useCurrentAsset();

  const { imageUrl, generateImage, isGenerating, isError, resetExportState } =
    useExportHTMLImage({
      options: (pane) => ({
        width: CANVAS_EXPORT_WIDTH,
        height: mode ? getCanvasHeight(mode) : pane.scrollHeight,
        backgroundColor: themePalette.grey[400],
      }),
    });

  const { prepareModelToExport } = usePrepareModelToExport();

  const handleGenerateModelImage = async () => {
    const undoPreparingActions = prepareModelToExport();

    setTimeout(() => {
      generateImage(
        document.querySelector<HTMLElement>(
          `#${ReactFlowId} .react-flow__renderer`,
        ),
      ).then(undoPreparingActions);
    }, 10);
  };

  useEffect(() => {
    if (open) {
      setTimeout(handleGenerateModelImage, 0);
    } else {
      resetExportState();
    }
  }, [open]);

  const generatedFile = useMemo(() => {
    const fileName = getModelImageFileName(mode!, insightsTab, asset?.name);

    return {
      name: fileName,
      url: imageUrl,
      size: getTextFileSize(imageUrl),
    };
  }, [insightsTab, mode, imageUrl, asset]);

  return (
    <ModalWrapper {...props} title="Export as image">
      <ExportAsContent
        files={[{ isGenerating, generatedFile, isError }]}
        description="We have generated a model image for you. It may take some time to complete."
        onClose={onClose}
        initialGenerating
        continueEntityText={{ singular: 'image', plural: 'images' }}
      />
    </ModalWrapper>
  );
};
