import { useContext } from 'react';
import { DashboardContext } from '@context/DashboardContext/context';

export const useDashboard = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }

  return context;
};
