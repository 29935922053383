import React from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import { useAppDispatch } from '@hooks/store';
import { Box } from '@mui/material';
import { nodesApi } from '@store/services/nodes';
import { QueryTags } from '@store/services/tags';
import { useRestoreVersionMutation } from '@store/services/versions';
import { IVersion } from '@store/services/versions/types';
import { themePalette } from '@theme/muiTheme';
import { parseErrorResponse } from '@utils/helpers';
import { VersionCardBottomPart } from '@views/Project/components/Versions/VersionCardBottomPart';
import { VersionCardTopPart } from '@views/Project/components/Versions/VersionCardTopPart';

export type RestoreVersionModalProps = Required<
  Pick<ConfirmDialogProps, 'open' | 'onClose'>
> & {
  version: IVersion;
};

export const RestoreVersionModal = ({
  version,
  onClose,
  open,
}: RestoreVersionModalProps) => {
  const { setActiveVersionId } = useProject();
  const [restoreVersion] = useRestoreVersionMutation();
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const onModalClose = () => {
    onClose();
  };

  const handleRestoreVersionClick = async () => {
    try {
      await restoreVersion(version?.id).unwrap();
      showToast('Version has been restored successfully.', 'success');

      dispatch(nodesApi.util.invalidateTags([QueryTags.ProjectNodes]));
      setActiveVersionId('');
      onClose();
    } catch (err) {
      showToast(parseErrorResponse(err), 'error');
    }
  };

  return (
    <ConfirmDialog
      title="Restore version"
      text="Are you sure you want to restore the model version? This action will replace your current model with the selected version:"
      confirmName="Confirm"
      onConfirm={handleRestoreVersionClick}
      onClose={onModalClose}
      open={open}
    >
      <Box
        sx={{
          p: 3,
          border: `1px solid ${themePalette.grey[500]}`,
          borderRadius: '12px',
        }}
      >
        <VersionCardTopPart version={version} />
        <VersionCardBottomPart version={version} />
      </Box>
    </ConfirmDialog>
  );
};
