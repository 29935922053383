import useFilteredCanvas from '@components/MainStage/hooks/useFilteredCanvas';
import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project/ProjectProvider';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';

const usePrepareModelToExport = () => {
  const {
    toolbox: { mode },
  } = useProject();

  const { setNodes } = useStore();
  const { nodes } = useFilteredCanvas();

  const prepareModelToExport = () => {
    const initialData = {
      canvasTransform: '',
      nodes: JSON.parse(JSON.stringify(nodes)),
    };

    const viewport = document.querySelector<HTMLDivElement>(
      '.react-flow__viewport.react-flow__container',
    );

    if (viewport) {
      // save current canvas transform
      initialData.canvasTransform = viewport.style.transform;

      // set canvas transform to 0,0, and scale 100%
      viewport.style.transform = 'translate(250px, 0px) scale(1)';
    }

    // remove empty rows
    CanvasModelRenderer.removeEmptyRows(mode!, nodes);

    // undo all changes
    return () => {
      if (viewport && initialData.canvasTransform) {
        viewport.style.transform = initialData.canvasTransform;
      }

      setNodes(initialData.nodes);

      setTimeout(() => {
        CanvasModelRenderer.redrawFullCanvasLayers(mode!);
      }, 10);
    };
  };

  return { prepareModelToExport };
};

export default usePrepareModelToExport;
