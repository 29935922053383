import { useMemo } from 'react';
import { CANVAS_LEFT_MARGIN, CANVAS_WIDTH } from '@constants/canvas/general';
import useCanvasWidth from '@hooks/canvas/useCanvasWidth';
import { getCanvasHeight } from '@utils/canvasHelpers';

const useTranslateExtent = () => {
  const canvasWidth = useCanvasWidth();

  return useMemo<[[number, number], [number, number]]>(() => {
    return [
      [-CANVAS_LEFT_MARGIN, 0],
      [
        Math.max(CANVAS_WIDTH, canvasWidth - CANVAS_LEFT_MARGIN),
        getCanvasHeight(),
      ],
    ];
  }, [canvasWidth, getCanvasHeight()]);
};

export default useTranslateExtent;
