import React, { FC, useEffect } from 'react';
import { SelectItemProps } from '@components/Inputs/TextFieldSelect/types';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TrashIcon } from '@utils/iconsDefs';

type Props = SelectItemProps & {
  onDelete: () => void;
};

const DeleteButton = styled(IconButton)({
  p: '1px',
  display: 'none',
  '.select-option:has(&):hover &': {
    display: 'flex',
  },
});

const MenuItemDeletable: FC<Props> = ({ label, onDelete, onCloseMenu }) => {
  useEffect(() => {
    const ruleIndex = document.styleSheets[0].insertRule(
      '.select-option:hover { padding-right: 2px; }',
    );

    return () => {
      document.styleSheets[0].deleteRule(ruleIndex);
    };
  }, []);

  return (
    <Stack
      flex={1}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      overflow="hidden"
      height={32}
    >
      <TypographyWithElipsis sx={{ width: '100%' }} color="inherit">
        {label}
      </TypographyWithElipsis>

      <DeleteButton
        onClick={(e) => {
          e.stopPropagation();
          onCloseMenu();
          onDelete();
        }}
      >
        <TrashIcon style={{ minWidth: 20 }} />
      </DeleteButton>
    </Stack>
  );
};

export default MenuItemDeletable;
