import React, { useMemo } from 'react';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectSettings from '@hooks/useProjectSettings';
import { transformArrayToOptions } from '@utils/helpers';

const RiskActionDropdown = () => {
  const { viewOnly } = useProject();

  const {
    settings: { risk_action },
  } = useProjectSettings();

  const options: ISelectOption[] = useMemo(() => {
    return transformArrayToOptions(risk_action ?? []);
  }, [risk_action]);

  return (
    <ControlledTextFieldSelect
      name={RiskKeys.RiskAction}
      label="Risk action"
      placeholder="Choose risk action"
      disabled={viewOnly}
      options={options}
      resetOnDoubleClick
      required
    />
  );
};

export default RiskActionDropdown;
