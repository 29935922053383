import React, { memo } from 'react';
import { LayerTypes, Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import LayerMenu from '@views/Project/components/ListMenu/components/LayerMenu';

export const LayerModeMenus = memo(() => {
  const {
    toolbox: { mode },
  } = useProject();

  if (mode === Modes.BusinessAlignment) {
    return (
      <>
        <LayerMenu layerId={LayerTypes.Contextual} />
        <LayerMenu layerId={LayerTypes.Conceptual} />
        <LayerMenu layerId={LayerTypes.Logical} />
      </>
    );
  }

  if (mode === Modes.Controls) {
    return (
      <>
        <LayerMenu layerId={LayerTypes.Conceptual} />
        <LayerMenu layerId={LayerTypes.Logical} />
        <LayerMenu layerId={LayerTypes.Physical} />
        <LayerMenu layerId={LayerTypes.Component} />
        <LayerMenu layerId={LayerTypes.Initiatives} />
      </>
    );
  }

  return (
    <>
      <LayerMenu layerId={LayerTypes.SensitiveElements} />
      <LayerMenu layerId={LayerTypes.AssociatedRisks} />
      <LayerMenu layerId={LayerTypes.Physical} />
      <LayerMenu layerId={LayerTypes.Initiatives} />
    </>
  );
});
