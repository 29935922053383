import {
  InitiativeFundingMechanism,
  InitiativeProgressStatus,
  InitiativeTypes,
  OperationalModel,
  StewardsOpinion,
} from '@constants/canvas/layers';
import { ProjectStatus } from '@constants/entities/project';
import { EnterpriseRolesType } from '@constants/entities/user';

export interface IDashboardBarItemData {
  id: string;
  name: string;
}

export interface IDistributionItem<IdType = string | null> {
  id: IdType;
  name: string;
  items: IDashboardBarItemData[];
}

export interface IBusinessDashboardAttribute {
  total: number;
  totalComplete: number;
  measurelessCount: number;
  overdueCount: number;
  perDomainsData: IDistributionItem[];
  perStrategicData: IDistributionItem[];
}

export interface IBusinessDashboardResponse {
  'business-attribute': IBusinessDashboardAttribute;
  'security-attribute': IBusinessDashboardAttribute;
}

export interface IMaturity {
  name: string;
  current_maturity: string;
  target_maturity: string;
}
export type IMapping = Record<string, string[]>;

export interface ICapabilityTechs {
  id: string;
  name: string;
  relatedTechnologies: string[];
}

export interface IOperatingModel {
  [OperationalModel.InHouse]?: number;
  [OperationalModel.Outsourced]?: number;
  [OperationalModel.Hybrid]?: number;
}

export interface IEffectiveness {
  Yes: number;
  No: number;
}

export interface IControlsDashboardAttribute {
  totalCount: number;
  auditFindingCount: number;
  incompleteCount: number;
  overdueCount: number;
  policyCount: number;
  operationalModel: IOperatingModel;
  maturity: IMaturity[];
  technologyMapping: IMapping;

  designEffectiveness: IEffectiveness;
  operationalEffectiveness: IEffectiveness;
}

export enum BudgetDistribution {
  PerService = 'Per service',
  PerStrategicEnabler = 'Per strategic enabler',
}

export interface IBudgetNode {
  name: string;
  type: InitiativeTypes;
}

export interface IBudgetNodes {
  'Funding required': IBudgetNode[];
  'Funding secured': IBudgetNode[];
}

export interface IBudget {
  name: string;
  'Funding required': number;
  'Funding secured': number;
  nodes: IBudgetNodes;
}

export interface IInitiativeBudget {
  perService: IBudget[];
  perStrategicEnabler: IBudget[];
}

export interface IInitiaiveTypeStats {
  'Strategic initiative'?: string[];
  'Audit finding'?: string[];
  'Remediation action'?: string[];
  Operational?: string[];
}

export interface IFundingMechanismStats {
  [InitiativeFundingMechanism.FundingSecured]?: string[];
  [InitiativeFundingMechanism.FundingRequired]?: string[];
  [InitiativeFundingMechanism.BusinessAsUsual]?: string[];
}

export interface IInitiativeAttribute {
  budget: IInitiativeBudget;
  type: IInitiaiveTypeStats;
  funding_mechanism: IFundingMechanismStats;
  totalFundingRequired: number;
  totalFundingSecured: number;
}

export interface IControlsDashboardResponse {
  capability: IControlsDashboardAttribute;
  function: Omit<IControlsDashboardAttribute, 'technologyMapping'>;
  service: Omit<IControlsDashboardAttribute, 'technologyMapping'>;
  'change-initiative': IInitiativeAttribute;
}

export interface IAssetAttribute {
  total: number;
  incompleteCount: number;
  overdueCount: number;
  withoutRiskCount: number;
  perDomainsData: IDistributionItem[];
}

export interface IRiskAppetiteAndToleranceStats {
  aboveTolerance: string[];
  withinTolerance: string[];
  withinAppetite: string[];
}

export interface IRiskDistributionItem {
  id: string | null;
  name: string;
  nodes: {
    high: string[];
    other: string[];
  };
}

export interface IRiskStewardOpinionStats {
  [StewardsOpinion.Pending]: string[];
  [StewardsOpinion.Challenged]: string[];
  [StewardsOpinion.Concurred]: string[];
}

export interface IRiskAttribute {
  total: number;
  incompleteCount: number;
  overdueCount: number;
  withRemediationActionCount: number;
  appetite_and_tolarance: IRiskAppetiteAndToleranceStats;
  perDomainsData: IRiskDistributionItem[];
  steward_opinion: IRiskStewardOpinionStats;
}

export interface IRemActionProgressStats {
  [InitiativeProgressStatus.ToDo]: string[];
  [InitiativeProgressStatus.InFlight]: string[];
  [InitiativeProgressStatus.OnHold]: string[];
}

export interface IRemActionAttribute {
  progress_status: IRemActionProgressStats;
  funding_mechanism: IFundingMechanismStats;
  perDomainsData: IDistributionItem[];
  perService: IDistributionItem[];
}

export interface IRiskDashboardResponse {
  assets: IAssetAttribute;
  risks: IRiskAttribute;
  remediationActions: IRemActionAttribute;
}

export interface IAdminDashboardResponse {
  projects: number;
  activeUsers: number;
  allocatedLicenses: number;
  rolesBreakdown: Record<EnterpriseRolesType, [number, number]>;
  projectStatusBreakdown: Record<ProjectStatus, number>;
  perTierData: IDistributionItem<string>[];
  perIndustriesData: IDistributionItem<string>[];
}
