import React, { FC, ReactNode } from 'react';
import { SxProps, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';

type Props = SxProps & {
  children: ReactNode;
  required?: boolean;
};

const Label: FC<Props> = ({ children, required, ...props }) => {
  return (
    <Typography variant="h5" sx={{ mb: 1, ...props }}>
      {children}
      {required && <span style={{ color: themePalette.system.error }}>*</span>}
    </Typography>
  );
};

export default Label;
