import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import useIsNodeDisabled from '@components/MainStage/CustomNode/useIsNodeDisabled';
import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project';
import useCanEditModel from '@hooks/useCanEditModel';

type NodesDataContextType = {
  getIsNodeTargetConnected: (id: string) => boolean;
  getIsNodeSourceConnected: (id: string) => boolean;
  getActiveNode: () => any;
  getActiveEdge: () => any;
  insightsTab: string;
  isConnectable: boolean;
  isNodeDisabled: (canvasNodeData: any) => boolean;
  isEdgeDisabled: (edge: { child_id: string; parent_id: string }) => boolean;
};

const defaultValue = {
  getIsNodeTargetConnected: () => false,
  getIsNodeSourceConnected: () => false,
  getActiveNode: () => null,
  getActiveEdge: () => null,
  insightsTab: '',
  isConnectable: false,
  isNodeDisabled: () => false,
  isEdgeDisabled: () => false,
};

const NodesDataContext = createContext<NodesDataContextType>(defaultValue);

export const NodesDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    getIsNodeTargetConnected,
    getIsNodeSourceConnected,
    getActiveNode,
    getActiveEdge,
    nodes,
  } = useStore();
  const { insightsTab } = useProject();
  const isConnectable = useCanEditModel();
  const isNodeDisabled = useIsNodeDisabled();

  const isEdgeDisabled = useCallback(
    ({ child_id, parent_id }: { child_id: string; parent_id: string }) => {
      const child = nodes.find((node) => node.id === child_id);
      const parent = nodes.find((node) => node.id === parent_id);

      return [child, parent].some((node) =>
        isNodeDisabled(node?.data?.canvas ?? {}),
      );
    },
    [nodes],
  );

  const value = useMemo(
    () => ({
      getIsNodeTargetConnected,
      getIsNodeSourceConnected,
      getActiveNode,
      getActiveEdge,
      insightsTab,
      isConnectable,
      isNodeDisabled,
      isEdgeDisabled,
    }),
    [insightsTab, isEdgeDisabled, isConnectable],
  );

  return (
    <NodesDataContext.Provider value={value}>
      {children}
    </NodesDataContext.Provider>
  );
};

export const useNodesDataContext = () => {
  const context = useContext(NodesDataContext);

  if (context === defaultValue) {
    throw new Error(
      'useNodesDataContext must be used within a NodesDataProvider',
    );
  }

  return context;
};
