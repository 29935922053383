import { UnionFromArray } from '@constants/types';

export enum UserStatus {
  Active = 'active',
  Invited = 'invited',
  InviteExpired = 'invite-expired',
  Disabled = 'disabled',
}

export enum UserRoles {
  SuperAdmin = 'super-admin',
  QmsArchitect = 'qms-architect',
  QmsModeller = 'qms-modeller',
  ClientModeller = 'client-modeller',
  Contributor = 'contributor',
  Viewer = 'viewer',
}

export const UserStatusesWithoutDisabled = Object.values(UserStatus).filter(
  (status) => status !== UserStatus.Disabled,
);

export const ConsultantsRoles = [
  UserRoles.SuperAdmin,
  UserRoles.QmsArchitect,
  UserRoles.QmsModeller,
];

export const ProjectUserRoles = [
  UserRoles.Viewer,
  UserRoles.ClientModeller,
  UserRoles.Contributor,
];

export const ReadableUserStatuses = {
  [UserStatus.Active]: 'Active',
  [UserStatus.Invited]: 'Invitation sent',
  [UserStatus.InviteExpired]: 'Invitation expired',
  [UserStatus.Disabled]: 'Deactivated',
};

export const ReadableUserRoles = {
  [UserRoles.SuperAdmin]: 'Super admin',
  [UserRoles.QmsArchitect]: 'Architect',
  [UserRoles.QmsModeller]: 'Enterprise modeller',
  [UserRoles.ClientModeller]: 'Modeller',
  [UserRoles.Contributor]: 'Contributor',
  [UserRoles.Viewer]: 'Viewer',
};

export const ReadableEnterpriseUserRoles = {
  [UserRoles.ClientModeller]: 'Modeller',
  [UserRoles.Contributor]: 'Contributor',
  [UserRoles.Viewer]: 'Viewer',
};

export const QmsRoles = [
  UserRoles.SuperAdmin,
  UserRoles.QmsArchitect,
  UserRoles.QmsModeller,
];

export const EnterpriseRoles = [
  UserRoles.ClientModeller,
  UserRoles.Contributor,
  UserRoles.Viewer,
] as const;

/*
 * Object with configuration for the roles permissions or elements visibility based on the user role
 */
export const RolesPermissionsOrElements: Record<string, UserRoles[]> = {
  SidebarProject: EnterpriseRoles as any as UserRoles[],
  AddUserOrShareProjectButtons: [UserRoles.SuperAdmin, UserRoles.QmsArchitect],
  AddUserToProjectButton: [UserRoles.QmsModeller, UserRoles.ClientModeller],
  EnterpriseAndProjectSettingsTabs: [
    ...QmsRoles,
    UserRoles.ClientModeller,
    UserRoles.Contributor,
  ],
  EditEnterpriseSettings: [...QmsRoles, UserRoles.ClientModeller],
  EditOrganizationTier: [UserRoles.SuperAdmin],
  EditProjectConsultants: [UserRoles.SuperAdmin, UserRoles.QmsArchitect],
  EditProjectSettings: [...QmsRoles, UserRoles.ClientModeller],
  SeeProjectSettings: [UserRoles.Contributor],
  StandardHeaderShareProjectButton: [
    UserRoles.SuperAdmin,
    UserRoles.QmsArchitect,
  ],
  StandardHeaderAddUserButton: [
    UserRoles.QmsModeller,
    UserRoles.ClientModeller,
  ],
  UploadFAQFile: [UserRoles.SuperAdmin],
  ProjectsAssigneeFilter: [UserRoles.SuperAdmin, UserRoles.QmsArchitect],
  CreateProject: [UserRoles.SuperAdmin, UserRoles.QmsArchitect],
  ProjectActionsMenu: QmsRoles,
  ProjectActionsMenuAllItems: [UserRoles.SuperAdmin, UserRoles.QmsArchitect],
  ProjectActionsMenuExportItem: QmsRoles,
  EditProjectStatus: [
    UserRoles.SuperAdmin,
    UserRoles.QmsArchitect,
    UserRoles.QmsModeller,
  ],
  EditMainModel: [...QmsRoles, UserRoles.ClientModeller],
  CreateScenario: [
    ...QmsRoles,
    UserRoles.ClientModeller,
    UserRoles.Contributor,
  ],
  EditScenarios: [...QmsRoles, UserRoles.ClientModeller, UserRoles.Contributor],
  PromoteScenario: [...QmsRoles, UserRoles.ClientModeller],
  DuplicateScenario: [
    ...QmsRoles,
    UserRoles.ClientModeller,
    UserRoles.Contributor,
  ],
  DeletePublicScenario: [...QmsRoles, UserRoles.ClientModeller],
  ScenarioActionsColumn: [
    ...QmsRoles,
    UserRoles.ClientModeller,
    UserRoles.Contributor,
  ],
  RestoreVersion: [...QmsRoles, UserRoles.ClientModeller],
  SidebarSuperAdminItems: [UserRoles.SuperAdmin],
  ProjectActivityHistory: [UserRoles.SuperAdmin],
  BusinessUnitProfileInput: EnterpriseRoles as any as UserRoles[],
};

export type EnterpriseRolesType = UnionFromArray<typeof EnterpriseRoles>;
