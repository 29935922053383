import React, { FC, ReactNode } from 'react';
import { Stack, Tooltip, TooltipProps, Typography } from '@mui/material';

export type CustomTooltipProps = TooltipProps & {
  icon?: React.ReactNode;
  title?: ReactNode;
  subtitle?: string;
  skip?: boolean;
  sx?: any;
};

export const CustomTooltip: FC<CustomTooltipProps> = ({
  title,
  subtitle,
  children,
  icon,
  skip,
  sx = {},
  ...props
}) => {
  if (skip) return <>{children}</>;

  return (
    <Tooltip
      title={
        <Stack gap={1} sx={{ ...sx }}>
          {icon}

          <Typography variant="body2" color="white">
            {title}
          </Typography>

          {subtitle && (
            <Typography
              variant="body2"
              color="white"
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
