import { useAppDispatch, useAppSelector } from '@hooks/store';
import { IRecentProject } from '@store/services/auth/types';
import {
  initProjects,
  selectRecently,
  shiftProject,
} from '@store/slices/recentlyProjectsSlice';

const useRecentlyProjects = () => {
  const recentlyProjects = useAppSelector(selectRecently);
  const dispatch = useAppDispatch();

  const addRecentlyProject = (project: IRecentProject) => {
    dispatch(shiftProject(project));
  };

  const initRecentlyProjects = (projects: IRecentProject[]) => {
    dispatch(initProjects(projects));
  };

  return { recentlyProjects, addRecentlyProject, initRecentlyProjects };
};

export default useRecentlyProjects;
