import React, { FC } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import ReferencesInputsGroup from '@components/EntityDrawers/components/ReferencesInputsGroup/ReferencesInputsGroup';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import EntityAlerts from '@views/Project/components/EntityAlerts';

const Standard: FC = () => {
  const { editMode } = useDrawerContext();

  return (
    <>
      <EntityAlerts />

      <NameInput
        editMode={editMode}
        label="Service name"
        placeholder="Service name"
      />

      <DescriptionInput
        label="Service description"
        placeholder="Service description"
        required
      />

      <OwnerInput label="Service owner" placeholder="Service owner" />
      <DomainDropdown />
      <ReferencesInputsGroup />
      <ValidationStatus />

      {editMode && <CreatedReviewDates dateReviewedClearable />}
    </>
  );
};

export default Standard;
