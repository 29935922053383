import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Label from '@components/Inputs/Label';
import LinkInput from '@components/Inputs/LinkInput/LinkInput';
import { Box, FormHelperText, SxProps, TextFieldProps } from '@mui/material';

export type ControlledTextFieldProps = TextFieldProps & {
  name: string;
  boxSx?: SxProps;
};

const ControlledLinkInput: FC<ControlledTextFieldProps> = ({
  name,
  label,
  defaultValue,
  helperText,
  boxSx,
  required,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, ...field },
        fieldState: { error },
      }) => (
        <Box sx={{ width: '100%', ...boxSx }}>
          {label && <Label required={required}>{label}</Label>}

          <LinkInput
            value={value ?? ''}
            onChange={onChange}
            error={!!error?.message}
            {...field}
            {...props}
          />

          <FormHelperText error={!!error?.message}>
            {error?.message || helperText}
          </FormHelperText>
        </Box>
      )}
    />
  );
};

export default ControlledLinkInput;
