import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditLogUserItem } from '@store/services/audit-log/types';
import { RootState } from '@store/store';

interface UserActivityHistoryState {
  logs: AuditLogUserItem[];
}

const initialState: UserActivityHistoryState = {
  logs: [],
};

const userActivityHistorySlice = createSlice({
  name: 'UserActivityHistory',
  initialState,
  reducers: {
    addLogs: (
      state,
      { payload: { newLogs } }: PayloadAction<{ newLogs: AuditLogUserItem[] }>,
    ) => {
      state.logs.push(...newLogs);
    },
    clearLogs: (state) => {
      state.logs = [];
    },
  },
});

export const selectUserActivityHistoryLogs = (state: RootState) =>
  state.userActivityHistory.logs;

export const { addLogs, clearLogs } = userActivityHistorySlice.actions;
export default userActivityHistorySlice.reducer;
