import { useParams } from 'react-router-dom';
import { useAppSelector } from '@hooks/store';
import { selectProjectTabs } from '@store/slices/projectSlice';

const useProjectTabs = () => {
  const { id = '' } = useParams<{ id: string }>();

  const tabs = useAppSelector(selectProjectTabs);

  return tabs[id] || { currentId: null, scenarios: [] };
};

export default useProjectTabs;
