import React from 'react';
import useStore from '@components/MainStage/store';
import { NewNodePlusIcon } from '@utils/iconsDefs';

import {
  IconWrapper,
  NewNodeStyled,
  NodeContent,
  StyledText,
  TextWrapper,
} from './NewNodeStyled';

export const NewNode = () => {
  const { getActiveNode } = useStore();
  const activeNode = getActiveNode();

  return (
    <NewNodeStyled>
      <NodeContent>
        <IconWrapper>
          <NewNodePlusIcon width={20} height={20} />
        </IconWrapper>
        <TextWrapper>
          <StyledText variant="h4">
            {activeNode?.data?.dto?.label || 'Add new entity'}
          </StyledText>
        </TextWrapper>
      </NodeContent>
    </NewNodeStyled>
  );
};
