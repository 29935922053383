import React, { FC } from 'react';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import {
  InitiativeProgressStatusOptions,
  InitiativeTypeOptions,
} from '@components/EntityDrawers/constants/common';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { InitiativeKeys } from '@components/EntityDrawers/drawers/Initiative/keys';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import ControlledDatePicker from '@components/Inputs/controllers/ControlledDatePicker';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useCurrentAsset from '@hooks/useCurrentAsset';
import EntityAlerts from '@views/Project/components/EntityAlerts';

const Standard: FC = () => {
  const { viewOnly } = useProject();
  const { editMode } = useDrawerContext();
  const {
    toolbox: { mode },
  } = useProject();

  const { dto: asset } = useCurrentAsset();

  const activeNode = useSourceNode();

  const isDomainDisabled =
    editMode &&
    mode === Modes.RiskManagement &&
    Boolean(asset?.id) &&
    activeNode?.data?.dto?.parent_asset !== asset?.id;

  return (
    <>
      <EntityAlerts />

      <NameInput
        editMode={editMode}
        label="Initiative name"
        placeholder="Initiative name"
      />

      {!asset?.id && (
        <ControlledTextFieldSelect
          name={InitiativeKeys.Type}
          label="Initiative type"
          placeholder="Choose initiative type"
          disabled={viewOnly}
          required
          options={InitiativeTypeOptions}
        />
      )}

      <DescriptionInput
        label="Initiative description"
        placeholder="Initiative description"
      />

      <DomainDropdown disabled={isDomainDisabled} />

      <ControlledTextFieldSelect
        name={InitiativeKeys.ProgressStatus}
        label="Progress status"
        placeholder="Choose progress status"
        disabled={viewOnly}
        options={InitiativeProgressStatusOptions}
      />

      <ControlledTextField
        name={InitiativeKeys.BusinessSponsor}
        label="Business sponsor"
        placeholder="Business sponsor"
        disabled={viewOnly}
      />

      <ControlledTextField
        name={InitiativeKeys.ProgramManager}
        label="Program manager"
        placeholder="Program manager"
        disabled={viewOnly}
      />

      {editMode && (
        <ControlledDatePicker
          name={CommonKeys.DateCreated}
          label="Date created"
          readOnly
        />
      )}
    </>
  );
};

export default Standard;
