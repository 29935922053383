import React, { FC, memo, useEffect, useMemo } from 'react';
import {
  LayerSubLayers,
  LayerTypes,
  Modes,
  PhysicalSubLayerTypes,
  ReadableLayerTitles,
} from '@constants/canvas/layers';
import { PhysicalLayerInRiskModeLabel } from '@constants/canvas/riskManagement';
import { useMenuNodes } from '@context/MenuNodesProvider';
import { useProject } from '@context/Project/ProjectProvider';
import useToggle from '@hooks/useToggle';
import { Collapse, MenuItem, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

import SubLayer from './SubLayer';

type Props = {
  layerId: LayerTypes;
};

const LayerMenu: FC<Props> = ({ layerId }) => {
  const {
    toolbox: { mode },
  } = useProject();

  const [isExpanded, { toggle, off }] = useToggle(false);

  useEffect(() => {
    off();
  }, [mode]);

  const { nodes } = useMenuNodes();

  const layerNodes = useMemo(
    () =>
      nodes.filter((node) =>
        LayerSubLayers[layerId].includes(
          node.parentNode as PhysicalSubLayerTypes,
        ),
      ),
    [layerId, nodes],
  );

  let riskModeLayerMenuLabel = '';

  if (mode === Modes.RiskManagement) {
    switch (layerId) {
      case LayerTypes.Physical:
        riskModeLayerMenuLabel = PhysicalLayerInRiskModeLabel;
        break;
      case LayerTypes.Initiatives:
        riskModeLayerMenuLabel =
          ReadableLayerTitles[LayerTypes.RemediationAction];
        break;
      default:
        riskModeLayerMenuLabel = ReadableLayerTitles[layerId];
        break;
    }
  }

  return (
    <div>
      <MenuItem
        sx={{ justifyContent: 'space-between', whiteSpace: 'nowrap' }}
        onClick={toggle}
      >
        <Typography variant="h5">
          {mode === Modes.RiskManagement
            ? riskModeLayerMenuLabel
            : `${ReadableLayerTitles[layerId]}`}
          {` (${layerNodes.length})`}
        </Typography>

        <DropdownIcon
          fill={themePalette.grey[isExpanded ? 1000 : 800]}
          style={{ transform: isExpanded ? 'rotate(180deg)' : '' }}
        />
      </MenuItem>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {LayerSubLayers[layerId].map((subLayerId) => (
          <SubLayer
            key={subLayerId}
            subLayerId={subLayerId}
            layerId={layerId}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default memo(LayerMenu);
