import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { ISelectOption } from '@constants/entities/ui';
import useProjectId from '@hooks/useProjectId';
import { useGetBusinessAttributesQuery } from '@store/services/nodes';
import { transformValueToDomainId } from '@utils/helpers';

const useBusinessAttributesOptions = () => {
  const projectId = useProjectId(true);
  const activeNode = useSourceNode();

  const { watch, setValue } = useFormContext();

  const { data, isLoading, isFetching } = useGetBusinessAttributesQuery({
    projectId,
    domainId: watch(CommonKeys.Domain),
  });

  useEffect(() => {
    const dataLoaded = !isLoading && !isFetching && data;
    const domainChanged =
      transformValueToDomainId(watch(CommonKeys.Domain)) !==
      activeNode?.data?.dto?.domain_id;

    if (dataLoaded && domainChanged) {
      setValue(
        AssetKeys.BusinessAttributes,
        watch(AssetKeys.BusinessAttributes)?.filter(
          ({ value }: ISelectOption) => data?.some((d) => d.id === value),
        ),
      );
    }
  }, [data, watch(CommonKeys.Domain), activeNode]);

  const options: ISelectOption[] = useMemo(() => {
    return (data ?? []).map(({ id, name }) => ({ value: id, label: name }));
  }, [data]);

  return {
    options,
    isLoading: isLoading || isFetching,
  };
};

export default useBusinessAttributesOptions;
