import React, { FC, memo } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import { useProject } from '@context/Project/ProjectProvider';
import useScenarioMode from '@hooks/useScenarioMode';
import { Box, Stack, Switch, Typography } from '@mui/material';
import { ScenarioIcon } from '@utils/iconsDefs';

const ScenarioSwitch: FC = () => {
  const { scenarioMode, enterScenarioMode, leaveScenarioMode } =
    useScenarioMode();
  const { isVersionsShown } = useProject();

  return (
    <Stack gap={3} direction="row" alignItems="center">
      <CustomTooltip
        slotProps={{ tooltip: { sx: { mr: 10 } } }}
        placement="bottom-end"
        icon={<ScenarioIcon fill="white" width={20} height={20} />}
        title="Scenarios mode"
        subtitle="Forked model versions that provides ability to create various modes without interrupting main project model."
      >
        <Stack direction="row" gap={1} sx={{ cursor: 'default' }}>
          <ScenarioIcon />
          <Box sx={{ '@media (max-width: 1600px)': { display: 'none' } }}>
            <Typography variant="h4">Scenarios mode</Typography>
          </Box>
        </Stack>
      </CustomTooltip>

      <Switch
        checked={scenarioMode}
        disabled={isVersionsShown}
        onChange={(_, checked) =>
          checked ? enterScenarioMode() : leaveScenarioMode()
        }
      />
    </Stack>
  );
};

export default memo(ScenarioSwitch);
