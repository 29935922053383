import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

export const ChangeInitiativeStatusWrapper = styled('div')`
  position: absolute;
  top: -20px;
  left: 11px;
  display: flex;
  z-index: -1;
`;

export const InitiativeStatusBlock = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 79px;
  height: 20px;
  font-size: 12px;
  line-height: 16px;
  color: ${themePalette.grey[800]};
  font-weight: 500;
  background-color: ${themePalette.grey[500]};
  border-radius: 4px 4px 0 0;
`;
