import React, { FC, useMemo } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledTextFieldSelect, {
  ControlledTextFieldSelectProps,
} from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { ISelectOption, WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectId from '@hooks/useProjectId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetProjectDomainsQuery } from '@store/services/projects';
import { parseDomains, transformArrayToOptions } from '@utils/helpers';

type Props = Partial<
  Pick<
    ControlledTextFieldSelectProps,
    'name' | 'label' | 'required' | 'disabled' | 'value'
  >
>;

const DomainDropdown: FC<Props> = ({
  name = CommonKeys.Domain,
  label = 'Domain',
  required = true,
  disabled,
  ...props
}) => {
  const projectId = useProjectId();
  const { viewOnly } = useProject();

  const { data } = useGetProjectDomainsQuery(projectId || skipToken);

  const domains = useMemo<ISelectOption[]>(
    () => [
      { value: WideDomainKey, label: 'Enterprise wide' },
      ...(parseDomains(data ?? [])
        .map(({ id, name, subDomains }) => [
          { value: id, label: name },
          ...transformArrayToOptions(subDomains, {
            label: 'name',
            value: 'id',
          }),
        ])
        .flat(2) as ISelectOption[]),
    ],
    [data],
  );

  return (
    <ControlledTextFieldSelect
      name={name}
      label={label}
      required={required}
      disabled={viewOnly || disabled}
      options={domains}
      {...props}
    />
  );
};

export default DomainDropdown;
