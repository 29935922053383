import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { CharacteristicFormValues } from '@components/EntityDrawers/drawers/Characteristic/form';
import { CharacteristicKeys } from '@components/EntityDrawers/drawers/Characteristic/keys';
import ControlledAutocomplete from '@components/Inputs/controllers/ControlledAutocomplete';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectSettings from '@hooks/useProjectSettings';
import { transformArrayToOptions } from '@utils/helpers';

const ElementTypeDetailsInput = () => {
  const { viewOnly } = useProject();

  const { watch } = useFormContext<CharacteristicFormValues>();

  const {
    settings: { sensitive },
    isLoading,
  } = useProjectSettings();

  const selectedSensitive = watch(CommonKeys.Name);

  const options: ISelectOption[] = useMemo(() => {
    const details = (sensitive ?? []).find(
      ({ name }) => name === selectedSensitive,
    )?.children;

    return transformArrayToOptions(details ?? []);
  }, [sensitive, selectedSensitive]);

  return (
    <ControlledAutocomplete
      name={CharacteristicKeys.ElementDetail}
      label="Sensitive element type details"
      placeholder="Choose type details"
      readOnly={viewOnly || !selectedSensitive}
      loading={isLoading}
      options={options}
      multiple
      slotProps={{
        popper: {
          sx: { '& .MuiAutocomplete-listbox': { maxHeight: 210 } },
        },
      }}
    />
  );
};

export default ElementTypeDetailsInput;
