import React, { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledCheckbox from '@components/Inputs/controllers/ControlledCheckbox';
import {
  CommonReportDescription,
  ExportLayerKeys,
  ExportLayersSettingsDescription,
  ReadableExportLayers,
} from '@constants/entities/reports';
import useProjectData from '@hooks/useProjectData';
import useProjectId from '@hooks/useProjectId';
import { Stack } from '@mui/material';
import { omitKeys } from '@utils/helpers';
import ExportAsContent from '@views/ReportsModel/components/ExportAsContent/ExportAsContent';
import { ModalWrapper } from '@views/ReportsModel/components/ExportAsContent/ExportAsContent.styled';
import { ModalProps } from '@views/ReportsModel/constants/types';
import { getAvailableLayers, getFileData } from '@views/ReportsModel/helpers';
import useExportLayers from '@views/ReportsModel/hooks/useExportLayers';

type FormValues = {
  all: boolean;
} & Record<ExportLayerKeys, boolean>;

export const ExportLayersReportModal = memo((props: ModalProps) => {
  const { open, onClose } = props;
  const projectId = useProjectId(true);

  const [{ subscription }] = useProjectData();

  const availableLayers: ExportLayerKeys[] = useMemo(
    () => getAvailableLayers(subscription?.modes ?? []),
    [subscription?.modes],
  );

  const form = useForm<FormValues>({
    defaultValues: {
      all: true,
      ...availableLayers.reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    },
  });

  const layers = Object.entries(omitKeys(form.watch(), ['all'])).reduce<
    ExportLayerKeys[]
  >(
    (acc, [key, value]) => (value ? [...acc, key as ExportLayerKeys] : acc),
    [],
  );

  const {
    handleGenerate,
    isLoading,
    fileContent,
    setFileContent,
    isError,
    setError,
    fileName,
  } = useExportLayers(projectId, layers);

  useEffect(() => {
    if (open) {
      form.reset();
      setError(false);
      setFileContent('');
    }
  }, [open]);

  const handleChangeAll = (all: boolean) => {
    availableLayers.forEach((key) => {
      form.setValue(key, all);
    });
  };

  const handleChangeLayer = (key: ExportLayerKeys) => (checked: boolean) => {
    if (!checked) {
      return form.setValue('all', false);
    }

    const isAllLayersSelected = Object.values(
      omitKeys(form.getValues(), ['all', key]),
    ).every((value) => value);

    if (isAllLayersSelected) {
      form.setValue('all', true);
    }
  };

  return (
    <ModalWrapper {...props} title="Export as report">
      <ExportAsContent
        files={[
          {
            isGenerating: isLoading,
            generatedFile: getFileData(fileContent, fileName),
            isError,
          },
        ]}
        description={({ isExportSettingsStep }) =>
          isExportSettingsStep
            ? ExportLayersSettingsDescription
            : CommonReportDescription
        }
        onGenerateReport={handleGenerate}
        disabled={!layers.length}
        onClose={onClose}
      >
        <FormProvider {...form}>
          <Stack gap={3} mt={4}>
            <ControlledCheckbox
              label="All layers"
              variant="h5"
              name="all"
              onChange={handleChangeAll}
            />

            {availableLayers.map((layerKey) => (
              <ControlledCheckbox
                key={layerKey}
                defaultValue
                label={ReadableExportLayers[layerKey]}
                name={layerKey}
                onChange={handleChangeLayer(
                  layerKey as unknown as ExportLayerKeys,
                )}
              />
            ))}
          </Stack>
        </FormProvider>
      </ExportAsContent>
    </ModalWrapper>
  );
});
