import React from 'react';
import { NodeProps } from 'reactflow';
import useCollapseController from '@components/MainStage/hooks/useCollapseController';
import { CollapseIcon } from '@components/MainStage/MainStage.styled';
import { UI_SMALL_LAYERS } from '@constants/canvas/general';
import { LayerTypes } from '@constants/canvas/layers';
import useCanvasWidth from '@hooks/canvas/useCanvasWidth';
import { Stack } from '@mui/material';

import { LayerNodeStyled, StyledText } from './LayerNode.styled';

export type NodeData = {
  label: string;
};

export function LayerNode({ id, data }: NodeProps<NodeData>) {
  const isSmall = UI_SMALL_LAYERS.includes(id as LayerTypes);
  const canvasWidth = useCanvasWidth();

  const { collapsed, handleToggleLayer } = useCollapseController(id);

  return (
    <LayerNodeStyled id={id} $small={isSmall} $canvasWidth={canvasWidth}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ cursor: 'pointer' }}
        onClick={handleToggleLayer}
      >
        <StyledText collapsed={collapsed} variant="h3">
          {data.label}
        </StyledText>

        <CollapseIcon collapsed={collapsed} />
      </Stack>
    </LayerNodeStyled>
  );
}
