import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useProjectFilters } from '@context/ProjectFilters/ProjectFiltersProvider';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { useLogoutMutation } from '@store/services/auth';
import authorizedApi from '@store/services/authorizedApi';
import { loggedOut, selectTokens } from '@store/slices/authSlice';
import { clearProjectTabs, scenarioModeOff } from '@store/slices/projectSlice';
import { resetProjects } from '@store/slices/recentlyProjectsSlice';

const useLogout = (onSuccess?: () => void) => {
  const dispatch = useAppDispatch();
  const { handleDomainsChange, handleModeChange } = useProject();
  const { handleClearAllFilters } = useProjectFilters();
  const { accessToken, refreshToken } = useAppSelector(selectTokens);

  const [logout] = useLogoutMutation();

  return () => {
    logout({ IdToken: accessToken || '', RefreshToken: refreshToken || '' })
      .unwrap()
      .finally(() => {
        if (onSuccess) onSuccess();
        dispatch(loggedOut());
        dispatch(clearProjectTabs());
        dispatch(resetProjects());
        dispatch(authorizedApi.util.resetApiState());
        dispatch(scenarioModeOff());

        handleModeChange(Modes.BusinessAlignment);
        handleDomainsChange([]);
        handleClearAllFilters();
      });
  };
};

export default useLogout;
