import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

export const KeyControlsImpactWrapper = styled('div')`
  position: absolute;
  top: -16px;
  right: -12px;
  display: flex;
`;

export const KeyControlsImpactValueBlock = styled('span')<{
  $borderColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 58px;
  height: 20px;
  font-size: 12px;
  line-height: 16px;
  color: ${themePalette.grey[900]};
  font-weight: 500;
  border: 1px solid ${({ $borderColor }) => $borderColor};
  background-color: ${themePalette.white};
  border-radius: 4px;
`;
