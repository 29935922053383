import React, { FC } from 'react';
import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import { ContextualKeys } from '@components/EntityDrawers/drawers/Contextual/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledLinkInput from '@components/Inputs/controllers/ControlledLinkInput';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const SourcesInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => (
  <>
    <ControlledTextField
      name={createName('name')}
      label="Source name"
      placeholder="Source name"
      required
      disabled={viewOnly}
      multiline
    />

    <ControlledLinkInput
      name={createName('link')}
      label="Source link"
      placeholder="Source link"
      disabled={viewOnly}
      multiline
    />
  </>
);

const SmallSourcesGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={ContextualKeys.Sources}
      viewOnly={viewOnly}
      groupObject={NameLinkObject}
      {...getInputGroupsProps('source')}
    >
      {SourcesInputs}
    </ControlledInputGroups>
  );
};

export default SmallSourcesGroup;
