import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import Toolbox from '@views/Project/components/Toolbox/Toolbox';
import useSetupInitialMode from '@views/Project/hooks/useSetupInitialMode';
import useProjectRedirect from '@views/ProjectHome/useProjectRedirect';

export const ProjectHome = memo(() => {
  useSetupInitialMode();
  useProjectRedirect();

  return (
    <Stack sx={{ height: '100%', position: 'relative' }}>
      <Toolbox />
      <Outlet />
    </Stack>
  );
});
