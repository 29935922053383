import useProjectId from '@hooks/useProjectId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetOneProjectQuery } from '@store/services/projects';
import { IProject } from '@store/services/projects/types';

const useProjectData = (id?: string | typeof skipToken, refetch = false) => {
  const projectId = useProjectId();

  const { data, ...options } = useGetOneProjectQuery(
    id || projectId || skipToken,
    { refetchOnMountOrArgChange: refetch },
  );

  const project = (data ?? {}) as Partial<IProject>;

  return [project, options] as const;
};

export default useProjectData;
