import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ControlledRadioGroup from '@components/Inputs/controllers/ControlledRadioGroup';
import {
  CommonReportDescription,
  ExportAssetsSettingsDescription,
} from '@constants/entities/reports';
import useProjectId from '@hooks/useProjectId';
import { Stack } from '@mui/material';
import ExportAsContent from '@views/ReportsModel/components/ExportAsContent/ExportAsContent';
import { ModalWrapper } from '@views/ReportsModel/components/ExportAsContent/ExportAsContent.styled';
import { ModalProps } from '@views/ReportsModel/constants/types';
import { getFileData } from '@views/ReportsModel/helpers';
import useExportAssets from '@views/ReportsModel/hooks/useExportAssets';

type FormValues = {
  assets: 'all' | 'selected';
};

const options = [
  { label: 'Selected asset', value: 'selected' },
  { label: 'All assets', value: 'all' },
];

export const ExportAssetReportModal: FC<ModalProps> = (props) => {
  const { open, onClose } = props;
  const projectId = useProjectId(true);

  const form = useForm<FormValues>({
    defaultValues: { assets: 'selected' },
  });

  const {
    isError,
    setError,
    fileContent,
    setFileContent,
    fileName,
    isLoading,
    handleGenerate,
  } = useExportAssets(projectId, form.watch('assets'));

  useEffect(() => {
    if (open) {
      form.reset();
      setFileContent('');
      setError(false);
    }
  }, [open]);

  return (
    <ModalWrapper {...props} title="Export as report">
      <ExportAsContent
        files={[
          {
            isGenerating: isLoading,
            generatedFile: getFileData(fileContent, fileName),
            isError,
          },
        ]}
        description={({ isExportSettingsStep }) =>
          isExportSettingsStep
            ? ExportAssetsSettingsDescription
            : CommonReportDescription
        }
        onGenerateReport={handleGenerate}
        onClose={onClose}
      >
        <FormProvider {...form}>
          <Stack mt={4}>
            <ControlledRadioGroup options={options} name="assets" />
          </Stack>
        </FormProvider>
      </ExportAsContent>
    </ModalWrapper>
  );
};
