import React, { FC } from 'react';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { getNodeIcon } from '@utils/canvasIconHelpers';
import {
  IconWrapper,
  NodeInfoContent,
  NodeInfoWrapper,
} from '@views/ReportsModel/components/JustificationReport/components/NodeInfo/NodeInfo.styled';

type Props = {
  type: string;
  label: string;
  definition: string;
};

const NodeInfo: FC<Props> = ({ type, label, definition }) => {
  return (
    <NodeInfoWrapper>
      <NodeInfoContent>
        <IconWrapper>{getNodeIcon({ type })}</IconWrapper>

        <TypographyWithElipsis variant="h5">{label}</TypographyWithElipsis>
      </NodeInfoContent>

      <TypographyWithElipsis variant="body2" color="grey.900">
        {definition}
      </TypographyWithElipsis>
    </NodeInfoWrapper>
  );
};

export default NodeInfo;
