import { ConceptualSubLayerTypes } from '@constants/canvas/layers';

const NameFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]: 'Business attribute name',
  [ConceptualSubLayerTypes.SecurityAttributes]: 'Security attribute name',
};

const DefinitionFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]: 'Business attribute definition',
  [ConceptualSubLayerTypes.SecurityAttributes]: 'Security attribute definition',
};

const OwnerFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]: 'Business attribute owner',
  [ConceptualSubLayerTypes.SecurityAttributes]: 'Security attribute owner',
};

const MeasurementApproachesFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]:
    'Business attribute measurement approaches',
  [ConceptualSubLayerTypes.SecurityAttributes]:
    'Security attribute measurement approaches',
};

const MetricTypeFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]:
    'Business attribute metric type',
  [ConceptualSubLayerTypes.SecurityAttributes]:
    'Security attribute metric type',
};

const MetricExpectOutputs = {
  [ConceptualSubLayerTypes.BusinessAttributes]:
    'Business attribute metric expect outputs',
  [ConceptualSubLayerTypes.SecurityAttributes]:
    'Security attribute metric expect outputs',
};

const PerformanceTargetFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]:
    'Business attribute performance target',
  [ConceptualSubLayerTypes.SecurityAttributes]:
    'Security attribute performance target',
};

const PrimaryThresholdFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]:
    'Business attribute primary threshold',
  [ConceptualSubLayerTypes.SecurityAttributes]:
    'Security attribute primary threshold',
};

const SecondaryThresholdFieldTitles = {
  [ConceptualSubLayerTypes.BusinessAttributes]:
    'Business attribute secondary threshold',
  [ConceptualSubLayerTypes.SecurityAttributes]:
    'Security attribute secondary threshold',
};

export const getLabels = (subLayer: ConceptualSubLayerTypes) => {
  return {
    nameLabel: NameFieldTitles[subLayer],
    definitionLabel: DefinitionFieldTitles[subLayer],
    ownerLabel: OwnerFieldTitles[subLayer],
    measurementApproachesLabel: MeasurementApproachesFieldTitles[subLayer],
    metricTypeLabel: MetricTypeFieldTitles[subLayer],
    metricExpectOutputsLabel: MetricExpectOutputs[subLayer],
    performanceTargetLabel: PerformanceTargetFieldTitles[subLayer],
    primaryThresholdLabel: PrimaryThresholdFieldTitles[subLayer],
    secondaryThresholdLabel: SecondaryThresholdFieldTitles[subLayer],
  };
};
