import { Modes } from '@constants/canvas/layers';
import { onLoading } from '@store/helpers';
import authorizedApi from '@store/services/authorizedApi';
import { ICanvasNodesResponse } from '@store/services/nodes/types';
import { QueryTags } from '@store/services/tags';
import queryString from 'query-string';

import { IGetVersionsParams, IGetVersionsResponse } from './types';

const versionsApi = authorizedApi.injectEndpoints({
  endpoints: (builder) => ({
    getVersions: builder.query<IGetVersionsResponse, IGetVersionsParams>({
      query: (params) => ({
        url: `/versions?${queryString.stringify(params)}`,
      }),

      onQueryStarted: onLoading,
      providesTags: [QueryTags.ProjectVersions],
    }),

    getVersionsNodes: builder.query<
      ICanvasNodesResponse,
      { versionId: string; mode: Modes }
    >({
      query: ({ versionId, mode }) => ({
        url: `v2/versions/${versionId}/nodes/`,
        params: { model: mode },
      }),

      onQueryStarted: onLoading,
    }),

    restoreVersion: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/versions/${id}/restore`,
        method: 'POST',
      }),

      onQueryStarted: onLoading,
      invalidatesTags: [QueryTags.ProjectVersions],
    }),
  }),
});

export const {
  useGetVersionsQuery,
  useGetVersionsNodesQuery,
  useRestoreVersionMutation,
} = versionsApi;
