import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import { IVersion } from '@store/services/versions/types';
import { themePalette } from '@theme/muiTheme';
import { useVersionStatusInfo } from '@views/Project/components/Versions/hooks/useVersionsStatusInfo';

type Props = {
  version: IVersion;
};

export const VersionCardBottomPart = ({ version }: Props) => {
  return (
    <>
      <Box sx={{ display: 'flex', mt: 1 }}>
        <Avatar
          src={version.author?.avatar}
          sx={{
            width: 24,
            height: 24,
            backgroundColor: themePalette.grey[600],
          }}
        />
        <Typography variant="body1" color="grey.800" sx={{ ml: 1 }}>
          {version.author?.full_name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
        {useVersionStatusInfo(version)}
      </Box>
    </>
  );
};
