import React, { FC } from 'react';
import SelectorButton from '@components/selectors/core/SelectorButton';
import {
  ExportJustificationStatementSuccess,
  GenerateImageError,
} from '@constants/entities/reports';
import { useToast } from '@context/Toast/ToastProvider';
import useExportHTMLImage from '@hooks/useExportHTMLImage';
import useLoader from '@hooks/useLoader';
import { MenuItem, Popover } from '@mui/material';
import { downloadFile } from '@utils/helpers';
import { ReportExportIcon } from '@utils/iconsDefs';
import { getJustStatementFileName } from '@views/ReportsModel/helpers';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

type Props = {
  label: string;
};

const ExportButton: FC<Props> = ({ label }) => {
  const { showToast } = useToast();
  const { generateImage, isGenerating } = useExportHTMLImage();
  useLoader(isGenerating, 'justification-statement-export');

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'justificationReport',
  });

  const handleExportClick = async () => {
    try {
      popupState.close();

      const images = await Promise.all(
        Array.from(
          document.querySelectorAll<HTMLElement>('.statement-report'),
        ).map((element) => generateImage(element)),
      );

      images.forEach((url, index) => {
        downloadFile(url, getJustStatementFileName(label, index));
      });

      showToast(ExportJustificationStatementSuccess, 'success');
    } catch (e) {
      showToast(GenerateImageError, 'error');
    }
  };

  return (
    <>
      <SelectorButton
        title="Export as"
        icon={<ReportExportIcon />}
        open={popupState.isOpen}
        {...bindTrigger(popupState)}
      />

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{ paper: { sx: { width: 220, mt: 3 } } }}
      >
        <MenuItem onClick={handleExportClick}>Image (.png)</MenuItem>
      </Popover>
    </>
  );
};

export default ExportButton;
