import { ConceptualContent } from '@components/EntityDrawers/drawers/Conceptual/content';
import { ConceptualFormValues } from '@components/EntityDrawers/drawers/Conceptual/form';
import { ConceptualSettings } from '@components/EntityDrawers/drawers/Conceptual/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import withTabs from '@components/EntityDrawers/hocs/withTabs';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { ConceptualMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddConceptualEntity
  | ProjectDrawerTypes.EditConceptualEntity;

export const ConceptualDrawer = withTabs<Type>(
  withDrawer<ConceptualFormValues, ConceptualMetaData, Type>(
    ConceptualContent,
    ConceptualSettings,
  ),
);
