import { Box } from '@mui/material';
import styled from 'styled-components';

export const Wrapper = styled(Box)`
  width: 100%;
  height: 96px;
  border: 2px solid #616161;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: rgba(2, 22, 21, 0.6);
`;
