import { Stack } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { styled } from 'styled-components';

export const Wrapper = styled(Stack)`
  flex: 1;
  position: relative;
  top: -132px;
  max-width: 988px;
`;

export const ExportViewWrapper = styled(Stack)`
  padding: 24px;
  background: ${themePalette.white};
`;

export const SlidesBox = styled('div')<{
  $slides: number;
  $current: number;
}>`
  display: flex;
  transform: translateX(-${(props) => (props.$current * 100) / props.$slides}%);
  overflow: hidden;
  width: ${(props) => props.$slides * 100}%;
  transition: transform 0.5s;
  max-height: 345px;
`;

export const ContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
  height: 344px;
`;

export const DoughnutWrapper = styled('div')`
  position: relative;
  width: 340px;
  height: 340px;
  padding: 16px;
`;

export const DoughnutTitle = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const ListWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 300px;
`;

export const ListItemStyledWrapper = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ListItemStyled = styled('div')`
  width: 250px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 14px;
  border: 1px solid ${themePalette.grey[500]};
  background-color: ${themePalette.grey[400]};
  border-radius: 12px;
  text-align: center;
  margin: 0 4px;
`;
