import React, { FC } from 'react';
import { SuccessfullyUploadedStats } from '@components/UploadModal/types';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { UploadFileStatusSuccessItem } from './UploadFileStatusSuccessItem';

export type UploadFileStatusSuccessProps = SuccessfullyUploadedStats;

export const UploadFileStatusSuccess: FC<UploadFileStatusSuccessProps> = ({
  title,
  items,
}) => (
  <Stack direction="column" gap={2}>
    <Typography variant="h4">{title}</Typography>

    <Stack direction="row" gap={3}>
      {items.map((el, i) => (
        <UploadFileStatusSuccessItem
          key={el.text}
          text={el.text}
          value={el.value}
          withDivider={i < items.length - 1}
        />
      ))}
    </Stack>
  </Stack>
);
