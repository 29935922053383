import React from 'react';
import { useFormContext } from 'react-hook-form';
import useImpactOptions from '@components/EntityDrawers/drawers/Risk/hooks/useImpactOptions';
import useRiskScore from '@components/EntityDrawers/drawers/Risk/hooks/useRiskScore';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';

const InherentImpact = () => {
  const { viewOnly } = useProject();
  const { watch, setValue, trigger } = useFormContext();
  const { calculateRiskScore } = useRiskScore();
  const options = useImpactOptions();

  const handleScoreCalculate = (impact: string) => {
    const score = calculateRiskScore({
      likelihood: watch(RiskKeys.InherentLikelihood),
      impact,
    });

    setValue(RiskKeys.InherentScore, score);

    if (!watch(RiskKeys.HasCapability)) {
      setValue(RiskKeys.ResidualImpact, impact);
      setValue(RiskKeys.ResidualScore, score);
    }

    trigger(RiskKeys.ResidualScore);
  };

  return (
    <ControlledTextFieldSelect
      name={RiskKeys.InherentImpact}
      label="Inherent risk impact"
      placeholder="Choose inherent risk impact"
      disabled={viewOnly}
      options={options}
      resetOnDoubleClick
      required
      changeHandler={handleScoreCalculate}
    />
  );
};

export default InherentImpact;
