import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectData from '@hooks/useProjectData';
import useProjectId from '@hooks/useProjectId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetAssetsListQuery } from '@store/services/nodes';

const useAssetsList = () => {
  const [{ subscription }] = useProjectData();
  const id = useProjectId(true);
  const { activeVersionId } = useProject();

  const riskManagementModeAvailable = subscription?.modes.includes(
    Modes.RiskManagement,
  );

  const { data, isLoading, isFetching } = useGetAssetsListQuery(
    riskManagementModeAvailable
      ? { projectId: id, versionId: activeVersionId ?? undefined }
      : skipToken,
  );

  return { assetOptions: data ?? [], isLoading: isLoading || isFetching };
};

export default useAssetsList;
