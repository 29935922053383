import { configureStore } from '@reduxjs/toolkit';
import authorizedApi from '@store/services/authorizedApi';
import baseApi from '@store/services/baseApi';
import assetsReducer from '@store/slices/assetsSlice';
import authReducer from '@store/slices/authSlice';
import { deleteRelationSlice } from '@store/slices/deleteRelationSlice';
import projectActivityHistoryReducer from '@store/slices/projectActivityHistorySlice';
import projectReducer from '@store/slices/projectSlice';
import recentlyProjectsSlice from '@store/slices/recentlyProjectsSlice';
import uiReducer from '@store/slices/uiSlice';
import userActivityHistoryReducer from '@store/slices/userActivityHistorySlice';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  auth: authReducer,
  ui: uiReducer,
  project: projectReducer,
  assets: assetsReducer,
  userActivityHistory: userActivityHistoryReducer,
  projectActivityHistory: projectActivityHistoryReducer,
  [deleteRelationSlice.name]: deleteRelationSlice.reducer,
  [recentlyProjectsSlice.name]: recentlyProjectsSlice.reducer,
  [authorizedApi.reducerPath]: authorizedApi.reducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'project', 'assets'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
      .concat(authorizedApi.middleware)
      .concat(baseApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
