import React, { useMemo } from 'react';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectSettings from '@hooks/useProjectSettings';
import { transformArrayToOptions } from '@utils/helpers';

const TaxonomyDropdown = () => {
  const { viewOnly } = useProject();

  const {
    settings: { risk_taxonomy },
  } = useProjectSettings();

  const options: ISelectOption[] = useMemo(() => {
    return transformArrayToOptions(risk_taxonomy ?? []);
  }, [risk_taxonomy]);

  return (
    <ControlledTextFieldSelect
      name={RiskKeys.Taxonomy}
      label="Risk taxonomy"
      placeholder="Choose risk taxonomy"
      disabled={viewOnly}
      options={options}
      resetOnDoubleClick
    />
  );
};

export default TaxonomyDropdown;
