import useAssets from '@hooks/useAssets';
import useCurrentAsset from '@hooks/useCurrentAsset';
import { useGenerateAssetsReportMutation } from '@store/services/reports';
import { getRiskAssessmentFileName } from '@views/ReportsModel/helpers';
import useExportState from '@views/ReportsModel/hooks/useExportState';

const useExportAssets = (
  projectId: string,
  assetVariant: 'selected' | 'all',
) => {
  const { isError, setError, fileContent, setFileContent } = useExportState();

  const { selectedAssetId } = useAssets();
  const { dto: asset } = useCurrentAsset();

  const [generateAssetsReport, { isLoading, reset }] =
    useGenerateAssetsReportMutation();

  const handleGenerate = async () => {
    try {
      const excelData = await generateAssetsReport({
        projectId,
        asset_id:
          assetVariant === 'all' ? undefined : selectedAssetId ?? undefined,
      }).unwrap();

      setFileContent(excelData);
    } catch (e) {
      setError(true);
    }
  };

  const fileName = getRiskAssessmentFileName(
    assetVariant === 'selected' ? asset?.name : undefined,
  );

  return {
    isError,
    fileContent,
    isLoading,
    setFileContent,
    setError,
    handleGenerate,
    fileName,
    reset,
  };
};

export default useExportAssets;
