import React, { useMemo } from 'react';
import MenuItemDeletable from '@components/Inputs/TextFieldSelect/items/MenuItemDeletable';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
} from '@components/Inputs/TextFieldSelect/TextFieldSelect';
import DeleteAssetModal from '@components/MainStage/ContextMenu/NodeContextMenu/DeleteAssetModal';
import useDeleteAsset from '@components/MainStage/hooks/useDeleteAsset';
import { useProject } from '@context/Project/ProjectProvider';
import useAssets from '@hooks/useAssets';
import useCanEditModel from '@hooks/useCanEditModel';
import { Stack, Typography } from '@mui/material';
import useAssetsList from '@views/Project/hooks/useAssetsList';

const ChooseAsset = () => {
  const { handleCloseDrawer, isVersionsShown } = useProject();
  const { selectedAssetId, selectAsset, resetNewAssetMode, isNewAssetMode } =
    useAssets();

  const { assetOptions, isLoading } = useAssetsList();
  const canEditModel = useCanEditModel();
  const showDeleteBtn = canEditModel && !isVersionsShown;

  const handleSelectAsset = async (assetId: string) => {
    await handleCloseDrawer();

    resetNewAssetMode();
    selectAsset(assetId);
  };

  const {
    isConfirmDeleteAssetOpen,
    confirmDeleteAssetControls,
    handleDeleteAsset,
    deleteAssetName,
  } = useDeleteAsset();

  const value = useMemo(() => {
    if (isNewAssetMode || !selectedAssetId) return DEFAULT_PLACEHOLDER_VALUE;

    return selectedAssetId;
  }, [selectedAssetId, isNewAssetMode]);

  return (
    <>
      <DeleteAssetModal
        open={isConfirmDeleteAssetOpen}
        onClose={confirmDeleteAssetControls.off}
        assetName={deleteAssetName}
        onConfirm={handleDeleteAsset}
      />

      <Stack gap={1}>
        <Typography variant="h5">Choose an asset</Typography>

        <TextFieldSelect
          value={value}
          onChange={(event) => handleSelectAsset(event.target.value)}
          placeholder="No asset"
          options={assetOptions}
          itemComponent={showDeleteBtn ? MenuItemDeletable : undefined}
          itemProps={({ value }) => ({
            onDelete: () => confirmDeleteAssetControls.on(value as string),
          })}
          disabled={isLoading || isNewAssetMode}
          maxMenuWidth={210}
        />
      </Stack>
    </>
  );
};

export default ChooseAsset;
