import { onLoading } from '@store/helpers';
import {
  ILoginRequest,
  ILoginResponse,
  IUser,
} from '@store/services/auth/types';
import authorizedApi from '@store/services/authorizedApi';
import baseApi from '@store/services/baseApi';
import { QueryTags } from '@store/services/tags';
import { initProjects } from '@store/slices/recentlyProjectsSlice';
import { createParamsString } from '@utils/helpers';

const authBaseApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    signIn: builder.mutation<ILoginResponse, ILoginRequest>({
      query: (body) => ({
        url: '/user/login/',
        method: 'POST',
        body,
      }),

      onQueryStarted: (_, { dispatch, queryFulfilled, requestId }) => {
        onLoading(_, { dispatch, queryFulfilled, requestId });

        queryFulfilled.then(() => {
          dispatch(authApi.util.invalidateTags([QueryTags.User]));
        });
      },
    }),

    oAuth: builder.mutation<ILoginResponse, { code: string }>({
      query: (body) => ({
        url: '/user/oauth/',
        method: 'POST',
        body,
      }),

      onQueryStarted: (_, { dispatch, queryFulfilled, requestId }) => {
        onLoading(_, { dispatch, queryFulfilled, requestId });

        queryFulfilled.then(() => {
          dispatch(authApi.util.invalidateTags([QueryTags.User]));
        });
      },
    }),
  }),
});

export const authApi = authorizedApi.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<IUser, void>({
      query: () => '/user/me/',
      providesTags: [QueryTags.User],

      onQueryStarted: async (_, { dispatch, queryFulfilled, requestId }) => {
        onLoading(_, { dispatch, queryFulfilled, requestId });
        const { data } = await queryFulfilled;

        dispatch(initProjects(data.recently_projects));
      },
    }),

    checkUserEmail: builder.mutation<{ exists: boolean }, string>({
      query: (email: string) => ({
        url: `/user/check-email?${createParamsString({ email })}`,
        method: 'GET',
      }),
    }),

    logout: builder.mutation<void, ILoginResponse>({
      query: (body) => ({ url: '/user/logout/', method: 'POST', body }),
      onQueryStarted: onLoading,
    }),
  }),
});

export const { useSignInMutation, useOAuthMutation } = authBaseApi;
export const { useGetMeQuery, useLogoutMutation, useCheckUserEmailMutation } =
  authApi;
