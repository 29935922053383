import React from 'react';
import { BudgetDistribution } from '@store/services/dashboards/types';
import {
  CapabilitiesNodeIcon,
  FunctionsNodeIcon,
  ServicesIcon,
} from '@utils/iconsDefs';

export enum BusinessDashboardDistribution {
  PerDomain = 'Per domain',
  PerSE = 'Per strategic enabler',
}

export enum BusinessAlignmentTabs {
  BusinessAttributes,
  SecurityAttributes,
  ChangeInitiatives,
}

export const businessDashboardDistributionOptions = [
  {
    value: BusinessDashboardDistribution.PerDomain,
    label: BusinessDashboardDistribution.PerDomain,
  },
  {
    value: BusinessDashboardDistribution.PerSE,
    label: BusinessDashboardDistribution.PerSE,
  },
];

export const investmentsDistributionOptions = [
  {
    value: BudgetDistribution.PerStrategicEnabler,
    label: BudgetDistribution.PerStrategicEnabler,
  },
  {
    value: BudgetDistribution.PerService,
    label: BudgetDistribution.PerService,
  },
];

export enum ControlsTabs {
  Services,
  Capabilities,
  Functions,
  ChangeInitiatives,
}

export type ControlsCommonTabs =
  | ControlsTabs.Services
  | ControlsTabs.Capabilities
  | ControlsTabs.Functions;

export enum RiskManagmentTabs {
  Assets,
  Risks,
  RemediationActions,
}

export enum RemActionsDistribution {
  PerDomain = 'Per domain',
  PerService = 'Per service',
}

export const remActionsDistributionOptions = [
  {
    value: RemActionsDistribution.PerDomain,
    label: RemActionsDistribution.PerDomain,
  },
  {
    value: RemActionsDistribution.PerService,
    label: RemActionsDistribution.PerService,
  },
];

export enum DistributionKeys {
  BusinessAttributesDistribution = 'business-attributes-distribution',
  SecurityAttributesDistribution = 'security-attributes-distribution',
  InvestmentsDistribution = 'investments-distribution',
  MaturityGap = 'maturity-gap',
  CapabilityToTechnology = 'capability-to-technology',
  AssetsDistribution = 'assets-distribution',
  RiskDistribution = 'risk-distribution',
  RemediationActionsDistribution = 'remediation-actions-distribution',
}

export const DistributionTitles = {
  [DistributionKeys.BusinessAttributesDistribution]:
    'Business attributes distribution',
  [DistributionKeys.SecurityAttributesDistribution]:
    'Security attributes distribution',
  [DistributionKeys.InvestmentsDistribution]: 'Investments distribution',
  [DistributionKeys.MaturityGap]: 'Maturity gap analysis',
  [DistributionKeys.CapabilityToTechnology]: 'Capability to technology mapping',
  [DistributionKeys.AssetsDistribution]: 'Assets distribution',
  [DistributionKeys.RiskDistribution]: 'Risk distribution',
  [DistributionKeys.RemediationActionsDistribution]:
    'Remediation actions distribution',
};

export const ControlsTabToDataKey = {
  [ControlsTabs.Services]: 'service',
  [ControlsTabs.Capabilities]: 'capability',
  [ControlsTabs.Functions]: 'function',
  [ControlsTabs.ChangeInitiatives]: 'change-initiative',
} as const;

export const MaturityGapColorsRanges: Record<string, [number, number]> = {
  green: [0, 2.49],
  yellow: [2.5, 3.99],
  red: [4, 5],
};

export const MaturityTitles = {
  [ControlsTabs.Services]: 'Service name',
  [ControlsTabs.Capabilities]: 'Capability name',
  [ControlsTabs.Functions]: 'Function name',
};

export const MaturityIcons = {
  [ControlsTabs.Services]: <ServicesIcon style={{ minWidth: 24 }} />,
  [ControlsTabs.Capabilities]: (
    <CapabilitiesNodeIcon style={{ minWidth: 24 }} />
  ),
  [ControlsTabs.Functions]: <FunctionsNodeIcon style={{ minWidth: 24 }} />,
};
