import { LayerTypes } from '@constants/canvas/layers';

export enum ProjectDrawerTypes {
  AddContextualEntity = 'add-contextual-entity',
  EditContextualEntity = 'edit-contextual-entity',

  AddConceptualEntity = 'add-conceptual-entity',
  EditConceptualEntity = 'edit-conceptual-entity',

  AddLogicalEntity = 'add-logical-entity',
  EditLogicalEntity = 'edit-logical-entity',

  AddPhysicalEntity = 'add-physical-entity',
  EditPhysicalEntity = 'edit-physical-entity',

  AddComponentEntity = 'add-component-entity',
  EditComponentEntity = 'edit-component-entity',

  AddInitiativesEntity = 'add-initiatives-entity',
  EditInitiativesEntity = 'edit-initiatives-entity',

  AddAssessmentScopeEntity = 'add-assessment-scope-entity',
  EditAssessmentScopeEntity = 'edit-assessment-scope-entity',

  AddSensitiveElementEntity = 'add-sensitive-element-entity',
  EditSensitiveElementEntity = 'edit-sensitive-element-entity',

  AddAssociatedRiskEntity = 'add-associated-risk-entity',
  EditAssociatedRiskEntity = 'edit-associated-risk-entity',

  AddRelationship = 'add-relationship',
  EditRelationship = 'edit-relationship',

  Insights = 'insights',
}

export const AddNodeDrawerMap = {
  [LayerTypes.Contextual]: ProjectDrawerTypes.AddContextualEntity,
  [LayerTypes.Conceptual]: ProjectDrawerTypes.AddConceptualEntity,
  [LayerTypes.Logical]: ProjectDrawerTypes.AddLogicalEntity,
  [LayerTypes.Physical]: ProjectDrawerTypes.AddPhysicalEntity,
  [LayerTypes.Component]: ProjectDrawerTypes.AddComponentEntity,
  [LayerTypes.Initiatives]: ProjectDrawerTypes.AddInitiativesEntity,
  [LayerTypes.AssessmentScope]: ProjectDrawerTypes.AddAssessmentScopeEntity,
  [LayerTypes.SensitiveElements]: ProjectDrawerTypes.AddSensitiveElementEntity,
  [LayerTypes.AssociatedRisks]: ProjectDrawerTypes.AddAssociatedRiskEntity,
  [LayerTypes.RemediationAction]: ProjectDrawerTypes.AddInitiativesEntity,
};

export const EditNodeDrawerMap = {
  [LayerTypes.Contextual]: ProjectDrawerTypes.EditContextualEntity,
  [LayerTypes.Conceptual]: ProjectDrawerTypes.EditConceptualEntity,
  [LayerTypes.Logical]: ProjectDrawerTypes.EditLogicalEntity,
  [LayerTypes.Physical]: ProjectDrawerTypes.EditPhysicalEntity,
  [LayerTypes.Component]: ProjectDrawerTypes.EditComponentEntity,
  [LayerTypes.Initiatives]: ProjectDrawerTypes.EditInitiativesEntity,
  [LayerTypes.AssessmentScope]: ProjectDrawerTypes.EditAssessmentScopeEntity,
  [LayerTypes.SensitiveElements]: ProjectDrawerTypes.EditSensitiveElementEntity,
  [LayerTypes.AssociatedRisks]: ProjectDrawerTypes.EditAssociatedRiskEntity,
  [LayerTypes.RemediationAction]: ProjectDrawerTypes.EditInitiativesEntity,
};
