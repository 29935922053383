import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import {
  defaultValues,
  RiskFields,
  RiskFormValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Risk/form';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { StewardsOpinion } from '@constants/canvas/layers';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { RiskMetaData } from '@store/services/nodes/types';
import { hasRiskCapabilityRelation } from '@utils/canvasHelpers';
import {
  add,
  checkValuesIncomplete,
  transformCommonKeys,
} from '@utils/drawers';
import dayjs from 'dayjs';

export const RiskSettings: DrawerSettings<RiskFormValues, RiskMetaData> = {
  editType: ProjectDrawerTypes.EditAssociatedRiskEntity,
  defaultValues: ({ edges, nodes, activeNode }) => {
    const hasCapabilityConnection = hasRiskCapabilityRelation(
      activeNode?.id,
      edges,
      nodes,
    );

    return {
      ...defaultValues,
      [RiskKeys.HasCapability]: hasCapabilityConnection,
    };
  },
  validationSchema,
  checkIncompleteFields: checkValuesIncomplete<RiskFormValues>(
    CommonKeys.Name,
    CommonKeys.Owner,
    RiskKeys.Assessor,
    RiskKeys.DateIdentified,
    RiskKeys.InherentLikelihood,
    RiskKeys.InherentImpact,
    RiskKeys.InherentJustification,
    RiskKeys.ResidualLikelihood,
    RiskKeys.ResidualImpact,
    RiskKeys.ResidualJustification,
    RiskKeys.RiskAction,
  ),
  transformNodeDtoToFormValues: (nodeDto, context) => {
    const { meta_data, id } = nodeDto;
    const {
      inherent_likelihood,
      inherent_impact,
      residual_likelihood,
      residual_impact,
    } = meta_data;

    const {
      settings: { risk_assessment },
    } = context;

    const hasCapabilityConnection = hasRiskCapabilityRelation(
      id,
      context.edges,
      context.nodes,
    );

    const inherentScore =
      inherent_likelihood !== undefined && inherent_impact !== undefined
        ? risk_assessment?.matrix[inherent_likelihood]?.[inherent_impact]
        : 0;

    const residualScore = hasCapabilityConnection
      ? risk_assessment?.matrix[residual_likelihood]?.[residual_impact] ?? 0
      : inherentScore;

    return {
      ...transformCommonKeys<RiskFormValues, RiskMetaData>(...RiskFields)(
        nodeDto,
        context,
      ),

      [RiskKeys.Taxonomy]: meta_data.taxonomy ?? null,
      [RiskKeys.Assessor]: meta_data.assessor ?? '',
      [RiskKeys.Steward]: meta_data.steward ?? '',
      [RiskKeys.StewardOpinion]:
        meta_data.steward_opinion ?? StewardsOpinion.Pending,
      [RiskKeys.StewardJustificationNote]:
        meta_data.steward_justification_note ?? '',

      [RiskKeys.DateIdentified]: meta_data.date_identified
        ? dayjs(meta_data.date_identified)
        : null,
      [CommonKeys.DateReviewed]: meta_data.date_reviewed
        ? dayjs(meta_data.date_reviewed)
        : null,

      [RiskKeys.InherentLikelihood]: inherent_likelihood?.toString() ?? '',
      [RiskKeys.InherentImpact]: inherent_impact?.toString() ?? '',
      [RiskKeys.InherentJustification]: meta_data.inherent_justification ?? '',
      [RiskKeys.InherentScore]: inherentScore,

      [RiskKeys.ResidualLikelihood]:
        (hasCapabilityConnection
          ? residual_likelihood
          : inherent_likelihood
        )?.toString() ?? '',

      [RiskKeys.ResidualImpact]:
        (hasCapabilityConnection
          ? residual_impact
          : inherent_impact
        )?.toString() ?? '',

      [RiskKeys.ResidualJustification]:
        meta_data[
          hasCapabilityConnection
            ? 'residual_justification'
            : 'inherent_justification'
        ] ?? '',

      [RiskKeys.ResidualScore]: residualScore,

      [RiskKeys.RiskAction]: meta_data.risk_action ?? null,

      [RiskKeys.HasCapability]: hasCapabilityConnection,
    };
  },
  transformMetaDataToRequestBody: (values) => ({
    ...add(!!values[RiskKeys.Taxonomy], {
      taxonomy: values[RiskKeys.Taxonomy],
    }),
    ...add(!!values[RiskKeys.Assessor], {
      assessor: values[RiskKeys.Assessor],
    }),
    ...add(!!values[RiskKeys.Steward], {
      steward: values[RiskKeys.Steward],
    }),
    ...add(!!values[RiskKeys.StewardOpinion], {
      steward_opinion: values[RiskKeys.StewardOpinion],
    }),
    ...add(!!values[RiskKeys.StewardJustificationNote], {
      steward_justification_note: values[RiskKeys.StewardJustificationNote],
    }),

    ...add(!!values[RiskKeys.DateIdentified]?.isValid(), {
      date_identified: values[RiskKeys.DateIdentified],
    }),
    ...add(!!values[CommonKeys.DateReviewed]?.isValid(), {
      date_reviewed: values[CommonKeys.DateReviewed],
    }),

    ...add(!!values[RiskKeys.InherentLikelihood], {
      inherent_likelihood: Number(values[RiskKeys.InherentLikelihood]),
    }),
    ...add(!!values[RiskKeys.InherentImpact], {
      inherent_impact: Number(values[RiskKeys.InherentImpact]),
    }),
    ...add(!!values[RiskKeys.InherentJustification], {
      inherent_justification: values[RiskKeys.InherentJustification],
    }),

    ...add(!!values[RiskKeys.ResidualLikelihood], {
      residual_likelihood: Number(values[RiskKeys.ResidualLikelihood]),
    }),
    ...add(!!values[RiskKeys.ResidualImpact], {
      residual_impact: Number(values[RiskKeys.ResidualImpact]),
    }),
    ...add(!!values[RiskKeys.ResidualJustification], {
      residual_justification: values[RiskKeys.ResidualJustification],
    }),

    ...add(!!values[RiskKeys.RiskAction], {
      risk_action: values[RiskKeys.RiskAction],
    }),
  }),
};
