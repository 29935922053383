import React from 'react';
import useRiskScore from '@components/EntityDrawers/drawers/Risk/hooks/useRiskScore';
import useScoreColor from '@components/EntityDrawers/drawers/Risk/hooks/useScoreColor';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import {
  RiskScoreBlock,
  RiskScoreWrapper,
} from '@components/MainStage/CustomNode/CustomNode.styled';
import useStore from '@components/MainStage/store';
import { themePalette } from '@theme/muiTheme';
import { hasRiskCapabilityRelation } from '@utils/canvasHelpers';

const riskScoreColor: Record<string, string> = {
  grey: themePalette.system.success,
  green: themePalette.system.success,
  yellow: themePalette.system.alert,
  red: themePalette.system.error,
};

const formatScoreData = (value: any) =>
  value !== undefined ? value.toString() : '';

export const RiskScore = ({ data }: any) => {
  const {
    dto: { id, meta_data },
  } = data;

  const { nodes, edges } = useStore();
  const { calculateRiskScore } = useRiskScore();

  const hasCapabilityConnection = hasRiskCapabilityRelation(id, edges, nodes);

  const inherentScoreOptions = {
    likelihood: formatScoreData(meta_data[RiskKeys.InherentLikelihood]),
    impact: formatScoreData(meta_data[RiskKeys.InherentImpact]),
  };

  const residualScoreOptions = hasCapabilityConnection
    ? {
        likelihood: formatScoreData(meta_data[RiskKeys.ResidualLikelihood]),
        impact: formatScoreData(meta_data[RiskKeys.ResidualImpact]),
      }
    : inherentScoreOptions;

  const inherentImpactScore = calculateRiskScore(inherentScoreOptions);
  const residualImpactScore = calculateRiskScore(residualScoreOptions);

  const inherentImpactScoreColor = useScoreColor(inherentImpactScore);
  const residualImpactScoreColor = useScoreColor(residualImpactScore);

  const shouldRenderScores =
    inherentImpactScore !== 0 && residualImpactScore !== 0;

  return shouldRenderScores ? (
    <RiskScoreWrapper>
      {inherentImpactScoreColor && (
        <RiskScoreBlock
          $riskScoreColor={riskScoreColor[inherentImpactScoreColor]}
        >
          {inherentImpactScore}
        </RiskScoreBlock>
      )}
      {residualImpactScoreColor && (
        <RiskScoreBlock
          $riskScoreColor={riskScoreColor[residualImpactScoreColor]}
        >
          {residualImpactScore}
        </RiskScoreBlock>
      )}
    </RiskScoreWrapper>
  ) : null;
};
