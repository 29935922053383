import React, { FC, PropsWithChildren, useMemo, useState } from 'react';
import { DistributionKeys } from '@constants/entities/project-dashboard';
import { DashboardContext } from '@context/DashboardContext/context';
import { initialPerValue, PerValue } from '@context/DashboardContext/types';
import { useProject } from '@context/Project/ProjectProvider';
import useTabs from '@hooks/useTabs';
import useToggle from '@hooks/useToggle';

const DashboardProvider: FC<PropsWithChildren> = ({ children }) => {
  const {
    toolbox: { mode },
  } = useProject();

  const [isExportOpen, { on, off, setData }, exportDistribution] =
    useToggle<DistributionKeys>();

  const closeExportModal = () => {
    off();
    setData(null);
  };

  const [perValue, setPerValue] = useState<PerValue>(null);
  const { tab, changeTab } = useTabs();

  const handleChangeTab = (newTab: number) => {
    if (mode) {
      setPerValue(initialPerValue[mode][newTab]);
    }

    setData(null);
    changeTab({} as any, newTab);
  };

  const openExportModal = (distribution?: DistributionKeys) => {
    setData(distribution ?? null);
    on();
  };

  const value = useMemo(
    () => ({
      tab,
      handleChangeTab,
      perValue,
      changePerValue: setPerValue,
      openExportModal,
      exportDistribution,
      isExportOpen,
      closeExportModal,
    }),
    [
      tab,
      handleChangeTab,
      perValue,
      setPerValue,
      openExportModal,
      exportDistribution,
      isExportOpen,
      closeExportModal,
    ],
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
