import useProjectSettings from '@hooks/useProjectSettings';

type Options = {
  likelihood: string;
  impact: string;
};

const useRiskScore = () => {
  const {
    settings: { risk_assessment },
  } = useProjectSettings();

  const calculateRiskScore = ({ likelihood, impact }: Options) => {
    if (!risk_assessment) return 0;
    if (!likelihood || !impact) return 0;

    return risk_assessment?.matrix[Number(likelihood)][Number(impact)] ?? 0;
  };

  return { calculateRiskScore };
};

export default useRiskScore;
