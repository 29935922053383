import React, { FC } from 'react';
import { ListItemProps } from '@components/Inputs/Autocomplete/types';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { Checkbox, MenuItem } from '@mui/material';

const BaseListItem: FC<ListItemProps> = ({
  props: { className, ...props },
  option,
  state: { selected },
  multiple,
}) => {
  return (
    <MenuItem
      component="li"
      sx={{ color: selected ? 'grey.1000' : 'grey.900' }}
      {...props}
    >
      {multiple && <Checkbox checked={selected} />}

      <TypographyWithElipsis
        variant="body1"
        color="inherit"
        sx={{ width: '100%' }}
      >
        {option.label}
      </TypographyWithElipsis>
    </MenuItem>
  );
};

export default BaseListItem;
