import authorizedApi from '@store/services/authorizedApi';
import {
  IGetQnousRefLogResponse,
  IGetQnousRefLogsRequest,
  IGetQnousRefsParams,
  IQnousRefResponse,
  IUploadQnousRefResponse,
} from '@store/services/qnous-ref/types';
import { QueryTags } from '@store/services/tags';

const qnousRefApi = authorizedApi.injectEndpoints({
  endpoints: (builder) => ({
    getQnousRefs: builder.query<IQnousRefResponse, IGetQnousRefsParams | void>({
      query: (params) => ({
        url: `/qnous_ref/`,
        params,
      }),
      providesTags: [QueryTags.QnousRefLogs],
    }),

    getQnousRefLogs: builder.query<
      IGetQnousRefLogResponse,
      IGetQnousRefLogsRequest
    >({
      query: (params) => ({
        url: '/qnous_ref/logs',
        params,
      }),
      providesTags: [QueryTags.QnousRefLogs],
    }),

    uploadQnousRefFile: builder.mutation<
      IUploadQnousRefResponse,
      { file: File }
    >({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: '/qnous_ref/import',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [QueryTags.QnousRefLogs],
    }),
  }),
});

export const {
  useGetQnousRefsQuery,
  useGetQnousRefLogsQuery,
  useUploadQnousRefFileMutation,
} = qnousRefApi;
