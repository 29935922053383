import React, { useCallback, useRef } from 'react';
import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { NodesTypes } from '@constants/canvas/general';
import useAssets from '@hooks/useAssets';
import useProjectId from '@hooks/useProjectId';
import { useUpdateNodePositionMutation } from '@store/services/nodes';
import NodeView from '@utils/canvas/NodeView';

const useDragNode = ({
  menu,
  closeMenu,
}: {
  menu: any;
  closeMenu: () => void;
}) => {
  const nodeDragRef = useRef<Node | null>(null);
  const [updateNodePositionRequest] = useUpdateNodePositionMutation();
  const { selectedAssetId } = useAssets();
  const projectId = useProjectId(true);

  const { updateNodePosition, unselectActiveEdge, unselectActiveNode } =
    useStore();

  const onNodeDragStart = useCallback(
    (_: React.MouseEvent, node: Node) => {
      unselectActiveEdge();
      unselectActiveNode();
      if (menu) closeMenu();
      nodeDragRef.current = node;
    },
    [menu],
  );

  const onNodeDragStop = useCallback(
    (_: React.MouseEvent, node: Node) => {
      if (node.type === NodesTypes.NewNode) return;

      const nodeView = new NodeView(node.id);

      if (nodeDragRef.current) {
        updateNodePosition(nodeDragRef.current.id, {
          y: nodeView.YPosition,
        });

        const updatedNode = {
          id: node.id,
          x: node.position.x,
          y: nodeView.YPosition,
        };

        const requestParams = {
          projectId,
          ...(selectedAssetId && { asset_id: selectedAssetId }),
          nodes: [updatedNode],
        };

        updateNodePositionRequest(requestParams).unwrap();
      }

      nodeDragRef.current = null;
    },
    [projectId, selectedAssetId],
  );

  return { onNodeDragStart, onNodeDragStop };
};

export default useDragNode;
