import authorizedApi from '@store/services/authorizedApi';
import { createParamsString } from '@utils/helpers';

import { IAssetsReportRequest, ILayersReportRequest } from './types';

const reportsApi = authorizedApi.injectEndpoints({
  endpoints: (builder) => ({
    generateLayersReport: builder.mutation<string, ILayersReportRequest>({
      query: ({ projectId, layers }) => ({
        url: `projects/${projectId}/export/?${createParamsString({ layers })}`,
        method: 'GET',
        responseHandler: (response: Response) => response.arrayBuffer(),
      }),
    }),

    generateAssetsReport: builder.mutation<string, IAssetsReportRequest>({
      query: ({ projectId, ...params }) => ({
        url: `projects/${projectId}/export/asset/`,
        params,
        method: 'GET',
        responseHandler: (response: Response) => response.arrayBuffer(),
      }),
    }),

    generateRegisterReport: builder.mutation<string, string>({
      query: (projectId) => ({
        url: `projects/${projectId}/export/risk-register/`,
        method: 'GET',
        responseHandler: (response: Response) => response.arrayBuffer(),
      }),
    }),
  }),
});

export const {
  useGenerateLayersReportMutation,
  useGenerateAssetsReportMutation,
  useGenerateRegisterReportMutation,
} = reportsApi;
