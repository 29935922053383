import { useAppDispatch, useAppSelector } from '@hooks/store';
import useProjectId from '@hooks/useProjectId';
import {
  resetAssetId,
  selectAssetIds,
  selectIsNewAssetMode,
  setAssetId,
  switchOffNewAssetMode,
  switchOnNewAssetMode,
} from '@store/slices/assetsSlice';

const useAssets = () => {
  const projectId = useProjectId(true);
  const dispatch = useAppDispatch();
  const selectedAssetIds = useAppSelector(selectAssetIds);
  const isNewAssetMode = useAppSelector(selectIsNewAssetMode);

  const selectAsset = (assetId: string) =>
    dispatch(setAssetId({ assetId, projectId }));
  const resetAsset = () => dispatch(resetAssetId(projectId));

  const setNewAssetMode = () => dispatch(switchOnNewAssetMode());
  const resetNewAssetMode = () => dispatch(switchOffNewAssetMode());

  return {
    selectedAssetId: selectedAssetIds[projectId] || null,
    isNewAssetMode,
    selectAsset,
    resetAsset,
    setNewAssetMode,
    resetNewAssetMode,
  };
};

export default useAssets;
