import { layerRulesKeys } from '@components/EntityDrawers/constants/common';
import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CommonKeys,
  CoreRequiredKeys,
  LogicPhysicalKeys,
} from '@components/EntityDrawers/constants/keys';
import {
  defaultValues,
  PhysicalFormValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Physical/form';
import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { PhysicalSubLayerTypes } from '@constants/canvas/layers';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { NameLink, PhysicalMetaData } from '@store/services/nodes/types';
import {
  checkValuesIncomplete,
  prepareBasicLogicPhysicalData,
  transformCommonKeys,
} from '@utils/drawers';
import { filterEmptyAndOmitId, getEntitiesArray } from '@utils/helpers';

export const PhysicalSettings: DrawerSettings<
  PhysicalFormValues,
  PhysicalMetaData
> = {
  editType: ProjectDrawerTypes.EditPhysicalEntity,
  defaultValues,
  validationSchema,

  checkIncompleteFields: checkValuesIncomplete(
    ...CoreRequiredKeys,
    CommonKeys.Description,
    CommonKeys.QnousRef,
    CommonKeys.NistRef,
  ),

  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<PhysicalFormValues, PhysicalMetaData>(
      ...LogicPhysicalKeys,
    )(nodeDto, context),

    [layerRulesKeys[context.subLayer as PhysicalSubLayerTypes]]:
      getEntitiesArray<NameLink>(nodeDto.meta_data.rules, NameLinkObject),
  }),

  transformMetaDataToRequestBody: (metadata, { subLayer }) => {
    const rulesObject = {
      [PhysicalKeys.Standards]: metadata[PhysicalKeys.Standards],
      [PhysicalKeys.TechInstructions]: metadata[PhysicalKeys.TechInstructions],
    } as const;

    return {
      ...prepareBasicLogicPhysicalData(metadata),
      rules: filterEmptyAndOmitId<NameLink>(
        rulesObject[layerRulesKeys[subLayer as PhysicalSubLayerTypes]] ?? [],
      ),
    } as PhysicalMetaData;
  },
};
