import { Node } from 'reactflow';
import { getCollapsedState } from '@components/MainStage/collapsedLayers.store';
import { canvasState } from '@components/MainStage/store';
import {
  AllLayersListByMode,
  CANVAS_DEFAULT_HEIGHT,
  CANVAS_HEIGHT_PADDING,
} from '@constants/canvas/general';
import { subLayersNodesByMode } from '@constants/canvas/layerNodes';
import {
  LayerSubLayers,
  LayerTypes,
  ModeLayers,
  Modes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { setCanvasHeight } from '@store/slices/uiSlice';
import { store } from '@store/store';
import LayerView from '@utils/canvas/LayerView';
import LayerViewManager from '@utils/canvas/LayerViewManager';
import NodesRow from '@utils/canvas/NodesRow';
import SubLayerViewManager from '@utils/canvas/SubLayerViewManager';

class CanvasModelRenderer {
  // This method builds the canvas layers and sublayers hierarchy from scratch.
  static async redrawFullCanvasLayers(mode: Modes) {
    const { isCollapsed } = getCollapsedState();
    const layerIds = ModeLayers[mode];

    let topLayerHeight = 0;

    layerIds.forEach((layerId) => {
      const layerView = LayerViewManager.getInstance(layerId);

      const subLayers = LayerSubLayers[layerId];

      // All sublayers should be placed under the layer Title block.
      let topSubLayerHeight = LayerView.TopTitleBlockHeight;

      subLayers.forEach((subLayerId) => {
        const subLayerView = SubLayerViewManager.getInstance(subLayerId);

        if (isCollapsed(subLayerId)) {
          subLayerView.collapse();
        } else {
          subLayerView.fitNodes();
        }

        subLayerView.setYPosition(topLayerHeight + topSubLayerHeight);
        topSubLayerHeight += subLayerView.height;
      });

      if (isCollapsed(layerId)) {
        layerView.collapse();
      } else {
        layerView.fitSubLayers();
      }

      layerView.setYPosition(topLayerHeight);
      topLayerHeight += layerView.height;
    });

    CanvasModelRenderer.resetCanvasHeight(mode);
  }

  // This method adjusts the position of the layers and sublayers below the given layer.
  static async adjustBellowLayers(
    mode: Modes,
    startLayerId: SubLayerTypes | LayerTypes,
  ) {
    const startIndex = AllLayersListByMode[mode].indexOf(startLayerId);
    const mergedLayers = AllLayersListByMode[mode].slice(startIndex);

    let offset = 0;

    mergedLayers.forEach((id) => {
      const isLayer = (ModeLayers[mode] as readonly any[]).includes(id);

      if (isLayer) {
        const layerView = LayerViewManager.getInstance(id as LayerTypes);
        layerView.shiftYPosition(offset);
      } else {
        const { isCollapsed } = getCollapsedState();

        const subLayerView = SubLayerViewManager.getInstance(
          id as SubLayerTypes,
        );

        subLayerView.shiftYPosition(offset);

        const isCollapsedSubLayerOrParent =
          isCollapsed(id) || isCollapsed(subLayerView.parentLayerId);

        if (
          subLayerView.rowsCountHeightDifference !== 0 &&
          !isCollapsedSubLayerOrParent
        ) {
          const moveDownRowsCount = -subLayerView.rowsCountHeightDifference;
          offset += moveDownRowsCount * NodesRow.Height;

          subLayerView.fitNodes();

          const parentLayer = LayerViewManager.getInstance(
            subLayerView.parentLayerId,
          );

          parentLayer.fitSubLayers();
        }
      }
    });

    CanvasModelRenderer.resetCanvasHeight(mode);
  }

  static removeEmptyRows(mode: Modes, filteredNodes: Node[]) {
    const layers = subLayersNodesByMode[mode];

    const visibleNodes = filteredNodes.filter((node) => !node.hidden);
    canvasState().setNodes(visibleNodes);

    layers.forEach((layerNode) => {
      SubLayerViewManager.getInstance(
        layerNode.id as SubLayerTypes,
      ).collapseRows();
    });

    this.redrawFullCanvasLayers(mode);
  }

  static async redrawAfterSubLayerChanges(
    mode: Modes,
    subLayerId: SubLayerTypes,
  ) {
    const subLayerView = SubLayerViewManager.getInstance(subLayerId);

    if (subLayerView.rowsCountHeightDifference !== 0) {
      CanvasModelRenderer.adjustBellowLayers(mode, subLayerId);
    }
  }

  static calculateCanvasHeight(mode: Modes | null) {
    if (!mode) return CANVAS_DEFAULT_HEIGHT;

    const layers = ModeLayers[mode] as readonly LayerTypes[];

    return layers.reduce((acc, id) => {
      try {
        return acc + LayerViewManager.getInstance(id).height;
      } catch (e) {
        return acc;
      }
    }, CANVAS_HEIGHT_PADDING);
  }

  static resetCanvasHeight(mode: Modes | null) {
    const height = CanvasModelRenderer.calculateCanvasHeight(mode);
    store.dispatch(setCanvasHeight(height));
  }
}

export default CanvasModelRenderer;
