import { ComponentContent } from '@components/EntityDrawers/drawers/Component/content';
import { ComponentFormValues } from '@components/EntityDrawers/drawers/Component/form';
import { ComponentSettings } from '@components/EntityDrawers/drawers/Component/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import withTabs from '@components/EntityDrawers/hocs/withTabs';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { ComponentMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddComponentEntity
  | ProjectDrawerTypes.EditComponentEntity;

export const ComponentDrawer = withTabs<Type>(
  withDrawer<ComponentFormValues, ComponentMetaData, Type>(
    ComponentContent,
    ComponentSettings,
  ),
);
