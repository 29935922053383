import { useParams } from 'react-router-dom';
import { useAppSelector } from '@hooks/store';
import useProjectTabs from '@hooks/useProjectTabs';
import { selectScenarioMode } from '@store/slices/projectSlice';

const useProjectId = (includeScenario = false) => {
  const { id = '' } = useParams<{ id: string }>();

  const currentScenarioId = useProjectTabs().currentId;
  const scenarioMode = useAppSelector(selectScenarioMode);

  if (includeScenario && scenarioMode) {
    return currentScenarioId ?? '';
  }

  return id;
};

export default useProjectId;
