import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledDatePicker from '@components/Inputs/controllers/ControlledDatePicker';
import { useProject } from '@context/Project/ProjectProvider';

const DateIdentifiedInput: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledDatePicker
      name={RiskKeys.DateIdentified}
      label="Date identified"
      readOnly={viewOnly}
      required
      validateOnBlur
      triggerKeys={[CommonKeys.DateReviewed]}
      timezone="UTC"
      clearable
    />
  );
};

export default DateIdentifiedInput;
