import { useReactFlow } from 'reactflow';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import {
  resetCanvasZoom,
  selectCanvasZoom,
  setCanvasZoom,
} from '@store/slices/uiSlice';

const useCanvasZoom = () => {
  const zoom = useAppSelector(selectCanvasZoom);
  const dispatch = useAppDispatch();
  const { getZoom } = useReactFlow();

  const setZoom = (zoom: number) => {
    dispatch(setCanvasZoom(zoom));
  };

  const resetZoom = () => {
    dispatch(resetCanvasZoom());
  };

  const saveCurrentZoom = () => {
    setZoom(getZoom());
  };

  return { zoom, setZoom, resetZoom, saveCurrentZoom };
};

export default useCanvasZoom;
