import { Stack } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { styled } from 'styled-components';

export const ControlsWrapper = styled('div')`
  display: flex;
  border-radius: 8px;
  background: white;
  border: 1px solid ${themePalette.grey[500]};
`;

export const ControlsButton = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

export const ControlsMidButton = styled(Stack)`
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  border-left: 1px solid ${themePalette.grey[500]};
  border-right: 1px solid ${themePalette.grey[500]};
  cursor: default;
`;
