import React, {
  ChangeEvent,
  FC,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CustomDialog,
  CustomDialogProps,
} from '@components/Dialogs/CustomDialog';
import { WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useChangeFilterHandler from '@hooks/useChangeFilterHandler';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { TreeView } from '@mui/x-tree-view';
import { IDomain } from '@store/services/projects/types';
import { themePalette } from '@theme/muiTheme';
import TreeItem, {
  SelectHandler,
  SelectHandlerOptions,
} from '@views/Project/components/ScopeFilterModal/TreeItem';
import { buildTree, ITreeItem } from '@views/Project/helpers';

type Props = Pick<CustomDialogProps, 'open' | 'onClose'> & {
  domains: IDomain[];
};

const renderTree = (
  nodes: ITreeItem,
  parentsId: string[],
  onSelect: SelectHandler,
  onClick: (nodeId: string) => void,
) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    label={nodes.name}
    onSelect={({ nodeId, checked }) => onSelect({ nodeId, checked, parentsId })}
    onClick={() => onClick(nodes.id)}
    hasChildren={!!nodes.children?.length}
  >
    {nodes.children?.map((item) =>
      renderTree(item, [...parentsId, nodes.id], onSelect, () =>
        onClick(item.id),
      ),
    )}
  </TreeItem>
);

const ScopeFilterModal: FC<Props> = ({ domains, ...props }) => {
  const [selected, setSelected] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { toolbox, handleDomainsChange } = useProject();

  const changeFilterHandler = useChangeFilterHandler();

  useEffect(() => {
    setSelected(
      toolbox.domains?.length
        ? toolbox.domains
        : domains.map(({ id }) => id).concat(WideDomainKey),
    );
  }, [props.open, toolbox, domains]);

  const handleAllChange = (
    _: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      setSelected(domains.map(({ id }) => id).concat(WideDomainKey));
    } else {
      setSelected([]);
    }
  };

  const treeDomains = useMemo(() => {
    return buildTree(domains);
  }, [domains]);

  const handleSelect = ({
    nodeId,
    checked,
    parentsId,
  }: SelectHandlerOptions) => {
    if (checked) {
      setSelected((ids) => Array.from(new Set([...ids, ...parentsId, nodeId])));
    } else {
      setSelected((ids) => ids.filter((id) => id !== nodeId));
    }
  };

  const handleConfirm = changeFilterHandler({
    isNodeVisible: (selectedDomains, node) =>
      selectedDomains.includes(node?.data.dto.domain_id ?? WideDomainKey),
    onSetValues: (selectedDomains) => {
      handleDomainsChange(selectedDomains);
      props.onClose?.();
    },
  });

  const handleClear = () => {
    setSelected([]);
  };

  const isNotChanged =
    (!toolbox.domains.length && selected.length === domains.length + 1) ||
    (selected.length === toolbox.domains.length &&
      selected.every((id) => toolbox.domains.includes(id)));

  const hanleClick = (nodeId: string) => {
    if (expanded.includes(nodeId)) {
      setExpanded((ids) => ids.filter((id) => id !== nodeId));
    } else {
      setExpanded((ids) => [...ids, nodeId]);
    }
  };

  return (
    <CustomDialog
      {...props}
      title="Scope filters"
      sx={{ '& .MuiDialog-paper': { minWidth: 598, height: 595 } }}
    >
      <Divider sx={{ mt: 3 }} />

      <Stack direction="row" gap={2} alignItems="center" p="16px 24px">
        <Checkbox
          checked={domains.length + 1 === selected.length}
          onChange={handleAllChange}
        />
        <Typography variant="h4">Select all</Typography>
      </Stack>

      <Divider />

      <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
        <TreeView
          expanded={expanded}
          disableSelection
          multiSelect
          selected={selected}
        >
          {treeDomains.map((item) =>
            renderTree(item, [], handleSelect, hanleClick),
          )}
        </TreeView>
      </Box>

      <Stack
        p={6}
        direction="row"
        alignItems="center"
        gap={4}
        borderTop={`1px solid ${themePalette.grey[500]}`}
      >
        <Button
          sx={{ flexBasis: '100px' }}
          variant="under-text"
          onClick={handleClear}
          disabled={!selected.length}
        >
          Clear all
        </Button>

        <Button
          sx={{ flex: 1 }}
          size="large"
          variant="contained"
          onClick={() => handleConfirm(selected)}
          disabled={!selected.length || isNotChanged}
        >
          Confirm
        </Button>
      </Stack>
    </CustomDialog>
  );
};

export default memo(ScopeFilterModal);
