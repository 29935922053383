import React from 'react';
import { StewardsOpinionOptions } from '@components/EntityDrawers/constants/common';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';

const StewardsOpinionDropdown = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextFieldSelect
      name={RiskKeys.StewardOpinion}
      label="Risk steward’s opinion"
      disabled={viewOnly}
      options={StewardsOpinionOptions}
    />
  );
};

export default StewardsOpinionDropdown;
