import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

export const UploadBoxStyled = styled('div')`
  width: 550px;
  padding: 32px 24px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  border: 1px dashed ${themePalette.grey[500]};
`;
