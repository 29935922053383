import { subLayersNodeIdsByMode } from '@constants/canvas/layerNodes';
import { ModeLayers, Modes } from '@constants/canvas/layers';
import { DateFormats } from '@constants/dates';
import { InsightKeys, ReadableInsights } from '@constants/entities/insights';
import {
  ExportLayerKeys,
  LayerToExportMap,
  MAX_STATEMENTS_IN_VIEW,
  ReadableExportLayers,
} from '@constants/entities/reports';
import { nanoid } from '@reduxjs/toolkit';
import { ConceptualSource } from '@store/services/nodes/types';
import { Tabs } from '@views/Insights/types';
import {
  StatementSlideData,
  StatementSlideItem,
} from '@views/ReportsModel/constants/types';
import dayjs from 'dayjs';

const InsightsTabTitles = {
  [Tabs.Dependency]: ReadableInsights[InsightKeys.Dependency],
  [Tabs.FocusOn]: ReadableInsights[InsightKeys.FocusOn],
  [Tabs.Maturity]: ReadableInsights[InsightKeys.Maturity],
  [Tabs.ModelEvolution]: ReadableInsights[InsightKeys.ModelEvolution],
  [Tabs.Completeness]: ReadableInsights[InsightKeys.Completeness],
  [Tabs.KeyControls]: ReadableInsights[InsightKeys.KeyControls],
};

export const ModesTitles = {
  [Modes.BusinessAlignment]: 'Business alignment',
  [Modes.Controls]: 'Controls mode',
  [Modes.RiskManagement]: ':assetName asset risk assessment',
};

export const getModelImageFileName = (
  mode: Modes,
  tab?: Tabs,
  assetName = '',
) => {
  const isInsight = tab && tab !== Tabs.Default;

  const title = isInsight
    ? `${InsightsTabTitles[tab]} insight`
    : ModesTitles[mode].replace(':assetName', assetName);

  return `${title} model ${dayjs().format(DateFormats[1])}.png`;
};

const checkIsAllLayersSelected = (
  mode: Modes.BusinessAlignment | Modes.Controls,
  layers: ExportLayerKeys[],
) =>
  ModeLayers[mode].length === layers.length &&
  (ModeLayers[mode] as unknown as (keyof typeof LayerToExportMap)[]).every(
    (layer) => layers.includes(LayerToExportMap[layer]),
  );

export const getLayersReportFileName = (layers: ExportLayerKeys[]) => {
  const allBALayers = checkIsAllLayersSelected(Modes.BusinessAlignment, layers);
  const allControlsLayers = checkIsAllLayersSelected(Modes.Controls, layers);

  const isAllSelected =
    Array.from(
      new Set([
        ...ModeLayers[Modes.BusinessAlignment],
        ...ModeLayers[Modes.Controls],
      ]),
    ).length === layers.length;

  if (isAllSelected) {
    return 'All model layers report.xlsx';
  }

  if (allBALayers) return 'Business alignment mode report.xlsx';

  if (allControlsLayers) return 'Controls mode report.xlsx';

  return `${layers.map((l) => ReadableExportLayers[l]).join(', ')} report.xlsx`;
};

export const getRiskAssessmentFileName = (selectedAsset?: string) => {
  if (selectedAsset) {
    return `${selectedAsset} risk assessment report ${dayjs().format(
      DateFormats[1],
    )}.xlsx`;
  }

  return `Asset risk assessments report ${dayjs().format(DateFormats[1])}.xlsx`;
};

export const getRiskRegisterFileName = () => {
  return `Risk register report ${dayjs().format(DateFormats[1])}.xlsx`;
};

export const getJustStatementFileName = (name: string, index = 0) => {
  return `${index + 1}. ${name} justification register report ${dayjs().format(
    DateFormats[1],
  )}.png`;
};

export const getFileData = (fileContent: string, fileName: string) => {
  if (!fileContent) return { url: '', name: fileName, size: 0 };

  const blob = new Blob([fileContent], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  return {
    url: window.URL.createObjectURL(blob),
    name: fileName,
    size: blob.size,
  };
};

export const getCanvasHeight = (mode: Modes) => {
  const modeLayersIds = subLayersNodeIdsByMode[mode];
  const lastLayerId = modeLayersIds[modeLayersIds.length - 1];

  const lastLayerNode = document.querySelector(`[data-id="${lastLayerId}"]`);
  if (!lastLayerNode) return 0;

  const styles = window.getComputedStyle(lastLayerNode);

  // Extract the transformation matrix
  const transform =
    styles.getPropertyValue('transform') ||
    styles.getPropertyValue('-webkit-transform') ||
    styles.getPropertyValue('-moz-transform') ||
    styles.getPropertyValue('-ms-transform') ||
    styles.getPropertyValue('-o-transform');

  // Parse the transformation matrix
  const matrix = transform.match(/^matrix\((.+)\)$/);
  if (!matrix) return 0;

  // Extract translateY value from the matrix
  const translateY = parseFloat(matrix[1].split(', ')[5]);

  return translateY + lastLayerNode.clientHeight;
};

export const getAvailableLayers = (modes: Modes[]): ExportLayerKeys[] =>
  Array.from(
    new Set(
      modes.reduce<ExportLayerKeys[]>((acc, m) => {
        if (m === Modes.RiskManagement) return acc;

        return [
          ...acc,
          ...(
            Object.values(
              ModeLayers[m],
            ) as unknown as (keyof typeof LayerToExportMap)[]
          ).reduce<ExportLayerKeys[]>(
            (layers, l) => [
              ...layers,
              ...(Object.hasOwn(LayerToExportMap, l)
                ? [LayerToExportMap[l]]
                : []),
            ],
            [],
          ),
        ];
      }, []),
    ),
  );

export const getStatementPages = (
  source: ConceptualSource[],
  total: number,
): StatementSlideData[] =>
  new Array(total).fill(0).map((_, slideIndex) => {
    const slideItems: StatementSlideItem[] = [];

    source
      .filter((source) => source.type)
      .forEach((source) => {
        (source.justification_statement ?? [])
          .filter((statement) => statement.length)
          .forEach((statement) => {
            slideItems.push({
              justification_statement: statement,
              type: source.type,
              id: nanoid(10),
            });
          });
      });

    const statements: StatementSlideItem[] = slideItems.slice(
      slideIndex * MAX_STATEMENTS_IN_VIEW,
      slideIndex * MAX_STATEMENTS_IN_VIEW + MAX_STATEMENTS_IN_VIEW,
    );

    return statements.reduce<StatementSlideData>(
      (acc, item, index) => {
        const { leftStatements, rightStatements } = acc;

        if (index % 2 === 0) {
          leftStatements.push(item);
        } else {
          rightStatements.push(item);
        }

        return { leftStatements, rightStatements };
      },
      { leftStatements: [], rightStatements: [] },
    );
  });
