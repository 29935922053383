import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinkWrapper = styled(Stack)<{
  $error?: boolean;
  $disabled: boolean;
}>`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  gap: 8px;
  min-height: 44px;

  border-radius: 12px;
  border: 1px solid
    ${({ theme, $error, $disabled }) => {
      if ($disabled) {
        return theme.palette.grey[600];
      }

      return $error ? theme.palette.system?.error : theme.palette.grey[700];
    }};
`;
