import React, { memo, useState } from 'react';
import { NodeProps, Position } from 'reactflow';
import { ChangeInitiativeStatus } from '@components/MainStage/CustomNode/ChangeInitiativeStatus';
import { KeyControlsImpact } from '@components/MainStage/CustomNode/KeyControlsImpact';
import { KeyControlsImpactColor } from '@components/MainStage/CustomNode/KeyControlsImpact/helpers';
import { RiskScore } from '@components/MainStage/CustomNode/RiskScore';
import { InitiativeProgressStatus } from '@constants/canvas/layers';
import { useNodesDataContext } from '@context/NodesDataContext';
import { Typography } from '@mui/material';
import { NodeDTOTypes } from '@store/services/nodes/types';
import { ImpactType } from '@store/services/projects/types';
import { getInitiativeNodeIcon, getNodeIcon } from '@utils/canvasIconHelpers';
import { Tabs } from '@views/Insights/types';

import {
  BottomHandleWrapper,
  CustomNodeContent,
  CustomNodeStyled,
  CustomNodeToolbar,
  IconWrapper,
  IncompleteLabel,
  MaturityBlock,
  MaturityWrapper,
  StyledHandle,
  StyledText,
  StyledTooltipLeftArrow,
  TextWrapper,
  TopHandleWrapper,
} from './CustomNode.styled';

let hoverDelay: ReturnType<typeof setTimeout>;

export const CustomNode = memo(function CustomNode({
  id,
  data,
  selected,
}: NodeProps) {
  const {
    getIsNodeTargetConnected,
    getIsNodeSourceConnected,
    getActiveNode,
    getActiveEdge,
    isConnectable,
    isNodeDisabled,
    insightsTab,
  } = useNodesDataContext();

  const [isHovered, setIsHovered] = useState(false);

  const activeNode = getActiveNode();
  const activeEdge = getActiveEdge();

  const targetSelectedWithEdge = id === activeEdge?.target;
  const sourceSelectedWithEdge = id === activeEdge?.source;
  const { type, is_complete: isComplete, label, meta_data } = data.dto;
  const {
    selectedWithEdge,
    selectedInInsight,
    selectedInMaturity,
    maturityBackground,
    keyControlImpact,
    disabled,
  } = data.canvas;

  const isIncomplete = !isComplete;
  const isDisabled = isNodeDisabled(data.canvas);
  const isNodeTargetHidden = type === NodeDTOTypes.StrategicPriority;

  const isNodeTargetConnected = getIsNodeTargetConnected(id);
  const isNodeSourceConnected = getIsNodeSourceConnected(id);

  const nodeIcon =
    type === NodeDTOTypes.Initiatives
      ? getInitiativeNodeIcon({
          type: meta_data?.type,
          disabled: isDisabled,
        })
      : getNodeIcon({ type, disabled: isDisabled });

  const handleMouseEnter = () => {
    hoverDelay = setTimeout(() => {
      setIsHovered(true);
    }, 1000);
  };

  const handleMouseLeave = () => {
    clearTimeout(hoverDelay);
    setIsHovered(false);
  };

  const isSelectedInInsight =
    insightsTab === Tabs.Dependency &&
    selectedInInsight &&
    !activeNode &&
    !selectedWithEdge &&
    !activeEdge?.id;

  const shouldBeSelectedInMaturity =
    selectedInMaturity &&
    meta_data?.current_maturity &&
    meta_data?.target_maturity &&
    isComplete;

  const isInitiativeStatusShown =
    type === NodeDTOTypes.Initiatives &&
    (meta_data?.progress_status === InitiativeProgressStatus.Completed ||
      meta_data?.progress_status === InitiativeProgressStatus.Cancelled);

  const isKeyControlImpactShown =
    selectedInInsight && keyControlImpact && !disabled;
  const keyControlImpactColor =
    KeyControlsImpactColor[keyControlImpact as ImpactType];

  return (
    <CustomNodeStyled
      $selected={selected}
      $incomplete={isIncomplete}
      $selectedWithEdge={selectedWithEdge && activeEdge?.id}
      $selectedInInsight={isSelectedInInsight}
      $selectedInMaturity={shouldBeSelectedInMaturity}
      $maturityBackground={maturityBackground}
      $keyControlsImpactColor={keyControlImpactColor}
      $disabled={isDisabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isIncomplete && (
        <IncompleteLabel $disabled={isDisabled}>
          <Typography
            variant="body2"
            color={isDisabled ? 'grey.700' : 'system.error'}
          >
            Incomplete
          </Typography>
        </IncompleteLabel>
      )}
      {shouldBeSelectedInMaturity && (
        <MaturityWrapper>
          <MaturityBlock
            $maturityBackground={maturityBackground}
            $isDisabled={disabled}
          >
            {meta_data?.current_maturity}
          </MaturityBlock>
          <MaturityBlock $isDisabled={disabled}>
            {meta_data?.target_maturity}
          </MaturityBlock>
        </MaturityWrapper>
      )}
      {type === NodeDTOTypes.EvaluatedRisk && <RiskScore data={data} />}
      {isInitiativeStatusShown && (
        <ChangeInitiativeStatus status={meta_data.progress_status} />
      )}
      {isKeyControlImpactShown && (
        <KeyControlsImpact impact={keyControlImpact} />
      )}
      <CustomNodeToolbar
        isVisible={label.length > 30 && isHovered}
        position={Position.Right}
      >
        <StyledTooltipLeftArrow />
        <Typography variant="body2" color="white">
          {label}
        </Typography>
      </CustomNodeToolbar>
      <CustomNodeContent>
        <IconWrapper>{nodeIcon}</IconWrapper>
        <TextWrapper>
          <StyledText
            variant="h5"
            color={isDisabled ? 'grey.700' : 'grey.1000'}
          >
            {data?.dto?.label}
          </StyledText>
        </TextWrapper>
      </CustomNodeContent>
      {!isNodeTargetHidden && (
        <TopHandleWrapper $isHandleConnected={isNodeTargetConnected}>
          <StyledHandle
            type="target"
            position={Position.Top}
            $selectedWithEdge={targetSelectedWithEdge}
            isConnectable={isConnectable}
            $faded={isDisabled}
          />
        </TopHandleWrapper>
      )}
      <BottomHandleWrapper $isHandleConnected={isNodeSourceConnected}>
        <StyledHandle
          type="source"
          position={Position.Bottom}
          $selectedWithEdge={sourceSelectedWithEdge}
          isConnectable={isConnectable}
          $faded={isDisabled}
        />
      </BottomHandleWrapper>
    </CustomNodeStyled>
  );
});
