import useStore from '@components/MainStage/store';
import { LayerTypes, SubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project';
import { useToast } from '@context/Toast';
import LayerView from '@utils/canvas/LayerView';
import LayerViewManager from '@utils/canvas/LayerViewManager';
import SubLayerViewManager from '@utils/canvas/SubLayerViewManager';
import { Tabs } from '@views/Insights/types';

const useAbleCollapseLayer = (id: SubLayerTypes | LayerTypes) => {
  const { showToast } = useToast();
  const { insightsTab, drawer } = useProject();
  const { getActiveNode } = useStore();

  const isSublayerContainsSelectedNode = (id: SubLayerTypes) => {
    return SubLayerViewManager.getInstance(id).hasSelected();
  };

  const isSomeNodeOpenedInLayer = () => {
    const activeNode = getActiveNode();

    const isDrawerOpened = Boolean(drawer?.type) && activeNode;

    return (
      isDrawerOpened &&
      [
        activeNode!.parentNode,
        SubLayerViewManager.getInstance(activeNode!.parentNode as SubLayerTypes)
          .parentLayerId,
      ].includes(id)
    );
  };

  const isAbleToCollapse = () => {
    if (isSomeNodeOpenedInLayer()) return false;

    if ([Tabs.FocusOn, Tabs.Maturity].includes(insightsTab)) {
      if (LayerView.isLayer(id)) {
        return !LayerViewManager.getInstance(id as LayerTypes).subLayerIds.some(
          isSublayerContainsSelectedNode,
        );
      }

      return !isSublayerContainsSelectedNode(id as SubLayerTypes);
    }

    return true;
  };

  const handleUnableCollapse = () => {
    showToast(
      'Cannot collapse layer. It contains the selected entity for model filtering.',
      'info',
    );
  };

  return { isAbleToCollapse, handleUnableCollapse };
};

export default useAbleCollapseLayer;
