import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import ControlledTextField, {
  ControlledTextFieldProps,
} from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

type Props = Pick<
  ControlledTextFieldProps,
  'label' | 'placeholder' | 'multiline'
> & {
  editMode?: boolean;
};

const NameInput: FC<Props> = ({ editMode, ...props }) => {
  const { viewOnly } = useProject();
  const activeNode = useSourceNode();

  return (
    <ControlledTextField
      name={CommonKeys.Name}
      helperText={!editMode && "Entity may be saved by entering it's name only"}
      required
      disabled={viewOnly}
      autoFocus={!activeNode?.data?.dto?.name}
      autoComplete="off"
      {...props}
    />
  );
};

export default NameInput;
