import React, { FC } from 'react';
import {
  ConceptualSourceObject,
  JustificationStatementObject,
} from '@components/EntityDrawers/constants/empty-objects';
import { ConceptualKeys } from '@components/EntityDrawers/drawers/Conceptual/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledLinkInput from '@components/Inputs/controllers/ControlledLinkInput';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { ConceptualSourceTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const JustificationStatementInputs: FC<InputsBlockProps> = ({
  createName,
  viewOnly,
}) => (
  <ControlledTextField
    name={createName('name')}
    placeholder="Justification statement"
    disabled={viewOnly}
    multiline
  />
);
const SourceInputs: FC<InputsBlockProps> = ({ createName, viewOnly }) => (
  <>
    <ControlledTextFieldSelect
      name={createName('type')}
      label="Source type"
      placeholder="Choose type"
      disabled={viewOnly}
      required
      options={SourceTypeOptions}
    />
    <ControlledTextField
      name={createName('name')}
      label="Source name"
      placeholder="Source name"
      required
      disabled={viewOnly}
      multiline
    />

    <ControlledInputGroups
      name={createName(ConceptualKeys.JustificationStatement)}
      viewOnly={viewOnly}
      groupObject={JustificationStatementObject}
      titleProps={{ variant: 'h5' }}
      {...getInputGroupsProps('Justification statement')}
    >
      {JustificationStatementInputs}
    </ControlledInputGroups>

    <ControlledLinkInput
      name={createName('link')}
      label="Source link"
      placeholder="Source link"
      disabled={viewOnly}
      multiline
    />
  </>
);

const SourceTypeOptions = [
  { value: ConceptualSourceTypes.Documentation, label: 'Documentation' },
  { value: ConceptualSourceTypes.Interview, label: 'Interview' },
];

const SourcesGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={ConceptualKeys.Sources}
      groupObject={ConceptualSourceObject}
      {...getInputGroupsProps('source')}
      viewOnly={viewOnly}
    >
      {SourceInputs}
    </ControlledInputGroups>
  );
};

export default SourcesGroup;
