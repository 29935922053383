import React, { FC, useMemo } from 'react';
import useStore from '@components/MainStage/store';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import useAssets from '@hooks/useAssets';
import useToggle from '@hooks/useToggle';
import { Button, MenuItem, Popover } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { FileIcon, PlusIcon } from '@utils/iconsDefs';
import UploadAssetsModal from '@views/Project/components/UploadAssetsModal';
import useAddAsset from '@views/Project/hooks/useAddAsset';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

const AddAsset: FC = () => {
  const { handleCloseDrawer, setDrawer } = useProject();
  const { setNewAssetMode, selectedAssetId, isNewAssetMode } = useAssets();
  const { nodes } = useStore();

  const addAsset = useAddAsset();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: `add-asset-popup`,
  });

  const [isUploadModalOpen, { on: openUploadModal, off: closeUploadModal }] =
    useToggle();

  const buttonColor = useMemo(
    () =>
      popupState.isOpen ? themePalette.green[700] : themePalette.primary.main,
    [popupState.isOpen],
  );

  const handleAddNewAsset = async () => {
    popupState.close();
    await handleCloseDrawer();

    setNewAssetMode();
    setDrawer({ type: ProjectDrawerTypes.AddAssessmentScopeEntity });

    if (!selectedAssetId) {
      addAsset({ canvasNodes: nodes });
    }
  };

  const handleOpenUploadModal = async () => {
    popupState.close();
    await handleCloseDrawer();
    openUploadModal();
  };

  return (
    <>
      <Button
        variant="text"
        sx={{ color: buttonColor }}
        {...bindTrigger(popupState)}
        disabled={isNewAssetMode}
      >
        <PlusIcon fill="currentColor" />
        Add an asset
      </Button>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{
          paper: { sx: { minWidth: 210, mt: 3 } },
        }}
      >
        <MenuItem onClick={handleAddNewAsset}>
          <PlusIcon fill="currentColor" />
          Add single asset
        </MenuItem>

        <MenuItem onClick={handleOpenUploadModal}>
          <FileIcon fill="currentColor" />
          Upload assets
        </MenuItem>
      </Popover>

      <UploadAssetsModal
        key={isUploadModalOpen.toString()}
        open={isUploadModalOpen}
        onClose={closeUploadModal}
      />
    </>
  );
};

export default AddAsset;
