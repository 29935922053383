import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
} from '@components/Inputs/TextFieldSelect';
import useProjectSettings from '@hooks/useProjectSettings';

const CriticalityRatingDropdown = () => {
  const {
    settings: { risk_criticality_level },
  } = useProjectSettings();

  const { watch } = useFormContext();

  const options = useMemo(() => {
    return (risk_criticality_level ?? []).map(({ name }) => ({
      label: name,
      value: name,
    }));
  }, [risk_criticality_level]);

  const value = useMemo(() => {
    const score = watch(RiskKeys.ResidualScore);

    if (!score) return '';

    return (
      risk_criticality_level?.find(({ rangeMin, rangeMax }) => {
        return score >= rangeMin && score <= rangeMax;
      })?.name ?? ''
    );
  }, [
    risk_criticality_level,
    watch(RiskKeys.HasCapability),
    watch(RiskKeys.ResidualScore),
    watch(RiskKeys.ResidualLikelihood),
    watch(RiskKeys.ResidualImpact),
  ]);

  return (
    <TextFieldSelect
      label="Risk criticality rating"
      options={options}
      value={value || DEFAULT_PLACEHOLDER_VALUE}
      placeholder="Risk criticality rating"
      disabled
      required
    />
  );
};

export default CriticalityRatingDropdown;
