import React, { FC } from 'react';
import { useSliderContext } from '@context/SliderProvider';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ControlLeftIcon } from '@utils/iconsDefs';
import {
  ControlsButton,
  ControlsMidButton,
  ControlsWrapper,
} from '@views/ReportsDashboard/components/SliderControls/SliderControls.styled';

const SliderControls: FC = () => {
  const { currentSlide, totalSlides, nextStep, prevStep } = useSliderContext();

  if (totalSlides <= 1) return <Box />;

  return (
    <ControlsWrapper>
      <ControlsButton onClick={prevStep}>
        <ControlLeftIcon />
      </ControlsButton>

      <ControlsMidButton>
        <Typography variant="body2" color="grey.1000">
          {currentSlide + 1} of {totalSlides}
        </Typography>
      </ControlsMidButton>

      <ControlsButton onClick={nextStep}>
        <ControlLeftIcon style={{ transform: 'rotate(180deg)' }} />
      </ControlsButton>
    </ControlsWrapper>
  );
};

export default SliderControls;
