import { createContext } from 'react';
import {
  BusinessAlignmentTabs,
  BusinessDashboardDistribution,
} from '@constants/entities/project-dashboard';
import { DashboardContextValues } from '@context/DashboardContext/types';

export const DashboardContext = createContext<DashboardContextValues>({
  tab: BusinessAlignmentTabs.BusinessAttributes,
  handleChangeTab: () => {},
  perValue: BusinessDashboardDistribution.PerDomain,
  changePerValue: () => {},
  openExportModal: () => {},
  exportDistribution: null,
  isExportOpen: false,
  closeExportModal: () => {},
});
