import { ConceptualMeasurementObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CoreFormKeys,
  CoreRequiredKeys,
} from '@components/EntityDrawers/constants/keys';
import {
  ConceptualFormValues,
  defaultValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Conceptual/form';
import { ConceptualKeys } from '@components/EntityDrawers/drawers/Conceptual/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import {
  ConceptualMeasurement,
  ConceptualMetaData,
} from '@store/services/nodes/types';
import {
  checkArrayFieldsIncomplete,
  checkValuesIncomplete,
  transformCommonKeys,
  transformSourcesFormValuesToMetaData,
  transformSourcesToFormValues,
} from '@utils/drawers';
import { filterEmptyAndOmitId, getEntitiesArray } from '@utils/helpers';

export const ConceptualSettings: DrawerSettings<
  ConceptualFormValues,
  ConceptualMetaData
> = {
  editType: ProjectDrawerTypes.EditConceptualEntity,
  defaultValues,
  validationSchema,

  checkIncompleteFields: (values) =>
    checkValuesIncomplete<ConceptualFormValues>(...CoreRequiredKeys)(values) ||
    checkArrayFieldsIncomplete(values[ConceptualKeys.Sources], [
      'name',
      'type',
    ]),

  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<ConceptualFormValues, ConceptualMetaData>(
      ...CoreFormKeys,
    )(nodeDto, context),

    // @ts-ignore
    [ConceptualKeys.Sources]: transformSourcesToFormValues(
      nodeDto.meta_data.source ?? [],
    ),

    [ConceptualKeys.Measurement]: getEntitiesArray<ConceptualMeasurement>(
      nodeDto.meta_data.measurement ?? [],
      ConceptualMeasurementObject,
    ),

    [ConceptualKeys.Definition]: nodeDto.meta_data.definition ?? '',
  }),

  transformMetaDataToRequestBody: ({ sources, measurement, definition }) =>
    ({
      source: transformSourcesFormValuesToMetaData(sources),
      measurement: filterEmptyAndOmitId<ConceptualMeasurement>(measurement),
      ...(definition ? { definition } : {}),
    }) as ConceptualMetaData,
};
