import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledTextField, {
  ControlledTextFieldProps,
} from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

type Props = Pick<
  ControlledTextFieldProps,
  'label' | 'placeholder' | 'multiline' | 'required'
>;

const DescriptionInput: FC<Props> = (props) => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextField
      name={CommonKeys.Description}
      disabled={viewOnly}
      multiline
      {...props}
    />
  );
};

export default DescriptionInput;
