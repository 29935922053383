import useStore from '@components/MainStage/store';
import {
  BusinessAlignmentSubLayer,
  ControlsSubLayer,
  RiskManagementSubLayer,
  subLayerParent,
} from '@constants/canvas/layers';
import { AddNodeDrawerMap } from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import useCenterNodeViewport from '@hooks/canvas/useCenterNodeViewport';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import { calculatePositionForNewNode } from '@utils/canvasHelpers';

type Options = {
  subLayerId:
    | BusinessAlignmentSubLayer
    | ControlsSubLayer
    | RiskManagementSubLayer;
  onAdd?: (newNodeId: string) => void;
};

const useAddEntity = ({ subLayerId, onAdd }: Options) => {
  const { addChildNode, nodes, clearLocalNodes, getActiveNode } = useStore();
  const {
    setDrawer,
    handleCloseDrawer,
    toolbox: { mode },
  } = useProject();
  const centerNode = useCenterNodeViewport();

  return async () => {
    const activeNode = getActiveNode();

    const isDoubleAdding =
      activeNode &&
      activeNode.id.startsWith('local') &&
      activeNode.parentNode === subLayerId;

    if (isDoubleAdding) return;

    await handleCloseDrawer();
    clearLocalNodes();

    const position = calculatePositionForNewNode(subLayerId, nodes);
    const newNodeId = addChildNode(subLayerId, position);

    CanvasModelRenderer.adjustBellowLayers(mode!, subLayerId);

    setDrawer({ type: AddNodeDrawerMap[subLayerParent[subLayerId]] });

    centerNode(position, subLayerId);
    onAdd?.(newNodeId);
  };
};

export default useAddEntity;
