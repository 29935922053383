import React from 'react';
import { ExistingChildNodeProps } from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/types';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { Box, Checkbox, Stack } from '@mui/material';
import { themePalette } from '@theme/muiTheme';

export const ExistingNodeItemChild = ({
  id,
  name,
  isChecked,
  isDisabled,
  handleChildChange,
}: ExistingChildNodeProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: themePalette.grey[400] },
      }}
      p="16px 24px 16px 36px"
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={isChecked}
          disabled={isDisabled}
          onChange={(event) => handleChildChange(id, event.target.checked)}
        />

        <TypographyWithElipsis variant="body1" maxWidth="490px" sx={{ ml: 2 }}>
          {name}
        </TypographyWithElipsis>
      </Box>
    </Stack>
  );
};
