export enum ProjectStatus {
  Onboarding = 'onboarding',
  Engagement = 'engagement',
  OnPause = 'on_pause',
  PaymentDelay = 'payment_delay',
  Support = 'support',
  Archived = 'archived',
}

export const ReadableProjectStatus = {
  [ProjectStatus.Onboarding]: 'Onboarding',
  [ProjectStatus.Engagement]: 'Engagement',
  [ProjectStatus.OnPause]: 'On pause',
  [ProjectStatus.PaymentDelay]: 'Payment delay',
  [ProjectStatus.Support]: 'Support',
  [ProjectStatus.Archived]: 'Archived',
};
