import { useEffect } from 'react';
import useRecentlyProjects from '@hooks/useRecentlyProjects';
import useUser from '@hooks/useUser';

const useInitResentProjects = () => {
  const [{ recently_projects }] = useUser();
  const { initRecentlyProjects } = useRecentlyProjects();

  useEffect(() => {
    if (recently_projects?.length) {
      initRecentlyProjects(recently_projects);
    }
  }, [recently_projects]);
};

export default useInitResentProjects;
