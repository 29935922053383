import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CoreFormKeys,
  CoreFormKeysType,
} from '@components/EntityDrawers/constants/keys';
import { ContextualKeys } from '@components/EntityDrawers/drawers/Contextual/keys';
import { ShapeType } from '@constants/types';
import { NameLinksYup } from '@constants/validation';
import { NameLink } from '@store/services/nodes/types';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import { withId } from '@utils/helpers';
import * as yup from 'yup';

export type ContextualFormValues = yup.InferType<typeof validationSchema> & {
  [ContextualKeys.Sources]: NameLink[];
};

export const validationSchema = generateSchema<ShapeType<CoreFormKeysType>>({
  ...getYups(...CoreFormKeys),
  [ContextualKeys.Sources]: NameLinksYup(),
});

export const defaultValues: ContextualFormValues = {
  ...getDefs(...CoreFormKeys),
  [ContextualKeys.Sources]: [withId(NameLinkObject)],
};
