import { ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themePalette } from '@theme/muiTheme';
import { FilterIcon } from '@utils/iconsDefs';

export const StyledFilterIcon = styled(FilterIcon)``;
export const StyledFilterButton = styled(ButtonBase)<{
  $isActive: boolean;
  $isDisabled: boolean;
}>`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid
    ${({ $isActive, $isDisabled }) => {
      if ($isDisabled) {
        return themePalette.grey[500];
      }
      if ($isActive) {
        return themePalette.grey[900];
      }
      return themePalette.grey[700];
    }};

  &:hover {
    border: 1px solid ${themePalette.grey[800]};
  }
`;

export const StyledText = styled(Typography)<{
  $disabled: boolean;
}>`
  padding: 8px 12px;
  text-decoration: underline;
  color: ${({ $disabled }) => themePalette.grey[$disabled ? 700 : 1000]};
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};

  &:hover {
    color: ${({ $disabled }) => themePalette.grey[$disabled ? 700 : 900]};
  }
`;
