import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CommonKeys,
  LogicPhysicalFormKeysType,
  LogicPhysicalKeys,
} from '@components/EntityDrawers/constants/keys';
import { PhysicalKeys } from '@components/EntityDrawers/drawers/Physical/keys';
import { ShapeType } from '@constants/types';
import { NameLinksYup } from '@constants/validation';
import { NameLink, NameObjective } from '@store/services/nodes/types';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import { withId } from '@utils/helpers';
import * as yup from 'yup';

export type PhysicalFormValues = yup.InferType<typeof validationSchema> & {
  [CommonKeys.Metrics]: NameObjective[];
  [PhysicalKeys.TechInstructions]: NameLink[];
  [PhysicalKeys.Standards]: NameLink[];
};

export const validationSchema = generateSchema<
  ShapeType<LogicPhysicalFormKeysType>
>({
  ...getYups(...LogicPhysicalKeys),
  [PhysicalKeys.TechInstructions]: NameLinksYup(),
  [PhysicalKeys.Standards]: NameLinksYup(),
});

export const defaultValues: PhysicalFormValues = {
  ...getDefs(...LogicPhysicalKeys),
  [PhysicalKeys.TechInstructions]: [withId(NameLinkObject)],
  [PhysicalKeys.Standards]: [withId(NameLinkObject)],
};
