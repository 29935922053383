import React, { FC } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import EntityAlerts from '@views/Project/components/EntityAlerts';

const Standard: FC = () => {
  const { editMode } = useDrawerContext();

  return (
    <>
      <EntityAlerts />

      <NameInput
        label="Technology name"
        placeholder="Technology name"
        editMode={editMode}
      />

      <DescriptionInput
        label="Technology description"
        placeholder="Technology description"
        required
      />

      <OwnerInput label="Technology owner" placeholder="Technology owner" />
      <DomainDropdown />

      <ValidationStatus />

      {editMode && <CreatedReviewDates dateReviewedClearable />}
    </>
  );
};

export default Standard;
