import { Node } from 'reactflow';
import { connectableLayers } from '@constants/canvas/general';
import { Modes, SubLayerTypes } from '@constants/canvas/layers';
import { NodeDTOTypes } from '@store/services/nodes/types';
import { IDomain } from '@store/services/projects/types';

type EdgeConnectableProps = {
  targetNode: Node;
  sourceNode: Node;
  domains: IDomain[];
  getNodeById: (id: string) => Node | undefined;
  mode: Modes;
};

const atLeastOneParentIsSpecificType = (
  node: Node,
  parentType: string,
  getNodeById: (id: string) => Node | undefined,
): boolean => {
  const { parentsIds } = node.data.dto;

  return parentsIds.some((id: string) => {
    const parent = getNodeById(id);
    return parent?.data.dto.type === parentType;
  });
};

const isRiskModeConnectable = (
  sourceNode: Node,
  targetNode: Node,
  mode: Modes,
) => {
  if (mode === Modes.RiskManagement) {
    if (
      (sourceNode.data.dto.type === NodeDTOTypes.Functions ||
        sourceNode.data.dto.type === NodeDTOTypes.Capabilities) &&
      !targetNode.data.dto.parent_asset
    ) {
      return false;
    }
  }
  return true;
};

const isFunctionsLayerConnectable = (
  sourceNode: Node,
  targetNode: Node,
  getNodeById: (id: string) => Node | undefined,
) => {
  return !(
    targetNode.data.dto.type === NodeDTOTypes.Functions &&
    sourceNode.data.dto.type === NodeDTOTypes.Capabilities &&
    atLeastOneParentIsSpecificType(
      targetNode,
      NodeDTOTypes.Capabilities,
      getNodeById,
    )
  );
};

const isCapabilityLayerConnectable = (
  sourceNode: Node,
  targetNode: Node,
  getNodeById: (id: string) => Node | undefined,
) => {
  return !(
    targetNode.data.dto.type === NodeDTOTypes.Capabilities &&
    sourceNode.data.dto.type === NodeDTOTypes.Service &&
    atLeastOneParentIsSpecificType(
      targetNode,
      NodeDTOTypes.Service,
      getNodeById,
    )
  );
};

const areDomainsCorrect = (
  sourceNode: Node,
  targetNode: Node,
  domains: IDomain[],
) => {
  const sourceNodeDomain = domains.find(
    (domain) => domain.id === sourceNode?.data.dto.domain_id,
  );
  const targetNodeDomain = domains.find(
    (domain) => domain.id === targetNode?.data.dto.domain_id,
  );

  if (!(sourceNodeDomain && targetNodeDomain)) {
    return true;
  }

  if (
    sourceNodeDomain?.level === targetNodeDomain?.level &&
    sourceNodeDomain?.id === targetNodeDomain?.id
  ) {
    return true;
  }

  if (sourceNodeDomain?.level === 2 || targetNodeDomain?.level === 2) {
    const childDomain =
      sourceNodeDomain?.level === 2 ? sourceNodeDomain : targetNodeDomain;
    const parentDomain =
      sourceNodeDomain?.level === 2 ? targetNodeDomain : sourceNodeDomain;

    if (childDomain?.parentId === parentDomain?.id) {
      return true;
    }
  }

  return false;
};

const areLayersConnectable = (sourceNode: Node, targetNode: Node) => {
  const sourceNodeParent = sourceNode?.parentNode as SubLayerTypes;
  const targetNodeParent = targetNode?.parentNode as SubLayerTypes;

  if (sourceNodeParent) {
    return connectableLayers[sourceNodeParent].some(
      (layer: SubLayerTypes) => layer === targetNodeParent,
    );
  }

  return false;
};

export const isEdgeConnectable = ({
  sourceNode,
  targetNode,
  domains = [],
  getNodeById,
  mode,
}: EdgeConnectableProps) => {
  if (!isRiskModeConnectable(sourceNode, targetNode, mode)) {
    return false;
  }

  if (!isFunctionsLayerConnectable(sourceNode, targetNode, getNodeById)) {
    return false;
  }

  if (!isCapabilityLayerConnectable(sourceNode, targetNode, getNodeById)) {
    return false;
  }

  if (!areDomainsCorrect(sourceNode, targetNode, domains)) {
    return false;
  }

  return areLayersConnectable(sourceNode, targetNode);
};
