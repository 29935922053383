import { styled } from '@mui/material/styles';

export const AppStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  height: '100dvh',
});

export const ContentWrapper = styled('div')({
  width: '100%',
  height: '100%',
});
