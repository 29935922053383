import React from 'react';
import BasicSelectDropdown from '@components/selectors/BasicSelectDropdown';
import { Modes } from '@constants/canvas/layers';
import useModesDropdown from '@views/Project/components/Toolbox/ModesDropdown/useModesDropdown';
import { modeTooltips } from '@views/Project/constants/toolbox';

const ModesDropdown = () => {
  const { mode, handleModeSelect, isReadOnly, options } = useModesDropdown();

  return (
    <BasicSelectDropdown
      options={options}
      value={mode ?? ''}
      onChange={(m) => handleModeSelect(m as Modes)}
      button={{ id: 'mode-selector', sx: { width: 240 } }}
      disabled={isReadOnly}
      popover={{ horizontalAlign: 'left' }}
      tooltip={{
        placement: 'bottom-start',
        ...(mode ? modeTooltips[mode] : {}),
      }}
    />
  );
};

export default ModesDropdown;
