import React, { FC, useEffect, useMemo, useState } from 'react';
import { Node } from 'reactflow';
import { CustomDialog } from '@components/Dialogs/CustomDialog';
import { ExistingNodeItem } from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/ExistingNodeItem';
import {
  formatData,
  shouldAddChild,
} from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/helpers';
import {
  AddExistingNodesModalProps,
  AssignedNode,
  FormattedData,
} from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/types';
import EmptyControlsModal from '@components/MainStage/ContextMenu/SubLayerContextMenu/EmptyControlsModal';
import useStore from '@components/MainStage/store';
import { compatibleLayerTypeByLayerId } from '@constants/canvas/general';
import { SubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useAssignNodesToAssetMutation,
  useGetAssetCompatibleNodesQuery,
} from '@store/services/nodes';
import { themePalette } from '@theme/muiTheme';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import { calculatePositionForNewNode } from '@utils/canvasHelpers';
import { parseErrorResponse } from '@utils/helpers';
import { PlusIcon } from '@utils/iconsDefs';

export const AddExistingNodesModal: FC<AddExistingNodesModalProps> = ({
  assetId,
  open,
  onClose,
  layerId,
}) => {
  const [formattedData, setFormattedData] = useState<FormattedData>({});
  const { showToast } = useToast();
  const { nodes, updateNode } = useStore();
  const {
    toolbox: { mode },
  } = useProject();
  const allNodes = [...nodes];
  const {
    data,
    isLoading: isNodesLoading,
    isFetching: isNodesFetching,
    isUninitialized,
  } = useGetAssetCompatibleNodesQuery(
    assetId && open
      ? {
          nodeId: assetId,
          type: compatibleLayerTypeByLayerId[layerId],
        }
      : skipToken,
  );
  const [assignNodesToAsset] = useAssignNodesToAssetMutation();
  const saveResponseToRedux = useSaveEntitiesResponse();

  const getAssignedNodes = (formattedData: FormattedData) => {
    const assignedNodes: AssignedNode[] = [];

    Object.values(formattedData).forEach((parent) => {
      parent.children.forEach((child) => {
        if (shouldAddChild(child, assignedNodes)) {
          const position = calculatePositionForNewNode(
            layerId as SubLayerTypes,
            allNodes,
            updateNode,
          );
          assignedNodes.push({
            id: child.id,
            x: position.x,
            y: position.y,
          });
          allNodes.push({
            id: child.id,
            position,
            parentNode: layerId,
          } as Node);
        }
      });
    });
    return assignedNodes;
  };

  const handleAddNodesToModel = async () => {
    try {
      if (!assetId) return;

      const assignedNodes = getAssignedNodes(formattedData);

      const response = await assignNodesToAsset({
        assetId,
        nodes: assignedNodes,
      }).unwrap();

      saveResponseToRedux(response);

      CanvasModelRenderer.redrawAfterSubLayerChanges(
        mode!,
        layerId as SubLayerTypes,
      );

      onClose?.();

      const message =
        assignedNodes.length === 1
          ? 'entity has been successfully added to the model.'
          : 'entities have been successfully added to the model.';

      showToast(`${assignedNodes.length} ${message}`, 'success');
    } catch (error: any) {
      showToast(error.message || parseErrorResponse(error), 'error');
    }
  };

  const initialFormattedData = useMemo(() => {
    if (!data) return {};
    return formatData(data, allNodes);
  }, [data]);

  useEffect(() => {
    if (!data) return;

    setFormattedData(initialFormattedData);
  }, [initialFormattedData]);

  const isAddNodesButtonDisabled = useMemo(() => {
    const isDifferentSelected = Object.values(formattedData).some((parent) => {
      const initialParent = initialFormattedData[parent.id];

      return parent.children.some(
        (child, index) =>
          child.isChecked !== initialParent?.children[index].isChecked,
      );
    });

    return (
      !isDifferentSelected ||
      !Object.values(formattedData).some((parent) =>
        parent.children.some((child) => child.isChecked),
      ) ||
      Object.values(formattedData).every((parent) =>
        parent.children.every((child) => child.isDisabled),
      )
    );
  }, [formattedData, initialFormattedData, data]);

  if (isUninitialized || isNodesLoading || isNodesFetching) return null;

  if (!Object.values(data ?? {}).length) {
    return <EmptyControlsModal open={open} onClose={onClose} />;
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Add existing entities"
      sx={{ '& .MuiDialog-paper': { minWidth: 598, height: 538 } }}
    >
      <Divider sx={{ mt: 3 }} />

      {data && (
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          {Object.values(formattedData).map(({ id, name, children }) => {
            return (
              <ExistingNodeItem
                key={id}
                id={id}
                name={name}
                nodes={children}
                formattedData={formattedData}
                setFormattedData={setFormattedData}
              />
            );
          })}
        </Box>
      )}

      <Stack
        p={6}
        direction="row"
        alignItems="center"
        gap={4}
        borderTop={`1px solid ${themePalette.grey[500]}`}
      >
        <Button
          sx={{ flex: 1 }}
          size="large"
          variant="contained"
          onClick={handleAddNodesToModel}
          disabled={isAddNodesButtonDisabled}
        >
          <PlusIcon
            fill={
              isAddNodesButtonDisabled
                ? themePalette.white
                : themePalette.grey[1000]
            }
          />
          <Typography variant="h4" sx={{ ml: 2 }}>
            Add to the model
          </Typography>
        </Button>
      </Stack>
    </CustomDialog>
  );
};
