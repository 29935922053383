import { useCallback, useState } from 'react';
import useStore from '@components/MainStage/store';
import { Modes, SubLayerTypes } from '@constants/canvas/layers';
import { ProjectDrawerProps } from '@context/Project/types';
import useAssets from '@hooks/useAssets';
import usePromisedConfirmModal from '@hooks/usePromisedConfirmModal';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import { isElementLocal } from '@utils/drawers';

const useDrawer = (mode?: Modes | null) => {
  const { resetNewAssetMode } = useAssets();

  const {
    clearLocalNodes,
    unselectActiveNode,
    unselectActiveEdge,
    clearLocalEdges,
    getActiveNode,
    updateNodeLabel,
  } = useStore();

  const [drawer, setDrawer] = useState<ProjectDrawerProps | null>(null);
  const hideDrawer = useCallback(() => setDrawer(null), []);

  const [hasChanges, setHasChanges] = useState(false);
  const [isUnsavedModalOpen, { openModal, closeModal, confirmAction }] =
    usePromisedConfirmModal();

  const handleCloseDrawer = useCallback(
    async (forceOpenModal?: boolean, unselectEntity = true) => {
      if (forceOpenModal || hasChanges) await openModal();

      const { id = '', data, parentNode } = getActiveNode() ?? {};

      if (id) {
        updateNodeLabel(id, data?.dto?.name ?? '');
      }

      hideDrawer();
      setHasChanges(false);

      if (unselectEntity) {
        unselectActiveNode();
        unselectActiveEdge();
      }

      clearLocalNodes();
      clearLocalEdges();

      // Redraw layers if the new node was not saved
      if (isElementLocal(id) && mode) {
        CanvasModelRenderer.redrawAfterSubLayerChanges(
          mode,
          parentNode as SubLayerTypes,
        );
      }

      resetNewAssetMode();
    },
    [mode, hasChanges, hideDrawer],
  );

  return {
    drawer,
    setDrawer,
    hideDrawer,

    isUnsavedModalOpen,
    closeModal,
    confirmAction,
    handleCloseDrawer,

    hasChanges,
    setHasChanges,
  };
};

export default useDrawer;
