import React, { FC } from 'react';
import {
  Dialog,
  DialogProps,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { CloseIcon } from '@utils/iconsDefs';

export type CustomDialogProps = Omit<DialogProps, 'onClose'> & {
  title?: string;
  onClose?: () => void;
};

export const CustomDialog: FC<CustomDialogProps> = ({
  title,
  children,
  ...props
}) => {
  return (
    <Dialog {...props}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p="24px 24px 12px"
      >
        <Typography variant="h2">{title}</Typography>

        <IconButton onClick={props.onClose} size="small" sx={{ p: 1 }}>
          <CloseIcon fill={themePalette.grey[900]} width={14} height={14} />
        </IconButton>
      </Stack>

      {children}
    </Dialog>
  );
};
