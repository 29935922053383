export enum RelationshipTypes {
  SupportedBy = 'Supported by',
  Has = 'Has',
  Uses = 'Uses',
  AtTheStageOf = 'At the stage of',
  ThwartedBy = 'Thwarted by',
  ExposesTheAsset = 'Exposes the asset',
  RequiresFurther = 'Requires further',
  ResultsIn = 'Results in',
  CoveredIn = 'Covered in',
  Addresses = 'Addresses',
}

export enum WeightTypes {
  Auto = 'auto',
  Manual = 'manual',
}
export enum DependencyTypes {
  AND = 'AND',
  OR = 'OR',
  XOR = 'XOR',
}
