import { useState } from 'react';

const useExportState = () => {
  const [fileContent, setFileContent] = useState('');
  const [isError, setError] = useState(false);

  return {
    isError,
    fileContent,
    setFileContent,
    setError,
  };
};

export default useExportState;
