import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import ProjectRightDrawer from '@components/EntityDrawers/components/ProjectRightDrawer';
import {
  RelationshipDependencyOptions,
  RelationshipTypesOptions,
} from '@components/EntityDrawers/constants/common';
import {
  defaultRelationshipTypes,
  defaultValues,
  RelationshipFormValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Relationship/form';
import useCurrentANDRelations from '@components/EntityDrawers/hooks/useCurrentANDRelations';
import useDrawerInfo from '@components/EntityDrawers/hooks/useDrawerIfo';
import useSaveRelationship from '@components/EntityDrawers/hooks/useSaveRelationship';
import useWeightValueController from '@components/EntityDrawers/hooks/useWeightValueController';
import ControlledSwitch from '@components/Inputs/controllers/ControlledSwitch';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { DatePicker } from '@components/Inputs/DatePicker';
import useStore from '@components/MainStage/store';
import {
  InitiativesSubLayerTypes,
  LogicalSubLayerTypes,
  PhysicalSubLayerTypes,
} from '@constants/canvas/layers';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { DependencyTypes, WeightTypes } from '@constants/entities/relationship';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectDrawerChanges from '@context/Project/useProjectDrawerChanges';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { cutNumberPeriods } from '@utils/helpers';
import dayjs from 'dayjs';

type Props = {
  type:
    | ProjectDrawerTypes.AddRelationship
    | ProjectDrawerTypes.EditRelationship;
};

const initiativeConnectLayers = [
  LogicalSubLayerTypes.Services,
  PhysicalSubLayerTypes.Capabilities,
  PhysicalSubLayerTypes.Functions,
];

export const RelationshipDrawer: FC<Props> = ({ type }) => {
  const {
    handleCloseDrawer,
    viewOnly,
    toolbox: { mode },
  } = useProject();

  const { getActiveEdge, nodes } = useStore();
  const activeEdge = getActiveEdge();

  const { open, editMode, drawerTitle, badge } = useDrawerInfo({
    type,
    editType: ProjectDrawerTypes.EditRelationship,
    newTitle: 'Add new relationship',
    editTitle: 'Edit relationship',
    insightsTitle: 'Relationship metadata',
  });

  const form = useForm<RelationshipFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    watch,
    formState: { isValid, isDirty },
    clearErrors,
    reset,
  } = form;

  useProjectDrawerChanges(isDirty, open);
  const { dependency } = watch();

  const { isLastANDAuto, isFirstANDAuto } = useCurrentANDRelations(
    dependency as DependencyTypes,
    editMode,
  );

  useEffect(() => {
    clearErrors();

    if (open && editMode && activeEdge?.data?.dto) {
      const {
        type,
        dependency,
        description,
        weight,
        weight_type,
        valid_status,
      } = activeEdge.data.dto;

      setTimeout(
        () =>
          reset({
            type,
            dependency,
            valid_status,
            description: description || '',
            weight: cutNumberPeriods(weight),
            weight_manual: weight_type === WeightTypes.Manual,
            isLastANDAuto,
          }),
        0,
      );

      return;
    }

    reset({
      ...defaultValues,
      type: defaultRelationshipTypes[mode],
    });
  }, [open, activeEdge?.id, editMode, isLastANDAuto, mode]);

  useWeightValueController(form, open, editMode);

  const { handleSave, isLoading } = useSaveRelationship({
    editMode,
    form,
  });

  const weightTypeText = useMemo(() => {
    if (isLastANDAuto) {
      return 'Weight modification is disabled since this is the last auto-assigned AND relationship';
    }

    if (isFirstANDAuto) {
      return 'Weight modification is disabled until another AND relationship is added';
    }

    return '';
  }, [isLastANDAuto, activeEdge?.id, isFirstANDAuto, dependency]);

  const isInitiativeRelation = useMemo(() => {
    if (!activeEdge?.data?.dto) return false;

    const { parent_id, child_id } = activeEdge.data.dto;

    const parent = nodes.find((node) => node.id === parent_id);
    const child = nodes.find((node) => node.id === child_id);

    if (parent && child) {
      const isParentLogicOrPhysical = initiativeConnectLayers.includes(
        parent.parentNode as LogicalSubLayerTypes,
      );

      const isChildInitiative =
        child.parentNode === InitiativesSubLayerTypes.ChangeInitiatives;

      return isParentLogicOrPhysical && isChildInitiative;
    }

    return false;
  }, [activeEdge?.id, activeEdge]);

  const handleClose = useCallback(() => {
    handleCloseDrawer();
  }, [handleCloseDrawer]);

  if (!open) return null;

  return (
    <FormProvider {...form}>
      <ProjectRightDrawer
        title={drawerTitle}
        onClose={handleClose}
        disabledSave={!isValid || isLoading || (editMode && !isDirty)}
        onSave={handleSave}
        badge={badge}
      >
        <ControlledTextFieldSelect
          name="type"
          label="Relationship type"
          disabled={viewOnly}
          required
          options={RelationshipTypesOptions}
        />

        <ControlledTextField
          name="description"
          label="Relationship description"
          placeholder="Relationship description"
          disabled={viewOnly}
        />

        <ControlledTextFieldSelect
          name="dependency"
          label="Relationship dependency"
          disabled={isLastANDAuto || viewOnly || isInitiativeRelation}
          required
          helperText={
            isLastANDAuto
              ? 'Changing relationship dependency is disabled. This is the last auto-assigned AND relationship. To update dependency, set an auto-assigned weight for another AND relationship from the parent Entity first.'
              : ''
          }
          options={RelationshipDependencyOptions}
        />

        <Stack gap={4}>
          <ControlledTextField
            name="weight"
            label="Relationship weight in %"
            placeholder="Relationship weight"
            required
            helperText={
              watch('weight_manual')
                ? ''
                : 'The system has predefined this weight. Modifying it will impact the weight of other "and" relationships'
            }
            InputProps={{ readOnly: !watch('weight_manual') }}
            disabled={viewOnly}
          />

          <ControlledSwitch
            name="weight_manual"
            label="Modify weight"
            helperText={weightTypeText}
            disabled={isFirstANDAuto || isLastANDAuto || viewOnly}
          />
        </Stack>

        <ValidationStatus name="valid_status" disabledAll />

        {editMode && (
          <>
            <DatePicker
              readOnly
              label="Date created"
              value={dayjs(activeEdge?.data?.dto?.created_at)}
            />

            <DatePicker
              readOnly
              label="Date reviewed"
              placeholder=" "
              value={
                activeEdge?.data?.dto?.reviewed_at
                  ? dayjs(activeEdge?.data?.dto?.reviewed_at)
                  : null
              }
            />
          </>
        )}
      </ProjectRightDrawer>
    </FormProvider>
  );
};
