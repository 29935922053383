import { useEffect } from 'react';
import { DefaultValues, FieldValues, UseFormReturn } from 'react-hook-form';
import { Node } from 'reactflow';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import useTransformContext, {
  TransformContext,
} from '@components/EntityDrawers/hooks/useTransformContext';
import { EntityData } from '@components/MainStage/types';
import { NodeDTO } from '@store/services/nodes/types';

export type NodeFormResetOptions<T extends FieldValues, M> = {
  open: boolean;
  form: UseFormReturn<T>;
  editMode: boolean;
  transformNodeDtoToFormValues: (
    nodeDto: NodeDTO<M>,
    context: TransformContext,
  ) => T;
  defaultValues:
    | DefaultValues<T>
    | ((context: TransformContext) => DefaultValues<T>);
};

const useNodeFormReset = <T extends FieldValues, M>({
  open,
  form: { reset, clearErrors },
  transformNodeDtoToFormValues,
  defaultValues,
  editMode,
}: NodeFormResetOptions<T, M>) => {
  const activeNode = useSourceNode() as Node<EntityData<NodeDTO<any>>>;
  const { context } = useTransformContext();

  useEffect(() => {
    clearErrors();

    if (!activeNode) return;

    if (!(open && editMode)) {
      return reset(
        typeof defaultValues === 'function'
          ? defaultValues(context)
          : defaultValues,
      );
    }

    reset(
      transformNodeDtoToFormValues(activeNode.data.dto as NodeDTO<M>, context),
    );

    return () => {
      clearErrors();
      reset();
    };
  }, [activeNode?.id, open, editMode]);
};

export default useNodeFormReset;
