import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useAssets from '@hooks/useAssets';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import useToggle from '@hooks/useToggle';
import { useDeleteNodeMutation } from '@store/services/nodes';
import { parseErrorResponse } from '@utils/helpers';
import useAssetsList from '@views/Project/hooks/useAssetsList';

const useDeleteAsset = () => {
  const { showToast } = useToast();
  const {
    hideDrawer,
    toolbox: { mode },
  } = useProject();
  const { selectedAssetId, resetAsset } = useAssets();
  const { assetOptions } = useAssetsList();

  const saveResponseToRedux = useSaveEntitiesResponse();
  const [deleteNodeRequest] = useDeleteNodeMutation();

  const [isConfirmDeleteAssetOpen, confirmDeleteAssetControls, deleteAssetId] =
    useToggle<string | null>();

  const handleDeleteAsset = async () => {
    confirmDeleteAssetControls.off();
    confirmDeleteAssetControls.setData(null);

    try {
      if (!mode) throw new Error('No selected mode');
      if (!deleteAssetId) throw new Error('No selected asset');

      const response = await deleteNodeRequest({
        id: deleteAssetId,
        mode,
        assetId: selectedAssetId || undefined,
      }).unwrap();

      if (response) {
        saveResponseToRedux(response);
      }

      if (selectedAssetId === deleteAssetId) resetAsset();

      showToast('The entity has been successfully deleted.', 'success');
      hideDrawer();
    } catch (e) {
      showToast(parseErrorResponse(e), 'error');
    }
  };

  const deleteAssetName =
    assetOptions.find((asset) => asset.value === deleteAssetId)?.label ??
    deleteAssetId ??
    '';

  return {
    isConfirmDeleteAssetOpen,
    confirmDeleteAssetControls,
    handleDeleteAsset,
    deleteAssetName,
  };
};

export default useDeleteAsset;
