import React, { FC } from 'react';
import { OperationalModelOptions } from '@components/EntityDrawers/constants/common';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';

const OperationalModelDropdown: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextFieldSelect
      name={CommonKeys.OperationalModel}
      label="Operating model"
      placeholder="Choose operating model"
      disabled={viewOnly}
      options={OperationalModelOptions}
      resetOnDoubleClick
    />
  );
};

export default OperationalModelDropdown;
