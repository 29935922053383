import { useEffect } from 'react';
import useStore from '@components/MainStage/store';

const useDeleteKey = (
  type: 'node' | 'edge',
  onDeleteOpen: (id: string) => void,
) => {
  const { getActiveNode, getActiveEdge } = useStore();

  useEffect(() => {
    const handleDeleteFromKeyboard = (evt: KeyboardEvent) => {
      if (evt.key === 'Delete') {
        const activeNode = getActiveNode();

        if (
          activeNode &&
          !activeNode.id.startsWith('local') &&
          type === 'node'
        ) {
          return onDeleteOpen(activeNode.id);
        }

        const activeEdge = getActiveEdge();

        if (
          activeEdge &&
          !activeEdge.id.startsWith('local') &&
          type === 'edge'
        ) {
          onDeleteOpen(activeEdge.id);
        }
      }
    };

    const canvas = document.querySelector('#canvas') as unknown as Document;

    canvas?.addEventListener('keydown', handleDeleteFromKeyboard);

    return () => {
      canvas?.removeEventListener('keydown', handleDeleteFromKeyboard);
    };
  }, []);
};

export default useDeleteKey;
