import { useState } from 'react';
import { Modes } from '@constants/canvas/layers';
import { ProjectToolBox, ProjectViews } from '@context/Project/types';
import { filtersSubject } from '@context/ProjectFilters/FiltersSubject';
import { BehaviorSubject } from 'rxjs';

export const modeSubject = new BehaviorSubject<Modes | null>(null);

const useToolbox = () => {
  const [toolbox, setToolbox] = useState<ProjectToolBox>({
    mode: null,
    domains: [],
    view: ProjectViews.Model,
  });

  const changeToolbox = (newToolbox: Partial<ProjectToolBox>) => {
    setToolbox((prev) => ({ ...prev, ...newToolbox }));
  };

  const handleViewChange = (view: ProjectViews) => changeToolbox({ view });

  const handleModeChange = (mode: Modes | null) => {
    changeToolbox({ mode });
    modeSubject.next(mode);
  };

  const handleDomainsChange = (domains: string[]) => {
    changeToolbox({ domains });
    filtersSubject.next({ ...filtersSubject.value, domains });
  };

  return {
    toolbox,
    handleViewChange,
    handleModeChange,
    handleDomainsChange,
  };
};

export default useToolbox;
