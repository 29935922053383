import Box from '@mui/material/Box';
import { themePalette } from '@theme/muiTheme';
import { styled } from 'styled-components';

export const Wrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px 0 24px 0;
  background: ${themePalette.white};
`;

export const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 24px;
  gap: 24px;
`;
