import {
  CommonKeys,
  CoreFormKeys,
} from '@components/EntityDrawers/constants/keys';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import { ShapeType } from '@constants/types';
import { YupString } from '@constants/validation';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import * as yup from 'yup';

export type AssessmentFormValues = yup.InferType<typeof validationSchema>;

export const AssetFields = [...CoreFormKeys, CommonKeys.Description] as const;

const AssetShape = {
  [AssetKeys.Identifier]: YupString(),
  [AssetKeys.Criticality]: YupString(),
  [AssetKeys.BusinessAttributes]: yup.array().of(yup.object()),
} as const;

export const validationSchema = generateSchema<
  ShapeType<typeof AssetFields> & typeof AssetShape
>({
  ...getYups(...AssetFields),
  ...AssetShape,
});

export const defaultValues: AssessmentFormValues = {
  ...getDefs(...AssetFields),
  [AssetKeys.Identifier]: '',
  [AssetKeys.Criticality]: '',
  [AssetKeys.BusinessAttributes]: [],
};
