import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { RelationshipFormValues } from '@components/EntityDrawers/drawers/Relationship/form';
import useCurrentANDRelations from '@components/EntityDrawers/hooks/useCurrentANDRelations';
import useStore from '@components/MainStage/store';
import { DependencyTypes, WeightTypes } from '@constants/entities/relationship';
import { cutNumberPeriods } from '@utils/helpers';

const MAX_WEIGHT_SUM = 100;

const useWeightValueController = (
  { watch, setValue, trigger }: UseFormReturn<RelationshipFormValues>,
  open: boolean,
  editMode = false,
) => {
  const { getActiveEdge } = useStore();
  const activeEdge = getActiveEdge();

  const {
    weight,
    weight_type: initialWeightType,
    dependency: initialDependency,
  } = activeEdge?.data?.dto ?? {};

  const initialWeight = cutNumberPeriods(weight || 0);

  const { dependency, weight_manual } = watch();

  const { manualSum, autoCount, isLastANDAuto } = useCurrentANDRelations(
    dependency as DependencyTypes,
    editMode,
  );

  const freeWeight = MAX_WEIGHT_SUM - manualSum;

  const calculateNewEdgeWeight = () => {
    setValue('maxWeight', MAX_WEIGHT_SUM, { shouldValidate: true });

    if (weight_manual) {
      setValue('maxWeight', freeWeight, { shouldValidate: true });

      return cutNumberPeriods(freeWeight);
    }

    return cutNumberPeriods(freeWeight / (autoCount + 1));
  };

  const calculateEditedEdgeWeight = () => {
    // If initial dependency was not AND -
    // we don`t add initialWeight to free weight (as OR, XOR could have 0-100 range)
    const addCurrentRelationWeight =
      initialDependency === DependencyTypes.AND ? initialWeight : '0';

    const maximumWeightForRelation = cutNumberPeriods(
      freeWeight + parseFloat(addCurrentRelationWeight),
    );

    if (weight_manual) {
      // Weight set as Manual (by default or by user)

      // Set maximum weight for manual (current relation weight + sum of all other auto relations)
      setValue('maxWeight', parseFloat(maximumWeightForRelation), {
        shouldValidate: true,
      });

      // if relation has Manual type by default - we just return initial weight
      if (
        initialWeightType === WeightTypes.Manual &&
        initialDependency === DependencyTypes.AND
      ) {
        return initialWeight;
      }

      // if weight type set as Manual, but initial type is Auto -
      // we return `maximum weight` as predefined value
      return maximumWeightForRelation;
    }

    // Weight set as Auto (by default or by user)

    // Set maximum weight as 100 - just to reset previous value.
    // There is no need to set maximum weight for auto relations, because user can't change it
    setValue('maxWeight', MAX_WEIGHT_SUM, { shouldValidate: true });

    // if relation has Auto type by default - we just return initial weight
    if (
      initialWeightType === WeightTypes.Auto &&
      initialDependency === DependencyTypes.AND
    ) {
      return initialWeight;
    }

    // Calculate and return weight for auto relation if initial type is Manual
    return cutNumberPeriods(
      parseFloat(maximumWeightForRelation) / (autoCount + 1),
    );
  };

  useEffect(() => {
    try {
      if (dependency === DependencyTypes.AND) {
        if (!autoCount) {
          setValue('weight_manual', false);
        }

        const weight = editMode
          ? calculateEditedEdgeWeight()
          : calculateNewEdgeWeight();

        return setValue('weight', weight, { shouldValidate: true });
      }

      const isTypeManualCurrentAndInitial =
        weight_manual && initialWeightType === WeightTypes.Manual;

      const isTypeAutoCurrentAndInitial =
        !weight_manual && initialWeightType === WeightTypes.Auto;

      if (
        (isTypeManualCurrentAndInitial || isTypeAutoCurrentAndInitial) &&
        initialDependency !== DependencyTypes.AND
      ) {
        return setValue('weight', initialWeight);
      }

      return setValue('weight', cutNumberPeriods(MAX_WEIGHT_SUM));
    } finally {
      trigger();
    }
  }, [
    initialWeight,
    initialWeightType,
    initialDependency,
    dependency,
    weight_manual,
    editMode,
    open,
    isLastANDAuto,
    activeEdge?.id,
  ]);
};

export default useWeightValueController;
