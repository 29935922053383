import { Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

export const NewNodeStyled = styled('div')`
  width: 212px;
  height: 48px;
  position: relative;
  background-color: ${themePalette.white};
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%239c9c9b' stroke-width='2' stroke-dasharray='3%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
`;

export const NodeContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const IconWrapper = styled('div')`
  width: 48px;
  height: calc(100% - 2px);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-right: 1px solid ${themePalette.grey[500]};
  background: ${themePalette.white};
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
`;

export const TextWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StyledText = styled(Typography)`
  max-width: 140px;
  line-height: 20px;
  text-align: center;
  color: ${themePalette.grey[1000]};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;
