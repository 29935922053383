import { createContext, useContext } from 'react';

type DrawerContextType = {
  isIncomplete: boolean | undefined;
  isReviewRequired: boolean;
  editMode: boolean;
};

const DrawerContext = createContext<DrawerContextType>({
  isIncomplete: false,
  isReviewRequired: false,
  editMode: false,
});

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawerContext must be used within DrawerProvider');
  }

  return context;
};

export const DrawerProvider = DrawerContext.Provider;
