import React, { FC } from 'react';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

const AssessorInput: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextField
      name={RiskKeys.Assessor}
      label="Risk assessor"
      placeholder="Risk assessor"
      disabled={viewOnly}
      required
    />
  );
};

export default AssessorInput;
