import { Edge, Node } from 'reactflow';
import { NodesTypes } from '@constants/canvas/general';

const traverseNodes = (
  allNodes: Node[],
  connectedNodes: Set<string>,
  nodeId: string,
  findChildren: boolean,
  findParents: boolean,
  ignoreIncomplete: boolean,
) => {
  const currentNode = allNodes.find(
    (node) =>
      node.id === nodeId && (node.data.canvas.collapsed ? true : !node.hidden),
  );

  if (currentNode && !connectedNodes.has(currentNode.id)) {
    if (!currentNode.data.dto.is_complete && !ignoreIncomplete) {
      return;
    }

    connectedNodes.add(currentNode.id);

    if (findChildren && currentNode.data.dto.childrenIds) {
      currentNode.data.dto.childrenIds.forEach((childId: string) => {
        traverseNodes(
          allNodes,
          connectedNodes,
          childId,
          true,
          false,
          ignoreIncomplete,
        );
      });
    }

    if (findParents && currentNode.data.dto.parentsIds) {
      currentNode.data.dto.parentsIds.forEach((parentId: string) => {
        traverseNodes(
          allNodes,
          connectedNodes,
          parentId,
          false,
          true,
          ignoreIncomplete,
        );
      });
    }
  }
};

export const filterConnectedNodes = (
  allNodes: Node[],
  allEdges: Edge[],
  startNodeId: string,
): { connectedNodes: Node[]; connectedEdges: Edge[] } => {
  const connectedNodes = new Set<string>(
    allNodes.reduce<string[]>((acc, node) => {
      if (node.type !== NodesTypes.CustomNode) {
        acc.push(node.id);
      }

      return acc;
    }, []),
  );

  traverseNodes(allNodes, connectedNodes, startNodeId, true, true, true);

  const connectedNodesWithHidden = allNodes.map((node) => ({
    ...node,
    hidden:
      node.hidden ||
      node.data.canvas?.unconnected ||
      !connectedNodes.has(node.id),
    data: {
      ...node.data,
      canvas: {
        ...node.data.canvas,
        unconnected:
          node.data.canvas?.unconnected || !connectedNodes.has(node.id),
      },
    },
  }));

  const connectedEdges = allEdges.map((edge: Edge) => ({
    ...edge,
    hidden:
      edge.hidden ||
      !connectedNodes.has(edge.source) ||
      !connectedNodes.has(edge.target),
  }));

  return {
    connectedNodes: connectedNodesWithHidden,
    connectedEdges,
  };
};

export const filterNodesForMaturity = (
  allNodes: Node[],
  startNodeId: string,
): { connectedNodes: Node[] } => {
  const connectedNodes = new Set<string>(
    allNodes
      .filter((node) => node.type !== NodesTypes.CustomNode)
      .map((node) => node.id),
  );

  traverseNodes(allNodes, connectedNodes, startNodeId, true, false, false);

  const connectedDisabledNodes = allNodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      canvas: {
        ...node.data.canvas,
        disabled: node.data.canvas?.disabled || !connectedNodes.has(node.id),
      },
    },
  }));

  return {
    connectedNodes: connectedDisabledNodes,
  };
};
