import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import useScoreColor from '@components/EntityDrawers/drawers/Risk/hooks/useScoreColor';
import { Chip } from '@mui/material';

type Props = {
  fieldKey: string;
};

const RiskScoreChip: FC<Props> = ({ fieldKey }) => {
  const { watch } = useFormContext();
  const score = watch(fieldKey);

  const chipVariant = useScoreColor(score);

  return (
    <Chip
      label={`LOC ${score}`}
      variant={chipVariant}
      sx={{ width: 'fit-content' }}
    />
  );
};

export default RiskScoreChip;
