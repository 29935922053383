import React from 'react';
import { useFormContext } from 'react-hook-form';
import useLikelihoodOptions from '@components/EntityDrawers/drawers/Risk/hooks/useLikelihoodOptions';
import useRiskScore from '@components/EntityDrawers/drawers/Risk/hooks/useRiskScore';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';

const InherentLikelihood = () => {
  const { viewOnly } = useProject();
  const { watch, setValue, trigger } = useFormContext();
  const { calculateRiskScore } = useRiskScore();
  const options = useLikelihoodOptions();

  const handleScoreCalculate = (likelihood: string) => {
    const score = calculateRiskScore({
      likelihood,
      impact: watch(RiskKeys.InherentImpact),
    });

    setValue(RiskKeys.InherentScore, score);

    if (!watch(RiskKeys.HasCapability)) {
      setValue(RiskKeys.ResidualLikelihood, likelihood);
      setValue(RiskKeys.ResidualScore, score);
    }

    trigger(RiskKeys.ResidualScore);
  };

  return (
    <ControlledTextFieldSelect
      name={RiskKeys.InherentLikelihood}
      label="Inherent risk likelihood"
      placeholder="Choose inherent risk likelihood"
      disabled={viewOnly}
      options={options}
      resetOnDoubleClick
      required
      changeHandler={handleScoreCalculate}
    />
  );
};

export default InherentLikelihood;
