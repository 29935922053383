import React, { memo } from 'react';
import ActionList from '@components/ActionsMenu/ActionList';
import { useProject } from '@context/Project/ProjectProvider';
import useToggle from '@hooks/useToggle';
import { Popover } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { MoreIcon } from '@utils/iconsDefs';
import { AdditionalSettingsStyled } from '@views/Project/components/AdditionalSettings/AdditionalSettings.styled';
import useAdditionalMenuItems from '@views/Project/components/AdditionalSettings/useAdditionalMenuItems';
import {
  ExportAsImageModal,
  ExportAssetReportModal,
  ExportLayersReportModal,
} from '@views/ReportsModel';
import { ExportRiskRegisterReportModal } from '@views/ReportsModel/components/ExportRiskRegisterReportModal';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

export const AdditionalSettings = memo(() => {
  const {
    isVersionsShown,
    toolbox: { mode },
  } = useProject();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'project-additional-settings',
  });

  const [isExportModelImageOpen, exportModelImageControls] = useToggle();
  const [isExportLayersOpen, exportLayersControls] = useToggle();
  const [isExportAssetOpen, exportAssetControls] = useToggle();
  const [isExportRegisterOpen, exportRegisterControls] = useToggle();

  const items = useAdditionalMenuItems({
    onOpenExportAsImage: exportModelImageControls.on,
    onOpenExportLayersReport: exportLayersControls.on,
    onOpenExportRiskRegisterReport: exportRegisterControls.on,
    onOpenExportAssetReport: exportAssetControls.on,
  });

  const isAdditionalSettingsDisabled = isVersionsShown;

  return (
    <>
      {mode && isExportModelImageOpen && (
        <ExportAsImageModal
          open={isExportModelImageOpen}
          onClose={exportModelImageControls.off}
        />
      )}

      {isExportLayersOpen && (
        <ExportLayersReportModal
          open={isExportLayersOpen}
          onClose={exportLayersControls.off}
        />
      )}

      {isExportAssetOpen && (
        <ExportAssetReportModal
          open={isExportAssetOpen}
          onClose={exportAssetControls.off}
        />
      )}

      {isExportRegisterOpen && (
        <ExportRiskRegisterReportModal
          open={isExportRegisterOpen}
          onClose={exportRegisterControls.off}
        />
      )}

      <AdditionalSettingsStyled
        $isActive={popupState.isOpen}
        disabled={isAdditionalSettingsDisabled}
        $isDisabled={isAdditionalSettingsDisabled}
        {...bindTrigger(popupState)}
      >
        <MoreIcon
          fill={themePalette.grey[isAdditionalSettingsDisabled ? 700 : 1000]}
        />
      </AdditionalSettingsStyled>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{ paper: { sx: { width: 220, mt: 3 } } }}
      >
        <ActionList items={items} onClose={popupState.close} />
      </Popover>
    </>
  );
});
