import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { DateFormats } from '@constants/dates';

type TwoDatesValidationOptions = {
  form: UseFormReturn<any>;
  startKeyName: string;
  endKeyName: string;
  startName?: string;
  endName?: string;
  error: (key?: string) => string;
  canBeEqual?: boolean;
  ignore?: boolean;
};

const useTwoDatesValidation = ({
  form,
  startKeyName,
  endKeyName,
  endName,
  error,
  canBeEqual = false,
  ignore,
}: TwoDatesValidationOptions) => {
  const { watch, setError, clearErrors } = form;

  useEffect(() => {
    if (ignore) return clearErrors;

    clearErrors([startKeyName, endKeyName]);
    const startDate = watch(startKeyName);
    const endDate = watch(endKeyName);

    if (!startDate || !endDate) return clearErrors;

    const isIncorrectDates =
      endDate.isBefore(startDate, 'day') ||
      (!canBeEqual && endDate.isSame(startDate, 'day'));

    if (isIncorrectDates) {
      if (endDate?.isValid()) {
        setTimeout(() => {
          setError(endKeyName, { message: error(endName) });
        }, 0);
      }
    }

    return clearErrors;
  }, [
    watch(startKeyName)?.format(DateFormats[1]),
    watch(endKeyName)?.format(DateFormats[1]),
  ]);
};

export default useTwoDatesValidation;
