import React from 'react';
import { useFormContext } from 'react-hook-form';
import useLikelihoodOptions from '@components/EntityDrawers/drawers/Risk/hooks/useLikelihoodOptions';
import useRiskScore from '@components/EntityDrawers/drawers/Risk/hooks/useRiskScore';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';

const ResidualLikelihood = () => {
  const { viewOnly } = useProject();
  const { watch, setValue } = useFormContext();
  const { calculateRiskScore } = useRiskScore();
  const options = useLikelihoodOptions();

  const handleScoreCalculate = (likelihood: string) => {
    const score = calculateRiskScore({
      likelihood,
      impact: watch(RiskKeys.ResidualImpact),
    });

    setValue(RiskKeys.ResidualScore, score, { shouldValidate: true });
  };

  return (
    <ControlledTextFieldSelect
      name={RiskKeys.ResidualLikelihood}
      label="Residual risk likelihood"
      placeholder="Choose residual risk likelihood"
      disabled={viewOnly || !watch(RiskKeys.HasCapability)}
      options={options}
      resetOnDoubleClick
      required
      changeHandler={handleScoreCalculate}
    />
  );
};

export default ResidualLikelihood;
