import React from 'react';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import useBusinessAttributesOptions from '@components/EntityDrawers/hooks/useBusinessAttributesOptions';
import ControlledAutocomplete from '@components/Inputs/controllers/ControlledAutocomplete';
import { useProject } from '@context/Project/ProjectProvider';

const BusinessAttributesInput = () => {
  const { viewOnly } = useProject();

  const { options, isLoading } = useBusinessAttributesOptions();

  return (
    <ControlledAutocomplete
      name={AssetKeys.BusinessAttributes}
      label="Business attributes"
      placeholder="Select business attributes"
      options={options}
      loading={isLoading}
      readOnly={viewOnly}
      multiple
    />
  );
};

export default BusinessAttributesInput;
