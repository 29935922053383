import React, { FC, memo } from 'react';
import { ContextMenu } from '@components/MainStage/ContextMenu/ContextMenu';
import useDeleteEdgeCheck from '@components/MainStage/hooks/useDeleteEdgeCheck';
import { ContextMenuProps } from '@components/MainStage/types';
import { useAppDispatch } from '@hooks/store';
import useCanEditModel from '@hooks/useCanEditModel';
import { openModal } from '@store/slices/deleteRelationSlice';
import { themePalette } from '@theme/muiTheme';
import { TrashIcon } from '@utils/iconsDefs';

const EdgeContextMenu: FC<ContextMenuProps> = ({ isOpen, menu, onClose }) => {
  const dispatch = useAppDispatch();
  const canDeleteRelation = useCanEditModel();

  const { checkIsCapabilityFunctionEdge, checkIsExistedRemediationEdge } =
    useDeleteEdgeCheck();

  const menuItems = [
    ...(canDeleteRelation
      ? [
          {
            icon: <TrashIcon />,
            text: 'Delete',
            color: themePalette.system.error,
            disableHoverColor: true,
            onClick: () => {
              onClose();

              if (checkIsCapabilityFunctionEdge()) return;
              if (checkIsExistedRemediationEdge()) return;

              if (menu?.id) {
                dispatch(openModal(menu?.id));
              }
            },
          },
        ]
      : []),
  ];

  if (menuItems.length === 0) return null;

  return isOpen && menu && menu.type === 'edge' ? (
    <ContextMenu menuItems={menuItems} {...menu} />
  ) : null;
};

export default memo(EdgeContextMenu);
