import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ActionList from '@components/ActionsMenu/ActionList';
import BasePopover from '@components/ActionsMenu/BasePopover';
import MenuItem from '@components/ActionsMenu/MenuItem';
import { IActionItem } from '@components/ActionsMenu/types';
import { SxProps } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';
import PopupState from 'material-ui-popup-state';
import { bindPopover, bindTrigger } from 'material-ui-popup-state/hooks';

type Props = {
  item: IActionItem;
  onClose?: () => void;
  popoverSx?: SxProps;
};

const MIN_SUB_MENU_SPACE = 230;
const DEFAULT_LIST_WIDTH = 220;
const SUB_OFFSET = 4;

const MenuSubItem: FC<Props> = ({ item, onClose, popoverSx }) => {
  const listRef = useRef<HTMLElement>();

  const [horizontal, setHorizontal] = useState<'right' | 'left' | null>(null);
  const [offset, setOffset] = useState(0);

  const itemRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setTimeout(() => {
      const distanceToRight =
        document.body.clientWidth - node.getBoundingClientRect().right;

      setHorizontal(distanceToRight < MIN_SUB_MENU_SPACE ? 'left' : 'right');
    }, 100);
  }, []);

  useEffect(() => {
    if (horizontal === 'left') {
      setOffset(
        (listRef.current?.clientWidth ?? DEFAULT_LIST_WIDTH) + SUB_OFFSET,
      );
    }

    if (horizontal === 'right') {
      setOffset(-SUB_OFFSET);
    }
  }, [horizontal]);

  return (
    <PopupState popupId={item.text} variant="popover">
      {(popupState) => {
        const { onClick } = bindTrigger(popupState);

        return (
          <>
            <MenuItem
              ref={itemRef}
              item={{ ...item, onClick }}
              endIcon={
                <DropdownIcon
                  style={{ transform: 'rotate(-90deg)' }}
                  width={24}
                  height={24}
                  fill={
                    popupState.isOpen ? themePalette.grey[1000] : 'currentColor'
                  }
                />
              }
            />

            <BasePopover
              mt={0}
              {...bindPopover(popupState)}
              transformOrigin={{
                vertical: 0,
                horizontal: offset,
              }}
              anchorOrigin={
                horizontal ? { vertical: 'top', horizontal } : undefined
              }
              sx={popoverSx}
            >
              <ActionList
                ref={listRef}
                items={item.subItems ?? []}
                onClose={() => {
                  onClose?.();
                  popupState.close();
                }}
              />
            </BasePopover>
          </>
        );
      }}
    </PopupState>
  );
};

export default MenuSubItem;
