import React, { forwardRef, ReactNode } from 'react';
import { IActionItem } from '@components/ActionsMenu/types';
import { Divider, MenuItem as MuiMenuItem } from '@mui/material';
import Box from '@mui/material/Box';

type Props = {
  item: IActionItem;
  endIcon?: ReactNode;
};

const MenuItem = ({ item, endIcon }: Props, ref: any) => {
  const {
    text,
    divider,
    disabled,
    color,
    onClick,
    disableHoverColor,
    sx,
    icon,
  } = item;

  return (
    <>
      {divider && <Divider sx={{ my: '0 !important' }} />}

      <MuiMenuItem
        ref={ref}
        sx={{
          gap: 2,
          color: color || 'grey.900',
          ...(disableHoverColor
            ? { '&:hover': { color: `${color} !important` || 'grey.900' } }
            : {}),
          ...sx,
        }}
        onClick={onClick}
        disabled={disabled}
        disableRipple
      >
        {icon}
        {text}

        {endIcon && (
          <>
            <Box flex={1} />
            {endIcon}
          </>
        )}
      </MuiMenuItem>
    </>
  );
};

export default forwardRef<any, Props>(MenuItem);
