import { useMemo } from 'react';
import useProjectSettings from '@hooks/useProjectSettings';
import { ChipProps } from '@mui/material';

const ChipVariants: ChipProps['variant'][] = ['green', 'yellow', 'red'];

const useScoreColor = (score: number): ChipProps['variant'] => {
  const {
    settings: { risk_appetit_tolerance },
  } = useProjectSettings();

  const variantIndex = useMemo(
    () =>
      risk_appetit_tolerance?.findIndex(
        ({ rangeMin, rangeMax }) => score >= rangeMin && score <= rangeMax,
      ) ?? 0,
    [score, risk_appetit_tolerance],
  );

  if (score === 0 || variantIndex === -1) return 'grey';

  return ChipVariants[variantIndex];
};

export default useScoreColor;
