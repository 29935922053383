import React, { FC, useMemo } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import ReferencesInputsGroup from '@components/EntityDrawers/components/ReferencesInputsGroup/ReferencesInputsGroup';
import {
  PhysicalDescriptionFiledTitles,
  PhysicalNameFiledTitles,
  PhysicalOwnerFiledTitles,
} from '@components/EntityDrawers/constants/common';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import { Modes, PhysicalSubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import EntityAlerts from '@views/Project/components/EntityAlerts';

const Standard: FC = () => {
  const activeNode = useSourceNode();
  const { editMode } = useDrawerContext();
  const {
    toolbox: { mode },
  } = useProject();

  const { nameLabel, descriptionLabel, ownerLabel } = useMemo(() => {
    if (!activeNode) return {};

    const subLayer = activeNode.parentNode as PhysicalSubLayerTypes;

    return {
      nameLabel: PhysicalNameFiledTitles[subLayer],
      descriptionLabel: PhysicalDescriptionFiledTitles[subLayer],
      ownerLabel: PhysicalOwnerFiledTitles[subLayer],
    };
  }, [activeNode?.parentNode]);

  return (
    <>
      <EntityAlerts />

      <NameInput
        label={nameLabel}
        placeholder={nameLabel}
        editMode={editMode}
      />

      <DescriptionInput
        label={descriptionLabel}
        placeholder={descriptionLabel}
        required
      />

      <OwnerInput label={ownerLabel} placeholder={ownerLabel} />
      <DomainDropdown disabled={mode === Modes.RiskManagement} />
      <ReferencesInputsGroup />
      <ValidationStatus />

      {editMode && <CreatedReviewDates dateReviewedClearable />}
    </>
  );
};

export default Standard;
