import { useCallback, useState } from 'react';
import { FileData } from '@components/UploadModal/types';
import { useDebounce } from '@hooks/useDebounce';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { type MutationDefinition, QueryStatus } from '@reduxjs/toolkit/query';
import { formatNumber, parseErrorResponse } from '@utils/helpers';
import { defaultMaxFileSize } from '@views/Operations/constants';

type Options<ResponseType> = {
  onSuccess?: (res: ResponseType) => void;
  maxFileSize?: number;
  payload?: Record<string, any>;
};

const useFileUpload = <ResponseType>(
  useMutation: UseMutation<
    MutationDefinition<{ file: File } & any, any, any, ResponseType>
  >,
  {
    onSuccess,
    maxFileSize = defaultMaxFileSize,
    payload,
  }: Options<ResponseType> = {},
) => {
  const [uploadFile, { isLoading, status, reset }] = useMutation();

  const [uploadError, setUploadError] = useState('');
  const [uploadResult, setUploadResult] = useState<ResponseType | null>(null);

  const [fileData, setFileData] = useState<FileData>({
    name: '',
    size: 0,
  });

  const onDrop = useCallback(async ([file]: File[]) => {
    if (file.size > maxFileSize) {
      setFileData({ name: file.name, size: file.size });
      setUploadError(
        `File exceeds the maximum allowed size of ${formatNumber(
          maxFileSize,
        )}.`,
      );
      return;
    }

    setFileData({ name: file.name, size: file.size });
    setUploadError('');

    try {
      const res = await uploadFile({ file, ...payload }).unwrap();
      setUploadResult(res);
      onSuccess?.(res);
    } catch (error) {
      setUploadError(parseErrorResponse(error));
    }
  }, []);

  const isUploadDisabled = status === QueryStatus.fulfilled;

  // use debounce to avoid blinking of <UplaodFileStatus />
  const showFileStatus = useDebounce(
    isLoading || uploadError || status === QueryStatus.fulfilled,
    50,
  );

  const resetUploadState = () => {
    setFileData({ name: '', size: 0 });
    reset();
    setUploadError('');
    setUploadResult(null);
  };

  return {
    uploadError,
    uploadResult,
    fileData,
    onDrop,
    isUploadDisabled,
    showFileStatus,
    isLoading,
    status,
    resetUploadState,
  };
};

export default useFileUpload;
