import { useCollapsedActions } from '@components/MainStage/collapsedLayers.store';
import { SubLayerTypes } from '@constants/canvas/layers';
import { useToast } from '@context/Toast';
import SubLayerViewManager from '@utils/canvas/SubLayerViewManager';

const useIsActionInSubLayerAllowed = () => {
  const { isCollapsed } = useCollapsedActions();
  const { showToast } = useToast();

  const isNodeActionAllowed = (id: SubLayerTypes) => {
    const isCollapsedLayerOrSubLayer =
      isCollapsed(id) ||
      isCollapsed(SubLayerViewManager.getInstance(id)!.parentLayerId);

    if (isCollapsedLayerOrSubLayer) {
      showToast(
        'Action is unavailable as the selected layer is collapsed.',
        'info',
      );

      return false;
    }

    return true;
  };

  return { isNodeActionAllowed };
};

export default useIsActionInSubLayerAllowed;
