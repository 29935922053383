import { Modes } from '@constants/canvas/layers';
import {
  BusinessAlignmentTabs,
  BusinessDashboardDistribution,
  ControlsTabs,
  DistributionKeys,
  RemActionsDistribution,
  RiskManagmentTabs,
} from '@constants/entities/project-dashboard';
import { BudgetDistribution } from '@store/services/dashboards/types';

export type PerValue =
  | null
  | BusinessDashboardDistribution
  | BudgetDistribution
  | RemActionsDistribution;

export type DashboardTab =
  | BusinessAlignmentTabs
  | ControlsTabs
  | RiskManagmentTabs;

export type DashboardContextValues = {
  tab: DashboardTab;
  handleChangeTab: (t: DashboardTab) => void;
  perValue: PerValue;
  changePerValue: (v: PerValue) => void;
  openExportModal: (type?: DistributionKeys) => void;
  exportDistribution: DistributionKeys | null;
  isExportOpen: boolean;
  closeExportModal: () => void;
};

export const initialPerValue = {
  [Modes.BusinessAlignment]: [
    BusinessDashboardDistribution.PerDomain,
    BusinessDashboardDistribution.PerDomain,
    BudgetDistribution.PerStrategicEnabler,
  ],

  [Modes.Controls]: [null, null, null, BudgetDistribution.PerService],

  [Modes.RiskManagement]: [null, null, RemActionsDistribution.PerDomain],
};
