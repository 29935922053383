import React from 'react';
import { ExistingNodeItemChild } from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/ExistingNodeItemChild';
import {
  ExistingNode,
  ExistingNodeItemProps,
} from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/types';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import useToggle from '@hooks/useToggle';
import { Box, Checkbox, Collapse, Stack, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

export const ExistingNodeItem = ({
  id,
  name,
  nodes,
  formattedData,
  setFormattedData,
}: ExistingNodeItemProps) => {
  const [isExpanded, { toggle }] = useToggle(true);

  const handleCheckboxChange = (checked: boolean) => {
    const newData = {
      ...formattedData,
      [id]: {
        ...formattedData[id],
        children: formattedData[id].children.map((child: ExistingNode) => ({
          ...child,
          isChecked: child.isDisabled ? child.isChecked : checked,
        })),
      },
    };

    setFormattedData(newData);
  };

  const handleChildChange = (childId: string, checked: boolean) => {
    const newData = {
      ...formattedData,
      [id]: {
        ...formattedData[id],
        children: formattedData[id].children.map((child: ExistingNode) => ({
          ...child,
          isChecked: childId === child.id ? checked : child.isChecked,
        })),
      },
    };

    setFormattedData(newData);
  };

  const isParentChecked = formattedData[id].children.every(
    (child: ExistingNode) => child.isChecked,
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          '&:hover': { backgroundColor: themePalette.grey[400] },
        }}
        p="16px 24px"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={isParentChecked}
            disabled={nodes.every((n) => n.isDisabled)}
            onChange={(event) => handleCheckboxChange(event.target.checked)}
          />

          <TypographyWithElipsis variant="h5" maxWidth="425px" sx={{ ml: 2 }}>
            {name}
          </TypographyWithElipsis>
          <Typography
            variant="h5"
            color="grey.800"
            sx={{ whiteSpace: 'break-spaces' }}
          >
            {` (${nodes.length} ${nodes.length === 1 ? 'entity' : 'entities'})`}
          </Typography>
        </Box>

        <DropdownIcon
          fill={themePalette.grey[1000]}
          style={{ transform: isExpanded ? 'rotate(180deg)' : '' }}
          onClick={toggle}
        />
      </Stack>
      <Collapse in={isExpanded} timeout="auto">
        {nodes.map(({ name, id: childId, isChecked, isDisabled }) => {
          return (
            <ExistingNodeItemChild
              key={childId}
              id={childId}
              name={name}
              handleChildChange={handleChildChange}
              isChecked={isChecked}
              isDisabled={isDisabled}
            />
          );
        })}
      </Collapse>
    </>
  );
};
