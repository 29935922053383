import { FC, useEffect, useMemo } from 'react';
import { Node } from 'reactflow';
import {
  CustomDialog,
  CustomDialogProps,
} from '@components/Dialogs/CustomDialog';
import { MAX_STATEMENTS_IN_VIEW } from '@constants/entities/reports';
import SliderProvider from '@context/SliderProvider';
import useStepper from '@hooks/useSteppr/useStepper';
import { Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { ConceptualMetaData, NodeDTOTypes } from '@store/services/nodes/types';
import SliderControls from '@views/ReportsDashboard/components/SliderControls/SliderControls';
import ExportButton from '@views/ReportsModel/components/JustificationReport/components/ExportButton';
import JustificationSlide from '@views/ReportsModel/components/JustificationReport/components/JustificationSlide/JustificationSlide';
import { SlidesBox } from '@views/ReportsModel/components/JustificationReport/components/JustificationSlide/JustificationSlide.styled';
import NodeInfo from '@views/ReportsModel/components/JustificationReport/components/NodeInfo/NodeInfo';
import {
  HeaderWrapper,
  Wrapper,
} from '@views/ReportsModel/components/JustificationReport/JustificationReportModal.styled';
import { getStatementPages } from '@views/ReportsModel/helpers';

type Props = Pick<CustomDialogProps, 'open' | 'onClose'> & {
  node: Node;
};

export const JustificationReportModal: FC<Props> = ({
  open,
  onClose,
  node,
}) => {
  const {
    type = NodeDTOTypes.BusinessAttribute,
    label = '',
    meta_data,
  } = node.data.dto;

  const { definition, source } = meta_data as ConceptualMetaData;
  const totalJustificationStatements =
    source
      ?.filter((s) => s.type)
      .map((s) => s.justification_statement ?? [])
      .flat().length ?? 0;
  const totalSlides = Math.ceil(
    totalJustificationStatements / MAX_STATEMENTS_IN_VIEW,
  );
  const [currentSlide, stepper] = useStepper(totalSlides);

  useEffect(() => {
    if (!open) {
      stepper.resetStepper();
    }
  }, [open]);

  const pages = useMemo(
    () =>
      totalSlides
        ? getStatementPages(source, totalSlides)
        : [{ leftStatements: [], rightStatements: [] }],
    [source, totalSlides],
  );

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Justification statement"
      sx={{
        '& .MuiDialog-paper': {
          minWidth: 988,
          minHeight: 634,
          overflowX: 'hidden',
          overflowY: 'auto',
        },
      }}
    >
      <Typography px={6} variant="body1" color="grey.900">
        Below are the justification statement that led into the selection of the
        specific attribute:
      </Typography>

      <Wrapper>
        <HeaderWrapper>
          <NodeInfo label={label} definition={definition} type={type} />
          <ExportButton label={label} />
        </HeaderWrapper>

        <Box sx={{ flex: 1, py: 6 }}>
          <SlidesBox $slides={totalSlides} $current={currentSlide}>
            {pages.map(({ leftStatements, rightStatements }, index) => (
              <JustificationSlide
                key={index}
                label={label}
                leftStatements={leftStatements}
                rightStatements={rightStatements}
                type={type}
                definition={definition}
              />
            ))}
          </SlidesBox>
        </Box>

        <Typography variant="body1" color="grey.800" px={6}>
          *Statements provided during interviews are represented by green
          arrows, whereas with red arrows all those <br /> identified in
          corporate documents
        </Typography>
      </Wrapper>

      {totalSlides > 1 && (
        <SliderProvider value={{ currentSlide, totalSlides, ...stepper }}>
          <Stack px={6} pb={6} flexDirection="row" justifyContent="flex-end">
            <SliderControls />
          </Stack>
        </SliderProvider>
      )}
    </CustomDialog>
  );
};
