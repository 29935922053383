import { useCallback } from 'react';
import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project';
import useResetViewport from '@hooks/canvas/useResetViewport';
import { resetNodeState } from '@utils/canvasHelpers';
import { Tabs } from '@views/Insights/types';

const usePaneClick = () => {
  const { drawer, handleCloseDrawer, insightsTab: type } = useProject();
  const resetViewPort = useResetViewport();
  const {
    getActiveNode,
    getActiveEdge,
    unselectActiveNode,
    unselectActiveEdge,
    setNodes,
    setEdges,
    nodes,
    edges,
  } = useStore();

  const activeNode = getActiveNode();
  const activeEdge = getActiveEdge();

  return useCallback(async () => {
    if (type === Tabs.FocusOn) {
      // On first click - hide drawer, but keep selected node
      if (drawer) {
        await handleCloseDrawer(undefined, false);
        unselectActiveEdge();
        resetViewPort();
        return;
      }

      // On second click - unselect node and show all nodes
      if (activeNode) {
        setNodes(nodes.map(resetNodeState));
        setEdges(edges.map((edge) => ({ ...edge, hidden: false })));
      }

      return;
    }

    if (type === Tabs.Dependency) {
      if (drawer) {
        await handleCloseDrawer();
        resetViewPort();
        return;
      }
    }

    if (type === Tabs.Maturity) {
      setNodes(
        nodes.map((node) => ({
          ...node,
          selected: false,
          ...node,
          data: {
            ...node.data,
            canvas: {
              ...node.data.canvas,
              disabled: false,
            },
          },
        })),
      );
    }

    await handleCloseDrawer();

    unselectActiveEdge();
    unselectActiveNode();
  }, [type, drawer, activeNode, activeEdge, nodes, handleCloseDrawer]);
};

export default usePaneClick;
