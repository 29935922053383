import { SubLayerTypes } from '@constants/canvas/layers';
import SublayerView from '@utils/canvas/SublayerView';

// Manager class for creating and managing LayerViewBase instances
class LayerViewManager {
  private static readonly instances = new Map<string, SublayerView>();

  static getInstance(id: SubLayerTypes): SublayerView | null {
    if (!this.instances.has(id)) {
      if (!SublayerView.isSubLayer(id)) return null;

      const instance = new SublayerView(id);
      this.instances.set(id, instance);
    }

    return this.instances.get(id) as SublayerView;
  }

  static disposeInstance(id: string): void {
    const instance = this.instances.get(id);
    if (instance) {
      instance.dispose();
      this.instances.delete(id);
    }
  }

  static disposeAll(): void {
    this.instances.forEach((instance) => instance.dispose());
    this.instances.clear();
  }
}

export default LayerViewManager;
