import { ReactNode } from 'react';
import { LoginSideItem } from '@components/LoginSideItem';
import {
  LoginContent,
  LoginContentWrapper,
  LoginPageStyled,
  LoginSideBlock,
  LoginSideBlockImage,
  LoginSideContent,
  LoginSideItemsWrapper,
  LoginSideTitle,
} from '@components/LoginWrapper/LoginWrapper.styled';
import { themePalette } from '@theme/muiTheme';
import {
  InsightWithBgIcon,
  LayerWithBgIcon,
  ScenarioWithBgIcon,
} from '@utils/iconsDefs';

type LoginWrapperProps = {
  children: ReactNode;
};

export function LoginWrapper({ children }: LoginWrapperProps) {
  return (
    <LoginPageStyled>
      <LoginContentWrapper id="login-container">
        <LoginContent>{children}</LoginContent>
      </LoginContentWrapper>

      <LoginSideBlock>
        <LoginSideBlockImage
          src="https://cdn.solution.qnous.io/login_bg.webp"
          alt=""
          loading="lazy"
        />
        <LoginSideContent>
          <LoginSideTitle>
            Redefining
            <span style={{ color: themePalette.primary.main }}> Security</span>
          </LoginSideTitle>

          <LoginSideItemsWrapper>
            <LoginSideItem
              icon={<LayerWithBgIcon />}
              text="All in One Solution"
            />
            <LoginSideItem
              icon={<InsightWithBgIcon />}
              text="Actionable Insights"
            />
            <LoginSideItem
              icon={<ScenarioWithBgIcon />}
              text="Customized Scenarios"
            />
          </LoginSideItemsWrapper>
        </LoginSideContent>
      </LoginSideBlock>
    </LoginPageStyled>
  );
}
