import React, { FC, useMemo, useState } from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
} from '@components/Inputs/TextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useAssets from '@hooks/useAssets';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import { useDeleteRelationshipMutation } from '@store/services/nodes';
import {
  IAvailableRelationship,
  ICanvasNodesResponse,
} from '@store/services/nodes/types';
import { parseErrorResponse } from '@utils/helpers';

type Props = Pick<ConfirmDialogProps, 'open' | 'onClose'> & {
  id: string;
  relationOptions?: IAvailableRelationship[] | null;
};

const SelectReplacementModal: FC<Props> = ({
  id,
  open,
  onClose,
  relationOptions,
}) => {
  const { showToast } = useToast();
  const saveResponse = useSaveEntitiesResponse();
  const {
    hideDrawer,
    toolbox: { mode },
  } = useProject();
  const { selectedAssetId } = useAssets();

  const [deleteRelation] = useDeleteRelationshipMutation();

  const [replaceRelationId, setReplaceRelationId] = useState(
    DEFAULT_PLACEHOLDER_VALUE,
  );

  const handleDeleteWithReplacement = async () => {
    try {
      if (!mode) return;

      const response = await deleteRelation({
        id,
        mode,
        assetId: selectedAssetId || undefined,
        allocateWeightRelationsId: [replaceRelationId],
      }).unwrap();

      saveResponse(response as ICanvasNodesResponse);
      onClose?.();

      hideDrawer();

      showToast(
        'The relationship has been successfully deleted. The weight value is allocated.',
        'success',
      );
    } catch (e: any) {
      showToast(parseErrorResponse(e), 'error');
    } finally {
      setReplaceRelationId(DEFAULT_PLACEHOLDER_VALUE);
    }
  };

  const options = useMemo(
    () => relationOptions?.map(({ id, name }) => ({ value: id, label: name })),
    [relationOptions],
  );

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title="Delete relationship"
      text="Are you sure that you want to delete the relationship between entities? Please, select a relationship to allocate the weight value to. The selected relationship will be automatically changed to an auto-assigned weight."
      onConfirm={handleDeleteWithReplacement}
      disabled={replaceRelationId === DEFAULT_PLACEHOLDER_VALUE}
    >
      <TextFieldSelect
        label="Allocation relationship"
        value={replaceRelationId}
        onChange={(e) => setReplaceRelationId(e.target.value)}
        placeholder="Select allocation relationship"
        options={options}
      />
    </ConfirmDialog>
  );
};

export default SelectReplacementModal;
