export type PaginationParams = {
  page: number;
  pageSize: number;
};

export enum SortDirection {
  ASC = 'ASCENDING',
  DESC = 'DESCENDING',
}

export enum ResponseCodes {
  ITEM_BLOCKED = 'ITEM_BLOCKED',
  USER_INVALID = 'USER_INVALID',
  REMEDIATION_ACTION_COULD_BE_REMOVED = 1002,
  SERVICE_TIER_USERS_LIMIT = 1003,
  NEED_SERVICE_TIER_TO_RESET_PROJECT = 1031,
}

export type ResponseError = {
  status: number;
  data: {
    message: string;
    code?: ResponseCodes;
  };
};
