import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CommonKeys,
  CoreRequiredKeys,
  LogicPhysicalKeys,
} from '@components/EntityDrawers/constants/keys';
import {
  defaultValues,
  LogicalFormValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Logical/form';
import { LogicalKeys } from '@components/EntityDrawers/drawers/Logical/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { LogicalMetaData, NameLink } from '@store/services/nodes/types';
import {
  checkValuesIncomplete,
  prepareBasicLogicPhysicalData,
  transformCommonKeys,
} from '@utils/drawers';
import { filterEmptyAndOmitId, getEntitiesArray } from '@utils/helpers';

export const LogicalSettings: DrawerSettings<
  LogicalFormValues,
  LogicalMetaData
> = {
  editType: ProjectDrawerTypes.EditLogicalEntity,
  defaultValues,
  validationSchema,

  checkIncompleteFields: checkValuesIncomplete<LogicalFormValues>(
    ...CoreRequiredKeys,
    CommonKeys.QnousRef,
    CommonKeys.NistRef,
  ),

  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<LogicalFormValues, LogicalMetaData>(
      ...LogicPhysicalKeys,
    )(nodeDto, context),

    [LogicalKeys.Policies]: getEntitiesArray<NameLink>(
      nodeDto.meta_data.policy,
      NameLinkObject,
    ),
  }),

  transformMetaDataToRequestBody: ({ policies, ...metadata }) =>
    ({
      ...prepareBasicLogicPhysicalData(metadata),
      policy: filterEmptyAndOmitId<NameLink>(policies),
    }) as LogicalMetaData,
};
