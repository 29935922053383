import { useLocation, useNavigate } from 'react-router-dom';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import useAssets from '@hooks/useAssets';
import useProjectId from '@hooks/useProjectId';
import { ROUTES } from '@router/routes';
import {
  scenarioModeOff,
  scenarioModeOn,
  selectProjectTabs,
  selectScenarioMode,
} from '@store/slices/projectSlice';

type LeaveScenarioModeOptions = { withRedirect?: boolean } | void;

const useScenarioMode = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { handleCloseDrawer } = useProject();
  const id = useProjectId();
  const projectsTabs = useAppSelector(selectProjectTabs);
  const { resetAsset } = useAssets();
  const { saveCurrentZoom } = useCanvasZoom();

  const dispatch = useAppDispatch();
  const scenarioMode = useAppSelector(selectScenarioMode);

  const enterScenarioMode = async () => {
    await handleCloseDrawer();
    dispatch(scenarioModeOn());
    resetAsset();
    saveCurrentZoom();

    const isNoTabs = !projectsTabs[id]?.scenarios.length;

    if (isNoTabs) {
      navigate(ROUTES.PROJECT.SCENARIOS.replace(':id', id));
    }
  };

  const leaveScenarioMode = async ({
    withRedirect = true,
  }: LeaveScenarioModeOptions = {}) => {
    await handleCloseDrawer();
    dispatch(scenarioModeOff());
    resetAsset();
    saveCurrentZoom();

    if (
      withRedirect &&
      pathname === ROUTES.PROJECT.SCENARIOS.replace(':id', id)
    ) {
      navigate(ROUTES.PROJECT.MODEL.replace(':id', id));
    }
  };

  return { scenarioMode, enterScenarioMode, leaveScenarioMode };
};

export default useScenarioMode;
