import { Handle, NodeToolbar } from 'reactflow';
import { Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import NodeView from '@utils/canvas/NodeView';
import { TooltipLeftArrow } from '@utils/iconsDefs';
import styled, { css } from 'styled-components';

interface Props {
  $selected?: boolean;
  $incomplete?: boolean;
  $selectedWithEdge?: boolean;
  $selectedInInsight?: boolean;
  $selectedInMaturity?: boolean;
  $maturityBackground?: boolean;
  $keyControlsImpactColor?: string;
  $disabled?: boolean;
}

interface HandleProps {
  $isHandleConnected?: boolean;
  $selected?: boolean;
  $selectedWithEdge?: boolean;
  $faded?: boolean;
}
const borderStyles = css<Props>`
  ${({
    $selectedInInsight,
    $selected,
    $selectedWithEdge,
    $selectedInMaturity,
    $maturityBackground,
    $keyControlsImpactColor,
    $incomplete,
    $disabled,
  }) => {
    if ($selectedInInsight) {
      return `border: 2px solid ${themePalette.green[900]}`;
    }
    if (($selected || $selectedWithEdge) && !$selectedInMaturity) {
      return `border: 2px solid ${themePalette.green[900]}`;
    }
    if ($disabled) {
      return `border: 1px solid ${themePalette.grey[500]}`;
    }
    if ($selected && $selectedInMaturity) {
      return `border: 2px solid ${
        $maturityBackground || themePalette.primary.main
      }`;
    }
    if ($keyControlsImpactColor) {
      return `border: 1px solid ${$keyControlsImpactColor}`;
    }
    if ($selectedInMaturity && $maturityBackground) {
      return `border: 1px solid ${$maturityBackground}`;
    }
    if ($incomplete) {
      return `border: 1px solid ${themePalette.system.error}`;
    }
    return '';
  }}
`;

export const CustomNodeToolbar = styled(NodeToolbar)`
  background: ${themePalette.grey[1000]};
  position: relative;
  left: 0;
  top: 0;
  padding: 8px 12px;
  border-radius: 8px;
  max-width: 186px;
  box-shadow:
    0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0 12px 16px -4px rgba(16, 24, 40, 0.08);
  pointer-events: none;
`;

export const StyledTooltipLeftArrow = styled(TooltipLeftArrow)`
  position: absolute;
  top: 50%;
  left: -3px;
  transform: translate(-50%, -50%);
`;

export const CustomNodeStyled = styled('div')<Props>`
  width: 212px;
  height: ${NodeView.Height}px;
  position: relative;
  background: ${(p) =>
    p.$disabled ? themePalette.grey[400] : themePalette.white};
  border-radius: 12px;
  box-shadow: ${(p) =>
    p.$disabled
      ? 'none'
      : '0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.1)'};
  border: 1px solid white;

  &:hover {
    ${borderStyles};
    border: ${({ $selectedInMaturity, $disabled, $keyControlsImpactColor }) =>
      !$selectedInMaturity &&
      !$disabled &&
      !$keyControlsImpactColor &&
      `2px solid ${themePalette.green[900]}`};
  }

  ${borderStyles}
`;

export const StyledHandle = styled(Handle)<HandleProps>`
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid ${themePalette.grey[700]};
  ${({ $selectedWithEdge, $faded }) => {
    let styles = '';
    if ($faded) {
      styles += `background: ${themePalette.grey[600]}; border-color: ${themePalette.grey[600]}`;
    } else if ($selectedWithEdge) {
      styles += `background: ${themePalette.grey[1000]}; border-color: ${themePalette.grey[1000]}`;
    } else
      styles += `background: ${themePalette.grey[700]}; border-color: ${themePalette.grey[700]}`;

    return styles;
  }}
`;

const HandleWrapper = styled('div')<HandleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  opacity: ${(p) => (p.$isHandleConnected ? 1 : 0)};

  ${CustomNodeStyled}:hover & {
    opacity: 1;
  }
`;

export const TopHandleWrapper = styled(HandleWrapper)`
  top: 0;
  transform: translate(-50%, -50%);
`;

export const BottomHandleWrapper = styled(HandleWrapper)`
  bottom: 0;
  transform: translate(-50%, 50%);
`;

export const IncompleteLabel = styled('div')<Props>`
  position: absolute;
  top: -19px;
  left: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${({ $disabled }) =>
    $disabled ? themePalette.grey[500] : themePalette.red[600]};
  padding: 2px 8px;
  z-index: -1;
`;

export const CustomNodeContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`;

export const IconWrapper = styled('div')`
  background: transparent;
  width: 48px;
  height: 48px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const TextWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-left: 1px solid ${themePalette.grey[500]};
  overflow: hidden;
`;

export const StyledText = styled(Typography)`
  max-width: 140px;
  line-height: 20px;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AdditionalNodeInfo = styled('div')`
  position: absolute;
  top: -16px;
  right: -12px;
  display: flex;
`;

export const MaturityWrapper = AdditionalNodeInfo;
export const MaturityBlock = styled('span')<{
  $maturityBackground?: string;
  $isDisabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 20px;
  margin-left: 4px;
  font-size: 12px;
  color: ${({ $isDisabled }) => themePalette.grey[$isDisabled ? 700 : 900]};
  font-weight: 500;
  background-color: ${({ $isDisabled }) =>
    $isDisabled ? themePalette.grey[400] : themePalette.white};
  border: 1px solid
    ${({ $maturityBackground, $isDisabled }) =>
      $isDisabled
        ? themePalette.grey[500]
        : $maturityBackground || themePalette.grey[600]};
  border-radius: 4px;
`;

export const RiskScoreWrapper = AdditionalNodeInfo;

export const RiskScoreBlock = styled('span')<{
  $riskScoreColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 20px;
  margin-left: 4px;
  font-size: 12px;
  color: ${themePalette.grey[900]};
  font-weight: 500;
  background-color: ${themePalette.white};
  border: 1px solid
    ${({ $riskScoreColor }) => $riskScoreColor || themePalette.grey[600]};
  border-radius: 4px;
`;
