import { Edge, Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { NodesTypes } from '@constants/canvas/general';
import {
  SubLayersWithoutValidStatus,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { WideDomainKey } from '@constants/entities/ui';
import { filtersSubject } from '@context/ProjectFilters/FiltersSubject';

class CanvasMap {
  nodes: Node[];

  relations: Edge[];

  nodeMap: Map<string, Node>;

  relationMap: Map<string, Edge>;

  constructor() {
    const { nodes, edges } = useStore.getState();

    this.nodes = nodes;
    this.relations = edges;
    this.nodeMap = new Map(nodes.map((node) => [node.id, node]));
    this.relationMap = new Map(edges.map((edge) => [edge.id, edge]));

    this.subscribeToCanvas();
  }

  private subscribeToCanvas() {
    useStore.subscribe((state) => {
      this.nodes = state.nodes;
      this.relations = state.edges;
      this.nodeMap = new Map(this.nodes.map((node) => [node.id, node]));

      this.relationMap = new Map(this.relations.map((edge) => [edge.id, edge]));
    });
  }

  isNodeHiddenByFilters(id: string) {
    const { domains, reviewStatus, validationStatus } =
      filtersSubject.getValue();

    const node = this.nodeMap.get(id);
    if (!node) return false;

    if (domains.length) {
      const isCustomNode = node.type === NodesTypes.CustomNode;
      const isDomainIncluded = domains.includes(
        node.data?.dto?.domain_id ?? WideDomainKey,
      );

      return isCustomNode && !isDomainIncluded;
    }

    if (reviewStatus.length) {
      return !(
        node.type !== NodesTypes.CustomNode || node.data?.dto?.review_required
      );
    }

    if (validationStatus.length) {
      const nodeWithoutValidStatus = SubLayersWithoutValidStatus.includes(
        node.parentNode as SubLayerTypes,
      );

      const isVisible =
        node.type !== NodesTypes.CustomNode ||
        validationStatus.includes(node.data?.dto?.valid_status);

      return nodeWithoutValidStatus || !isVisible;
    }

    return false;
  }
}

export const canvasMap = new CanvasMap();
