import { Node } from 'reactflow';
import {
  AssignedNode,
  ExistingNode,
  FormattedData,
} from '@components/MainStage/ContextMenu/SubLayerContextMenu/AddExistingNodes/types';
import { nanoid } from '@reduxjs/toolkit';
import { IAssetCompatibleNodesResponse } from '@store/services/nodes/types';

const sortNodesByName = (nodes: ExistingNode[]) => {
  return [...nodes].sort((a, b) => a.name.localeCompare(b.name));
};

const formatNodes = (nodes: ExistingNode[], allNodes: Node[]) => {
  return nodes.map((node) => {
    const isNodeExistInAllNodes = allNodes.find((n) => n.id === node.id);
    return {
      ...node,
      isChecked: !!isNodeExistInAllNodes,
      isDisabled: !!isNodeExistInAllNodes,
    };
  });
};

export const shouldAddChild = (
  child: ExistingNode,
  assignedNodes: AssignedNode[],
) => {
  return (
    child.isChecked &&
    !child.isDisabled &&
    !assignedNodes.some((node) => node.id === child.id)
  );
};

export const formatData = (
  data: IAssetCompatibleNodesResponse,
  allNodes: Node[],
) => {
  return Object.entries(data)
    .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
    .reduce((acc: FormattedData, [name, nodes]) => {
      const id = nanoid();
      const sortedNodes = sortNodesByName(nodes);

      acc[id] = {
        id,
        name,
        children: formatNodes(sortedNodes, allNodes),
        isChecked: false,
      };
      return acc;
    }, {});
};
