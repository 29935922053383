import React, { FC } from 'react';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

const StewardJustificationNoteInput: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextField
      name={RiskKeys.StewardJustificationNote}
      label="Risk steward justification note"
      placeholder="Risk steward justification note"
      disabled={viewOnly}
      multiline
    />
  );
};

export default StewardJustificationNoteInput;
