import { LoadingKeys } from '@constants/entities/ui';
import { useAppDispatch } from '@hooks/store';
import { startLoading, stopLoading } from '@store/slices/uiSlice';

const useLoading = () => {
  const dispatch = useAppDispatch();

  const endLoading = (key: LoadingKeys) => {
    dispatch(stopLoading(key));
  };

  const beginLoading = (key: LoadingKeys) => {
    dispatch(startLoading(key));

    return () => {
      endLoading(key);
    };
  };

  return { beginLoading, endLoading };
};

export default useLoading;
