import { LAYER_HEIGHT } from '@constants/canvas/general';
import {
  LayerSubLayers,
  LayerTypes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import LayerViewBase from '@utils/canvas/LayerViewBase';
import SubLayerViewManager from '@utils/canvas/SubLayerViewManager';

class LayerView extends LayerViewBase<LayerTypes> {
  static readonly TopTitleBlockHeight: number = LAYER_HEIGHT;

  setHeight(newHeight: number) {
    super.setHeight(newHeight + LayerView.TopTitleBlockHeight);
  }

  get subLayerIds(): SubLayerTypes[] {
    return LayerSubLayers[this.id];
  }

  get subLayersHeight(): number {
    return this.subLayerIds.reduce<number>((commonHeight, subLayerId) => {
      const subLayerController = SubLayerViewManager.getInstance(subLayerId);

      return commonHeight + subLayerController.height;
    }, 0);
  }

  fitSubLayers(): void {
    this.setHeight(this.subLayersHeight);
  }

  static isLayer<T extends string>(id: T) {
    return Object.hasOwn(LayerSubLayers, id);
  }
}

export default LayerView;
