import { createElement, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InitiativeFormValues } from '@components/EntityDrawers/drawers/Initiative/form';
import Advanced from '@components/EntityDrawers/drawers/Initiative/tabs/Advanced';
import Standard from '@components/EntityDrawers/drawers/Initiative/tabs/Standard';
import { DrawerContentProps } from '@components/EntityDrawers/hocs/withDrawer';
import useTwoDatesValidation from '@hooks/useTwoDatesValidation';

export const InitiativeContent: FC<DrawerContentProps> = ({ tab }) => {
  const form = useFormContext<InitiativeFormValues>();

  useTwoDatesValidation({
    form,
    startKeyName: 'timelineStartDate',
    endKeyName: 'timelineEndDate',
    error: () => `Target date should be later than the start date.`,
  });

  return createElement([Standard, Advanced][tab ?? 0]);
};
