import { useReactFlow } from 'reactflow';
import { CANVAS_LEFT_MARGIN, CANVAS_WIDTH } from '@constants/canvas/general';

const useResetViewport = () => {
  const { setViewport, getViewport } = useReactFlow();

  return async (duration = 0) => {
    await new Promise((resolve) => {
      setTimeout(resolve, duration + 50);
    });

    const viewport = getViewport();

    const canvas = document.querySelector('#canvas');
    if (!canvas) return;

    const differenceX = canvas.clientWidth - CANVAS_WIDTH * viewport.zoom;

    if (differenceX > CANVAS_LEFT_MARGIN * viewport.zoom) {
      return setViewport(
        { ...viewport, x: CANVAS_LEFT_MARGIN * viewport.zoom },
        { duration: 0 },
      );
    }

    // If there is no extra space - we don't need to move canvas
    if (viewport.x <= differenceX) {
      setViewport({ ...viewport, x: differenceX }, { duration: 0 });
    }
  };
};

export default useResetViewport;
