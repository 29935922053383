import React, { FC, JSX } from 'react';
import { CustomTooltip } from '@components/CustomTooltip';
import { ListItemProps } from '@components/Inputs/Autocomplete/types';
import { useTruncatedText } from '@hooks/useTruncatedText';
import { Checkbox, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { InfoCircleEmptyIcon } from '@utils/iconsDefs';
import styled from 'styled-components';

const InfoIcon = styled(InfoCircleEmptyIcon)`
  min-width: 20px;
  height: 20px;

  fill: ${themePalette.grey[700]};

  &:hover {
    fill: ${themePalette.grey[900]};
  }
`;

type Props = ListItemProps & {
  renderTooltipTitle?: (option: any & { label: string }) => JSX.Element;
};

const ListItemWithInfo: FC<Props> = ({
  props,
  option,
  state: { selected },
  multiple,
  renderTooltipTitle,
}) => {
  const { textRef, isTruncated } = useTruncatedText<HTMLLIElement>(
    option.label,
  );

  return (
    <li {...props}>
      {multiple && <Checkbox checked={selected} />}

      <Typography
        ref={textRef}
        variant="body1"
        color="grey.900"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          display: 'block',
        }}
      >
        {option.label}
      </Typography>

      {isTruncated && (
        <CustomTooltip
          placement="left"
          title={renderTooltipTitle ? renderTooltipTitle(option) : option.label}
        >
          <InfoIcon />
        </CustomTooltip>
      )}
    </li>
  );
};

export default ListItemWithInfo;
