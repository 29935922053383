import React, { FC, memo } from 'react';
import { useProject } from '@context/Project/ProjectProvider';
import useCanEditModel from '@hooks/useCanEditModel';
import Box from '@mui/material/Box';
import AddAsset from '@views/Project/components/ListMenu/components/AssetsBlock/AddAsset';
import ChooseAsset from '@views/Project/components/ListMenu/components/AssetsBlock/ChooseAsset';

const AssetsBlock: FC = () => {
  const { isVersionsShown } = useProject();
  const canAddAsset = useCanEditModel();

  const showAddAsset = !isVersionsShown && canAddAsset;

  return (
    <Box pt={3} px={3}>
      <ChooseAsset />

      {showAddAsset && <AddAsset />}
    </Box>
  );
};

export default memo(AssetsBlock);
