import React, { FC, useEffect, useMemo, useRef } from 'react';
import CoreSelectorDropdown, {
  BodyProps,
  CoreSelectorDropdownProps,
} from '@components/selectors/core/CoreSelectorDropdown';
import { SelectorButtonProps } from '@components/selectors/core/SelectorButton';
import { ISelectOption } from '@constants/entities/ui';
import { MenuItem, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';

export type BasicSelectDropdownProps = Partial<
  Omit<CoreSelectorDropdownProps, 'button'>
> & {
  options: ISelectOption[];
  value: string;
  onChange: (value: string) => void;
  button?: Partial<SelectorButtonProps>;
  displayValue?: (title: string, value: string) => string;
};

const BasicSelectDropdown: FC<BasicSelectDropdownProps> = ({
  value,
  options,
  onChange,
  button,
  displayValue,
  disabled,
  ...props
}) => {
  const { label: title = '', icon: Icon = () => null } = useMemo(() => {
    return (
      options.find((option) => option.value === value) ?? ({} as ISelectOption)
    );
  }, [value]);

  return (
    <CoreSelectorDropdown
      button={{
        title: displayValue ? displayValue(title, value) : title,
        icon: (
          <Icon
            fill={themePalette.grey[disabled ? 700 : 1000]}
            width={20}
            height={20}
          />
        ),
        ...button,
      }}
      disabled={disabled}
      {...props}
    >
      {(p) => (
        <BasicBody {...p} options={options} value={value} onChange={onChange} />
      )}
    </CoreSelectorDropdown>
  );
};

const BasicBody: FC<
  BodyProps & Pick<BasicSelectDropdownProps, 'value' | 'options' | 'onChange'>
> = ({ close, isOpen, onChange, value, options }) => {
  const ref = useRef<HTMLLIElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, [isOpen]);

  return (
    <>
      {options.map(({ label, value: optionValue, icon: Icon }) => (
        <MenuItem
          {...(optionValue === value ? { ref } : {})}
          key={optionValue}
          onClick={() => {
            close();
            onChange(optionValue);
          }}
          sx={{
            '& .MuiTypography-root': {
              color: themePalette.grey[900],
            },
            '&:hover .MuiTypography-root': {
              color: themePalette.grey[1000],
            },
          }}
        >
          {Icon && (
            <Icon
              fill={themePalette.grey[optionValue === value ? 1000 : 800]}
            />
          )}

          <Typography
            variant="body1"
            color={optionValue === value ? 'grey.1000' : 'grey.900'}
          >
            {label}
          </Typography>
        </MenuItem>
      ))}
    </>
  );
};

export default BasicSelectDropdown;
