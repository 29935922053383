import React from 'react';
import {
  ChangeInitiativeStatusWrapper,
  InitiativeStatusBlock,
} from '@components/MainStage/CustomNode/ChangeInitiativeStatus/ChangeInitiativeStatus.styled';

type Props = {
  status: string;
};

export const ChangeInitiativeStatus = ({ status }: Props) => {
  return (
    <ChangeInitiativeStatusWrapper>
      <InitiativeStatusBlock>{status}</InitiativeStatusBlock>
    </ChangeInitiativeStatusWrapper>
  );
};
