import { useEffect, useRef } from 'react';
import { Edge } from 'reactflow';
import useDeleteEdgeCheck from '@components/MainStage/hooks/useDeleteEdgeCheck';
import useStore from '@components/MainStage/store';
import { EntityData } from '@components/MainStage/types';
import { useAppDispatch } from '@hooks/store';
import useCanEditModel from '@hooks/useCanEditModel';
import { EdgeDTO } from '@store/services/nodes/types';
import { openModal } from '@store/slices/deleteRelationSlice';
import { isElementLocal } from '@utils/drawers';

const useDeleteEdgeKey = () => {
  const dispatch = useAppDispatch();
  const canDeleteRelation = useCanEditModel();
  const { getActiveEdge } = useStore();
  const activeEdge = getActiveEdge() ?? null;

  const dataRef = useRef<{
    activeEdge: Edge<EntityData<EdgeDTO>> | null;
    canDeleteRelation: boolean;
  }>({ activeEdge, canDeleteRelation });

  dataRef.current = { activeEdge, canDeleteRelation };

  const { checkIsCapabilityFunctionEdge, checkIsExistedRemediationEdge } =
    useDeleteEdgeCheck();

  useEffect(() => {
    const handleDeleteKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Delete') {
        if (!dataRef.current.canDeleteRelation) return;

        if (
          dataRef.current.activeEdge &&
          !isElementLocal(dataRef.current.activeEdge.id)
        ) {
          if (checkIsExistedRemediationEdge()) return;
          if (checkIsCapabilityFunctionEdge()) return;

          dispatch(openModal(dataRef.current.activeEdge.id));
        }
      }
    };

    const canvas = document.querySelector('#canvas') as unknown as Document;
    canvas?.addEventListener('keydown', handleDeleteKeyDown);

    return () => {
      canvas?.removeEventListener('keydown', handleDeleteKeyDown);
    };
  }, []);
};

export default useDeleteEdgeKey;
