import { ExportLayerKeys } from '@constants/entities/reports';
import { useGenerateLayersReportMutation } from '@store/services/reports';
import { getLayersReportFileName } from '@views/ReportsModel/helpers';
import useExportState from '@views/ReportsModel/hooks/useExportState';

const useExportLayers = (projectId: string, layers: ExportLayerKeys[]) => {
  const { isError, setError, fileContent, setFileContent } = useExportState();
  const [generateLayersReport, { isLoading, reset }] =
    useGenerateLayersReportMutation();

  const handleGenerate = async () => {
    try {
      const excelData = await generateLayersReport({
        projectId,
        layers,
      }).unwrap();

      setFileContent(excelData);
    } catch (e) {
      setError(true);
    }
  };

  const fileName = getLayersReportFileName(layers);

  return {
    isError,
    fileContent,
    isLoading,
    setFileContent,
    setError,
    handleGenerate,
    fileName,
    reset,
  };
};

export default useExportLayers;
