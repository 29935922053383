import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledTextField, {
  ControlledTextFieldProps,
} from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

type Props = Pick<ControlledTextFieldProps, 'disabled'>;

const TargetMaturity: FC<Props> = (props) => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextField
      name={CommonKeys.TargetMaturity}
      label="Target maturity"
      placeholder="Target maturity"
      disabled={viewOnly}
      {...props}
    />
  );
};

export default TargetMaturity;
