import React, { FC } from 'react';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import { Alert, Stack } from '@mui/material';

const EntityAlerts: FC = () => {
  const { isReviewRequired, isIncomplete } = useDrawerContext();

  if (isReviewRequired || isIncomplete) {
    return (
      <Stack gap={4}>
        {isReviewRequired && (
          <Alert severity="warning" variant="standard">
            Please, review entity&apos;s data, perform any changes needed and
            update the review date
          </Alert>
        )}

        {isIncomplete && (
          <Alert severity="info" variant="standard">
            If not all mandatory fields are filled, the entity will be saved as
            incomplete
          </Alert>
        )}
      </Stack>
    );
  }

  return null;
};

export default EntityAlerts;
