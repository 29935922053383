import React, { createContext, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfirmDialog from '@components/Dialogs/ConfirmDialog';
import { UserRoles } from '@constants/entities/user';
import {
  ProjectContextProps,
  ToastProviderProps,
} from '@context/Project/types';
import useDrawer from '@context/Project/useDrawer';
import useToolbox from '@context/Project/useToolbox';
import useBeforeUnload from '@hooks/useBeforeUnload';
import useLoader from '@hooks/useLoader';
import useUser from '@hooks/useUser';
import { Tabs } from '@views/Insights/types';
import useProjectArchived from '@views/shared/hooks/useProjectArchived';

const ProjectDrawerContext = createContext<ProjectContextProps | undefined>(
  undefined,
);

const ProjectProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [{ role }] = useUser();

  const { toolbox, handleViewChange, handleModeChange, handleDomainsChange } =
    useToolbox();

  const {
    drawer,
    setDrawer,
    hideDrawer,
    handleCloseDrawer,

    isUnsavedModalOpen,
    closeModal,
    confirmAction,

    hasChanges,
    setHasChanges,
  } = useDrawer(toolbox.mode);

  const [isMainMenuExpanded, setIsMainMenuExpanded] = useState(true);
  const { pathname } = useLocation();
  const [insightsTab, setInsightsTab] = useState(Tabs.Default);
  const [isInsightExpanded, setInsightsExpanded] = useState(true);
  const [activeVersionId, setActiveVersionId] = useState('');
  const [isVersionsShown, setIsVersionsShown] = useState(false);
  const [isUILoading, setIsUILoading] = useState(false); // to prevent jumping behaviour
  const viewOnly = role === UserRoles.Viewer || isVersionsShown;

  const isInsightsMode = pathname.includes('insights');
  const isDashboardMode = pathname.includes('dashboard');
  const isModelMode = pathname.includes('model');
  const isRiskRegisterMode = pathname.includes('risks-log');

  useBeforeUnload(hasChanges);
  useLoader(isUILoading, 'project-ui-loader');

  const value = useMemo(
    () => ({
      setIsUILoading,

      drawer,
      setDrawer,
      hideDrawer,
      handleCloseDrawer,

      isUnsavedModalOpen,

      hasChanges,
      setHasChanges,

      toolbox,
      handleViewChange,
      handleModeChange,
      handleDomainsChange,

      isVersionsShown,
      setIsVersionsShown,
      activeVersionId,
      setActiveVersionId,

      isInsightsMode,
      insightsTab,
      handleInsightsTabChange: setInsightsTab,
      isInsightExpanded,
      setInsightsExpanded,

      viewOnly,
      isDashboardMode,
      isRiskRegisterMode,

      isMainMenuExpanded,
      setIsMainMenuExpanded,

      isModelMode,
    }),
    [
      drawer,
      handleCloseDrawer,
      hideDrawer,

      isUnsavedModalOpen,

      hasChanges,

      toolbox,

      isVersionsShown,
      activeVersionId,

      isInsightsMode,
      insightsTab,
      isInsightExpanded,

      viewOnly,
      isDashboardMode,
      isRiskRegisterMode,

      isMainMenuExpanded,

      isModelMode,
    ],
  );

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <ProjectDrawerContext.Provider value={value}>
      {children}

      <ConfirmDialog
        open={isUnsavedModalOpen}
        title="Discard changes"
        text="Recent changes have not been saved. Proceeding will result in losing any unsaved work."
        confirmName="Proceed"
        onClose={closeModal}
        onConfirm={confirmAction}
      />
    </ProjectDrawerContext.Provider>
  );
};

export const useProject = () => {
  const isArchived = useProjectArchived();

  const context = useContext(ProjectDrawerContext);

  if (!context) {
    throw new Error(
      'useProjectDrawer must be used within a ProjectDrawerProvider',
    );
  }

  return { ...context, viewOnly: context.viewOnly || isArchived };
};

export const usePureProject = () => {
  const context = useContext(ProjectDrawerContext);

  if (!context) {
    throw new Error(
      'useProjectDrawer must be used within a ProjectDrawerProvider',
    );
  }

  return context;
};

export default ProjectProvider;
