import React, { FC, useMemo } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import { getLabels } from '@components/EntityDrawers/drawers/Conceptual/field-labels';
import SourcesGroup from '@components/EntityDrawers/drawers/Conceptual/inputs/SourcesGroup';
import { ConceptualKeys } from '@components/EntityDrawers/drawers/Conceptual/keys';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { ConceptualSubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import EntityAlerts from '@views/Project/components/EntityAlerts';

const Standard: FC = () => {
  const { viewOnly } = useProject();
  const { editMode } = useDrawerContext();
  const activeNode = useSourceNode();

  const { nameLabel, definitionLabel, ownerLabel } = useMemo(
    () => getLabels(activeNode?.parentNode as ConceptualSubLayerTypes),
    [activeNode?.parentNode],
  );

  return (
    <>
      <EntityAlerts />

      <NameInput
        label={nameLabel}
        placeholder={nameLabel}
        editMode={editMode}
      />

      <ControlledTextField
        name={ConceptualKeys.Definition}
        label={definitionLabel}
        placeholder={definitionLabel}
        required
        multiline
        disabled={viewOnly}
      />

      <OwnerInput label={ownerLabel} placeholder={ownerLabel} />
      <DomainDropdown />
      <ValidationStatus />
      <SourcesGroup />

      {editMode && <CreatedReviewDates dateReviewedClearable />}
    </>
  );
};

export default Standard;
