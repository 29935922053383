import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
  TextFieldSelectProps,
} from '@components/Inputs/TextFieldSelect';
import { SxProps } from '@mui/material';

export type ControlledTextFieldSelectProps = TextFieldSelectProps & {
  name: string;
  changeHandler?: (value: string) => void;
  helperText?: string;
  boxSx?: SxProps;
};

const ControlledTextFieldSelect: FC<ControlledTextFieldSelectProps> = ({
  name,
  changeHandler,
  helperText,
  boxSx,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { ref, value, onChange, ...field },
        fieldState: { error },
      }) => (
        <TextFieldSelect
          ref={ref}
          helperText={helperText ?? error?.message}
          error={!!error?.message}
          value={value || DEFAULT_PLACEHOLDER_VALUE}
          onChange={(e) => {
            onChange(e);
            changeHandler?.(e.target.value);
          }}
          sx={boxSx}
          {...field}
          {...props}
        />
      )}
    />
  );
};

export default ControlledTextFieldSelect;
