import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { Checkbox, Stack, TypographyVariant } from '@mui/material';

export type ControlledRadioGroupProps = {
  label: string;
  name: string;
  variant?: TypographyVariant;
  onChange?: (value: boolean) => void;
  defaultValue?: boolean;
};

const ControlledCheckbox: FC<ControlledRadioGroupProps> = ({
  label,
  name,
  variant = 'body1',
  onChange: handleChange,
  defaultValue,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Stack flexDirection="row" alignItems="center" gap={2}>
          <Checkbox
            checked={value}
            onChange={(_, checked) => {
              handleChange?.(checked);
              onChange(checked);
            }}
          />

          <TypographyWithElipsis variant={variant}>
            {label}
          </TypographyWithElipsis>
        </Stack>
      )}
    />
  );
};

export default ControlledCheckbox;
