import React, { FC } from 'react';
import { Popover, PopoverProps } from '@mui/material';
import { bindPopover } from 'material-ui-popup-state/hooks';

type Props = ReturnType<typeof bindPopover> &
  PopoverProps & {
    width?: number;
    mt?: number;
  };

const BasePopover: FC<Props> = ({
  children,
  width = 220,
  anchorReference = 'anchorEl',
  mt = 3,
  ...props
}) => {
  return (
    <Popover
      anchorReference={anchorReference}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      slotProps={{ paper: { sx: { width, mt } } }}
      {...props}
    >
      {children}
    </Popover>
  );
};

export default BasePopover;
