import { PhysicalContent } from '@components/EntityDrawers/drawers/Physical/content';
import { PhysicalFormValues } from '@components/EntityDrawers/drawers/Physical/form';
import { PhysicalSettings } from '@components/EntityDrawers/drawers/Physical/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import withTabs from '@components/EntityDrawers/hocs/withTabs';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { PhysicalMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddPhysicalEntity
  | ProjectDrawerTypes.EditPhysicalEntity;

export const PhysicalDrawer = withTabs<Type>(
  withDrawer<PhysicalFormValues, PhysicalMetaData, Type>(
    PhysicalContent,
    PhysicalSettings,
  ),
);
