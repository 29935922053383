import { styled } from '@mui/material/styles';
import { themePalette } from '@theme/muiTheme';

export const LoginPageStyled = styled('div')({
  width: '100%',
  height: '100%',
  minHeight: '100vh',
  display: 'flex',
});

export const LoginContentWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  height: '100%',
  padding: '25px 136px',
  overflow: 'auto',

  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    width: '100%',
    padding: '25px',
  },
}));

export const LoginContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '448px',

  '@media (max-height: 800px)': {
    padding: '20px 0',
    height: '-webkit-fill-available',
  },
});

export const LoginButtonWrapper = styled('div')({
  width: '100%',
  '@media (max-height: 800px)': {
    paddingBottom: '20px',
  },
});

export const LoginSideBlock = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  width: '50%',
  height: '100vh',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'center',
  userSelect: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const LoginSideBlockImage = styled('img')({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
});

export const LoginSideContent = styled('div')({
  width: '100%',
  maxWidth: '578px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const LoginSideTitle = styled('h2')({
  position: 'relative',
  margin: 0,
  color: themePalette.white,
  fontSize: '48px',
  lineHeight: '54px',
});

export const LoginSideItemsWrapper = styled('div')({
  position: 'relative',
  zIndex: 1,
  marginTop: '64px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});
