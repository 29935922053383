import { FC } from 'react';

export enum LoadingKeys {
  RecalculateLayersHeight = 'recalculate-layers-height',
  Collapse = 'collapse',
}

export interface ISelectOption<V = string | number> {
  label: string;
  value: V;
  icon?: FC<any>;
  disabled?: boolean;
}

export const NewItemPrefix = 'new-item-';

export const WideDomainKey = 'wide';

export enum OperationsTabs {
  QNOUS_REFERENCE = 'qnous-reference',
  SERVICE_TIERS = 'service-tiers',
}
