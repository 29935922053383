import { FC, forwardRef, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useOutsideClick from '@hooks/useOutsideClick';
import { SnackbarProps, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

type CustomToastProps = Pick<
  SnackbarProps,
  'anchorOrigin' | 'onClose' | 'open' | 'autoHideDuration' | 'sx'
> & {
  type: 'success' | 'error' | 'info' | 'warning';
  text: FC | string;
  header?: string;
  containerId?: string;
  verticalCenter?: boolean;
};

// eslint-disable-next-line react/display-name
const Alert = forwardRef<HTMLDivElement, AlertProps>(({ ...props }, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAlert elevation={6} ref={ref} withShadow {...props} />
));

export function CustomToast({
  type,
  text: Text,
  containerId,
  sx,
  header,
  verticalCenter,
  ...props
}: CustomToastProps) {
  const alertRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (containerId) {
      const element = document.getElementById(containerId);

      setContainer(element || document.body);
    } else {
      setContainer(document.body);
    }
  }, [containerId]);

  useOutsideClick(alertRef, props.onClose as () => void);

  const snackbar = (
    <Snackbar
      sx={{ position: 'absolute', width: 'fit-content', minWidth: 510 }}
      {...props}
    >
      <Alert
        ref={alertRef}
        sx={sx}
        onClose={props.onClose as () => void}
        severity={type}
        verticalCenter={verticalCenter}
        iconSize="medium"
      >
        {header && (
          <Typography variant="h3" color="grey.900" mb={2}>
            {header}
          </Typography>
        )}

        <Typography variant="body1" color="grey.900">
          {typeof Text === 'string' ? Text : <Text />}
        </Typography>
      </Alert>
    </Snackbar>
  );

  return container ? createPortal(snackbar, container) : null;
}
