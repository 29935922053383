import { Edge } from 'reactflow';
import { getCollapsedState } from '@components/MainStage/collapsedLayers.store';
import useStore from '@components/MainStage/store';
import { SubLayersListByMode } from '@constants/canvas/general';
import { SubLayerTypes } from '@constants/canvas/layers';
import { modeSubject } from '@context/Project';
import { nanoid } from '@reduxjs/toolkit';
import { canvasMap } from '@utils/canvas/CanvasMap';
import SubLayerViewManager from '@utils/canvas/SubLayerViewManager';

class CollapseRelationController {
  private createRelations(source: string, childIds: string[]): Edge[] {
    return childIds.map((target) => ({
      id: source + target + nanoid(),
      source,
      target,
      data: {
        dto: { parent_id: source, child_id: target },
        canvas: {},
        isPhantom: true,
      },
    }));
  }

  private findChildIds(
    currentNodes: string[],
    stopLayer: SubLayerTypes,
  ): string[] {
    if (!currentNodes.length) return [];

    const isLastLayerAchieved = currentNodes.some(
      (id) => canvasMap.nodeMap.get(id)?.parentNode === stopLayer,
    );

    if (isLastLayerAchieved) {
      return currentNodes;
    }

    const childrenNodes = currentNodes.reduce<string[]>((newNodes, nodeId) => {
      if (canvasMap.isNodeHiddenByFilters(nodeId)) return newNodes;

      const visibleChildrenIds =
        canvasMap.nodeMap.get(nodeId)?.data.dto?.childrenIds ?? [];

      return [...newNodes, ...visibleChildrenIds];
    }, []);

    return this.findChildIds(childrenNodes, stopLayer);
  }

  private async generatePhantoms(
    layersToConnect: [SubLayerTypes, SubLayerTypes][],
  ) {
    return layersToConnect.reduce<Edge[]>(
      (phantomsAcc, [startLayer, lastLayer]) => {
        const startLayerView = SubLayerViewManager.getInstance(startLayer);

        const sublayerPhantoms = startLayerView!.nodes.reduce<Edge[]>(
          (subLayerPhantomsAcc, node) => {
            const childIds = this.findChildIds([node.id], lastLayer);
            const relations = this.createRelations(node.id, childIds);

            return subLayerPhantomsAcc.concat(relations);
          },
          [],
        );

        return phantomsAcc.concat(sublayerPhantoms);
      },
      [] as Edge[],
    );
  }

  private getFromToLayers() {
    const collapsedIds = getCollapsedState().collapsed;
    const subLayers = SubLayersListByMode[modeSubject.getValue()!];

    const result: [SubLayerTypes, SubLayerTypes][] = [];
    let currentStart: SubLayerTypes | null = null;

    subLayers?.forEach((id, currentIndex) => {
      const isCollapsedParent = collapsedIds.includes(
        SubLayerViewManager.getInstance(id)!.parentLayerId,
      );

      const isNextParentCollapsed = collapsedIds.includes(
        SubLayerViewManager.getInstance(subLayers[currentIndex + 1])
          ?.parentLayerId as any,
      );

      const isCollapsed = collapsedIds.includes(id) || isCollapsedParent;

      const isNextCollapsed =
        currentIndex !== subLayers.length - 1 &&
        (collapsedIds.includes(subLayers[currentIndex + 1]) ||
          isNextParentCollapsed);

      if (!isCollapsed) {
        if (currentStart === null) {
          if (isNextCollapsed) {
            currentStart = id;
          }
        } else {
          result.push([currentStart, id]);
          currentStart = isNextCollapsed ? id : null;
        }
      }
    });

    return result;
  }

  public async renderPhantoms() {
    const fromToLayers = this.getFromToLayers();
    const phantoms = await this.generatePhantoms(fromToLayers);
    useStore.getState().setPhantoms(phantoms);
  }
}

export const collapseRelationController = new CollapseRelationController();
