import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import ToastProvider from '@context/Toast/ToastProvider';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { persistor, store } from '@store/store';
import { muiTheme } from '@theme/muiTheme';
import { App } from '@views/App';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { PersistGate } from 'redux-persist/es/integration/react';

dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter basename="/">
        <ThemeProvider theme={muiTheme}>
          <CssBaseline />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ToastProvider>
              <ReactFlowProvider>
                <App />
              </ReactFlowProvider>
            </ToastProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
