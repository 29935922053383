import React, { forwardRef, MouseEvent } from 'react';
import { Checkbox, Stack, Typography } from '@mui/material';
import {
  TreeItem as MuiTreeItem,
  TreeItemProps,
  useTreeItem,
} from '@mui/x-tree-view';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

export type SelectHandlerOptions = {
  nodeId: string;
  checked: boolean;
  parentsId: string[];
};

export type SelectHandler = (options: SelectHandlerOptions) => void;

const CustomContent = forwardRef(function CustomContent(
  props: Pick<TreeItemProps, 'nodeId' | 'label'> & {
    onSelect: (ops: Omit<SelectHandlerOptions, 'parentsId'>) => void;
    onClick: () => void;
    hasChildren?: boolean;
  },
  ref,
) {
  const { label, nodeId, onSelect, hasChildren, onClick } = props;

  const { selected, expanded, preventSelection } = useTreeItem(nodeId);

  const handleExpansionClick = (event: MouseEvent<HTMLDivElement>) => {
    preventSelection(event);
    onClick();
  };

  const handleSelectChange = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onSelect({ nodeId, checked: !selected });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        '&:hover': { backgroundColor: themePalette.grey[400] },
      }}
      p="16px 24px"
      onClick={handleExpansionClick}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <Checkbox checked={selected} onClick={handleSelectChange} />

        <Typography variant="body1">{label}</Typography>
      </Stack>

      {hasChildren && (
        <DropdownIcon
          fill={themePalette.grey[1000]}
          style={{ transform: expanded ? 'rotate(180deg)' : '' }}
        />
      )}
    </Stack>
  );
});

const TreeItem = forwardRef(function CustomTreeItem(
  {
    onSelect,
    onClick,
    hasChildren,
    ...props
  }: Pick<TreeItemProps, 'nodeId' | 'label' | 'children'> & {
    onSelect: (ops: Omit<SelectHandlerOptions, 'parentsId'>) => void;
    onClick: () => void;
    hasChildren?: boolean;
  },
  ref: React.Ref<HTMLLIElement>,
) {
  return (
    <MuiTreeItem
      // eslint-disable-next-line react/no-unstable-nested-components
      ContentComponent={(props) => (
        <CustomContent
          onSelect={onSelect as any}
          hasChildren={hasChildren}
          {...props}
          onClick={onClick as any}
        />
      )}
      {...props}
      ref={ref}
    />
  );
});

export default TreeItem;
