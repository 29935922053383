import { LayerTypes, SubLayerTypes } from '@constants/canvas/layers';
import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

type CollapsedStoreType = {
  collapsed: (LayerTypes | SubLayerTypes)[];
  expand: (id: LayerTypes | SubLayerTypes) => void;
  collapse: (id: LayerTypes | SubLayerTypes) => void;
  reset: () => void;
  isCollapsed: (id: LayerTypes | SubLayerTypes) => boolean;
};

const useCollapsedLayers = create<CollapsedStoreType>((set, get) => ({
  collapsed: [],

  expand: (id: LayerTypes | SubLayerTypes) => {
    set(({ collapsed }) => ({
      collapsed: collapsed.filter((layerId) => layerId !== id),
    }));
  },

  collapse: (id: LayerTypes | SubLayerTypes) => {
    set(({ collapsed }) => ({
      collapsed: [...collapsed, id],
    }));
  },

  reset: () => {
    set({ collapsed: [] });
  },

  isCollapsed: (id: LayerTypes | SubLayerTypes) => get().collapsed.includes(id),
}));

export const useIsCollapsed = (id: LayerTypes | SubLayerTypes) => {
  const { isCollapsed } = useCollapsedLayers(
    useShallow((state) => ({
      isCollapsed: state.isCollapsed,
    })),
  );

  return isCollapsed(id);
};

export const useCollapsed = () => {
  return useCollapsedLayers(
    useShallow((state) => ({
      collapsed: state.collapsed,
    })),
  );
};

export const useCollapsedActions = () => {
  return useCollapsedLayers(
    useShallow((state) => ({
      isCollapsed: state.isCollapsed,
      collapse: state.collapse,
      expand: state.expand,
      reset: state.reset,
    })),
  );
};

export const { getState: getCollapsedState } = useCollapsedLayers;

export default useCollapsedLayers;
