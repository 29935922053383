import { useState } from 'react';
import { StepperState } from '@hooks/useSteppr/types';

const useStepper = (
  totalSteps: number,
  initialStep: number = 0,
): StepperState => {
  const [activeStep, setActiveStep] = useState<number>(initialStep);

  const goToStep = (step: number) => {
    if (step >= 0 && step < totalSteps) {
      setActiveStep(step);
    }
  };

  const nextStep = () => {
    setActiveStep((prevStep) =>
      prevStep < totalSteps - 1 ? prevStep + 1 : prevStep,
    );
  };

  const prevStep = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : prevStep));
  };

  const resetStepper = () => {
    setActiveStep(initialStep);
  };

  return [activeStep, { goToStep, nextStep, prevStep, resetStepper }];
};

export default useStepper;
