import { createContext, useContext } from 'react';
import { Node } from 'reactflow';

const MenuNodesContext = createContext<{
  nodes: Node[];
}>({
  nodes: [],
});

export const useMenuNodes = () => {
  const context = useContext(MenuNodesContext);

  if (!context) {
    throw new Error('useMenuNodes must be used within a MenuNodesProvider');
  }

  return context;
};

const MenuNodesProvider = MenuNodesContext.Provider;

export default MenuNodesProvider;
