import { compatibleLayerTypeByLayerId } from '@constants/canvas/general';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useCurrentAsset from '@hooks/useCurrentAsset';
import useNode from '@hooks/useNode';
import { NodeDTOTypes } from '@store/services/nodes/types';

const useIsNodeAddedToAsset = (nodeId: string) => {
  const {
    toolbox: { mode },
  } = useProject();

  const {
    data: { dto: node },
  } = useNode(nodeId);

  const { dto: currentAsset } = useCurrentAsset();

  const isCurrentAssetParentForNode =
    Boolean(node?.parent_asset) && node.parent_asset === currentAsset.id;

  return (
    mode === Modes.RiskManagement &&
    !isCurrentAssetParentForNode &&
    Object.values(compatibleLayerTypeByLayerId).includes(
      node?.type as NodeDTOTypes,
    )
  );
};

export default useIsNodeAddedToAsset;
