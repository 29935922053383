import { useMemo } from 'react';
import { WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import { IDomain } from '@store/services/projects/types';
import { transformDomainsToObject } from '@views/Project/helpers';

const useDomainsFilter = (domains: IDomain[]) => {
  const {
    toolbox: { domains: toolboxDomains },
    isDashboardMode,
  } = useProject();

  const ScopeName = useMemo(() => {
    if (!toolboxDomains.length || toolboxDomains.length === domains.length + 1)
      return '360° view';

    const domainsObject = transformDomainsToObject(domains, toolboxDomains);

    if (toolboxDomains.length === 1) {
      return domainsObject[toolboxDomains[0]]?.name || 'Domain';
    }

    const inheritedWide = toolboxDomains.includes(WideDomainKey);
    const DomainId = toolboxDomains.find(
      (id) => id !== WideDomainKey && domainsObject[id]?.level === 1,
    );

    if (toolboxDomains.length === 3) {
      const SubDomainId = toolboxDomains.find(
        (id) =>
          id !== WideDomainKey &&
          domainsObject[id]?.parentId === DomainId &&
          domainsObject[id]?.level === 2,
      );

      if (inheritedWide && DomainId && SubDomainId) {
        return domainsObject[SubDomainId]?.name;
      }
    }

    if (toolboxDomains.length === 2) {
      if (inheritedWide && DomainId) {
        return domainsObject[DomainId]?.name;
      }
    }

    return 'Multiple Domains';
  }, [toolboxDomains, domains]);

  if (isDashboardMode) {
    return { ScopeName: '360° view', ScopeCount: domains.length + 1 };
  }

  return {
    ScopeName,
    ScopeCount: toolboxDomains.length || domains.length + 1,
  };
};

export default useDomainsFilter;
