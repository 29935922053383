import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { DrawerBadges } from '@components/EntityDrawers/components/ProjectRightDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import { Tabs } from '@views/Insights/types';

type UseDrawerInfoOptions = {
  type: ProjectDrawerTypes;
  editType: ProjectDrawerTypes;
  newTitle?: string;
  editTitle?: string;
  insightsTitle?: string;
};

const InsightsBadgesMap = {
  [Tabs.Dependency]: DrawerBadges.Dependency,
  [Tabs.FocusOn]: DrawerBadges.FocusOn,
  [Tabs.Maturity]: DrawerBadges.Maturity,
  [Tabs.ModelEvolution]: DrawerBadges.ModelEvolution,
  [Tabs.Completeness]: DrawerBadges.Completeness,
  [Tabs.KeyControls]: DrawerBadges.KeyControls,
  [Tabs.Default]: undefined,
};

const useDrawerInfo = ({
  type,
  editType,
  newTitle = 'Add new entity',
  editTitle = 'Edit entity',
  insightsTitle = 'Entity metadata',
}: UseDrawerInfoOptions) => {
  const { pathname } = useLocation();
  const { drawer, insightsTab } = useProject();

  const insightsMode = pathname.includes('insights');

  const open = drawer?.type === type;
  const editMode = type === editType;

  const drawerTitle = useMemo(() => {
    if (insightsMode) {
      return insightsTitle;
    }

    if (editMode) {
      return editTitle;
    }

    return newTitle;
  }, [editMode, insightsMode, newTitle, editTitle, insightsTitle]);

  const badge = insightsMode ? InsightsBadgesMap[insightsTab] : undefined;

  return {
    open,
    editMode,
    drawerTitle,
    insightsMode,
    badge,
  };
};

export default useDrawerInfo;
