import React, { FC } from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import { Typography } from '@mui/material';

type Props = Pick<ConfirmDialogProps, 'open' | 'onClose' | 'onConfirm'> & {
  assetName: string;
};

const DeleteAssetModal: FC<Props> = ({ assetName, ...props }) => {
  return (
    <ConfirmDialog
      {...props}
      title="Delete entity"
      text={
        <Typography variant="body1" color="grey.900">
          Are you sure that you want to delete the entity{' '}
          <Typography variant="body1" color="grey.1000" component="span">
            {assetName}
          </Typography>
          ?
          <br />
          In this case entire asset risk assessment model will be deleted.
        </Typography>
      }
    />
  );
};

export default DeleteAssetModal;
