import { BehaviorSubject } from 'rxjs';

type Filters = {
  validationStatus: string[];
  reviewStatus: string[];
  domains: string[];
};

export const filtersSubject = new BehaviorSubject<Filters>({
  validationStatus: [],
  reviewStatus: [],
  domains: [],
});
