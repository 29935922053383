import React, { FC, useMemo } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import {
  NameFiledTitles,
  OwnerFiledTitles,
} from '@components/EntityDrawers/constants/common';
import SmallSourcesGroup from '@components/EntityDrawers/drawers/Contextual/inputs/SmallSourcesGroup';
import { DrawerContentProps } from '@components/EntityDrawers/hocs/withDrawer';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import { ContextualSubLayerTypes } from '@constants/canvas/layers';
import EntityAlerts from '@views/Project/components/EntityAlerts';

export const ContextualContent: FC<DrawerContentProps> = () => {
  const activeNode = useSourceNode();
  const { editMode } = useDrawerContext();

  const { nameLabel, ownerLabel } = useMemo(
    () =>
      activeNode
        ? {
            nameLabel:
              NameFiledTitles[activeNode.parentNode as ContextualSubLayerTypes],
            ownerLabel:
              OwnerFiledTitles[
                activeNode.parentNode as ContextualSubLayerTypes
              ],
          }
        : {},
    [activeNode?.parentNode],
  );

  return (
    <>
      <EntityAlerts />

      <NameInput
        label={nameLabel}
        placeholder="Entity name"
        editMode={editMode}
      />

      <OwnerInput label={ownerLabel} placeholder="Owner name" />
      <DomainDropdown />
      <SmallSourcesGroup />
      <ValidationStatus />

      {editMode && <CreatedReviewDates dateReviewedClearable />}
    </>
  );
};
