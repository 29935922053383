import React, { FC } from 'react';
import { YesNoOptions } from '@components/EntityDrawers/constants/common';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { useProject } from '@context/Project/ProjectProvider';

const DesignEffectivenessDropdown: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledTextFieldSelect
      name={CommonKeys.DesignEffectiveness}
      label="Design effectiveness"
      placeholder="Choose effectiveness"
      disabled={viewOnly}
      options={YesNoOptions}
      resetOnDoubleClick
    />
  );
};

export default DesignEffectivenessDropdown;
