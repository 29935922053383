import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledDatePicker from '@components/Inputs/controllers/ControlledDatePicker';
import { useProject } from '@context/Project/ProjectProvider';

const TimelineDates: FC = () => {
  const { viewOnly } = useProject();

  return (
    <>
      <ControlledDatePicker
        validateOnBlur
        name={CommonKeys.TimelineStartDate}
        label="Timeline start date"
        readOnly={viewOnly}
        clearable
      />

      <ControlledDatePicker
        name={CommonKeys.TimelineEndDate}
        label="Timeline end date"
        triggerKeys={[CommonKeys.TimelineStartDate]}
        readOnly={viewOnly}
        clearable
      />
    </>
  );
};

export default TimelineDates;
