import React, { FC, memo } from 'react';
import { AssessmentDrawer } from '@components/EntityDrawers/drawers/Assessment';
import { CharacteristicDrawer } from '@components/EntityDrawers/drawers/Characteristic';
import { ComponentDrawer } from '@components/EntityDrawers/drawers/Component';
import { ConceptualDrawer } from '@components/EntityDrawers/drawers/Conceptual';
import { ContextualDrawer } from '@components/EntityDrawers/drawers/Contextual';
import { InitiativeDrawer } from '@components/EntityDrawers/drawers/Initiative';
import { LogicalDrawer } from '@components/EntityDrawers/drawers/Logical';
import { PhysicalDrawer } from '@components/EntityDrawers/drawers/Physical';
import { RelationshipDrawer } from '@components/EntityDrawers/drawers/Relationship';
import { RiskDrawer } from '@components/EntityDrawers/drawers/Risk';
import { ProjectDrawerTypes } from '@constants/entities/drawers';

const DrawersOutlet: FC = memo(() => {
  return (
    <>
      <ContextualDrawer type={ProjectDrawerTypes.AddContextualEntity} />
      <ContextualDrawer type={ProjectDrawerTypes.EditContextualEntity} />

      <ConceptualDrawer type={ProjectDrawerTypes.AddConceptualEntity} />
      <ConceptualDrawer type={ProjectDrawerTypes.EditConceptualEntity} />

      <LogicalDrawer type={ProjectDrawerTypes.AddLogicalEntity} />
      <LogicalDrawer type={ProjectDrawerTypes.EditLogicalEntity} />

      <PhysicalDrawer type={ProjectDrawerTypes.AddPhysicalEntity} />
      <PhysicalDrawer type={ProjectDrawerTypes.EditPhysicalEntity} />

      <ComponentDrawer type={ProjectDrawerTypes.AddComponentEntity} />
      <ComponentDrawer type={ProjectDrawerTypes.EditComponentEntity} />

      <InitiativeDrawer type={ProjectDrawerTypes.AddInitiativesEntity} />
      <InitiativeDrawer type={ProjectDrawerTypes.EditInitiativesEntity} />

      <AssessmentDrawer type={ProjectDrawerTypes.AddAssessmentScopeEntity} />
      <AssessmentDrawer type={ProjectDrawerTypes.EditAssessmentScopeEntity} />

      <CharacteristicDrawer
        type={ProjectDrawerTypes.AddSensitiveElementEntity}
      />
      <CharacteristicDrawer
        type={ProjectDrawerTypes.EditSensitiveElementEntity}
      />

      <RiskDrawer type={ProjectDrawerTypes.AddAssociatedRiskEntity} />
      <RiskDrawer type={ProjectDrawerTypes.EditAssociatedRiskEntity} />

      <RelationshipDrawer type={ProjectDrawerTypes.AddRelationship} />
      <RelationshipDrawer type={ProjectDrawerTypes.EditRelationship} />
    </>
  );
});

export default DrawersOutlet;
