import React, { memo } from 'react';
import { UploadFileStatus } from '@components/UploadModal/core/UploadFileStatus';
import { GenerateReportError } from '@constants/entities/reports';
import { Button, Stack, Typography } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { downloadFile, quantityStr } from '@utils/helpers';
import { ImageIcon } from '@utils/iconsDefs';
import { ModalContent } from '@views/ReportsModel/components/ExportAsContent/ExportAsContent.styled';
import { ExportAsModalFC } from '@views/ReportsModel/constants/types';

const ExportAsContent: ExportAsModalFC = ({
  description,
  onClose,
  onGenerateReport,
  files,
  initialGenerating,
  children,
  disabled,
  continueEntityText = {
    singular: 'report',
    plural: 'reports',
  },
}) => {
  const isGenerating = files.some(({ isGenerating }) => isGenerating);

  const someError = files.some(({ isError }) => isError);
  const allErrors = files.every(({ isError }) => isError);

  const hasSomeUrl = files.some(({ generatedFile }) =>
    Boolean(generatedFile.url),
  );

  const isExportSettingsStep =
    Boolean(onGenerateReport) &&
    !isGenerating &&
    !initialGenerating &&
    !hasSomeUrl;

  const isDownloadStep = !isGenerating && hasSomeUrl;

  const handleDownloadReport = () => {
    if (hasSomeUrl) {
      files.forEach(({ generatedFile }) => {
        if (generatedFile.url)
          downloadFile(generatedFile.url, generatedFile.name);
      });
    }

    onClose?.();
  };

  const onContinue = isExportSettingsStep
    ? onGenerateReport
    : handleDownloadReport;

  const getContinueText = (title: string) =>
    quantityStr(
      files.length,
      `${title} ${continueEntityText.singular}`,
      `${title} ${continueEntityText.plural}`,
    );

  const continueText = isExportSettingsStep
    ? getContinueText('Generate')
    : getContinueText('Download');

  const isInitialGenerating =
    !!initialGenerating && !isDownloadStep && !someError;

  return (
    <ModalContent>
      <Typography color="grey.900" sx={{ whiteSpace: 'pre-wrap' }}>
        {typeof description === 'string'
          ? description
          : description({ isExportSettingsStep, isDownloadStep })}
      </Typography>

      {isExportSettingsStep && !someError ? (
        <>{children}</>
      ) : (
        files.map(
          ({
            isGenerating,
            generatedFile: { name: fileName, size: fileSize },
            isError,
          }) => (
            <UploadFileStatus
              key={fileName}
              uploadError={isError ? GenerateReportError : ''}
              isLoading={isGenerating || isInitialGenerating}
              fileData={{ name: fileName, size: fileSize }}
              status={
                isGenerating || isInitialGenerating
                  ? QueryStatus.pending
                  : QueryStatus.fulfilled
              }
              chipNames={{ loading: 'Generating...', uploaded: 'Generated' }}
              Icon={ImageIcon}
            />
          ),
        )
      )}

      {(isExportSettingsStep || isDownloadStep) && !allErrors && (
        <Stack flexDirection="row" gap={4} mt={6}>
          <Button fullWidth variant="outlined" size="large" onClick={onClose}>
            Back
          </Button>

          <Button
            fullWidth
            variant="contained"
            size="large"
            onClick={onContinue}
            disabled={disabled}
          >
            {continueText}
          </Button>
        </Stack>
      )}
    </ModalContent>
  );
};

export default memo(ExportAsContent);
