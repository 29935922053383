import { IconButton, styled } from '@mui/material';
import { themePalette } from '@theme/muiTheme';

export const StyledIconButton = styled(IconButton)`
  svg {
    stroke: ${themePalette.grey[800]};
  }
  &:hover svg {
    stroke: ${themePalette.grey[1000]};
  }
`;
