import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginWrapper } from '@components/LoginWrapper';
import { UserRoles } from '@constants/entities/user';
import useLogout from '@hooks/useLogout';
import useUser from '@hooks/useUser';
import { Box, Button, Typography } from '@mui/material';
import { ROUTES } from '@router/routes';

type Props = {
  allowedRoles: UserRoles[];
  children: ReactNode;
};

const RoleGuard: FC<Props> = ({ children, allowedRoles }) => {
  const [{ role }, { isLoading, isFetching, refetch }] = useUser();
  const handleLogout = useLogout();

  if (isLoading || isFetching) {
    return null;
  }

  if (!(isLoading || isFetching) && !role) {
    return (
      <LoginWrapper>
        <Typography variant="h3" align="center">
          Your role was unable to identify.
        </Typography>

        <Box width={250} mx="auto" display="flex" flexDirection="column">
          <Button variant="contained" onClick={refetch} sx={{ mt: 6 }}>
            Try Again
          </Button>

          <Button onClick={handleLogout} sx={{ mt: 2 }}>
            Logout
          </Button>
        </Box>
      </LoginWrapper>
    );
  }

  if (allowedRoles.includes(role)) {
    return <>{children}</>;
  }

  return <Navigate to={ROUTES.MAIN} />;
};

export default RoleGuard;
