import { CANVAS_DEFAULT_HEIGHT } from '@constants/canvas/general';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface UIState {
  loadingIds: string[];
  canvasZoom: number;
  canvasHeight: number;
}

const initialState: UIState = {
  loadingIds: [],
  canvasZoom: 1,
  canvasHeight: CANVAS_DEFAULT_HEIGHT,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoadings: (state, { payload }: PayloadAction<string[]>) => {
      state.loadingIds = payload ?? [];
    },

    startLoading: (state, { payload: id }: PayloadAction<string>) => {
      state.loadingIds = Array.from(new Set([...state.loadingIds, id]));
    },

    stopLoading: (state, { payload: id }: PayloadAction<string>) => {
      state.loadingIds = state.loadingIds.filter(
        (loadingId) => loadingId !== id,
      );
    },

    setCanvasZoom: (state, { payload: zoom }: PayloadAction<number>) => {
      state.canvasZoom = zoom;
    },

    resetCanvasZoom: (state) => {
      state.canvasZoom = initialState.canvasZoom;
    },

    setCanvasHeight: (state, { payload: height }: PayloadAction<number>) => {
      state.canvasHeight = height;
    },
  },
});

export const selectLoadingIds = (state: RootState) => state.ui.loadingIds;
export const selectCanvasZoom = (state: RootState) => state.ui.canvasZoom;
export const selectCanvasHeight = (state: RootState) => state.ui.canvasHeight;

export const {
  setLoadings,
  startLoading,
  stopLoading,
  setCanvasZoom,
  resetCanvasZoom,
  setCanvasHeight,
} = uiSlice.actions;
export default uiSlice.reducer;
