import React, { FC, useEffect } from 'react';
import { CommonReportDescription } from '@constants/entities/reports';
import useProjectId from '@hooks/useProjectId';
import ExportAsContent from '@views/ReportsModel/components/ExportAsContent/ExportAsContent';
import { ModalWrapper } from '@views/ReportsModel/components/ExportAsContent/ExportAsContent.styled';
import { ModalProps } from '@views/ReportsModel/constants/types';
import { getFileData } from '@views/ReportsModel/helpers';
import useExportRegister from '@views/ReportsModel/hooks/useExportRegister';

export const ExportRiskRegisterReportModal: FC<ModalProps> = (props) => {
  const { open, onClose } = props;
  const projectId = useProjectId(true);

  const {
    fileName,
    setFileContent,
    fileContent,
    setError,
    isError,
    handleGenerate,
    isLoading,
  } = useExportRegister(projectId);

  useEffect(() => {
    if (open) {
      handleGenerate();
    } else {
      setFileContent('');
      setError(false);
    }
  }, [open]);

  return (
    <ModalWrapper {...props} title="Export as report">
      <ExportAsContent
        files={[
          {
            isGenerating: isLoading,
            generatedFile: getFileData(fileContent, fileName),
            isError,
          },
        ]}
        description={CommonReportDescription}
        initialGenerating
        onClose={onClose}
      />
    </ModalWrapper>
  );
};
