import { CoreRequiredKeys } from '@components/EntityDrawers/constants/keys';
import {
  AssessmentFormValues,
  AssetFields,
  defaultValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Assessment/form';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { AssessmentMetaData } from '@store/services/nodes/types';
import { checkValuesIncomplete, transformCommonKeys } from '@utils/drawers';
import { omitCertainEmptyKeys, transformArrayToOptions } from '@utils/helpers';

export const AssessmentSettings: DrawerSettings<
  AssessmentFormValues,
  AssessmentMetaData
> = {
  editType: ProjectDrawerTypes.EditAssessmentScopeEntity,
  defaultValues,
  validationSchema,
  checkIncompleteFields: checkValuesIncomplete(
    ...CoreRequiredKeys,
    AssetKeys.Identifier,
    AssetKeys.Criticality,
  ),

  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<AssessmentFormValues, AssessmentMetaData>(
      ...AssetFields,
    )(nodeDto, context),

    [AssetKeys.Identifier]: nodeDto.meta_data.identifier ?? '',
    [AssetKeys.Criticality]: nodeDto.meta_data.criticality ?? '',

    [AssetKeys.BusinessAttributes]: transformArrayToOptions(
      nodeDto.businessAttributes ?? [],
      {
        label: 'name',
        value: 'id',
      },
    ),
  }),

  transformMetaDataToRequestBody: (values) =>
    omitCertainEmptyKeys(values, [AssetKeys.Criticality, AssetKeys.Identifier]),
};
