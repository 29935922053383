import { FC, ReactNode } from 'react';
import { Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';

import { Wrapper } from './LoginSideItem.styled';

export interface LoginSideItemProps {
  icon: ReactNode;
  text: string;
}

export const LoginSideItem: FC<LoginSideItemProps> = ({ icon, text }) => {
  return (
    <Wrapper>
      {icon}
      <Typography variant="h4" color={themePalette.white} sx={{ flex: 1 }}>
        {text}
      </Typography>
    </Wrapper>
  );
};
