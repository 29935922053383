import { MutableRefObject, useEffect } from 'react';

const useOutsideClick = (
  ref: MutableRefObject<any> | undefined | null,
  callback: () => void,
) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref?.current && !ref?.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    if (!ref) return;

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
