import { LogicalContent } from '@components/EntityDrawers/drawers/Logical/content';
import { LogicalFormValues } from '@components/EntityDrawers/drawers/Logical/form';
import { LogicalSettings } from '@components/EntityDrawers/drawers/Logical/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import withTabs from '@components/EntityDrawers/hocs/withTabs';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { LogicalMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddLogicalEntity
  | ProjectDrawerTypes.EditLogicalEntity;

export const LogicalDrawer = withTabs<Type>(
  withDrawer<LogicalFormValues, LogicalMetaData, Type>(
    LogicalContent,
    LogicalSettings,
  ),
);
