import React, { FC } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import ValidationStatus from '@components/EntityDrawers/components/inputs/ValidationStatus';
import AssetCriticalityLevelDropdown from '@components/EntityDrawers/drawers/Assessment/inputs/AssetCriticalityLevelDropdown';
import BusinessAttributesInput from '@components/EntityDrawers/drawers/Assessment/inputs/BusinessAttributesInput';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import { DrawerContentProps } from '@components/EntityDrawers/hocs/withDrawer';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectData from '@hooks/useProjectData';
import EntityAlerts from '@views/Project/components/EntityAlerts';

export const AssessmentContent: FC<DrawerContentProps> = () => {
  const [{ subscription }] = useProjectData();
  const { viewOnly } = useProject();
  const { editMode } = useDrawerContext();

  const isBusinessAttributeSown = subscription?.modes.includes(
    Modes.BusinessAlignment,
  );

  return (
    <>
      <EntityAlerts />

      <NameInput
        editMode={editMode}
        label="Asset name"
        placeholder="Asset name"
      />

      <ControlledTextField
        name={AssetKeys.Identifier}
        label="Asset identifier"
        placeholder="Asset identifier"
        disabled={viewOnly}
        required
      />

      <DescriptionInput
        label="Asset description"
        placeholder="Asset description"
      />

      <AssetCriticalityLevelDropdown />

      <OwnerInput label="Asset owner" placeholder="Asset owner" />
      <DomainDropdown />

      {isBusinessAttributeSown && <BusinessAttributesInput />}

      <ValidationStatus />

      {editMode && <CreatedReviewDates dateReviewedClearable />}
    </>
  );
};
