import { useState } from 'react';
import useEntityVisibilityCheck from '@components/EntityDrawers/hooks/useEntityVisibilityCheck';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import { NODE_WAS_UPDATED_IN_INSIGHTS } from '@constants/localStorage';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import { useAppDispatch } from '@hooks/store';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import useToggle from '@hooks/useToggle';
import { nodesApi, useUpdateNodeMutation } from '@store/services/nodes';
import {
  IAdditionalUpdateParams,
  IAvailableRelationship,
  IRelationsError,
  IUpdateNodeRequest,
  NodeDTO,
  NodeDTOTypes,
} from '@store/services/nodes/types';
import { QueryTags } from '@store/services/tags';
import { ResponseCodes } from '@store/services/types';
import { Tabs } from '@views/Insights/types';

const useUpdateNode = () => {
  const { showToast } = useToast();
  const { hideDrawer, insightsTab, isInsightsMode } = useProject();
  const { checkIsEntityBecameHidden } = useEntityVisibilityCheck();
  const activeNode = useSourceNode();
  const dispatch = useAppDispatch();

  const saveResponseToRedux = useSaveEntitiesResponse();

  const [updateNodeRequest, { isLoading: isUpdating }] =
    useUpdateNodeMutation();

  const [node, setNodeBody] = useState<IUpdateNodeRequest | null>(null);

  const [
    isBlockedRelationOpen,
    blockedRelationControls,
    blockedRelationOptions,
  ] = useToggle<IAvailableRelationship[] | null>();

  const [isDomainChangesConfirmOpen, domainChangesControls] = useToggle();

  const [isRiskActionChangesOpen, riskActionChangesControls] =
    useToggle<IUpdateNodeRequest | null>();

  const checkUpdatesFlag = () => {
    if (isInsightsMode) {
      localStorage.setItem(NODE_WAS_UPDATED_IN_INSIGHTS, 'true');
    } else {
      localStorage.setItem(NODE_WAS_UPDATED_IN_INSIGHTS, 'false');
    }
  };

  const checkMaturityChanges = (
    updatedNode: NodeDTO<{
      current_maturity: string;
      target_maturity: string;
    }>,
  ) => {
    if (insightsTab !== Tabs.Maturity) {
      return;
    }

    const { meta_data, ...entity } = (activeNode?.data?.dto ?? {}) as NodeDTO<{
      current_maturity: string;
      target_maturity: string;
    }>;

    const isCurrentCompleted = entity.is_complete;
    const isUpdatedCompleted = updatedNode.is_complete;
    const isCompletedChanged = isCurrentCompleted !== isUpdatedCompleted;

    const isMaturityChanged =
      updatedNode.meta_data.current_maturity !== meta_data.current_maturity ||
      updatedNode.meta_data.target_maturity !== meta_data.target_maturity;

    const isMaturityFilled =
      !!updatedNode.meta_data.current_maturity &&
      !!updatedNode.meta_data.target_maturity;

    const isToastShown =
      (isCurrentCompleted && isMaturityChanged) ||
      (isCompletedChanged && isMaturityFilled);

    if (isToastShown) {
      showToast(
        'Insight has been updated based on your changes to the entity maturity levels',
        'success',
      );
    }
  };

  const updateRequest = async (
    nodeBody: IUpdateNodeRequest,
    payload?: IAdditionalUpdateParams,
  ) => {
    setNodeBody(nodeBody);

    const response = await updateNodeRequest({
      ...nodeBody,
      ...payload,
    }).unwrap();

    const updatedNode = response.nodes.find(({ id }) => id === nodeBody.nodeId);

    if (updatedNode?.type === NodeDTOTypes.Asset) {
      dispatch(nodesApi.util.invalidateTags([QueryTags.AssetsList]));
    }

    checkUpdatesFlag();

    if (updatedNode) {
      checkMaturityChanges(updatedNode as NodeDTO<any>);
      checkIsEntityBecameHidden(updatedNode);
    }

    saveResponseToRedux(response);
  };

  const handleUpdateNode = async (nodeBody: IUpdateNodeRequest) => {
    try {
      await updateRequest(nodeBody);
      hideDrawer();
    } catch (e: unknown) {
      const error = e as IRelationsError;

      switch (error.data?.code) {
        case ResponseCodes.ITEM_BLOCKED: {
          if (error.data?.blockers.length >= 2) {
            return showToast(
              "Entity has multiple AND relationships with the last auto-assigned weight value. Delete these relationships first to change entity's domain.",
              'error',
            );
          }

          setNodeBody(nodeBody);
          domainChangesControls.on();

          if (error.data?.blockers.length === 1) {
            blockedRelationControls.setData(error.data.blockers[0]);
          }

          return;
        }

        case ResponseCodes.REMEDIATION_ACTION_COULD_BE_REMOVED: {
          setNodeBody(nodeBody);
          riskActionChangesControls.on();

          return;
        }

        default: {
          throw error;
        }
      }
    }
  };

  const handleChangeDomainProceed = async () => {
    domainChangesControls.off();

    if (blockedRelationOptions?.length) {
      return blockedRelationControls.on();
    }

    if (!node) {
      blockedRelationControls.off();
      throw new Error('Node not found. Please try again.');
    }

    await updateRequest(node, { updateDomainOptions: { force: true } });
    hideDrawer();
    setNodeBody(null);
  };

  const handleChangeRelationProceed = async (relationId: string) => {
    if (!node) {
      throw new Error('Node not found. Please try again.');
    }

    await updateRequest(node, {
      updateDomainOptions: {
        force: true,
        allocateWeightRelationsId: relationId,
      },
    });

    blockedRelationControls.off();
    blockedRelationControls.setData(null);
    hideDrawer();
  };

  const handleChangeRiskActionProceed = async (
    keepRemediationActions: boolean,
  ) => {
    if (!node) {
      throw new Error('Node not found. Please try again.');
    }

    await updateRequest(node, {
      keep_remediation_action: keepRemediationActions,
    });

    riskActionChangesControls.off();
    hideDrawer();
    setNodeBody(null);
  };

  return {
    isUpdating,
    handleUpdateNode,

    modalsProps: {
      isDomainChangesConfirmOpen,
      domainChangesControls,
      onDomainsChangesProceed: handleChangeDomainProceed,

      isBlockedRelationOpen,
      blockedRelationControls,
      blockedRelationOptions,
      onBlockedRelationProceed: handleChangeRelationProceed,

      isRiskActionChangesOpen,
      riskActionChangesControls,
      onRiskActionChangesProceed: handleChangeRiskActionProceed,
    },
  };
};

export default useUpdateNode;
