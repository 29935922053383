import { RiskContent } from '@components/EntityDrawers/drawers/Risk/content';
import { RiskFormValues } from '@components/EntityDrawers/drawers/Risk/form';
import { RiskSettings } from '@components/EntityDrawers/drawers/Risk/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import withTabs from '@components/EntityDrawers/hocs/withTabs';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { RiskMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddAssociatedRiskEntity
  | ProjectDrawerTypes.EditAssociatedRiskEntity;

export const RiskDrawer = withTabs<Type>(
  withDrawer<RiskFormValues, RiskMetaData, Type>(RiskContent, RiskSettings),
  ['General', 'Analysis'],
);
