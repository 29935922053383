import { WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import { useProjectFilters } from '@context/ProjectFilters/ProjectFiltersProvider';
import { useToast } from '@context/Toast/ToastProvider';
import { NodeDTO } from '@store/services/nodes/types';

const useEntityVisibilityCheck = () => {
  const {
    toolbox: { domains },
  } = useProject();
  const { showToast } = useToast();
  const { validationStatus, reviewStatus } = useProjectFilters();

  const checkIsEntityBecameHidden = (node: NodeDTO) => {
    const { review_required, valid_status, domain_id } = node;

    const isEntityVisible =
      (!reviewStatus.length || review_required) &&
      (!validationStatus.length || validationStatus.includes(valid_status)) &&
      (!domains.length || domains.includes(domain_id ?? WideDomainKey));

    if (!isEntityVisible) {
      showToast(
        'To make it visible please select the appropriate filtering options.',
        'success',
        {
          header: 'Entity saved successfully, but not visible.',
          width: '540px !important',
        },
      );
    }
  };

  return { checkIsEntityBecameHidden };
};

export default useEntityVisibilityCheck;
