import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useProjectId from '@hooks/useProjectId';
import useUser from '@hooks/useUser';
import { ROUTES } from '@router/routes';
import { useFetchProjectsMutation } from '@store/services/projects';

const useProjectRedirect = () => {
  const projectId = useProjectId();
  const [{ isQmsUser }] = useUser();
  const navigate = useNavigate();
  const [getProjects] = useFetchProjectsMutation();

  const checkProject = async () => {
    if (isQmsUser) return;

    const projects = await getProjects({ pageSize: 1000 }).unwrap();

    if (!projects.some((project) => project.id === projectId)) {
      navigate(ROUTES.PROJECT_SETTINGS.MAIN.replace(':id', projects[0]?.id));
    }
  };

  useEffect(() => {
    checkProject();
  }, []);
};

export default useProjectRedirect;
