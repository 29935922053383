import React, { forwardRef, MouseEvent, Ref } from 'react';
import MenuItem from '@components/ActionsMenu/MenuItem';
import MenuSubItem from '@components/ActionsMenu/MenuSubItem';
import { IActionItem } from '@components/ActionsMenu/types';
import { SxProps } from '@mui/material';

type Props = {
  items: IActionItem[];
  onClose?: () => void;
  popoverSx?: SxProps;
};

const ActionList = (
  { items, onClose, popoverSx }: Props,
  ref: Ref<HTMLDivElement>,
) => (
  <div ref={ref}>
    {items.map(({ onClick, ...item }) => {
      if (Object.hasOwn(item, 'subItems')) {
        return (
          <MenuSubItem
            key={item.text}
            item={item}
            onClose={onClose}
            popoverSx={popoverSx}
          />
        );
      }

      const handleClick = (e: MouseEvent) => {
        onClick?.(e);
        onClose?.();
      };

      return (
        <MenuItem key={item.text} item={{ ...item, onClick: handleClick }} />
      );
    })}
  </div>
);

export default forwardRef<any, Props>(ActionList);
