import React, { FC, memo, useMemo } from 'react';
import useStore from '@components/MainStage/store';
import { SideMenu } from '@components/SideMenu';
import { NodesTypes } from '@constants/canvas/general';
import { subLayersNodeIdsByMode } from '@constants/canvas/layerNodes';
import { Modes } from '@constants/canvas/layers';
import { WideDomainKey } from '@constants/entities/ui';
import MenuNodesProvider from '@context/MenuNodesProvider';
import { useProject } from '@context/Project/ProjectProvider';
import { useProjectFilters } from '@context/ProjectFilters/ProjectFiltersProvider';
import { Box, Typography } from '@mui/material';
import AssetsBlock from '@views/Project/components/ListMenu/components/AssetsBlock/AssetsBlock';
import { LayerModeMenus } from '@views/Project/components/ListMenu/components/LayerModeMenus';

type Props = {
  open: boolean;
};

export const ListMenu: FC<Props> = memo(({ open }) => {
  const { nodes } = useStore();

  const {
    isVersionsShown,
    toolbox: { domains, mode },
  } = useProject();

  const { reviewStatus, validationStatus } = useProjectFilters();

  const nodesProviderValue = useMemo(
    () => ({
      nodes: mode
        ? nodes.filter(
            (node) =>
              node.type === NodesTypes.CustomNode &&
              node.parentNode &&
              subLayersNodeIdsByMode[mode].includes(node.parentNode) &&
              (!domains.length ||
                domains.includes(node.data?.dto?.domain_id ?? WideDomainKey)) &&
              (!reviewStatus.length || node.data?.dto?.review_required) &&
              (!validationStatus.length ||
                validationStatus.includes(node.data?.dto?.valid_status ?? '')),
          )
        : [],
    }),
    [nodes, mode, domains, reviewStatus, validationStatus],
  );

  return (
    <SideMenu
      side="left"
      open={open}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      {mode === Modes.RiskManagement ? (
        <AssetsBlock />
      ) : (
        <Box p={3}>
          <Typography variant="h3" sx={{ whiteSpace: 'nowrap' }}>
            Entities list ({nodesProviderValue.nodes.length})
          </Typography>
        </Box>
      )}

      {!isVersionsShown && (
        <Box
          overflow="auto"
          flex={1}
          pb={4}
          sx={{ scrollbarGutter: 'stable both-edges' }}
        >
          <MenuNodesProvider value={nodesProviderValue}>
            <LayerModeMenus />
          </MenuNodesProvider>
        </Box>
      )}
    </SideMenu>
  );
});
