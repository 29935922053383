import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuditLogProjectItem } from '@store/services/audit-log/types';
import { RootState } from '@store/store';

interface ProjectActivityHistoryState {
  logs: AuditLogProjectItem[];
}

const initialState: ProjectActivityHistoryState = {
  logs: [],
};

const projectActivityHistorySlice = createSlice({
  name: 'ProjectActivityHistory',
  initialState,
  reducers: {
    addLogs: (
      state,
      {
        payload: { newLogs },
      }: PayloadAction<{ newLogs: AuditLogProjectItem[] }>,
    ) => {
      state.logs.push(...newLogs);
    },
    clearLogs: (state) => {
      state.logs = [];
    },
  },
});

export const selectProjectActivityHistoryLogs = (state: RootState) =>
  state.projectActivityHistory.logs;

export const { addLogs, clearLogs } = projectActivityHistorySlice.actions;
export default projectActivityHistorySlice.reducer;
