export enum RiskKeys {
  Taxonomy = 'taxonomy',
  DateIdentified = 'date_identified',
  Assessor = 'assessor',
  Steward = 'steward',
  StewardOpinion = 'steward_opinion',
  StewardJustificationNote = 'steward_justification_note',
  InherentLikelihood = 'inherent_likelihood',
  InherentImpact = 'inherent_impact',
  InherentJustification = 'inherent_justification',
  ResidualLikelihood = 'residual_likelihood',
  ResidualImpact = 'residual_impact',
  ResidualJustification = 'residual_justification',
  RiskAction = 'risk_action',

  InherentScore = 'inherent_score',
  ResidualScore = 'residual_score',
  HasCapability = 'has_capability',
}
