import React, { FC } from 'react';
import { DrawerWrapperProps } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { useProject } from '@context/Project/ProjectProvider';
import useTabs from '@hooks/useTabs';
import { Tab, Tabs } from '@mui/material';

const withTabs = <Type extends ProjectDrawerTypes>(
  DrawerComponent: FC<DrawerWrapperProps<Type>>,
  tabNames: string[] = ['Standard', 'Advanced'],
) => {
  return ({ type }: { type: Type }) => {
    const { drawer } = useProject();
    const isOpen = drawer?.type === type;

    const { tab, changeTab } = useTabs([isOpen]);

    return (
      <DrawerComponent
        type={type}
        drawerProps={{
          tab,

          headChildren: (
            <Tabs variant="fullWidth" value={tab} onChange={changeTab}>
              {tabNames.map((name) => (
                <Tab key={name} label={name} />
              ))}
            </Tabs>
          ),
        }}
      />
    );
  };
};

export default withTabs;
