import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { AssessmentScopeSubLayerTypes } from '@constants/canvas/layers';
import useCenterNodeViewport from '@hooks/canvas/useCenterNodeViewport';
import { calculatePositionForNewNode } from '@utils/canvasHelpers';

type Options = {
  canvasNodes: Node[];
};

const useAddAsset = () => {
  const { addChildNode, updateNode, setActiveNode } = useStore();
  const centerNode = useCenterNodeViewport();

  return async ({ canvasNodes }: Options) => {
    const position = calculatePositionForNewNode(
      AssessmentScopeSubLayerTypes.Asset,
      canvasNodes,
      updateNode,
    );

    const id = addChildNode(AssessmentScopeSubLayerTypes.Asset, position);
    setActiveNode(id);

    centerNode(position, AssessmentScopeSubLayerTypes.Asset);
  };
};

export default useAddAsset;
