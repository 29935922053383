import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import {
  CharacteristicFormValues,
  defaultValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Characteristic/form';
import { CharacteristicKeys } from '@components/EntityDrawers/drawers/Characteristic/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { CharacteristicMetaData } from '@store/services/nodes/types';
import {
  add,
  checkValuesIncomplete,
  transformCommonKeys,
} from '@utils/drawers';
import { transformArrayToOptions } from '@utils/helpers';

export const CharacteristicSettings: DrawerSettings<
  CharacteristicFormValues,
  CharacteristicMetaData
> = {
  editType: ProjectDrawerTypes.EditSensitiveElementEntity,
  defaultValues,
  validationSchema,

  checkIncompleteFields: checkValuesIncomplete(CharacteristicKeys.ElementType),

  transformNodeDtoToFormValues: (node, context) => ({
    ...transformCommonKeys<CharacteristicFormValues, CharacteristicMetaData>(
      CommonKeys.Name,
      CommonKeys.DateCreated,
    )(node, context),

    [CharacteristicKeys.ElementType]: node.meta_data.element_type ?? null,

    [CharacteristicKeys.ElementDetail]: transformArrayToOptions(
      node.meta_data.element_detail ?? [],
    ),
    [CommonKeys.Description]: node.meta_data.element_description ?? '',
  }),

  transformMetaDataToRequestBody: (values) =>
    ({
      element_type: values[CharacteristicKeys.ElementType],

      ...add(!!values[CharacteristicKeys.ElementDetail].length, {
        element_detail: values[CharacteristicKeys.ElementDetail]?.map(
          ({ value }) => value,
        ),
      }),

      ...add(!!values[CommonKeys.Description]?.length, {
        element_description: values[CommonKeys.Description],
      }),
    }) as CharacteristicMetaData,
};
