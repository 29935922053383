import { useCallback, useRef } from 'react';
import { HandleType } from 'reactflow';

const useConnect = () => {
  const draggedEdgeHandleType = useRef<HandleType | null>(null);

  const onConnectStart = useCallback(
    (
      _: any,
      params: {
        handleType: HandleType | null;
      },
    ) => {
      draggedEdgeHandleType.current = params.handleType;
    },
    [],
  );

  const onConnectEnd = useCallback(() => {
    draggedEdgeHandleType.current = null;
  }, []);

  return { onConnectStart, onConnectEnd };
};

export default useConnect;
