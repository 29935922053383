import React, { FC, ReactNode, useState } from 'react';
import BaseListItem from '@components/Inputs/Autocomplete/items/BaseListItem';
import { ListItemProps } from '@components/Inputs/Autocomplete/types';
import Label from '@components/Inputs/Label';
import {
  Box,
  CircularProgress,
  InputAdornment,
  SxProps,
  TextField,
  TextFieldProps,
} from '@mui/material';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material/Autocomplete';
import { trimLower } from '@utils/helpers';
import { DropdownIcon } from '@utils/iconsDefs';

export type AutocompleteProps = Pick<
  MuiAutocompleteProps<any, true, any, any>,
  | 'options'
  | 'value'
  | 'onChange'
  | 'loading'
  | 'readOnly'
  | 'multiple'
  | 'noOptionsText'
  | 'renderTags'
  | 'slotProps'
  | 'inputValue'
  | 'onInputChange'
  | 'popupIcon'
  | 'componentsProps'
  | 'filterOptions'
  | 'freeSolo'
  | 'clearOnBlur'
  | 'sx'
> &
  Pick<TextFieldProps, 'helperText' | 'error' | 'placeholder'> & {
    label?: string;
    required?: boolean;
  } & {
    itemComponent?: FC<ListItemProps & any>;
    itemProps?: (option: any & { label: string }) => Record<string, any>;
    isLoading?: boolean;
    startIcon?: ReactNode;
    notFoundText?: string;
    searchStartLength?: number;
    boxSx?: SxProps;
    trimLowerValue?: boolean;
  };

export const Autocomplete: FC<AutocompleteProps> = ({
  label,
  required,
  placeholder,
  helperText,
  error,
  readOnly,
  multiple,
  isLoading,
  itemComponent: ItemComponent = BaseListItem,
  itemProps,
  startIcon,
  noOptionsText,
  notFoundText = 'No matches found',
  searchStartLength = 2,
  boxSx,
  trimLowerValue,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  const getIconClassName = () => {
    const hasValue = Array.isArray(props.value)
      ? props.value.length > 0
      : props.value;

    return hasValue ? 'dropdown hasValue' : 'dropdown';
  };

  return (
    <Box sx={{ width: '100%', position: 'relative', ...boxSx }}>
      {label && <Label required={required}>{label}</Label>}

      <MuiAutocomplete
        multiple={multiple}
        isOptionEqualToValue={(option, value) => {
          const optionValue =
            typeof option === 'string' ? option : option.value;

          const valueValue = typeof value === 'string' ? value : value.value;

          if (trimLowerValue) {
            return trimLower(optionValue) === trimLower(valueValue);
          }

          return optionValue === valueValue;
        }}
        disableCloseOnSelect={multiple}
        disableClearable={!multiple}
        renderOption={(props, option, state) => (
          <ItemComponent
            props={props}
            option={option}
            state={state}
            multiple={multiple}
            {...(itemProps ? itemProps(option) : {})}
          />
        )}
        style={{ width: '100%' }}
        ChipProps={{ size: 'small', variant: 'grey' }}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={props.value?.length ? '' : placeholder}
            helperText={helperText}
            error={error}
            sx={{ height: '100%' }}
            disabled={readOnly}
            InputProps={{
              ...params.InputProps,
              startAdornment: startIcon ? (
                <InputAdornment position="start" sx={{ mr: 0 }}>
                  {startIcon}
                </InputAdornment>
              ) : (
                params.InputProps.startAdornment
              ),
            }}
          />
        )}
        popupIcon={<DropdownIcon className={getIconClassName()} />}
        disabled={readOnly}
        noOptionsText={
          inputValue.trim().length >= searchStartLength
            ? notFoundText
            : noOptionsText
        }
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'flip',
                enabled: false,
              },
            ],
          },
        }}
        {...props}
      />

      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
