import React, { FC } from 'react';
import CriticalityRatingDropdown from '@components/EntityDrawers/drawers/Risk/inputs/CriticalityRatingDropdown';
import InherentImpact from '@components/EntityDrawers/drawers/Risk/inputs/InherentImpact';
import InherentJustification from '@components/EntityDrawers/drawers/Risk/inputs/InherentJustification';
import InherentLikelihood from '@components/EntityDrawers/drawers/Risk/inputs/InherentLikelihood';
import InherentScore from '@components/EntityDrawers/drawers/Risk/inputs/InherentScore';
import ResidualImpact from '@components/EntityDrawers/drawers/Risk/inputs/ResidualImpact';
import ResidualJustification from '@components/EntityDrawers/drawers/Risk/inputs/ResidualJustification';
import ResidualLikelihood from '@components/EntityDrawers/drawers/Risk/inputs/ResidualLikelihood';
import ResidualScore from '@components/EntityDrawers/drawers/Risk/inputs/ResidualScore';
import RiskActionDropdown from '@components/EntityDrawers/drawers/Risk/inputs/RiskActionDropdown';
import { Stack } from '@mui/material';

const Analysis: FC = () => {
  return (
    <>
      <Stack gap={4}>
        <InherentLikelihood />
        <InherentImpact />
        <InherentJustification />
        <InherentScore />
      </Stack>

      <Stack gap={4}>
        <ResidualLikelihood />
        <ResidualImpact />
        <ResidualJustification />
        <ResidualScore />
      </Stack>

      <CriticalityRatingDropdown />
      <RiskActionDropdown />
    </>
  );
};

export default Analysis;
