import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface AssetsState {
  selectedAssetIds: Record<string, null | string>;
  isNewAssetMode: boolean;
}

const initialState: AssetsState = {
  selectedAssetIds: {},
  isNewAssetMode: false,
};

const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setAssetId: (
      state,
      {
        payload: { assetId, projectId },
      }: PayloadAction<{ assetId: string; projectId: string }>,
    ) => {
      state.selectedAssetIds[projectId] = assetId;
    },

    resetAssetId: (state, { payload }) => {
      state.selectedAssetIds[payload] = null;
    },

    switchOnNewAssetMode: (state) => {
      state.isNewAssetMode = true;
    },

    switchOffNewAssetMode: (state) => {
      state.isNewAssetMode = false;
    },
  },
});

export const selectAssetIds = (state: RootState) =>
  state.assets.selectedAssetIds;
export const selectIsNewAssetMode = (state: RootState) =>
  state.assets.isNewAssetMode;

export const {
  setAssetId,
  resetAssetId,
  switchOnNewAssetMode,
  switchOffNewAssetMode,
} = assetSlice.actions;

export default assetSlice.reducer;
