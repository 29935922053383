import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormHelperText,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from '@mui/material';

type Props = SwitchProps & {
  name: string;
  label?: string;
  helperText?: string;
};

const ControlledSwitch: FC<Props> = ({
  name,
  label,
  onChange,
  helperText,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onBlur, ...field } }) => (
        <>
          <Stack direction="row" sx={{ width: '100%', gap: 3 }}>
            {label && (
              <Typography variant="body1" color="grey.900">
                {label}
              </Typography>
            )}

            <Switch
              {...field}
              checked={field.value}
              onChange={(e, checked) => {
                onBlur();
                field.onChange(e);
                if (onChange) onChange(e, checked);
              }}
              {...props}
            />
          </Stack>

          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </>
      )}
    />
  );
};

export default ControlledSwitch;
