import { useEffect, useState } from 'react';
import { CANVAS_WIDTH } from '@constants/canvas/general';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';

const useCanvasWidth = () => {
  const [canvasWidth, setCanvasWidth] = useState(CANVAS_WIDTH);
  const { zoom } = useCanvasZoom();

  useEffect(() => {
    const elem = document.querySelector('#canvas');

    const observer = new ResizeObserver((entries) => {
      setCanvasWidth(entries[0].contentRect.width || CANVAS_WIDTH);
    });

    if (elem) {
      observer.observe(elem!);
    }

    return () => {
      if (elem) {
        observer.unobserve(elem!);
      }
    };
  }, []);

  return canvasWidth / zoom;
};

export default useCanvasWidth;
