import { Box, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

type VersionLineProps = {
  $isFirstItem?: boolean;
  $isLastItem?: boolean;
};

type VersionDotProps = {
  $isActive?: boolean;
};

export const VersionsStyled = styled('div')<{ $isHidden: boolean }>`
  display: flex;
  flex-direction: column;
  width: 354px;
  height: 100%;
  padding-bottom: 24px;
  background-color: ${themePalette.white};
  border-left: 1px solid ${themePalette.grey[500]};

  ${({ $isHidden }) => {
    let styles = '';

    if ($isHidden) {
      styles += `width: 0px; overflow: hidden; padding: 0;`;
    }

    return styles;
  }}
`;

export const StyledVersionCard = styled('div')`
  position: relative;

  &:hover {
    background-color: ${themePalette.grey[400]};
  }
`;

export const StyledVersionCardContent = styled(Box)`
  align-items: flex-start;
  padding: 12px 24px 12px 42px;
`;

export const StyledMenu = styled('div')`
  display: flex;
  opacity: 0;

  ${StyledVersionCard}:hover & {
    opacity: 1;
  }
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid ${themePalette.grey[700]};

  &:hover {
    border: 1px solid ${themePalette.grey[1000]};

    svg {
      fill: ${themePalette.grey[1000]};
    }
  }
`;

export const StyledVersionDot = styled('div')<VersionDotProps>`
  position: absolute;
  top: 22px;
  left: 24px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${({ $isActive }) =>
    $isActive ? themePalette.primary.main : themePalette.grey[800]};
  z-index: 3;
`;

export const StyledVersionLine = styled('div')<VersionLineProps>`
  position: absolute;
  top: ${(p) => (p.$isFirstItem ? '22px' : 0)};
  left: 27px;
  width: 1px;
  height: ${(p) => (p.$isLastItem ? '22px' : '100%')};
  background-color: ${themePalette.grey[500]};
  z-index: 2;
`;

export const FilterWrapper = styled('div')`
  display: flex;
  cursor: pointer;
`;
export const StyledFilterOption = styled('div')`
  display: flex;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: ${themePalette.grey[400]};
  }
`;

export const CloseIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ShowMoreStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 116px;
  margin: 24px 0 0 24px;
  padding: 8px 14px;
  border: 1px solid ${themePalette.grey[700]};
  border-radius: 12px;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    border-color: ${themePalette.grey[800]};
  }
`;

export const VersionNameStyled = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
