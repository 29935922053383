import { useEffect } from 'react';
import { useProject } from '@context/Project/ProjectProvider';

const useProjectDrawerChanges = (isDirty: boolean, open: boolean) => {
  const { setHasChanges } = useProject();

  useEffect(() => {
    if (!open) {
      return setHasChanges(false);
    }

    setHasChanges(isDirty);
  }, [open, isDirty]);
};

export default useProjectDrawerChanges;
