import { BASE_API_URL } from '@constants/config';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { QueryTags } from '@store/services/tags';
import { loggedOut, setTokens } from '@store/slices/authSlice';
import { clearProjectTabs } from '@store/slices/projectSlice';
import { setLoadings } from '@store/slices/uiSlice';
import { RootState } from '@store/store';
import { Mutex } from 'async-mutex';

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    return headers;
  },
});

const mutex = new Mutex();

const baseQueryWithReauth: BaseQueryFn = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const tokens = (api.getState() as RootState).auth;
        const refreshResult = await baseQuery(
          {
            url: '/user/refresh-token/',
            method: 'POST',
            body: {
              IdToken: tokens.accessToken,
              RefreshToken: tokens.refreshToken,
            },
          },
          api,
          extraOptions,
        );

        if (refreshResult.data) {
          const { idToken: accessToken, RefreshToken: refreshToken } =
            refreshResult.data as any;

          api.dispatch(setTokens({ accessToken, refreshToken }));

          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(loggedOut());
          api.dispatch(setLoadings([]));
          api.dispatch(clearProjectTabs());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const authorizedApi = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: 'authApi',
  tagTypes: Object.values(QueryTags).map(
    (value) => QueryTags[value as keyof typeof QueryTags],
  ),

  endpoints: () => ({}),
});

export default authorizedApi;
