import styled from 'styled-components';

type ContextMenuProps = {
  $top: number | false;
  $left: number | false;
  $position?: string | false;
};

export const ContextMenuStyled = styled('div')<ContextMenuProps>`
  position: ${({ $position = 'absolute' }) => $position};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  z-index: 20;
  ${({ $top, $left }) => {
    let styles = '';
    if ($top !== false) {
      styles += `top: ${$top}px;`;
    }
    if ($left !== false) {
      styles += `left: ${$left}px;`;
    }
    return styles;
  }}
`;

export const Content = styled(`div`)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4px 0;
  border-radius: 4px;
  border: none;
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

export const IconWrapper = styled('div')`
  width: 20px;
  height: 20px;
`;
