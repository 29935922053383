import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledRadioGroup, {
  ControlledRadioGroupProps,
} from '@components/Inputs/controllers/ControlledRadioGroup';
import { EntityValidationStatus } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';

export const DefaultStatusOptions = [
  { value: EntityValidationStatus.Draft, label: 'Draft' },
  { value: EntityValidationStatus.Validated, label: 'Validated' },
];

const ValidationStatus: FC<Partial<ControlledRadioGroupProps>> = ({
  name = CommonKeys.ValidationStatus,
  options = DefaultStatusOptions,
  required = true,
  disabledAll,
  ...props
}) => {
  const { viewOnly } = useProject();

  return (
    <ControlledRadioGroup
      name={name}
      options={options}
      label="Validation status"
      required={required}
      disabledAll={disabledAll || viewOnly}
      {...props}
    />
  );
};

export default ValidationStatus;
