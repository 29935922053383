import { MouseEvent, useCallback } from 'react';
import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { NodesTypes } from '@constants/canvas/general';
import { subLayerParent, SubLayerTypes } from '@constants/canvas/layers';
import { EditNodeDrawerMap } from '@constants/entities/drawers';
import { useProject } from '@context/Project';
import useResetViewport from '@hooks/canvas/useResetViewport';
import { filterConnectedNodes } from '@views/Insights/Stage/helpers';
import { Tabs } from '@views/Insights/types';

const useNodeClick = (onPaneClick: () => void) => {
  const {
    setDrawer,
    handleCloseDrawer,
    insightsTab: type,
    isVersionsShown,
  } = useProject();
  const resetViewPort = useResetViewport();

  const {
    unselectActiveEdge,
    selectNode,
    setActiveNode,
    setNodes,
    setEdges,
    nodes,
    edges,
  } = useStore();

  return useCallback(
    (_: MouseEvent, newNode: Node) => {
      if (
        newNode?.type !== NodesTypes.CustomNode ||
        (type === Tabs.Default && !isVersionsShown)
      ) {
        onPaneClick();
        return;
      }

      handleCloseDrawer();

      if (type === Tabs.FocusOn) {
        const { connectedNodes, connectedEdges } = filterConnectedNodes(
          nodes,
          edges,
          newNode.id,
        );
        setNodes(connectedNodes);
        setEdges(connectedEdges);

        unselectActiveEdge();
        selectNode(newNode.id);

        setDrawer({
          type: EditNodeDrawerMap[
            subLayerParent[newNode?.parentNode as SubLayerTypes]
          ],
        });

        resetViewPort();
      }

      if (
        type === Tabs.Dependency ||
        type === Tabs.ModelEvolution ||
        type === Tabs.KeyControls ||
        isVersionsShown
      ) {
        setActiveNode(newNode.id);

        setDrawer({
          type: EditNodeDrawerMap[
            subLayerParent[newNode?.parentNode as SubLayerTypes]
          ],
        });

        resetViewPort();
      }

      if (type === Tabs.Maturity) {
        unselectActiveEdge();
        setActiveNode(newNode.id);
      }

      if (type === Tabs.Completeness) {
        unselectActiveEdge();
        setActiveNode(newNode.id);

        setDrawer({
          type: EditNodeDrawerMap[
            subLayerParent[newNode?.parentNode as SubLayerTypes]
          ],
        });

        resetViewPort();
      }
    },
    [nodes, edges, type, isVersionsShown, handleCloseDrawer],
  );
};

export default useNodeClick;
