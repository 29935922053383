import { OmitId } from '@constants/types';
import {
  ConceptualMeasurement,
  ConceptualSource,
  Name,
  NameLink,
  NameObjective,
} from '@store/services/nodes/types';

export const NameLinkObject: Omit<NameLink, 'id'> = {
  name: '',
  link: '',
};

export const NameObject: Omit<Name, 'id'> = {
  name: '',
};

export const NameObjectiveObject: Omit<NameObjective, 'id'> = {
  name: '',
  objective: '',
};

export const JustificationStatementObject = {
  name: '',
};

export type ConceptualSourceFormValues = Omit<
  ConceptualSource,
  'id' | 'justification_statement'
> & { justification_statement: (typeof JustificationStatementObject)[] };

export const ConceptualSourceObject: ConceptualSourceFormValues = {
  type: '',
  name: '',
  justification_statement: [JustificationStatementObject],
  link: '',
};

export const ConceptualMeasurementObject: OmitId<ConceptualMeasurement> = {
  approach: '',
  metric_type: '',
  metric_output: '',
  target: '',
  primary_threshold: '',
  secondary_threshold: '',
};
