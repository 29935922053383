import React, { FC, memo, useState } from 'react';
import { IActionItem } from '@components/ActionsMenu/types';
import { ContextMenu } from '@components/MainStage/ContextMenu/ContextMenu';
import { DeleteNodeModal } from '@components/MainStage/ContextMenu/NodeContextMenu/DeleteNodeModal';
import useDeleteKey from '@components/MainStage/hooks/useDeleteKey';
import useIsNodeAddedToAsset from '@components/MainStage/hooks/useIsNodeAddedToAsset';
import useStore from '@components/MainStage/store';
import { ContextMenuProps } from '@components/MainStage/types';
import useCanEditModel from '@hooks/useCanEditModel';
import useToggle from '@hooks/useToggle';
import { NodeDTOTypes } from '@store/services/nodes/types';
import { themePalette } from '@theme/muiTheme';
import { CloseIcon, ReportIcon, TrashIcon } from '@utils/iconsDefs';
import { JustificationReportModal } from '@views/ReportsModel/components/JustificationReport/JustificationReportModal';

const NodeContextMenu: FC<ContextMenuProps> = ({ isOpen, onClose, menu }) => {
  const { getNodeById } = useStore();
  const [isDeleteOpen, deleteControls] = useToggle<null | string>();
  const [isJustificationReportOpen, justificationReportControls] = useToggle<
    null | boolean
  >();
  const [deleteId, setDeleteId] = useState<null | string>(null);
  const canDelete = useCanEditModel();

  const nodeId = deleteId || menu?.id || '';
  const isNodeAdded = useIsNodeAddedToAsset(nodeId);
  const node = getNodeById(nodeId);
  const isNodeWithJustificationReport =
    node?.data?.dto?.type === NodeDTOTypes.BusinessAttribute ||
    node?.data?.dto?.type === NodeDTOTypes.SecurityAttribute;

  const handleOpenDeleteModal = () => {
    deleteControls.on();
    onClose();
    setDeleteId(null);
  };

  const handleOpenJustificationReportModal = () => {
    justificationReportControls.on();
    onClose();
  };

  const contextMenuItems: IActionItem[] = [
    ...(isNodeWithJustificationReport
      ? [
          {
            icon: <ReportIcon fill="currentColor" />,
            text: 'Reports',
            subItems: [
              {
                text: 'Justification statement',
                onClick: handleOpenJustificationReportModal,
              },
            ],
          },
        ]
      : []),

    ...(canDelete
      ? [
          {
            icon: <TrashIcon />,
            text: 'Delete',
            divider: isNodeWithJustificationReport,
            disableHoverColor: true,
            color: themePalette.system.error,
            onClick: handleOpenDeleteModal,
          },
        ]
      : []),
  ];

  const removeMenuItems = [
    {
      icon: <CloseIcon fill={themePalette.grey[1000]} />,
      text: 'Remove entity',
      onClick: handleOpenDeleteModal,
      textColor: 'grey.1000',
    },
  ];

  useDeleteKey('node', (id) => {
    if (!canDelete) return;

    setDeleteId(id);
    deleteControls.on();
  });

  if (contextMenuItems.length === 0) return null;

  const menuItems = isNodeAdded ? removeMenuItems : contextMenuItems;

  return (
    <>
      <DeleteNodeModal
        open={isDeleteOpen}
        onClose={deleteControls.off}
        nodeId={nodeId}
      />

      {isNodeWithJustificationReport && (
        <JustificationReportModal
          open={isJustificationReportOpen}
          onClose={justificationReportControls.off}
          node={node}
        />
      )}

      {isOpen && menu && menu.type === 'node' && (
        <ContextMenu
          menuItems={menuItems}
          {...menu}
          top={menu.top - menuItems.length * 40}
        />
      )}
    </>
  );
};

export default memo(NodeContextMenu);
