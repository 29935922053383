import React, { FC, useMemo } from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import useStore from '@components/MainStage/store';
import useNode from '@hooks/useNode';
import { Typography } from '@mui/material';

type Props = Pick<ConfirmDialogProps, 'open' | 'onClose' | 'onConfirm'> & {
  nodeId: string;
};

const RemoveNodeModal: FC<Props> = ({ nodeId, ...props }) => {
  const { edges } = useStore();
  const {
    data: { dto },
  } = useNode(nodeId);

  const description = useMemo(() => {
    const hasChildrenEdges = edges.some((edge) => edge.source === nodeId);

    if (hasChildrenEdges) {
      return 'The entity and related entities will be removed from the asset risk assessment model but will remain in the Controls mode.';
    }

    return 'The entity will be removed from the asset risk assessment model but will remain in \nthe Controls mode.';
  }, [edges, nodeId]);

  return (
    <ConfirmDialog
      {...props}
      title="Remove entity"
      text={
        <Typography variant="body1" color="grey.900">
          Are you sure that you want to remove the entity{' '}
          <Typography variant="body1" color="grey.1000" component="span">
            {dto?.name}
          </Typography>
          ?
          <br />
          {description}
        </Typography>
      }
    />
  );
};

export default RemoveNodeModal;
