import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@components/Inputs/DatePicker';
import { DatePickerProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

type Props = Pick<
  DatePickerProps<Dayjs>,
  'minDate' | 'maxDate' | 'readOnly' | 'slotProps' | 'timezone'
> & {
  required?: boolean;
  label?: string;
  name: string;
  triggerKeys?: string[];
  validateOnBlur?: boolean;
  clearable?: boolean;
};

const ControlledDatePicker: FC<Props> = ({
  name,
  slotProps,
  triggerKeys,
  validateOnBlur = false,
  clearable = false,
  ...props
}) => {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onBlur, ref, onChange, ...field },
        fieldState: { error },
      }) => (
        <DatePicker
          onClose={validateOnBlur ? onBlur : undefined}
          onChange={(e) => {
            onChange(e);
            if (triggerKeys) trigger(triggerKeys);
          }}
          clearable={clearable}
          slotProps={{
            ...slotProps,
            textField: {
              error: !!error,
              helperText: error?.message,
              placeholder: 'Choose date',
              ...slotProps?.textField,
            },
          }}
          {...field}
          {...props}
        />
      )}
    />
  );
};

export default ControlledDatePicker;
