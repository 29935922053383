import React, { useMemo } from 'react';
import { AssetKeys } from '@components/EntityDrawers/drawers/Assessment/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectSettings from '@hooks/useProjectSettings';

const AssetCriticalityLevelDropdown = () => {
  const { viewOnly } = useProject();

  const {
    settings: { asset_criticality_level },
  } = useProjectSettings();

  const options: ISelectOption[] = useMemo(() => {
    return (asset_criticality_level?.map((name) => ({
      label: name,
      value: name,
    })) ?? []) as ISelectOption[];
  }, [asset_criticality_level]);

  return (
    <ControlledTextFieldSelect
      name={AssetKeys.Criticality}
      label="Asset criticality rating"
      placeholder="Asset criticality rating"
      disabled={viewOnly}
      options={options}
      resetOnDoubleClick
      required
    />
  );
};

export default AssetCriticalityLevelDropdown;
