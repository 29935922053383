import { BASE_API_URL } from '@constants/config';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({ baseUrl: BASE_API_URL });

const baseApi = createApi({
  baseQuery,
  reducerPath: 'baseApi',
  endpoints: () => ({}),
});

export default baseApi;
