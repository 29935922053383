import { onLoading } from '@store/helpers';
import { IGetUsersResponse } from '@store/services/admin/types';
import authorizedApi from '@store/services/authorizedApi';
import {
  IAddUsersToProjectRequest,
  ICompletenessInsight,
  ICompletenessInsightResponse,
  ICreateProjectRequest,
  IDomain,
  IGetProjectsParams,
  IGetProjectUsersParams,
  IInsightParams,
  IKeyControlsInsight,
  IProject,
  IProjectGlossary,
  IProjectRestoreRequest,
  IProjectUpdateRequest,
} from '@store/services/projects/types';
import { QueryTags } from '@store/services/tags';
import {
  createParamsString,
  hasDuplicates,
  transformValueToDomainId,
} from '@utils/helpers';
import querystring from 'query-string';

export const projectsApi = authorizedApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<IProject[], IGetProjectsParams | void>({
      query: ({
        name,
        industries,
        locations,
        statuses,
        assignee,
        ...params
      } = {}) => ({
        url: `/projects/?${createParamsString({
          ...params,
          name: name ?? undefined,
          status: statuses?.length ? statuses : undefined,
          industry_id: industries?.length ? industries : undefined,
          location: locations?.length ? locations : undefined,
          own: assignee?.length ? true : undefined,
        })}`,
      }),

      providesTags: [QueryTags.Projects],
      onQueryStarted: onLoading,
    }),

    fetchProjects: builder.mutation<IProject[], IGetProjectsParams | void>({
      query: ({
        name,
        industries,
        locations,
        statuses,
        assignee,
        ...params
      } = {}) => ({
        method: 'GET',
        url: `/projects/?${createParamsString({
          ...params,
          name: name ?? undefined,
          status: statuses?.length ? statuses : undefined,
          industry_id: industries?.length ? industries : undefined,
          location: locations?.length ? locations : undefined,
          own: assignee?.length ? true : undefined,
        })}`,
      }),
    }),

    getOneProject: builder.query<IProject, string>({
      query: (id: string) => ({
        url: `/projects/${id}/`,
      }),

      providesTags: [QueryTags.Project],
      onQueryStarted: onLoading,
    }),

    getProjectDomains: builder.query<IDomain[], string>({
      query: (id: string) => ({
        url: `/projects/${id}/domains/`,
      }),

      providesTags: [QueryTags.ProjectDomains],
      onQueryStarted: onLoading,
    }),

    getProjectGlossary: builder.query<IProjectGlossary, void>({
      query: () => `/projects/glossary`,
      onQueryStarted: onLoading,
      providesTags: [QueryTags.ProjectsGlossary],
    }),

    getProjectStats: builder.query<any, string>({
      query: (id: string) => ({
        url: `/stats/${id}/dashboard`,
      }),

      providesTags: [QueryTags.ProjectStats],
      onQueryStarted: onLoading,
    }),

    checkProjectNameUnique: builder.mutation<
      { hasSame: boolean },
      { name: string; currentId?: string }
    >({
      query: ({ name }) => ({
        url: `/projects/`,
        method: 'GET',
        params: { name: name.trim() },
      }),

      transformResponse: (response: IProject[], _, params) => {
        return {
          hasSame: hasDuplicates({
            array: response,
            key: 'name',
            value: params?.name,
            currentId: params?.currentId,
          }),
        };
      },
    }),

    createProject: builder.mutation<IProject, ICreateProjectRequest>({
      query: (body) => ({
        url: '/projects/',
        method: 'POST',
        body,
      }),

      onQueryStarted: onLoading,
    }),

    getCompletenessInsight: builder.query<ICompletenessInsight, IInsightParams>(
      {
        query: ({ projectId, domainIds, validStatus, reviewRequired }) => ({
          url: `/projects/${projectId}/insight?${createParamsString({
            type: 'completeness',
            domain_ids: domainIds,
            valid_status: validStatus,
            review_required: reviewRequired,
          })}`,
        }),
        providesTags: [QueryTags.CompletenessInsight],
        keepUnusedDataFor: 0,
        onQueryStarted: onLoading,

        transformResponse: (response: ICompletenessInsightResponse) => {
          const designPercentage = response.designCompleteness.total
            ? parseFloat(
                (
                  (response.designCompleteness.mapped /
                    response.designCompleteness.total) *
                  100
                ).toFixed(2),
              )
            : 0;

          const operationalPercentage = response.designCompleteness.total
            ? parseFloat(
                (
                  (Math.abs(
                    response.designCompleteness.mapped -
                      response.operationalCompleteness.totalOperational,
                  ) /
                    response.designCompleteness.total) *
                  100
                ).toFixed(2),
              )
            : 0;

          return {
            designCompleteness: {
              items: response.designCompleteness.items,
              percentage: designPercentage,
            },
            operationalCompleteness: {
              items: response.operationalCompleteness.items,
              percentage: operationalPercentage,
            },
          };
        },
      },
    ),

    getKeyControlsInsight: builder.query<IKeyControlsInsight, IInsightParams>({
      query: ({ projectId, domainIds, validStatus, reviewRequired }) => ({
        url: `/projects/${projectId}/insight?${querystring.stringify(
          {
            type: 'key-controls',
            domain_ids: domainIds?.map((id) =>
              transformValueToDomainId(id, true),
            ),
            valid_status: validStatus,
            review_required: reviewRequired,
          },
          { arrayFormat: 'bracket' },
        )}`,
      }),
      onQueryStarted: onLoading,
      providesTags: [QueryTags.KeyControlsInsight],
    }),

    updateProject: builder.mutation<IProject, IProjectUpdateRequest>({
      query: ({ id, ...body }) => ({
        url: `/projects/${id}/`,
        method: 'PATCH',
        body,
      }),

      onQueryStarted: onLoading,
      invalidatesTags: [
        QueryTags.Projects,
        QueryTags.Project,
        QueryTags.User,
        QueryTags.ProjectDomains,
      ],
    }),

    deleteProject: builder.mutation<void, string>({
      query: (id: string) => ({
        url: `/projects/${id}/`,
        method: 'DELETE',
      }),

      onQueryStarted: onLoading,
      invalidatesTags: [QueryTags.Projects, QueryTags.Project, QueryTags.User],
    }),

    archiveProject: builder.mutation<void, IProjectRestoreRequest>({
      query: ({ id, action, tierId }) => ({
        url: `/projects/${id}/archive?${createParamsString({
          action,
          tier_id: tierId,
        })}`,
        method: 'POST',
      }),

      onQueryStarted: onLoading,
      invalidatesTags: (_, err) =>
        err
          ? []
          : [
              QueryTags.Projects,
              QueryTags.Project,
              QueryTags.ProjectUsers,
              QueryTags.ProjectStats,
              QueryTags.Organization,
            ],
    }),

    getProjectUsers: builder.query<IGetUsersResponse, IGetProjectUsersParams>({
      query: ({ roles, statuses, ...params }: IGetProjectUsersParams) => ({
        url: `/projects/users/?${createParamsString({ roles, statuses })}`,
        params,
      }),
      providesTags: [QueryTags.ProjectUsers],
      onQueryStarted: onLoading,
    }),

    addUsersToProject: builder.mutation<void, IAddUsersToProjectRequest>({
      query: (body) => ({
        url: `/projects/users/enterprise/`,
        method: 'POST',
        body,
      }),

      onQueryStarted: onLoading,
      invalidatesTags: (_, error) =>
        error ? [] : [QueryTags.ProjectUsers, QueryTags.Project],
    }),

    assignUsersToProject: builder.mutation<
      void,
      { projectId: string; userId: string[] }
    >({
      query: ({ projectId, userId }) => ({
        url: 'projects/assign-user',
        method: 'POST',
        body: { user_id: userId, project_id: projectId },
      }),

      onQueryStarted: onLoading,
      invalidatesTags: (_, error) =>
        error
          ? []
          : [QueryTags.ProjectUsers, QueryTags.Project, QueryTags.Users],
    }),

    unassignUsersFromProject: builder.mutation<
      void,
      { projectId: string; userId: string[] }
    >({
      query: ({ projectId, userId }) => ({
        url: 'projects/unassign-user',
        method: 'POST',
        body: { user_id: userId, project_id: projectId },
      }),

      onQueryStarted: onLoading,
      invalidatesTags: (_, error) =>
        error
          ? []
          : [
              QueryTags.User,
              QueryTags.Users,
              QueryTags.ProjectUsers,
              QueryTags.Project,
            ],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useFetchProjectsMutation,
  useGetOneProjectQuery,
  useGetProjectDomainsQuery,
  useGetProjectStatsQuery,
  useGetProjectGlossaryQuery,
  useGetCompletenessInsightQuery,
  useGetKeyControlsInsightQuery,
  useDeleteProjectMutation,
  useArchiveProjectMutation,
  useUpdateProjectMutation,
  useGetProjectUsersQuery,
  useCreateProjectMutation,
  useCheckProjectNameUniqueMutation,
  useAssignUsersToProjectMutation,
  useUnassignUsersFromProjectMutation,
  useAddUsersToProjectMutation,
} = projectsApi;
