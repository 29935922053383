import { InitiativeContent } from '@components/EntityDrawers/drawers/Initiative/content';
import { InitiativeFormValues } from '@components/EntityDrawers/drawers/Initiative/form';
import { InitiativeSettings } from '@components/EntityDrawers/drawers/Initiative/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import withTabs from '@components/EntityDrawers/hocs/withTabs';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { InitiativeMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddInitiativesEntity
  | ProjectDrawerTypes.EditInitiativesEntity;

export const InitiativeDrawer = withTabs<Type>(
  withDrawer<InitiativeFormValues, InitiativeMetaData, Type>(
    InitiativeContent,
    InitiativeSettings,
  ),
);
