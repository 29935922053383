import {
  EntityValidationStatus,
  InitiativeDeliveryStatus,
  InitiativeFundingMechanism,
  InitiativeProgressStatus,
  InitiativeTypes,
  Modes,
  OperationalStatus,
  StewardsOpinion,
} from '@constants/canvas/layers';
import { Currency } from '@constants/entities/currency';
import {
  DependencyTypes,
  RelationshipTypes,
  WeightTypes,
} from '@constants/entities/relationship';
import { OmitId } from '@constants/types';

export type ConditionalIdOmit<O, Type> = O extends true
  ? Omit<Type, 'id'>
  : Type;

export type ContextualSource = {
  id: string;
  name: string;
  link: string;
};

export interface ContextualMetaData<IsInitial = false> {
  source: ConditionalIdOmit<IsInitial, ContextualSource>[];
}

export type ConceptualSource = {
  id: string;
  name: string;
  link: string;
  type: string;
  justification_statement: string[];
};

export type ConceptualMeasurement = {
  id: string;
  approach: string;
  metric_type: string;
  metric_output: string;
  target: string;
  primary_threshold: string;
  secondary_threshold: string;
};

export interface ConceptualMetaData<IsInitial = false> {
  source: ConditionalIdOmit<IsInitial, ConceptualSource>[];
  definition: string;
  measurement: ConditionalIdOmit<IsInitial, ConceptualMeasurement>[];
}

export type NameObjective = {
  id: string;
  name: string | null;
  objective: string | null;
};

export type NameLink = {
  id: string;
  name: string | null;
  link: string | null;
};

export type Name = {
  id: string;
  name: string | null;
};

export interface LogicalMetaData<IsInitial = false> {
  operational_status: OperationalStatus;
  start_date: string;
  end_date: string;
  metric: ConditionalIdOmit<IsInitial, NameObjective>[];
  current_maturity: string;
  target_maturity: string;
  policy: ConditionalIdOmit<IsInitial, NameLink>[];
  operational_model: string;
  operational_effectiveness: string;
  design_effectiveness: string;
}

export interface PhysicalMetaData {
  operational_status: OperationalStatus;
  start_date: string;
  end_date: string;

  metric: OmitId<NameObjective>[];
  rules: OmitId<NameLink>[];

  current_maturity: string;
  target_maturity: string;

  operational_model: string;
  operational_effectiveness: string;
  design_effectiveness: string;
}

export interface ComponentMetaData {
  operational_status: OperationalStatus;
  start_date: string;
  end_date: string;

  audit_finding: {
    description: string;
    link: string;
  }[];

  operational_model: string;
  operational_effectiveness: string;
  design_effectiveness: string;
}

export interface InitiativeMetaData {
  type: InitiativeTypes;
  business_sponsor: string;
  program_manager: string;
  progress_status: InitiativeProgressStatus;
  delivery_status: InitiativeDeliveryStatus;
  funding_mechanism: InitiativeFundingMechanism;
  allocated_budget: number;
  start_date: string;
  end_date: string;
  currency: Currency;
  progress_notes: string[];
  document: OmitId<NameLink>[];
}

export interface AssessmentMetaData {
  identifier: string;
  criticality: string;
}

export interface RiskMetaData {
  taxonomy: string;
  assessor: string;
  steward: string;
  steward_opinion: StewardsOpinion;
  steward_justification_note: string;

  date_identified: string;
  date_reviewed: string;

  inherent_likelihood: number;
  inherent_impact: number;
  inherent_justification: string;

  residual_likelihood: number;
  residual_impact: number;
  residual_justification: string;
  risk_action: string;
}

export interface CharacteristicMetaData {
  element_type: string;
  element_detail: string[];
  element_description: string;
}

export enum NodeDTOTypes {
  StrategicPriority = 'strategic-priority',
  StrategicEnabler = 'strategic-enabler',
  BusinessAttribute = 'business-attribute',
  SecurityAttribute = 'security-attribute',
  Service = 'service',
  Capabilities = 'capability',
  Functions = 'function',
  Technologies = 'technology',
  Initiatives = 'change-initiative',
  Asset = 'asset',
  Characteristic = 'characteristic',
  EvaluatedRisk = 'evaluated-risk',
}

export enum ProjectModesDTOTypes {
  BusinessAlignment = 'business',
  Controls = 'control',
  RiskManagement = 'risk_management',
}

export interface NodeDTO<T = object> {
  id: string;
  project_id: string;
  valid_status: EntityValidationStatus;
  name: string;
  type: NodeDTOTypes;
  x: number;
  y: number;
  qnous_ref: string[] | null;
  nist: string[] | null;
  domain_id: string | null;
  reviewed_at: string | null;
  requirement_owner: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  meta_data: T;
  parentsIds: string[];
  childrenIds: string[];
  review_required: boolean;
  is_complete: boolean;
  description: string | null;
  businessAttributes: { id: string }[];
  parent_asset?: string | null;
}

export interface EdgeDTO {
  id: string;
  parent_id: string;
  child_id: string;
  type: RelationshipTypes;
  weight: number;
  weight_type: WeightTypes;
  description: string;
  dependency: DependencyTypes;
  valid_status: string;
  created_at: string;
  updated_at: string;
  reviewed_at: string;
}

export interface INodes {
  nodes: NodeDTO[];
  relations: EdgeDTO[];
}

export interface ICanvasNodesResponse {
  nodes: NodeDTO<any>[];
  relations: EdgeDTO[];
}

export interface ICanvasNodesRequest {
  projectId: string;
  mode: Modes;
}

export interface IAssetNodesRequest {
  projectId: string;
  assetNodeId: string;
  versionId?: string | null;
}

export interface IAssetCompatibleNodesResponse {
  nodes: Record<string, { id: string; name: string }[]>;
}

export interface IAssetCompatibleNodesRequest {
  nodeId: string;
  type: string;
}

export interface ICreateNodeRequest
  extends Pick<
      NodeDTO,
      'name' | 'type' | 'requirement_owner' | 'meta_data' | 'x' | 'y'
    >,
    Partial<
      Pick<
        NodeDTO,
        | 'qnous_ref'
        | 'nist'
        | 'businessAttributes'
        | 'parent_asset'
        | 'valid_status'
        | 'reviewed_at'
        | 'domain_id'
        | 'description'
      >
    > {
  projectId: string;
  mode: Modes;
  assetId?: string | null;
}

export interface IUpdateDomainOptions {
  force?: boolean;
  allocateWeightRelationsId?: string;
}

export interface IAdditionalUpdateParams {
  updateDomainOptions?: IUpdateDomainOptions;
  keep_remediation_action?: boolean;
}

export interface IUpdateNodeRequest
  extends Omit<ICreateNodeRequest, 'type' | 'projectId'>,
    IAdditionalUpdateParams {
  nodeId: string;
}

export interface ICreateRelationshipRequest
  extends Pick<
    EdgeDTO,
    | 'type'
    | 'dependency'
    | 'description'
    | 'weight'
    | 'weight_type'
    | 'parent_id'
    | 'child_id'
  > {
  mode: Modes;
  assetId: string;
}

export interface IUpdateRelationshipRequest
  extends Pick<
    EdgeDTO,
    'id' | 'type' | 'dependency' | 'description' | 'weight' | 'weight_type'
  > {
  mode: Modes;
  assetId: string;
}

interface INodesPosition {
  id: string;
  x: number;
  y: number;
}

export interface IUpdateNodePositionRequest {
  projectId: string;
  assetId?: string | null;
  nodes: INodesPosition[];
}

export interface IAssignNodesToAssetRequest {
  action?: string;
  assetId: string;
  nodes: INodesPosition[];
}

export interface IDeleteNodeResponse extends ICanvasNodesResponse {}
export interface IDeleteNodeRequest {
  id: string;
  allocateWeightRelationsId?: string[];
  mode: Modes;
  assetId?: string;
}

export interface IAvailableRelationship {
  id: string;
  name: string;
}

export type IDeleteRelationshipResponse =
  | ICanvasNodesResponse
  | {
      blockers: IAvailableRelationship[][];
    };

export interface IDeleteRelationshipRequest {
  id: string;
  mode: Modes;
  assetId?: string;
  allocateWeightRelationsId?: string[];
}

export interface IRelationsError {
  status: number;
  data: {
    code: string;
    blockers: IAvailableRelationship[][];
  };
}

export enum NodeReferencesActions {
  Add = 'add',
  Rem = 'rem',
}

export interface IChangeNodeReferencesRequest {
  qnous_ref?: string[];
  nist: string[];
  action: NodeReferencesActions;
  type: 'qnous_ref' | 'nist';
  value: string;
}

export interface IChangeNodeReferencesResponse {
  qnous_ref: string[];
  nist: string[];
}

export type BusinessAttributesResponse = { id: string; name: string }[];
export interface IBusinessAttributesRequest {
  projectId: string;
  domainId: string;
}

export interface ImportAssetsFileRequest {
  file: File;
  projectId: string;
}
export interface ImportAssetsFileResponse {
  result: {
    created: number;
    updated: number;
  };
}

export interface ICheckDomainChangeImpactModelRequest {
  nodeId: string;
  domainId?: string | null;
}

export interface ICheckDomainChangeImpactModelResponse {
  success: boolean;
}

export interface IGetAssetsListRequest {
  projectId: string;
  versionId?: string | undefined;
}
