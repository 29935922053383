import { lazy } from 'react';
import { COOKIES_ACCEPTED } from '@constants/localStorage';
import useInitResentProjects from '@hooks/useInitResentProjects';
import useUser from '@hooks/useUser';
import { Router } from '@router/Router';
import { useGetProjectGlossaryQuery } from '@store/services/projects';
import { AppStyled, ContentWrapper } from '@views/App/App.styled';

const CookiesBanner = lazy(() =>
  import('@components/CookiesBanner').then((module) => ({
    default: module.CookiesBanner,
  })),
);

const LoadingView = lazy(() =>
  import('@components/LoadingView').then((module) => ({
    default: module.default,
  })),
);

export function App() {
  useUser();
  useGetProjectGlossaryQuery();
  useInitResentProjects();

  return (
    <AppStyled>
      <LoadingView />

      <ContentWrapper data-testid="content-wrapper">
        {localStorage.getItem(COOKIES_ACCEPTED) !== 'true' && <CookiesBanner />}

        <Router />
      </ContentWrapper>
    </AppStyled>
  );
}
