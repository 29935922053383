import React from 'react';
import { IActionItem } from '@components/ActionsMenu/types';
import { LayerTypes, Modes } from '@constants/canvas/layers';
import {
  BusinessAlignmentTabs,
  ControlsTabs,
  RiskManagmentTabs,
} from '@constants/entities/project-dashboard';
import { ImageIcon } from '@utils/iconsDefs';

export const ExportDashboardViewId = 'export-dashboard-report-view';
export const ReactFlowId = 'react-flow-container';
export const DefaultExportImageType = 'image/png';
export const DefaultExportImageFileName = 'report.png';
export const GenerateImageError =
  "We couldn't generate the image you requested due to a technical issue. Please try again later or contact support for assistance.";
export const GenerateReportError =
  'We encountered an issue while generating your report. Please try again later or contact support for assistance.';

export const CommonReportDescription =
  'We have generated a report for you. It may take some time to complete.';

export const ExportLayersSettingsDescription =
  'Please select the mode layers for which you would like to include information in your report. Note that selected filters will not be applied.';
export const ExportAssetsSettingsDescription =
  'Please choose the assets whose information you would like to include in your report. Note that selected filters will not be applied.';

export const ExportJustificationStatementSuccess =
  'Justification register report downloaded successfully';

export const MAX_BARS_COUNT_IN_REPORT_VIEW = 21;
export const MAX_STATEMENTS_IN_VIEW = 10;

export const MaxItemsPerScreen = {
  OnFirstFullScreen: 4,
  OnFirstChartScreen: 7,
  Default: 7,
};

export const FullReportFileNames = {
  [Modes.BusinessAlignment]: {
    [BusinessAlignmentTabs.BusinessAttributes]: 'Business attribute dashboard',
    [BusinessAlignmentTabs.SecurityAttributes]: 'Security attribute dashboard',
    [BusinessAlignmentTabs.ChangeInitiatives]: 'Change initiatives dashboard',
  },
  [Modes.Controls]: {
    [ControlsTabs.Services]: 'Services dashboard',
    [ControlsTabs.Capabilities]: 'Capabilities dashboard',
    [ControlsTabs.Functions]: 'Functions dashboard',
    [ControlsTabs.ChangeInitiatives]: 'Change initiatives dashboard',
  },
  [Modes.RiskManagement]: {
    [RiskManagmentTabs.Assets]: 'Assets dashboard',
    [RiskManagmentTabs.Risks]: 'Risks dashboard',
    [RiskManagmentTabs.RemediationActions]: 'Remediation actions dashboard',
  },
};

export const MaturityEntities = ['services', 'capabilities', 'functions'];

export const ImageExport = (onClick: () => void): IActionItem => ({
  text: 'Image (.png)',
  icon: <ImageIcon width={20} height={20} fill="currentColor" />,
  onClick,
});

export enum ExportLayerKeys {
  Contextual = 'contextual',
  Conceptual = 'conceptual',
  Logical = 'logical',
  Physical = 'physical',
  Component = 'component',
  Initiative = 'initiative',
}

export const LayerToExportMap = {
  [LayerTypes.Contextual]: ExportLayerKeys.Contextual,
  [LayerTypes.Conceptual]: ExportLayerKeys.Conceptual,
  [LayerTypes.Logical]: ExportLayerKeys.Logical,
  [LayerTypes.Physical]: ExportLayerKeys.Physical,
  [LayerTypes.Component]: ExportLayerKeys.Component,
  [LayerTypes.Initiatives]: ExportLayerKeys.Initiative,
} as const;

export const ReadableExportLayers = {
  [ExportLayerKeys.Contextual]: 'Contextual',
  [ExportLayerKeys.Conceptual]: 'Conceptual',
  [ExportLayerKeys.Logical]: 'Logical',
  [ExportLayerKeys.Physical]: 'Physical',
  [ExportLayerKeys.Component]: 'Component',
  [ExportLayerKeys.Initiative]: 'Initiatives',
};
