import { Node } from 'reactflow';
import {
  baseLayersProperties,
  LAYER_HEIGHT,
  NodesTypes,
} from '@constants/canvas/general';
import {
  ConceptualSubLayerTypes,
  ContextualSubLayerTypes,
  LayerTypes,
  LogicalSubLayerTypes,
  ReadableLayerTitles,
  ReadableSubLayerTitles,
} from '@constants/canvas/layers';
import { NodeDTOTypes } from '@store/services/nodes/types';
import SublayerView from '@utils/canvas/SublayerView';

export const BA_LAYERS = [
  NodeDTOTypes.StrategicPriority,
  NodeDTOTypes.StrategicEnabler,
  NodeDTOTypes.BusinessAttribute,
  NodeDTOTypes.SecurityAttribute,
  NodeDTOTypes.Service,
];

export const BA_PARENT_LAYER: any = {
  [NodeDTOTypes.StrategicPriority]: '',
  [NodeDTOTypes.StrategicEnabler]: NodeDTOTypes.StrategicPriority,
  [NodeDTOTypes.BusinessAttribute]: NodeDTOTypes.StrategicEnabler,
  [NodeDTOTypes.SecurityAttribute]: NodeDTOTypes.BusinessAttribute,
  [NodeDTOTypes.Service]: NodeDTOTypes.SecurityAttribute,
};

const businessAlignmentLayerNodes: Node[] = [
  {
    id: LayerTypes.Contextual,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.Contextual] },
    position: { x: 0, y: 0 },
    ...baseLayersProperties,
  },
  {
    id: LayerTypes.Conceptual,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.Conceptual] },
    position: { x: 0, y: LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: LayerTypes.Logical,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.Logical] },
    position: { x: 0, y: 2 * LAYER_HEIGHT + 4 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
];

export const businessAlignmentSubLayerNodes: Node[] = [
  {
    id: ContextualSubLayerTypes.StrategicPriorities,
    type: NodesTypes.SubLayerNode,
    data: {
      label:
        ReadableSubLayerTitles[ContextualSubLayerTypes.StrategicPriorities],
      withBottomBorder: true,
    },
    position: { x: 0, y: LAYER_HEIGHT },
    ...baseLayersProperties,
  },
  {
    id: ContextualSubLayerTypes.StrategicEnablers,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[ContextualSubLayerTypes.StrategicEnablers],
    },
    position: { x: 0, y: LAYER_HEIGHT + SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: ConceptualSubLayerTypes.BusinessAttributes,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[ConceptualSubLayerTypes.BusinessAttributes],
      withBottomBorder: true,
    },
    position: { x: 0, y: 2 * LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: ConceptualSubLayerTypes.SecurityAttributes,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[ConceptualSubLayerTypes.SecurityAttributes],
    },
    position: { x: 0, y: 2 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: LogicalSubLayerTypes.Services,
    type: NodesTypes.SubLayerNode,
    data: { label: ReadableSubLayerTitles[LogicalSubLayerTypes.Services] },
    position: { x: 0, y: 3 * LAYER_HEIGHT + 4 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
];

export const businessAlignmentBaseLayerNodes: Node[] = [
  ...businessAlignmentLayerNodes,
  ...businessAlignmentSubLayerNodes,
];
