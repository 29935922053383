import { useMemo } from 'react';
import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import useAssets from '@hooks/useAssets';
import { AssessmentMetaData, NodeDTO } from '@store/services/nodes/types';

type ReturnType = {
  node: Omit<Node, 'data'>;
  dto: NodeDTO<AssessmentMetaData>;
};

const useCurrentAsset = (): ReturnType => {
  const { selectedAssetId } = useAssets();
  const { nodes } = useStore();

  return useMemo(() => {
    const currentAsset = nodes.find((node) => node.id === selectedAssetId);

    if (!currentAsset) {
      return { node: {}, dto: {} } as ReturnType;
    }

    const { data, ...node } = currentAsset;

    return { node, dto: data.dto };
  }, [selectedAssetId, nodes]);
};

export default useCurrentAsset;
