import { CustomDialog } from '@components/Dialogs/CustomDialog';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalWrapper = styled(CustomDialog)({
  '& .MuiDialog-paper': {
    width: '100%',
    maxWidth: 600,
  },
});

export const ModalContent = styled(Stack)({
  padding: '0 24px 24px',
});
