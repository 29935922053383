import { useMemo } from 'react';
import { UserRoles } from '@constants/entities/user';
import useUser from '@hooks/useUser';

const useRolesCheck = (roles: UserRoles[]) => {
  const [{ role }] = useUser();

  return useMemo(() => roles.includes(role), [roles, role]);
};

export default useRolesCheck;
