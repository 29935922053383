import { WideDomainKey } from '@constants/entities/ui';
import { IDomain } from '@store/services/projects/types';

export interface ITreeItem {
  id: string;
  name: string;
  children?: ITreeItem[];
}

export const buildTree = (data: IDomain[]): ITreeItem[] => {
  const treeMap: Record<string, ITreeItem> = {};

  data.forEach((node) => {
    treeMap[node.id] = { id: node.id, name: node.name, children: [] };
  });

  const domainItems = data.reduce((tree, node) => {
    if (node.parentId && treeMap[node.parentId]) {
      treeMap[node.parentId].children?.push(treeMap[node.id]);
    } else {
      return tree.concat(treeMap[node.id]);
    }

    return tree;
  }, [] as ITreeItem[]);

  return [
    { id: WideDomainKey, name: 'Enterprise wide', children: domainItems },
  ];
};

export const findParentIds = (
  tree: ITreeItem[],
  itemId: string,
  parentIds: string[] = [],
): string[] => {
  return tree.reduce((acc, item) => {
    if (item.id === itemId) {
      return acc;
    }

    if (item.children) {
      return [...acc, item.id, ...findParentIds(item.children, item.id)];
    }

    return acc;
  }, parentIds);
};

export const transformDomainsToObject = (
  domains: IDomain[],
  domainIds: string[],
): Record<string, IDomain> => {
  const result = domains.reduce(
    (acc, domain) => {
      if (domainIds.includes(domain.id)) {
        return { ...acc, [domain.id]: domain };
      }

      return acc;
    },
    {} as Record<string, IDomain>,
  );

  return {
    ...result,
    [WideDomainKey]: {
      id: WideDomainKey,
      name: 'Enterprise wide',
      level: 0,
      parent: '',
      parentId: '',
      description: '',
      authority: '',
      projectId: '',
    },
  };
};

export const getUploadedAssetsMessage = (created: number, updated: number) => {
  if (created && updated) {
    return `You added ${created} and updated ${updated} assets.`;
  }

  if (created) return `You added ${created} assets.`;
  if (updated) return `You updated ${updated} assets.`;
};

export const getUploadedAssetsStats = (created: number, updated: number) => {
  const statsBlocks = [
    {
      id: 'assets-imported',
      title: 'Number of parsed elements:',
      items: [{ text: 'Assets', value: created }],
    },
  ];

  if (updated) {
    return statsBlocks.concat([
      {
        id: 'assets-updated',
        title: 'Number of updated elements:',
        items: [{ text: 'Assets', value: updated }],
      },
    ]);
  }

  return statsBlocks;
};
