import { themePalette } from '@theme/muiTheme';
import { FullViewIcon, MinusIcon, PlusIcon } from '@utils/iconsDefs';
import styled from 'styled-components';

export const CustomControlsStyled = styled('div')`
  position: absolute;
  right: 24px;
  bottom: 24px;
  display: flex;
  z-index: 10;
`;

export const ZoomControls = styled('div')`
  display: flex;
  border-radius: 8px;
  background: white;
  box-shadow:
    0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

export const StyledZoom = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  padding: 10px 8px;
  border-left: 1px solid ${themePalette.grey[500]};
  border-right: 1px solid ${themePalette.grey[500]};
  cursor: default;
`;

export const FullViewWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 16px;
  background: white;
  cursor: pointer;
  box-shadow:
    0 1px 2px 0 rgba(16, 24, 40, 0.06),
    0 1px 3px 0 rgba(16, 24, 40, 0.1);
`;

export const StyledMinusIcon = styled(MinusIcon)`
  fill: ${themePalette.grey[900]};

  &:hover {
    fill: ${themePalette.grey[1000]};
  }
`;

export const StyledPlusIcon = styled(PlusIcon)`
  fill: ${themePalette.grey[900]};

  &:hover {
    fill: ${themePalette.grey[1000]};
  }
`;

export const StyledFullViewIcon = styled(FullViewIcon)`
  fill: ${themePalette.grey[900]};

  &:hover {
    fill: ${themePalette.grey[1000]};
  }
`;
