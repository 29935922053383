import React, { FC, memo, useEffect } from 'react';
import ConfirmDeleteModal from '@components/MainStage/DeleteRelationModals/ConfirmDeleteModal';
import SelectReplacementModal from '@components/MainStage/DeleteRelationModals/SelectReplacementModal';
import useStore from '@components/MainStage/store';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import useToggle from '@hooks/useToggle';
import { IAvailableRelationship } from '@store/services/nodes/types';
import {
  closeModal,
  selectDeleteRelationState,
} from '@store/slices/deleteRelationSlice';

const DeleteRelationModals: FC = () => {
  const { unselectActiveEdge } = useStore();

  const dispatch = useAppDispatch();
  const { isOpen, relationId } = useAppSelector(selectDeleteRelationState);

  const [isConfirmDeleteOpen, confirmDeleteControls] = useToggle();
  const [isBlockedOpen, blockedControls, moveWeightRelationOptions] = useToggle<
    IAvailableRelationship[] | null
  >();

  useEffect(() => {
    if (isOpen) {
      confirmDeleteControls.on();
    } else {
      confirmDeleteControls.off();

      blockedControls.off();
      blockedControls.setData(null);
    }
  }, [isOpen]);

  const handleOnCloseModal = () => {
    unselectActiveEdge();
    confirmDeleteControls.off();
  };

  const handleFullClose = () => dispatch(closeModal());

  if (!relationId || !isOpen) return null;

  return (
    <>
      <ConfirmDeleteModal
        id={relationId}
        open={isConfirmDeleteOpen}
        onFullClose={handleFullClose}
        onModalClose={handleOnCloseModal}
        onOpenSelectReplacement={blockedControls.on}
      />

      <SelectReplacementModal
        id={relationId}
        open={isBlockedOpen}
        onClose={handleFullClose}
        relationOptions={moveWeightRelationOptions}
      />
    </>
  );
};

export default memo(DeleteRelationModals);
