import { styled } from '@mui/material/styles';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

export const MainStageStyled = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(100vh - 180px); // 180 - height of static header
  z-index: 5;

  .react-flow__attribution {
    display: none;
  }

  .react-flow__handle-connecting {
    background: ${themePalette.system.error};
    border-color: ${themePalette.system.error};
  }

  .react-flow__handle-valid {
    background: ${themePalette.green[900]};
    border-color: ${themePalette.green[900]};
  }

  .react-flow__edge-interaction {
    pointer-events: none;
  }

  .react-flow__edge-path {
    pointer-events: all;

    &:hover {
      stroke: ${themePalette.grey[800]} !important; // works only with important
    }
  }
`;

export const BlockBackground = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 10,
});

type CollapseIconProps = {
  collapsed: boolean;
};

export const CollapseIcon = styled(DropdownIcon)<CollapseIconProps>`
  min-width: 20px;
  margin-left: 2px;
  transition: 0.5s transform ease-in-out;
  fill: ${({ collapsed }) => themePalette.grey[collapsed ? 700 : 1000]};
  transform: ${({ collapsed }) => (collapsed ? 'none' : 'rotate(180deg)')};
`;
