import { AssessmentContent } from '@components/EntityDrawers/drawers/Assessment/content';
import { AssessmentFormValues } from '@components/EntityDrawers/drawers/Assessment/form';
import { AssessmentSettings } from '@components/EntityDrawers/drawers/Assessment/settings';
import withAssetDomainChange from '@components/EntityDrawers/hocs/withAssetDomainChange';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { AssessmentMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddAssessmentScopeEntity
  | ProjectDrawerTypes.EditAssessmentScopeEntity;

export const AssessmentDrawer = withAssetDomainChange(
  withDrawer<AssessmentFormValues, AssessmentMetaData, Type>(
    AssessmentContent,
    AssessmentSettings,
  ),
);
