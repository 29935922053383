import { useEffect } from 'react';
import { useDashboard } from '@context/DashboardContext/useDashboard';
import { useProject } from '@context/Project/ProjectProvider';

export const useDashboardControl = () => {
  const {
    toolbox: { mode },
  } = useProject();

  const { handleChangeTab } = useDashboard();

  useEffect(() => {
    handleChangeTab(0);
  }, [mode]);
};
