import React, { FC, ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';
import { themePalette } from '@theme/muiTheme';

export const SIDE_MENU_WIDTH = 234;

type Props = {
  children: ReactNode;
  open: boolean;
  width?: number;
  side?: 'left' | 'right';
  sx?: SxProps<any>;
};

export const SideMenu: FC<Props> = ({
  open,
  children,
  width = SIDE_MENU_WIDTH,
  side = 'right',
  sx,
}) => {
  return (
    <Box
      sx={{
        width: open ? width : 0,
        overflowX: 'hidden',
        [side === 'right'
          ? 'borderLeft'
          : 'borderRight']: `1px solid ${themePalette.grey[500]}`,
        transition: 'width 175ms ease-in-out',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
