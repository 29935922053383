import { CharacteristicContent } from '@components/EntityDrawers/drawers/Characteristic/content';
import { CharacteristicFormValues } from '@components/EntityDrawers/drawers/Characteristic/form';
import { CharacteristicSettings } from '@components/EntityDrawers/drawers/Characteristic/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { CharacteristicMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddSensitiveElementEntity
  | ProjectDrawerTypes.EditSensitiveElementEntity;

export const CharacteristicDrawer = withDrawer<
  CharacteristicFormValues,
  CharacteristicMetaData,
  Type
>(CharacteristicContent, CharacteristicSettings);
