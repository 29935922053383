import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import { StewardsOpinion } from '@constants/canvas/layers';
import { ShapeType } from '@constants/types';
import {
  ResidualScoreYup,
  YupDateIdentified,
  YupRiskDateReviewed,
  YupString,
} from '@constants/validation';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import * as yup from 'yup';

export type RiskFormValues = yup.InferType<typeof validationSchema>;

export const RiskFields = [
  CommonKeys.Name,
  CommonKeys.Owner,
  CommonKeys.Description,
  CommonKeys.DateCreated,
  CommonKeys.DateReviewed,
] as const;

const RiskShape = {
  [RiskKeys.Taxonomy]: yup.string().nullable(),
  [RiskKeys.Assessor]: YupString(),
  [RiskKeys.Steward]: YupString(),
  [RiskKeys.StewardOpinion]: yup.string(),
  [RiskKeys.StewardJustificationNote]: YupString(),
  [RiskKeys.DateIdentified]: YupDateIdentified(),

  [RiskKeys.InherentLikelihood]: yup.string(),
  [RiskKeys.InherentImpact]: yup.string(),
  [RiskKeys.InherentJustification]: YupString(),
  [RiskKeys.ResidualLikelihood]: yup.string(),
  [RiskKeys.ResidualImpact]: yup.string(),
  [RiskKeys.ResidualJustification]: YupString(),
  [RiskKeys.RiskAction]: yup.string().nullable(),

  [CommonKeys.DateReviewed]: YupRiskDateReviewed(),

  [RiskKeys.InherentScore]: yup.number(),
  [RiskKeys.ResidualScore]: ResidualScoreYup(),
  [RiskKeys.HasCapability]: yup.boolean(),
} as const;

export const validationSchema = generateSchema<
  ShapeType<typeof RiskFields> & typeof RiskShape
>({
  ...getYups(...RiskFields),
  ...RiskShape,
});

export const defaultValues: RiskFormValues = {
  ...getDefs(...RiskFields),

  [RiskKeys.Taxonomy]: null,
  [RiskKeys.Assessor]: '',
  [RiskKeys.Steward]: '',
  [RiskKeys.StewardOpinion]: StewardsOpinion.Pending,
  [RiskKeys.StewardJustificationNote]: '',
  [RiskKeys.DateIdentified]: null,

  [RiskKeys.InherentLikelihood]: '',
  [RiskKeys.InherentImpact]: '',
  [RiskKeys.InherentJustification]: '',
  [RiskKeys.ResidualLikelihood]: '',
  [RiskKeys.ResidualImpact]: '',
  [RiskKeys.ResidualJustification]: '',
  [RiskKeys.RiskAction]: null,

  [RiskKeys.InherentScore]: 0,
  [RiskKeys.ResidualScore]: 0,
  [RiskKeys.HasCapability]: true,
};
