import React, { memo, useEffect, useMemo, useState } from 'react';
import ReactFlow, { Background } from 'reactflow';
import EdgeContextMenu from '@components/MainStage/ContextMenu/EdgeContextMenu/EdgeContextMenu';
import NodeContextMenu from '@components/MainStage/ContextMenu/NodeContextMenu/NodeContextMenu';
import SubLayerContextMenu from '@components/MainStage/ContextMenu/SubLayerContextMenu/SubLayerContextMenu';
import { CustomControls } from '@components/MainStage/CustomControls';
import { CustomEdge } from '@components/MainStage/CustomEdge';
import { CustomNode } from '@components/MainStage/CustomNode';
import DeleteRelationModals from '@components/MainStage/DeleteRelationModals';
import useConnect from '@components/MainStage/hooks/stage/useConnect';
import useDragNode from '@components/MainStage/hooks/stage/useDragNode';
import useEdgeHandlers from '@components/MainStage/hooks/stage/useEdgeHandlers';
import useIsValidConnection from '@components/MainStage/hooks/stage/useIsValidConnection';
import useNodeHandlers from '@components/MainStage/hooks/stage/useNodeHandlers';
import usePanelClick from '@components/MainStage/hooks/stage/usePanelClick';
import useTranslateExtent from '@components/MainStage/hooks/stage/useTranslateExtent';
import useDeleteEdgeKey from '@components/MainStage/hooks/useDeleteEdgeKey';
import useFilteredCanvas from '@components/MainStage/hooks/useFilteredCanvas';
import { LayerNode } from '@components/MainStage/LayerNode';
import {
  BlockBackground,
  MainStageStyled,
} from '@components/MainStage/MainStage.styled';
import { NewNode } from '@components/MainStage/NewNode';
import { SubLayerNode } from '@components/MainStage/SubLayerNode';
import { MenuState } from '@components/MainStage/types';
import {
  canvasStageStyles,
  INITIAL_VIEWPORT,
  MAX_ZOOM,
  MIN_ZOOM,
} from '@constants/canvas/general';
import { ReactFlowId } from '@constants/entities/reports';
import { NodesDataProvider } from '@context/NodesDataContext';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import useCanvasZoomController from '@hooks/canvas/useCanvasZoomController';
import useToggle from '@hooks/useToggle';
import { Box } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { shallow } from 'zustand/shallow';

import useStore, { RFState } from './store';

import 'reactflow/dist/style.css';

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  addChildNode: state.addChildNode,
  setNodes: state.setNodes,
  updateNode: state.updateNode,
  updateNodePosition: state.updateNodePosition,
  getActiveNode: state.getActiveNode,
  getActiveEdge: state.getActiveEdge,
  getNodeById: state.getNodeById,
  unselectActiveNode: state.unselectActiveNode,
  setActiveNode: state.setActiveNode,
  selectNode: state.selectNode,
  selectEdge: state.selectEdge,
  unselectActiveEdge: state.unselectActiveEdge,
  clearLocalNodes: state.clearLocalNodes,
  clearLocalEdges: state.clearLocalEdges,
  selectNodeAndRemoveLocal: state.selectNodeAndRemoveLocal,
});

const nodeTypes = {
  customNode: CustomNode,
  newNode: NewNode,
  layerNode: LayerNode,
  subLayerNode: SubLayerNode,
};

const edgeTypes = {
  customEdge: CustomEdge,
};

const connectionLineStyle = {
  stroke: themePalette.grey[700],
  strokeWidth: 2,
  strokeDasharray: '6 6',
  zIndex: 25,
};

const defaultEdgeOptions = { type: 'customEdge' };

export const MainStage = memo(() => {
  const {
    handleCloseDrawer,
    hasChanges,
    toolbox: { mode },
  } = useProject();

  const { onNodesChange, onEdgesChange, getActiveNode } = useStore(
    selector,
    shallow,
  );

  const [menu, setMenu] = useState<MenuState | null>(null);
  const [isMenuOpen, { off: closeMenu, on: openMenu }] = useToggle();

  const { zoom } = useCanvasZoom();
  const { nodes, edges } = useFilteredCanvas();
  const onPaneClick = usePanelClick({ closeMenu });
  const activeNode = getActiveNode();

  const { onEdgeAdded, onEdgeClick, onEdgeContextMenu } = useEdgeHandlers({
    menu,
    closeMenu,
    openMenu,
    setMenu,
  });

  const { onMove, onNodeClick, onNodeContextMenu } = useNodeHandlers({
    menu,
    closeMenu,
    openMenu,
    setMenu,
    onPaneClick,
  });

  const { onNodeDragStart, onNodeDragStop } = useDragNode({
    menu,
    closeMenu,
  });

  const { onConnectStart, onConnectEnd } = useConnect();
  const isValidConnection = useIsValidConnection();
  const translateExtent = useTranslateExtent();
  const defaultViewport = useMemo(() => INITIAL_VIEWPORT(zoom), []);

  const isCanvasBlocked = useMemo(
    () => hasChanges || activeNode?.id.startsWith('local'),
    [hasChanges, activeNode],
  );

  const onBlockBackClick = () => {
    if (activeNode?.id.startsWith('local')) return handleCloseDrawer(true);
    handleCloseDrawer();
  };

  useEffect(() => {
    if (menu) closeMenu();
  }, [mode]);

  useCanvasZoomController();
  useDeleteEdgeKey();

  return (
    <Box id="canvas" position="relative">
      {isCanvasBlocked && <BlockBackground onClick={onBlockBackClick} />}

      <DeleteRelationModals />

      <MainStageStyled>
        <NodesDataProvider>
          <ReactFlow
            id={ReactFlowId}
            nodes={nodes}
            edges={edges}
            nodeDragThreshold={1}
            zoomOnPinch={false}
            onMove={onMove}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDragStart={onNodeDragStart}
            onNodeDragStop={onNodeDragStop}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            isValidConnection={isValidConnection}
            onConnect={onEdgeAdded}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            elevateNodesOnSelect
            defaultEdgeOptions={defaultEdgeOptions}
            onNodeContextMenu={onNodeContextMenu}
            onEdgeContextMenu={onEdgeContextMenu}
            onPaneClick={onPaneClick}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            multiSelectionKeyCode={null}
            deleteKeyCode={null}
            translateExtent={translateExtent}
            style={canvasStageStyles}
            zoomOnDoubleClick={false}
            connectionLineStyle={connectionLineStyle}
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            defaultViewport={defaultViewport}
          >
            <Background />

            <SubLayerContextMenu
              isOpen={isMenuOpen}
              onClose={closeMenu}
              menu={menu}
            />

            <NodeContextMenu
              isOpen={isMenuOpen}
              onClose={closeMenu}
              menu={menu}
            />

            <EdgeContextMenu
              isOpen={isMenuOpen}
              onClose={closeMenu}
              menu={menu}
            />

            <CustomControls />
          </ReactFlow>
        </NodesDataProvider>
      </MainStageStyled>
    </Box>
  );
});

MainStage.displayName = 'MainStage';
