import { Node } from 'reactflow';
import {
  baseLayersProperties,
  LAYER_HEIGHT,
  NodesTypes,
} from '@constants/canvas/general';
import {
  AssessmentScopeSubLayerTypes,
  AssociatedRisksSubLayerTypes,
  ChangeInitiativesSubLayerTypes,
  InitiativesSubLayerTypes,
  LayerTypes,
  PhysicalSubLayerTypes,
  ReadableLayerTitles,
  ReadableSubLayerTitles,
  SensitiveElementsSubLayerTypes,
} from '@constants/canvas/layers';
import { NodeDTOTypes } from '@store/services/nodes/types';
import SublayerView from '@utils/canvas/SublayerView';

export const RISK_MANAGEMENT_LAYERS = [
  NodeDTOTypes.Asset,
  NodeDTOTypes.Characteristic,
  NodeDTOTypes.EvaluatedRisk,
  NodeDTOTypes.Capabilities,
  NodeDTOTypes.Functions,
  NodeDTOTypes.Initiatives,
];

export const PhysicalLayerInRiskModeLabel = 'Controls';

export const RISK_MANAGEMENT_PARENT_LAYER = {};

const riskManagementLayerNodes: Node[] = [
  {
    id: LayerTypes.AssessmentScope,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.AssessmentScope] },
    position: { x: 0, y: 0 },
    ...baseLayersProperties,
  },
  {
    id: LayerTypes.SensitiveElements,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.SensitiveElements] },
    position: { x: 0, y: LAYER_HEIGHT + SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: LayerTypes.AssociatedRisks,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.AssociatedRisks] },
    position: { x: 0, y: 2 * LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: LayerTypes.Physical,
    type: NodesTypes.LayerNode,
    data: { label: PhysicalLayerInRiskModeLabel },
    position: { x: 0, y: 3 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: LayerTypes.Initiatives,
    type: NodesTypes.LayerNode,
    data: { label: ReadableLayerTitles[LayerTypes.RemediationAction] },
    position: { x: 0, y: 4 * LAYER_HEIGHT + 5 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
];

export const riskManagementSubLayerNodes: Node[] = [
  {
    id: AssessmentScopeSubLayerTypes.Asset,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[AssessmentScopeSubLayerTypes.Asset],
    },
    position: { x: 0, y: LAYER_HEIGHT },
    ...baseLayersProperties,
  },
  {
    id: SensitiveElementsSubLayerTypes.AssetCharacteristics,
    type: NodesTypes.SubLayerNode,
    data: {
      label:
        ReadableSubLayerTitles[
          SensitiveElementsSubLayerTypes.AssetCharacteristics
        ],
    },
    position: { x: 0, y: 2 * LAYER_HEIGHT + SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: AssociatedRisksSubLayerTypes.Risks,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[AssociatedRisksSubLayerTypes.Risks],
    },
    position: { x: 0, y: 3 * LAYER_HEIGHT + 2 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: PhysicalSubLayerTypes.Capabilities,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[PhysicalSubLayerTypes.Capabilities],
      withBottomBorder: true,
    },
    position: { x: 0, y: 4 * LAYER_HEIGHT + 3 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: PhysicalSubLayerTypes.Functions,
    type: NodesTypes.SubLayerNode,
    data: {
      label: ReadableSubLayerTitles[PhysicalSubLayerTypes.Functions],
    },
    position: { x: 0, y: 4 * LAYER_HEIGHT + 4 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
  {
    id: InitiativesSubLayerTypes.ChangeInitiatives,
    type: NodesTypes.SubLayerNode,
    data: {
      label:
        ReadableSubLayerTitles[
          ChangeInitiativesSubLayerTypes.RemediationAction
        ],
    },
    position: { x: 0, y: 5 * LAYER_HEIGHT + 5 * SublayerView.InitialHeight },
    ...baseLayersProperties,
  },
];

export const riskManagementBaseLayerNodes: Node[] = [
  ...riskManagementLayerNodes,
  ...riskManagementSubLayerNodes,
];
