import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface DeleteRelationState {
  isOpen: boolean;
  relationId: string | null;
}

const initialState: DeleteRelationState = {
  isOpen: false,
  relationId: null,
};

export const deleteRelationSlice = createSlice({
  name: 'deleteRelationState',
  initialState,
  reducers: {
    openModal: (state, { payload: relationId }: PayloadAction<string>) => {
      state.isOpen = true;
      state.relationId = relationId;
    },

    closeModal: () => initialState,
  },
});

export const selectDeleteRelationState = (state: RootState) =>
  state.deleteRelationState;

export const { openModal, closeModal } = deleteRelationSlice.actions;
