import { useState } from 'react';

interface PaginationState {
  page: number;
  pageSize: number;
}

export type WithPaginationFn = (
  fn: (...args: any) => void,
) => (...args: any) => void;

interface PaginationHandlers {
  handleChangePage: (page: number) => void;
  handleChangePageSize: (perPage: number) => void;
  resetPagination: () => void;
  withResetPagination: WithPaginationFn;
}

function usePagination(
  initialPage: number = 1,
  initialPerPage: number = 10,
): [PaginationState, PaginationHandlers] {
  const [page, setPage] = useState<number>(initialPage);
  const [pageSize, setPageSize] = useState<number>(initialPerPage);

  const handleChangePageSize = (newPerPage: number) => {
    setPageSize(newPerPage);
    setPage(1);
  };

  const resetPagination = () => setPage(1);

  const withResetPagination =
    (fn: (...args: any) => void) =>
    (...args: any) => {
      resetPagination();
      fn(...args);
    };

  return [
    { page, pageSize },
    {
      handleChangePage: setPage,
      handleChangePageSize,
      resetPagination,
      withResetPagination,
    },
  ];
}

export default usePagination;
