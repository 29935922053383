import React, { FC, useMemo } from 'react';
import {
  CustomDialog,
  CustomDialogProps,
} from '@components/Dialogs/CustomDialog';
import DropZoneBlock from '@components/UploadModal/core/DropZoneBlock/DropZoneBlock';
import { UploadFileStatus } from '@components/UploadModal/core/UploadFileStatus';
import { SuccessfullyUploadedStats } from '@components/UploadModal/types';
import { useToast } from '@context/Toast/ToastProvider';
import useFileUpload from '@hooks/useFileUpload';
import useProjectId from '@hooks/useProjectId';
import { DialogContent } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useImportAssetsFileMutation } from '@store/services/nodes';
import { ImportAssetsFileResponse } from '@store/services/nodes/types';
import {
  getUploadedAssetsMessage,
  getUploadedAssetsStats,
} from '@views/Project/helpers';

const UploadAssetsModal: FC<CustomDialogProps> = ({ onClose, ...props }) => {
  const projectId = useProjectId(true);
  const { showToast } = useToast();

  const {
    isUploadDisabled,
    onDrop,
    fileData,
    uploadError,
    uploadResult,
    showFileStatus,
    isLoading,
    status,
  } = useFileUpload<ImportAssetsFileResponse>(useImportAssetsFileMutation, {
    payload: { projectId },
  });

  const stats = useMemo<SuccessfullyUploadedStats[]>(() => {
    if (status === QueryStatus.fulfilled && uploadResult) {
      const { created, updated } = uploadResult.result;
      return getUploadedAssetsStats(created, updated);
    }

    return [];
  }, [status, uploadResult]);

  const handleClose = () => {
    onClose?.();

    if (uploadResult) {
      const {
        result: { created, updated },
      } = uploadResult;

      const message = getUploadedAssetsMessage(created, updated);
      showToast(`File has been uploaded successfully. ${message}`, 'success');
    }
  };

  return (
    <CustomDialog
      title="Upload file"
      sx={{ '& .MuiDialog-paper': { minWidth: 598 } }}
      onClose={handleClose}
      {...props}
    >
      <DialogContent sx={{ p: 6, gap: 0 }} className="upload-zone">
        <DropZoneBlock isDisabled={isUploadDisabled} options={{ onDrop }} />

        {showFileStatus && (
          <UploadFileStatus
            isLoading={isLoading}
            uploadError={uploadError}
            status={status}
            stats={stats}
            fileData={fileData}
          />
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default UploadAssetsModal;
