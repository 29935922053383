import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CoreFormKeys,
  CoreRequiredKeys,
} from '@components/EntityDrawers/constants/keys';
import {
  ContextualFormValues,
  defaultValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Contextual/form';
import { ContextualKeys } from '@components/EntityDrawers/drawers/Contextual/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { ContextualMetaData, NameLink } from '@store/services/nodes/types';
import {
  checkArrayFieldsIncomplete,
  checkValuesIncomplete,
  transformCommonKeys,
} from '@utils/drawers';
import { filterEmptyAndOmitId, getEntitiesArray } from '@utils/helpers';

export const ContextualSettings: DrawerSettings<
  ContextualFormValues,
  ContextualMetaData
> = {
  editType: ProjectDrawerTypes.EditContextualEntity,
  defaultValues,
  validationSchema,
  checkIncompleteFields: (values) =>
    checkValuesIncomplete<ContextualFormValues>(...CoreRequiredKeys)(values) ||
    checkArrayFieldsIncomplete(values[ContextualKeys.Sources], ['name']),
  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<ContextualFormValues, ContextualMetaData>(
      ...CoreFormKeys,
    )(nodeDto, context),

    [ContextualKeys.Sources]: getEntitiesArray<NameLink>(
      nodeDto.meta_data.source ?? [],
      NameLinkObject,
    ),
  }),

  transformMetaDataToRequestBody: ({ sources }) => ({
    source: filterEmptyAndOmitId<NameLink>(sources ?? []),
  }),
};
