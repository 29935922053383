import React, { FC } from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';

type Props = Pick<ConfirmDialogProps, 'open' | 'onClose'>;

const EmptyControlsModal: FC<Props> = (props) => {
  const { onClose } = props;
  const { handleModeChange } = useProject();

  const handleOpenControls = () => {
    onClose?.();
    handleModeChange(Modes.Controls);
  };

  return (
    <ConfirmDialog
      title="Empty controls library"
      text="Begin by creating entities and relationships in controls mode before adding them to the asset risk assessment model."
      confirmName="Open controls mode"
      onConfirm={handleOpenControls}
      {...props}
    />
  );
};

export default EmptyControlsModal;
