import { ISelectOption, WideDomainKey } from '@constants/entities/ui';
import { onLoading, successTags } from '@store/helpers';
import authorizedApi from '@store/services/authorizedApi';
import {
  BusinessAttributesResponse,
  IAssetCompatibleNodesRequest,
  IAssetCompatibleNodesResponse,
  IAssetNodesRequest,
  IAssignNodesToAssetRequest,
  IBusinessAttributesRequest,
  ICanvasNodesRequest,
  ICanvasNodesResponse,
  IChangeNodeReferencesRequest,
  IChangeNodeReferencesResponse,
  ICheckDomainChangeImpactModelRequest,
  ICheckDomainChangeImpactModelResponse,
  ICreateNodeRequest,
  ICreateRelationshipRequest,
  IDeleteNodeRequest,
  IDeleteRelationshipRequest,
  IDeleteRelationshipResponse,
  IGetAssetsListRequest,
  ImportAssetsFileRequest,
  ImportAssetsFileResponse,
  IUpdateNodePositionRequest,
  IUpdateNodeRequest,
  IUpdateRelationshipRequest,
  NodeDTO,
} from '@store/services/nodes/types';
import { QueryTags } from '@store/services/tags';
import { transformArrayToOptions } from '@utils/helpers';
import querystring from 'query-string';

export const nodesApi = authorizedApi.injectEndpoints({
  endpoints: (builder) => ({
    getProjectCanvasNodes: builder.query<
      ICanvasNodesResponse,
      ICanvasNodesRequest
    >({
      query: ({ projectId, mode }) => ({
        url: `/v2/projects/${projectId}/nodes/`,
        params: { model: mode },
      }),

      providesTags: [QueryTags.ProjectNodes],
      keepUnusedDataFor: 0,
      onQueryStarted: onLoading,
    }),

    getProjectAssetNodes: builder.query<
      ICanvasNodesResponse,
      IAssetNodesRequest
    >({
      query: ({ projectId, assetNodeId, versionId }) => ({
        url: `/projects/${projectId}/asset/${assetNodeId}/${
          versionId ? `?version_id=${versionId}` : ''
        }`,
      }),

      onQueryStarted: onLoading,
      providesTags: [QueryTags.AssetNodes],
      keepUnusedDataFor: 0,
    }),

    getAssetsList: builder.query<ISelectOption[], IGetAssetsListRequest>({
      query: ({ projectId, versionId }) => ({
        url: `/projects/${projectId}/asset/${
          versionId ? `?version_id=${versionId}` : ''
        }`,
      }),

      providesTags: [QueryTags.AssetsList],
      transformResponse: (nodes: NodeDTO[]) => {
        return transformArrayToOptions(nodes, {
          label: 'name',
          value: 'id',
        }).sort((a, b) => a.label.localeCompare(b.label));
      },
    }),

    getAssetCompatibleNodes: builder.query<
      IAssetCompatibleNodesResponse,
      IAssetCompatibleNodesRequest
    >({
      query: ({ nodeId, type }) => ({
        url: `/nodes/${nodeId}/compatible?type=${type}`,
      }),

      onQueryStarted: onLoading,
      providesTags: [QueryTags.AssetCompatibleNodes],
      keepUnusedDataFor: 0,
    }),

    checkDomainChangeImpactModel: builder.mutation<
      ICheckDomainChangeImpactModelResponse,
      ICheckDomainChangeImpactModelRequest
    >({
      query: ({ nodeId, domainId }) => ({
        method: 'GET',
        url: `/nodes/${nodeId}/check-domain${
          domainId ? `?domain_id=${domainId}` : ''
        }`,
      }),

      onQueryStarted: onLoading,
    }),

    createProjectNode: builder.mutation<
      ICanvasNodesResponse,
      ICreateNodeRequest
    >({
      query: ({ projectId, mode, assetId, ...body }: ICreateNodeRequest) => ({
        url: `v2/projects/${projectId}/nodes/`,
        method: 'POST',
        params: { model: mode, asset_id: assetId },
        body,
      }),

      onQueryStarted: onLoading,
      invalidatesTags: successTags([
        QueryTags.CompletenessInsight,
        QueryTags.BusinessAlignmentDashboard,
        QueryTags.ControlsDashboard,
        QueryTags.RiskDashboard,
      ]),
    }),

    updateNode: builder.mutation<ICanvasNodesResponse, IUpdateNodeRequest>({
      query: ({ nodeId, mode, assetId, ...body }: IUpdateNodeRequest) => ({
        url: `v2/nodes/${nodeId}/`,
        method: 'PATCH',
        params: { model: mode, asset_id: assetId },
        body,
      }),

      onQueryStarted: onLoading,
      invalidatesTags: (_, error) => [
        QueryTags.CompletenessInsight,
        QueryTags.KeyControlsInsight,
        ...(error
          ? []
          : [
              QueryTags.BusinessAlignmentDashboard,
              QueryTags.ControlsDashboard,
              QueryTags.RiskDashboard,
            ]),
      ],
    }),

    updateNodePosition: builder.mutation<void, IUpdateNodePositionRequest>({
      query: ({ projectId, ...body }: IUpdateNodePositionRequest) => ({
        url: `/projects/${projectId}/nodes/move`,
        method: 'POST',
        body,
      }),
    }),

    assignNodesToAsset: builder.mutation<
      ICanvasNodesResponse,
      IAssignNodesToAssetRequest
    >({
      query: ({ assetId, ...body }: IAssignNodesToAssetRequest) => ({
        url: `v2/nodes/${assetId}/assign`,
        method: 'POST',
        body,
      }),

      onQueryStarted: onLoading,
    }),

    deleteNode: builder.mutation<ICanvasNodesResponse, IDeleteNodeRequest>({
      query: ({
        id,
        allocateWeightRelationsId,
        mode,
        assetId,
      }: IDeleteNodeRequest) => ({
        url: `v2/nodes/${id}/?${querystring.stringify(
          { allocateWeightRelationsId, model: mode, asset_id: assetId },
          { arrayFormat: 'bracket' },
        )}`,
        method: 'DELETE',
      }),

      onQueryStarted: onLoading,
      invalidatesTags: successTags([
        QueryTags.AssetsList,
        QueryTags.CompletenessInsight,
        QueryTags.BusinessAlignmentDashboard,
        QueryTags.ControlsDashboard,
        QueryTags.RiskDashboard,
      ]),
    }),

    createRelationship: builder.mutation<
      ICanvasNodesResponse,
      ICreateRelationshipRequest
    >({
      query: ({ mode, assetId, ...body }: ICreateRelationshipRequest) => ({
        url: `v2/nodes/relations`,
        method: 'POST',
        body,
        params: { model: mode, asset_id: assetId },
      }),

      onQueryStarted: onLoading,
    }),

    updateRelationship: builder.mutation<
      ICanvasNodesResponse,
      IUpdateRelationshipRequest
    >({
      query: ({ id, mode, assetId, ...body }: IUpdateRelationshipRequest) => ({
        url: `v2/nodes/relations/${id}`,
        method: 'PATCH',
        body,
        params: { model: mode, asset_id: assetId },
      }),

      onQueryStarted: onLoading,
    }),

    deleteRelationship: builder.mutation<
      IDeleteRelationshipResponse,
      IDeleteRelationshipRequest
    >({
      query: ({
        id,
        allocateWeightRelationsId,
        mode,
        assetId,
      }: IDeleteRelationshipRequest) => ({
        url: `v2/nodes/relations/${id}/?${querystring.stringify(
          { allocateWeightRelationsId },
          { arrayFormat: 'bracket' },
        )}`,
        method: 'DELETE',
        params: { model: mode, asset_id: assetId },
      }),

      onQueryStarted: onLoading,
    }),

    changeNodeReferences: builder.mutation<
      IChangeNodeReferencesResponse,
      IChangeNodeReferencesRequest
    >({
      query: (body) => ({ url: `/nodes/refs`, body, method: 'POST' }),
    }),

    getBusinessAttributes: builder.query<
      BusinessAttributesResponse,
      IBusinessAttributesRequest
    >({
      query: ({ projectId, domainId }) => ({
        url: `/projects/${projectId}/compatible-ba${
          domainId !== WideDomainKey ? `?domainId=${domainId}` : ''
        }`,
      }),

      keepUnusedDataFor: 0,
    }),

    importAssetsFile: builder.mutation<
      ImportAssetsFileResponse,
      ImportAssetsFileRequest
    >({
      query: ({ file, projectId }) => {
        const body = new FormData();
        body.append('file', file);

        return {
          url: `/projects/${projectId}/asset/upload`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: [QueryTags.AssetsList, QueryTags.AssetNodes],
    }),
  }),
});

export const {
  useGetBusinessAttributesQuery,
  useGetProjectCanvasNodesQuery,
  useGetProjectAssetNodesQuery,
  useGetAssetCompatibleNodesQuery,
  useCheckDomainChangeImpactModelMutation,
  useAssignNodesToAssetMutation,
  useUpdateNodeMutation,
  useDeleteNodeMutation,
  useUpdateNodePositionMutation,
  useCreateProjectNodeMutation,
  useCreateRelationshipMutation,
  useUpdateRelationshipMutation,
  useDeleteRelationshipMutation,
  useChangeNodeReferencesMutation,
  useGetAssetsListQuery,
  useImportAssetsFileMutation,
} = nodesApi;
