import React from 'react';
import { InitiativeTypes } from '@constants/canvas/layers';
import { NodeDTOTypes } from '@store/services/nodes/types';
import {
  AssetCharacteristicIcon,
  AssetCharacteristicIconDisabled,
  AssetCharacteristicIconOutlined,
  AssetIcon,
  AssetIconDisabled,
  AssetIconOutlined,
  AssetRiskIcon,
  AssetRiskIconDisabled,
  AssetRiskIconOutlined,
  AuditIcon,
  AuditIconDisabled,
  BusinessAttributesDisabledIcon,
  BusinessAttributesIcon,
  BusinessAttributesOutlinedIcon,
  CapabilitiesNodeIcon,
  CapabilitiesNodeIconDisabled,
  CapabilitiesNodeIconOutlined,
  FunctionsNodeIcon,
  FunctionsNodeIconDisabled,
  FunctionsNodeIconOutlined,
  OperationalIcon,
  OperationalIconDisabled,
  RemediationActionIcon,
  RemediationActionIconDisabled,
  SecurityAttributesDisabledIcon,
  SecurityAttributesIcon,
  SecurityAttributesOutlinedIcon,
  ServicesDisabledIcon,
  ServicesIcon,
  ServicesOutlinedIcon,
  StrategicEnablersDisabledIcon,
  StrategicEnablersIcon,
  StrategicEnablersOutlinedIcon,
  StrategicInitiativeIcon,
  StrategicInitiativeIconDisabled,
  StrategicPrioritiesDisabledIcon,
  StrategicPrioritiesIcon,
  StrategicPrioritiesOutlinedIcon,
  TechnologiesNodeIcon,
  TechnologiesNodeIconDisabled,
  TechnologiesNodeIconOutlined,
} from '@utils/iconsDefs';

const defaultIconWidth = '100%';
const defaultIconHeight = '100%';

export const getNodeIcon = ({
  type,
  width = defaultIconWidth,
  height = defaultIconHeight,
  disabled,
}: {
  type: string;
  width?: string;
  height?: string;
  disabled?: boolean;
}) => {
  const iconMap: Record<string, React.ReactNode> = {
    [NodeDTOTypes.StrategicPriority]: disabled ? (
      <StrategicPrioritiesDisabledIcon width={width} height={height} />
    ) : (
      <StrategicPrioritiesIcon width={width} height={height} />
    ),
    [NodeDTOTypes.StrategicEnabler]: disabled ? (
      <StrategicEnablersDisabledIcon width={width} height={height} />
    ) : (
      <StrategicEnablersIcon width={width} height={height} />
    ),
    [NodeDTOTypes.BusinessAttribute]: disabled ? (
      <BusinessAttributesDisabledIcon width={width} height={height} />
    ) : (
      <BusinessAttributesIcon width={width} height={height} />
    ),
    [NodeDTOTypes.SecurityAttribute]: disabled ? (
      <SecurityAttributesDisabledIcon width={width} height={height} />
    ) : (
      <SecurityAttributesIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Service]: disabled ? (
      <ServicesDisabledIcon width={width} height={height} />
    ) : (
      <ServicesIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Functions]: disabled ? (
      <FunctionsNodeIconDisabled width={width} height={height} />
    ) : (
      <FunctionsNodeIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Technologies]: disabled ? (
      <TechnologiesNodeIconDisabled width={width} height={height} />
    ) : (
      <TechnologiesNodeIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Capabilities]: disabled ? (
      <CapabilitiesNodeIconDisabled width={width} height={height} />
    ) : (
      <CapabilitiesNodeIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Asset]: disabled ? (
      <AssetIconDisabled width={width} height={height} />
    ) : (
      <AssetIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Characteristic]: disabled ? (
      <AssetCharacteristicIconDisabled width={width} height={height} />
    ) : (
      <AssetCharacteristicIcon width={width} height={height} />
    ),
    [NodeDTOTypes.EvaluatedRisk]: disabled ? (
      <AssetRiskIconDisabled width={width} height={height} />
    ) : (
      <AssetRiskIcon width={width} height={height} />
    ),
  };

  return iconMap[type] || <StrategicPrioritiesIcon />;
};

export const getInitiativeNodeIcon = ({
  type,
  width,
  height,
  disabled,
}: {
  type: InitiativeTypes;
  width?: string;
  height?: string;
  disabled?: boolean;
}) => {
  const iconMap: Record<string, React.ReactNode> = {
    [InitiativeTypes.StrategicInitiative]: disabled ? (
      <StrategicInitiativeIconDisabled width={width} height={height} />
    ) : (
      <StrategicInitiativeIcon width={width} height={height} />
    ),
    [InitiativeTypes.RemediationAction]: disabled ? (
      <RemediationActionIconDisabled width={width} height={height} />
    ) : (
      <RemediationActionIcon width={width} height={height} />
    ),
    [InitiativeTypes.AuditFinding]: disabled ? (
      <AuditIconDisabled width={width} height={height} />
    ) : (
      <AuditIcon width={width} height={height} />
    ),
    [InitiativeTypes.Operational]: disabled ? (
      <OperationalIconDisabled width={width} height={height} />
    ) : (
      <OperationalIcon width={width} height={height} />
    ),
  };

  return iconMap[type] || <StrategicInitiativeIcon />;
};

export const getOutlinedNodeIcon = ({
  type,
  width = defaultIconWidth,
  height = defaultIconHeight,
}: {
  type: string;
  width?: string;
  height?: string;
}) => {
  const iconMap: Record<string, React.ReactNode> = {
    [NodeDTOTypes.StrategicPriority]: (
      <StrategicPrioritiesOutlinedIcon width={width} height={height} />
    ),
    [NodeDTOTypes.StrategicEnabler]: (
      <StrategicEnablersOutlinedIcon width={width} height={height} />
    ),
    [NodeDTOTypes.BusinessAttribute]: (
      <BusinessAttributesOutlinedIcon width={width} height={height} />
    ),
    [NodeDTOTypes.SecurityAttribute]: (
      <SecurityAttributesOutlinedIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Service]: (
      <ServicesOutlinedIcon width={width} height={height} />
    ),
    [NodeDTOTypes.Capabilities]: (
      <CapabilitiesNodeIconOutlined width={width} height={height} />
    ),
    [NodeDTOTypes.Functions]: (
      <FunctionsNodeIconOutlined width={width} height={height} />
    ),
    [NodeDTOTypes.Technologies]: (
      <TechnologiesNodeIconOutlined width={width} height={height} />
    ),
    [NodeDTOTypes.Asset]: <AssetIconOutlined width={width} height={height} />,
    [NodeDTOTypes.Characteristic]: (
      <AssetCharacteristicIconOutlined width={width} height={height} />
    ),
    [NodeDTOTypes.EvaluatedRisk]: (
      <AssetRiskIconOutlined width={width} height={height} />
    ),
  };

  return iconMap[type] || <StrategicPrioritiesOutlinedIcon />;
};
