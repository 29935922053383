import React, { FC } from 'react';
import { NameObject } from '@components/EntityDrawers/constants/empty-objects';
import { InitiativeKeys } from '@components/EntityDrawers/drawers/Initiative/keys';
import ControlledInputGroups, {
  InputsBlockProps,
} from '@components/Inputs/controllers/ControlledInputGroups';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';
import { getInputGroupsProps } from '@utils/helpers';

const ProgressNotesInputs: FC<InputsBlockProps> = ({
  createName,
  viewOnly,
}) => (
  <ControlledTextField
    name={createName('name')}
    label="Progress note"
    placeholder="Progress note"
    disabled={viewOnly}
    multiline
  />
);

const ProgressNotesGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledInputGroups
      name={InitiativeKeys.ProgressNotes}
      viewOnly={viewOnly}
      groupObject={NameObject}
      {...getInputGroupsProps('progress note')}
    >
      {ProgressNotesInputs}
    </ControlledInputGroups>
  );
};

export default ProgressNotesGroup;
