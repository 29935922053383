import { Node } from 'reactflow';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project/ProjectProvider';

type Options = {
  isNodeVisible: (values: string[], node: Node | undefined) => boolean;
  onSetValues: (values: string[]) => void;
};

const useChangeFilterHandler = () => {
  const { getActiveEdge, nodes } = useStore();

  const activeNode = useSourceNode();
  const activeEdge = getActiveEdge();

  const { handleCloseDrawer } = useProject();

  return ({ isNodeVisible, onSetValues }: Options) =>
    async (values: string[]) => {
      if (activeNode) {
        if (!(!values.length || isNodeVisible(values, activeNode))) {
          await handleCloseDrawer();
        }
      }

      if (activeEdge) {
        const parentNode = nodes.find(
          (node) => node.id === activeEdge.data?.dto?.parent_id,
        );

        const childNode = nodes.find(
          (node) => node.id === activeEdge.data?.dto?.child_id,
        );

        const isEdgeVisible =
          !values.length ||
          (isNodeVisible(values, parentNode) &&
            isNodeVisible(values, childNode));

        if (!isEdgeVisible) {
          await handleCloseDrawer();
        }
      }

      onSetValues(values);
    };
};

export default useChangeFilterHandler;
