import React, { FC } from 'react';
import { Divider, Typography } from '@mui/material';

export interface UploadFileStatusSuccessItemProps {
  text: string;
  value: number;
  withDivider?: boolean;
}

export const UploadFileStatusSuccessItem: FC<
  UploadFileStatusSuccessItemProps
> = ({ text, value, withDivider }) => (
  <>
    <Typography variant="body1" color="grey.800">
      {text}
      <Typography variant="body1" color="grey.1000" component="span">
        {' '}
        {value}
      </Typography>
    </Typography>
    {withDivider && <Divider flexItem orientation="vertical" />}
  </>
);
