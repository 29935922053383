import {
  BA_LAYERS,
  BA_PARENT_LAYER,
  businessAlignmentBaseLayerNodes,
  businessAlignmentSubLayerNodes,
} from '@constants/canvas/businessAlignment';
import {
  CONTROLS_LAYERS,
  CONTROLS_PARENT_LAYER,
  controlsBaseLayerNodes,
  controlsSubLayerNodes,
} from '@constants/canvas/controls';
import { Modes } from '@constants/canvas/layers';
import {
  RISK_MANAGEMENT_LAYERS,
  RISK_MANAGEMENT_PARENT_LAYER,
  riskManagementBaseLayerNodes,
  riskManagementSubLayerNodes,
} from '@constants/canvas/riskManagement';

export const baseLayerNodesByMode = {
  [Modes.BusinessAlignment]: businessAlignmentBaseLayerNodes,
  [Modes.Controls]: controlsBaseLayerNodes,
  [Modes.RiskManagement]: riskManagementBaseLayerNodes,
};

export const subLayersNodesByMode = {
  [Modes.BusinessAlignment]: businessAlignmentSubLayerNodes,
  [Modes.Controls]: controlsSubLayerNodes,
  [Modes.RiskManagement]: riskManagementSubLayerNodes,
};

export const subLayersNodeIdsByMode: Record<Modes, string[]> = {
  [Modes.BusinessAlignment]: businessAlignmentSubLayerNodes.map(
    (node) => node.id,
  ),
  [Modes.Controls]: controlsSubLayerNodes.map((node) => node.id),
  [Modes.RiskManagement]: riskManagementSubLayerNodes.map((node) => node.id),
};

export const layersByMode = {
  [Modes.BusinessAlignment]: BA_LAYERS,
  [Modes.Controls]: CONTROLS_LAYERS,
  [Modes.RiskManagement]: RISK_MANAGEMENT_LAYERS,
};

export const parentLayersByMode = {
  [Modes.BusinessAlignment]: BA_PARENT_LAYER,
  [Modes.Controls]: CONTROLS_PARENT_LAYER,
  [Modes.RiskManagement]: RISK_MANAGEMENT_PARENT_LAYER,
};
