import React, { FC, useMemo, useState } from 'react';
import ConfirmDialog from '@components/Dialogs/ConfirmDialog';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
} from '@components/Inputs/TextFieldSelect';
import { ToggleActions } from '@hooks/useToggle';
import {
  IAvailableRelationship,
  IUpdateNodeRequest,
} from '@store/services/nodes/types';

type Props = {
  isDomainChangesConfirmOpen: boolean;
  domainChangesControls: ToggleActions;
  onDomainsChangesProceed: () => void;

  isBlockedRelationOpen: boolean;
  blockedRelationControls: ToggleActions<IAvailableRelationship[] | null>;
  blockedRelationOptions: IAvailableRelationship[] | null;
  onBlockedRelationProceed: (replaceRelationId: string) => void;

  isRiskActionChangesOpen: boolean;
  riskActionChangesControls: ToggleActions;
  onRiskActionChangesProceed: (keepRemediationActions: boolean) => void;
};

const ConfirmationModals: FC<Props> = ({
  isDomainChangesConfirmOpen,
  domainChangesControls,
  onDomainsChangesProceed,

  isBlockedRelationOpen,
  blockedRelationControls,
  blockedRelationOptions,
  onBlockedRelationProceed,

  isRiskActionChangesOpen,
  riskActionChangesControls,
  onRiskActionChangesProceed,
}) => {
  const [replaceRelationId, setReplaceRelationId] = useState(
    DEFAULT_PLACEHOLDER_VALUE,
  );

  const options = useMemo(
    () =>
      blockedRelationOptions?.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [blockedRelationOptions],
  );

  return (
    <>
      <ConfirmDialog
        open={isDomainChangesConfirmOpen}
        onClose={domainChangesControls.off}
        title="Domain changes"
        text="Changing the domain will impact the relationship(s) the entity has within the model. Are you sure you want to proceed?"
        onConfirm={onDomainsChangesProceed}
      />

      <ConfirmDialog
        open={isBlockedRelationOpen}
        onClose={blockedRelationControls.off}
        title="Delete relationship"
        text="Changing entity’s domain results in deleting relationship with the last auto-assigned weight value. Please, select one relationship to allocate the weight value to."
        onConfirm={() => onBlockedRelationProceed(replaceRelationId)}
        disabled={replaceRelationId === DEFAULT_PLACEHOLDER_VALUE}
      >
        <TextFieldSelect
          label="Allocation relationship"
          value={replaceRelationId}
          onChange={(e) => setReplaceRelationId(e.target.value)}
          placeholder="Select allocation relationship"
          options={options}
        />
      </ConfirmDialog>

      <ConfirmDialog
        open={isRiskActionChangesOpen}
        onClose={riskActionChangesControls.off}
        title="Risk action changes"
        text="If the risk has been accepted, associated remediation actions could be removed from the asset risk assessment model. Select the option to proceed."
        onConfirm={() => onRiskActionChangesProceed(false)}
        onBack={() => onRiskActionChangesProceed(true)}
        confirmName="Remove"
        backName="Keep"
      />
    </>
  );
};

export default ConfirmationModals;
