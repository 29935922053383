import React, { FC, memo, MouseEvent, useMemo, useRef, useState } from 'react';
import SubLayerContextMenu from '@components/MainStage/ContextMenu/SubLayerContextMenu/SubLayerContextMenu';
import { MenuState } from '@components/MainStage/types';
import { compatibleLayerTypeByLayerId } from '@constants/canvas/general';
import {
  BusinessAlignmentSubLayer,
  ChangeInitiativesSubLayerTypes,
  ControlsSubLayer,
  InitiativesSubLayerTypes,
  LayerSubLayers,
  LayerTypes,
  Modes,
  PhysicalSubLayerTypes,
  ReadableSubLayerTitles,
  RiskManagementSubLayer,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { useMenuNodes } from '@context/MenuNodesProvider';
import { useProject } from '@context/Project/ProjectProvider';
import useAssets from '@hooks/useAssets';
import useCanEditModel from '@hooks/useCanEditModel';
import useToggle from '@hooks/useToggle';
import { IconButton, MenuItem, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { isLayerDisabled } from '@utils/canvasHelpers';
import { sortStringCb } from '@utils/helpers';
import { PlusIcon } from '@utils/iconsDefs';
import NodeItem from '@views/Project/components/ListMenu/components/NodeItem';
import useIsActionInSubLayerAllowed from '@views/Project/components/ListMenu/hooks/useIsActionInSubLayerAllowed';
import useAddEntity from '@views/Project/hooks/useAddEntity';

type Props = {
  layerId: LayerTypes;
  subLayerId:
    | BusinessAlignmentSubLayer
    | ControlsSubLayer
    | RiskManagementSubLayer;
};

const LayersMenuTopOffset = 195;
const ExistMenuLeftOffset = 190;

const SubLayer: FC<Props> = ({ layerId, subLayerId }) => {
  const handleAddClick = useAddEntity({ subLayerId });

  const [menu, setMenu] = useState<MenuState | null>(null);
  const [isMenuOpen, { off: closeMenu, on: openMenu }] = useToggle();
  const { isNodeActionAllowed } = useIsActionInSubLayerAllowed();

  const { nodes } = useMenuNodes();

  const layerNodes = useMemo(
    () =>
      nodes.filter((node) =>
        LayerSubLayers[layerId].includes(
          node.parentNode as PhysicalSubLayerTypes,
        ),
      ),
    [layerId, nodes],
  );

  const subLayerNodes = useMemo(
    () =>
      layerNodes
        .filter((node) => node.parentNode === subLayerId)
        .toSorted((a, b) => sortStringCb(a.data.dto.name, b.data.dto.name)),
    [layerNodes],
  );

  const menuRef = useRef(null);

  const {
    toolbox: { mode },
  } = useProject();
  const { selectedAssetId } = useAssets();

  const showAddNode = useCanEditModel();

  const isAddNodeDisabled = useMemo(
    () => isLayerDisabled(subLayerId, mode!, selectedAssetId),
    [subLayerId, mode, selectedAssetId],
  );

  const handlePlusClick = (event: MouseEvent) => {
    if (!isNodeActionAllowed(subLayerId as SubLayerTypes)) return;

    if (
      mode === Modes.RiskManagement &&
      Object.hasOwn(compatibleLayerTypeByLayerId, subLayerId)
    ) {
      setMenu({
        id: subLayerId,
        type: 'subLayer',
        top: event.clientY - LayersMenuTopOffset,
        left: ExistMenuLeftOffset,
      });

      openMenu();

      return;
    }

    handleAddClick();
  };

  return (
    <>
      <SubLayerContextMenu
        ref={menuRef}
        isOpen={isMenuOpen}
        onClose={closeMenu}
        menu={menu}
      />

      <MenuItem
        sx={{
          justifyContent: 'space-between',
          '&:hover .plus': {
            borderColor: `${
              themePalette.grey[isAddNodeDisabled ? 500 : 1000]
            } !important`,
          },
        }}
        disableRipple
      >
        <Typography
          variant="body1"
          color="grey.900"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {mode === Modes.RiskManagement &&
          subLayerId === InitiativesSubLayerTypes.ChangeInitiatives
            ? ReadableSubLayerTitles[
                ChangeInitiativesSubLayerTypes.RemediationAction
              ]
            : `${ReadableSubLayerTitles[subLayerId]} (${subLayerNodes.length})`}
        </Typography>

        {showAddNode && (
          <IconButton
            className="plus"
            sx={{ borderRadius: '4px', p: '3px' }}
            size="small"
            variant="simple"
            onClick={handlePlusClick}
            disabled={isAddNodeDisabled}
          >
            <PlusIcon width={16} height={16} style={{ minWidth: 16 }} />
          </IconButton>
        )}
      </MenuItem>

      {subLayerNodes.map((node) => (
        <NodeItem
          key={node.id}
          id={node.id}
          subLayerId={subLayerId}
          layerId={layerId}
          title={node.data.dto.label}
        />
      ))}
    </>
  );
};

export default memo(SubLayer);
