import { useGenerateRegisterReportMutation } from '@store/services/reports';
import { getRiskRegisterFileName } from '@views/ReportsModel/helpers';
import useExportState from '@views/ReportsModel/hooks/useExportState';

const useExportRegister = (projectId: string) => {
  const { isError, setError, fileContent, setFileContent } = useExportState();

  const [generateRegisterReport, { isLoading, reset }] =
    useGenerateRegisterReportMutation();

  const handleGenerate = async () => {
    try {
      const excelData = await generateRegisterReport(projectId).unwrap();
      setFileContent(excelData);
    } catch (e) {
      setError(true);
    }
  };

  return {
    isError,
    fileContent,
    isLoading,
    setFileContent,
    setError,
    handleGenerate,
    fileName: getRiskRegisterFileName(),
    reset,
  };
};

export default useExportRegister;
