export enum InitiativeKeys {
  Type = 'type',
  ProgressStatus = 'progress_status',
  BusinessSponsor = 'business_sponsor',
  ProgramManager = 'program_manager',
  FundingMechanism = 'funding_mechanism',
  AllocatedBudget = 'allocated_budget',
  DeliveryStatus = 'delivery_status',
  ProgressNotes = 'progress_notes',
  Document = 'document',
}
