import { useMemo } from 'react';
import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';

const useNode = (nodeId: string) => {
  const { getNodeById, nodes } = useStore();

  return useMemo(() => {
    return (getNodeById(nodeId) ?? {
      id: nodeId,
      data: { dto: {} },
    }) as Node;
  }, [nodeId, nodes]);
};

export default useNode;
