import {
  AssessmentScopeSubLayerTypes,
  AssociatedRisksSubLayerTypes,
  ChangeInitiativesSubLayerTypes,
  ComponentSubLayerTypes,
  ConceptualSubLayerTypes,
  ContextualSubLayerTypes,
  InitiativesSubLayerTypes,
  LayerSubLayers,
  LayerTypes,
  LogicalSubLayerTypes,
  ModeLayers,
  Modes,
  PhysicalSubLayerTypes,
  SensitiveElementsSubLayerTypes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { NodeDTOTypes } from '@store/services/nodes/types';
import { themePalette } from '@theme/muiTheme';

export enum NodesTypes {
  LayerNode = 'layerNode',
  SubLayerNode = 'subLayerNode',
  CustomNode = 'customNode',
  NewNode = 'newNode',
}

export enum EdgesTypes {
  CustomEdge = 'customEdge',
}

export const canvasStageStyles = {
  width: '100%',
  height: '100%',
  backgroundColor: themePalette.grey[400],
};

export const MAX_ZOOM = 2;
export const MIN_ZOOM = 0.5;
export const FIT_VIEW_X_PADDING = 50;

export const CANVAS_LEFT_MARGIN = 250; // to prevent from adding nodes into text
export const CANVAS_WIDTH = 2700; // for correct rendering layers inside
export const CANVAS_EXPORT_WIDTH = 2900; // Improvement: calculate width dynamically
export const CUSTOM_NODE_WIDTH = 252;

export const NODE_VIEW_HEIGHT = 48;
export const NODE_VIEW_WIDTH = 212;
export const LAYER_HEIGHT = 72;
export const COLLAPSED_LAYER_HEIGHT = 72;
export const NODE_VIEW_PADDING_Y = 20;
export const SUB_LAYER_PADDING_Y = 4;

export const NODE_AND_SUBLAYER_PADDING_SUM =
  NODE_VIEW_PADDING_Y + SUB_LAYER_PADDING_Y;

export const NODE_MENU_MARGIN = 14;
export const NODE_MENU_Y_HELP_OFFSET = 8;

export const MAX_X_ALLOWED_FOR_NODE_PLACEMENT = 1900;
export const ONE_ROW_LIMIT_NODES = 8;
export const X_MARGIN_FOR_NEW_ROW = 106;

export const VIEWPORT_TRANSITION_TIME = 2000;
export const ZOOM_TRANSITION_TIME = 600;

export const NodeYMargin = 24;

export const CANVAS_DEFAULT_HEIGHT = 5000;
export const CANVAS_HEIGHT_PADDING = 90;

export const INITIAL_VIEWPORT = (zoom = 1) => ({
  x: CANVAS_LEFT_MARGIN * zoom,
  y: 0,
  zoom,
});

export const UI_SMALL_LAYERS = [
  LayerTypes.Logical,
  LayerTypes.Component,
  LayerTypes.Initiatives,
  LayerTypes.AssessmentScope,
  LayerTypes.SensitiveElements,
  LayerTypes.AssociatedRisks,
  LayerTypes.RemediationAction,
];

export const baseLayersProperties = {
  draggable: false,
  selectable: false,
  focusable: false,
  zIndex: -1,
};

export const parentNodeTypes = {
  [NodeDTOTypes.StrategicPriority]: ContextualSubLayerTypes.StrategicPriorities,
  [NodeDTOTypes.StrategicEnabler]: ContextualSubLayerTypes.StrategicEnablers,
  [NodeDTOTypes.BusinessAttribute]: ConceptualSubLayerTypes.BusinessAttributes,
  [NodeDTOTypes.SecurityAttribute]: ConceptualSubLayerTypes.SecurityAttributes,
  [NodeDTOTypes.Service]: LogicalSubLayerTypes.Services,
  [NodeDTOTypes.Capabilities]: PhysicalSubLayerTypes.Capabilities,
  [NodeDTOTypes.Functions]: PhysicalSubLayerTypes.Functions,
  [NodeDTOTypes.Technologies]: ComponentSubLayerTypes.Technologies,
  [NodeDTOTypes.Initiatives]: InitiativesSubLayerTypes.ChangeInitiatives,
  [NodeDTOTypes.Asset]: AssessmentScopeSubLayerTypes.Asset,
  [NodeDTOTypes.Characteristic]:
    SensitiveElementsSubLayerTypes.AssetCharacteristics,
  [NodeDTOTypes.EvaluatedRisk]: AssociatedRisksSubLayerTypes.Risks,
};

export const AssetsSubLayerTypes: SubLayerTypes[] = [
  SensitiveElementsSubLayerTypes.AssetCharacteristics,
  AssociatedRisksSubLayerTypes.Risks,
  InitiativesSubLayerTypes.ChangeInitiatives,
];

export const nodeTypesByMode = {
  [Modes.BusinessAlignment]: [
    ContextualSubLayerTypes.StrategicPriorities,
    ContextualSubLayerTypes.StrategicEnablers,
    ConceptualSubLayerTypes.BusinessAttributes,
    ConceptualSubLayerTypes.SecurityAttributes,
    LogicalSubLayerTypes.Services,
  ],
  [Modes.Controls]: [
    ConceptualSubLayerTypes.BusinessAttributes,
    ConceptualSubLayerTypes.SecurityAttributes,
    LogicalSubLayerTypes.Services,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    ComponentSubLayerTypes.Technologies,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [Modes.RiskManagement]: [
    AssessmentScopeSubLayerTypes.Asset,
    SensitiveElementsSubLayerTypes.AssetCharacteristics,
    AssociatedRisksSubLayerTypes.Risks,
    AssociatedRisksSubLayerTypes.Risks,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
};

export const dependentLayers = {
  [ContextualSubLayerTypes.StrategicPriorities]: [
    ContextualSubLayerTypes.StrategicEnablers,
    LayerTypes.Conceptual,
    ConceptualSubLayerTypes.BusinessAttributes,
    ConceptualSubLayerTypes.SecurityAttributes,
    LayerTypes.Logical,
    LogicalSubLayerTypes.Services,
  ],
  [ContextualSubLayerTypes.StrategicEnablers]: [
    LayerTypes.Conceptual,
    ConceptualSubLayerTypes.BusinessAttributes,
    ConceptualSubLayerTypes.SecurityAttributes,
    LayerTypes.Logical,
    LogicalSubLayerTypes.Services,
  ],
  [ConceptualSubLayerTypes.BusinessAttributes]: [
    ConceptualSubLayerTypes.SecurityAttributes,
    LayerTypes.Logical,
    LogicalSubLayerTypes.Services,
    LayerTypes.Physical,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Component,
    ComponentSubLayerTypes.Technologies,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [ConceptualSubLayerTypes.SecurityAttributes]: [
    LayerTypes.Logical,
    LogicalSubLayerTypes.Services,
    LayerTypes.Physical,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Component,
    ComponentSubLayerTypes.Technologies,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [LogicalSubLayerTypes.Services]: [
    LayerTypes.Physical,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Component,
    ComponentSubLayerTypes.Technologies,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [PhysicalSubLayerTypes.Capabilities]: [
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Component,
    ComponentSubLayerTypes.Technologies,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [PhysicalSubLayerTypes.Functions]: [
    LayerTypes.Component,
    ComponentSubLayerTypes.Technologies,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [ComponentSubLayerTypes.Technologies]: [
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [InitiativesSubLayerTypes.ChangeInitiatives]: [],
  [AssessmentScopeSubLayerTypes.Asset]: [
    LayerTypes.SensitiveElements,
    SensitiveElementsSubLayerTypes.AssetCharacteristics,
    LayerTypes.AssociatedRisks,
    AssociatedRisksSubLayerTypes.Risks,
    LayerTypes.Physical,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [SensitiveElementsSubLayerTypes.AssetCharacteristics]: [
    LayerTypes.AssociatedRisks,
    AssociatedRisksSubLayerTypes.Risks,
    LayerTypes.Physical,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [AssociatedRisksSubLayerTypes.Risks]: [
    LayerTypes.Physical,
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
    LayerTypes.Initiatives,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [ChangeInitiativesSubLayerTypes.RemediationAction]: [],
};

export const connectableLayers = {
  [ContextualSubLayerTypes.StrategicPriorities]: [
    ContextualSubLayerTypes.StrategicEnablers,
  ],
  [ContextualSubLayerTypes.StrategicEnablers]: [
    ConceptualSubLayerTypes.BusinessAttributes,
  ],
  [ConceptualSubLayerTypes.BusinessAttributes]: [
    ConceptualSubLayerTypes.SecurityAttributes,
  ],
  [ConceptualSubLayerTypes.SecurityAttributes]: [LogicalSubLayerTypes.Services],
  [LogicalSubLayerTypes.Services]: [
    PhysicalSubLayerTypes.Capabilities,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [PhysicalSubLayerTypes.Capabilities]: [
    PhysicalSubLayerTypes.Functions,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [PhysicalSubLayerTypes.Functions]: [
    ComponentSubLayerTypes.Technologies,
    InitiativesSubLayerTypes.ChangeInitiatives,
  ],
  [ComponentSubLayerTypes.Technologies]: [],
  [InitiativesSubLayerTypes.ChangeInitiatives]: [],
  [AssessmentScopeSubLayerTypes.Asset]: [
    SensitiveElementsSubLayerTypes.AssetCharacteristics,
  ],
  [SensitiveElementsSubLayerTypes.AssetCharacteristics]: [
    AssociatedRisksSubLayerTypes.Risks,
  ],
  [AssociatedRisksSubLayerTypes.Risks]: [PhysicalSubLayerTypes.Capabilities],
  [ChangeInitiativesSubLayerTypes.RemediationAction]: [],
};
export const compatibleLayerTypeByLayerId: Record<string, string> = {
  [PhysicalSubLayerTypes.Capabilities]: NodeDTOTypes.Capabilities,
  [PhysicalSubLayerTypes.Functions]: NodeDTOTypes.Functions,
  [InitiativesSubLayerTypes.ChangeInitiatives]: NodeDTOTypes.Initiatives,
};

export const getMergedLayers = (mode: Modes) =>
  (ModeLayers[mode] as readonly LayerTypes[]).reduce<
    (LayerTypes | SubLayerTypes)[]
  >((acc, layerId) => {
    const mergedLayerAndSubLayers = [layerId, ...LayerSubLayers[layerId]] as (
      | LayerTypes
      | SubLayerTypes
    )[];

    return [...acc, ...mergedLayerAndSubLayers];
  }, []);

export const AllLayersListByMode = Object.fromEntries(
  Object.entries(Modes).map(([, mode]) => [
    mode,
    getMergedLayers(mode as Modes),
  ]),
) as Record<Modes, (LayerTypes | SubLayerTypes)[]>;

export const getSubLayers = (mode: Modes) =>
  (ModeLayers[mode] as unknown as LayerTypes[]).reduce<SubLayerTypes[]>(
    (acc, layerId) => [...acc, ...LayerSubLayers[layerId]],
    [] as SubLayerTypes[],
  );

export const SubLayersListByMode = Object.fromEntries(
  Object.entries(Modes).map(([, mode]) => [mode, getSubLayers(mode as Modes)]),
) as Record<Modes, SubLayerTypes[]>;
