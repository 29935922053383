import {
  ConceptualSourceFormValues,
  ConceptualSourceObject,
} from '@components/EntityDrawers/constants/empty-objects';
import {
  commonDefaults,
  commonTransformers,
  commonYups,
} from '@components/EntityDrawers/constants/fields';
import {
  CommonKeys,
  LogicPhysicalFormKeysType,
} from '@components/EntityDrawers/constants/keys';
import { TransformContext } from '@components/EntityDrawers/hooks/useTransformContext';
import { DateFormats } from '@constants/dates';
import { UnionFromArray } from '@constants/types';
import { nanoid } from '@reduxjs/toolkit';
import {
  ConceptualSource,
  NameObjective,
  NodeDTO,
} from '@store/services/nodes/types';
import {
  filterEmptyAndOmitId,
  omitCertainEmptyKeys,
  withId,
} from '@utils/helpers';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { ObjectShape } from 'yup';

export const isElementLocal = (id: string) => id.startsWith('local');

export const getYup = (key: CommonKeys) => ({ [key]: commonYups[key] });
export const getDef = (key: CommonKeys) => ({ [key]: commonDefaults[key] });

export const getYups = <T extends CommonKeys>(...keys: T[]) => {
  return keys.reduce(
    (acc, key) => ({ ...acc, ...getYup(key) }),
    {} as { [K in (typeof keys)[number]]: (typeof commonYups)[K] },
  );
};

export const getDefs = <T extends CommonKeys>(...keys: T[]) => {
  return keys.reduce(
    (acc, key) => ({ ...acc, ...getDef(key) }),
    {} as { [K in (typeof keys)[number]]: (typeof commonDefaults)[K] },
  );
};

export const transformCommonKeys =
  <FormValues, Meta>(...keys: CommonKeys[]) =>
  (node: NodeDTO<Meta>, context: TransformContext) => {
    return keys.reduce(
      (acc, key) => ({ ...acc, [key]: commonTransformers[key](node, context) }),
      {} as FormValues,
    );
  };

export const stringToDayjs = (date: string | undefined | null) =>
  date ? dayjs(date) : null;

export const prepareDate = (date: Dayjs | undefined | null, key: string) =>
  date?.isValid() ? { [key]: date?.format(DateFormats[2]) } : {};

export const prepareBasicLogicPhysicalData = (
  values: Partial<Record<UnionFromArray<LogicPhysicalFormKeysType>, any>>,
) => ({
  ...omitCertainEmptyKeys(values, [
    CommonKeys.OperationalStatus,
    CommonKeys.OperationalModel,
    CommonKeys.OperationalEffectiveness,
    CommonKeys.DesignEffectiveness,
  ]),

  ...prepareDate(values[CommonKeys.TimelineStartDate], 'start_date'),
  ...prepareDate(values[CommonKeys.TimelineEndDate], 'end_date'),

  metric: filterEmptyAndOmitId<NameObjective>(values[CommonKeys.Metrics]),

  ...(values[CommonKeys.CurrentMaturity]
    ? {
        current_maturity: parseFloat(
          values[CommonKeys.CurrentMaturity].toString().replace(',', '.'),
        ).toString(),
      }
    : {}),

  ...(values[CommonKeys.TargetMaturity]
    ? {
        target_maturity: parseFloat(
          values[CommonKeys.TargetMaturity].toString().replace(',', '.'),
        ).toString(),
      }
    : {}),
});

export const generateSchema = <T extends ObjectShape>(shape: any) =>
  yup.object().shape<T>(shape);

export const checkValuesIncomplete =
  <Values>(...keys: (keyof Values)[]) =>
  (values: Partial<Values>) => {
    return keys.some((key) => {
      const value = values[key];

      if (Array.isArray(value)) {
        return !value.length;
      }

      if (typeof value === 'string') {
        return !value.trim().length;
      }

      if (dayjs.isDayjs(value)) {
        return !value.isValid();
      }

      return !value;
    });
  };

export const checkArrayFieldsIncomplete = <Item>(
  array: Item[],
  requiredKeys: (keyof Item)[],
) => {
  return array.some((item) =>
    checkValuesIncomplete<Item>(...requiredKeys)(item),
  );
};

export const add = (isValid: boolean, obj: any) => (isValid ? obj : {});

export const transformSourcesToFormValues = (arr?: ConceptualSource[]) => {
  return arr?.length
    ? arr.map((e) => ({
        ...withId(e),
        justification_statement: (e.justification_statement?.length
          ? e.justification_statement
          : ['']
        ).map((js) => ({ id: nanoid(10), name: js })),
      }))
    : [
        {
          id: nanoid(10),
          ...ConceptualSourceObject,
          justification_statement: [{ id: nanoid(10), name: '' }],
        },
      ];
};

export const transformSourcesFormValuesToMetaData = (
  arr: ConceptualSourceFormValues[],
) => {
  return arr.map((source) => {
    const sourceMetaData: any = { ...source };

    if (sourceMetaData.justification_statement) {
      sourceMetaData.justification_statement = source.justification_statement
        .map((js) => js.name.trim())
        .filter((js) => js.length);
    }

    const { id, justification_statement, ...rest } = sourceMetaData;

    const filledEntries = Object.entries(rest).filter(
      ([, value]) => value?.toString().trim() !== '',
    );

    return {
      justification_statement,
      ...Object.fromEntries(filledEntries),
    };
  });
};
