import React, { ChangeEvent, FC } from 'react';
import { useFormContext } from 'react-hook-form';
import TimelineDates from '@components/EntityDrawers/components/inputs/TimelineDates';
import {
  InitiativeDeliveryStatusOptions,
  InitiativeFundingMechanismOptions,
} from '@components/EntityDrawers/constants/common';
import { InitiativeFormValues } from '@components/EntityDrawers/drawers/Initiative/form';
import DocumentsGroup from '@components/EntityDrawers/drawers/Initiative/inputs/DocumentsGroup';
import ProgressNotesGroup from '@components/EntityDrawers/drawers/Initiative/inputs/ProgressNotesGroup';
import { InitiativeKeys } from '@components/EntityDrawers/drawers/Initiative/keys';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import ControlledAmountInput from '@components/Inputs/controllers/ControlledAmountInput';
import ControlledRadioGroup from '@components/Inputs/controllers/ControlledRadioGroup';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { InitiativeFundingMechanism } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectSettings from '@hooks/useProjectSettings';

const Advanced: FC = () => {
  const { viewOnly } = useProject();
  const activeNode = useSourceNode();
  const {
    settings: { currency },
  } = useProjectSettings();

  const { watch, setValue, clearErrors } =
    useFormContext<InitiativeFormValues>();

  const isAmountVisible = [
    InitiativeFundingMechanism.FundingRequired,
    InitiativeFundingMechanism.FundingSecured,
  ].includes(
    watch(InitiativeKeys.FundingMechanism) as InitiativeFundingMechanism,
  );

  const handleChangeFundingMechanism = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;

    if (value === InitiativeFundingMechanism.BusinessAsUsual) {
      setValue(InitiativeKeys.AllocatedBudget, '');
      clearErrors(InitiativeKeys.AllocatedBudget);
    }

    setValue(InitiativeKeys.FundingMechanism, value, {
      shouldDirty: activeNode?.data?.dto?.funding_mechanism !== value,
    });
  };

  return (
    <>
      <TimelineDates />

      <ControlledTextFieldSelect
        name={InitiativeKeys.FundingMechanism}
        label="Funding mechanism"
        placeholder="Choose funding mechanism"
        disabled={viewOnly}
        options={InitiativeFundingMechanismOptions}
        onChange={handleChangeFundingMechanism}
      />

      {isAmountVisible && (
        <ControlledAmountInput
          name={InitiativeKeys.AllocatedBudget}
          label="Allocated budget"
          placeholder="1,000.00"
          disabled={viewOnly}
          currency={currency}
        />
      )}

      <ControlledRadioGroup
        name={InitiativeKeys.DeliveryStatus}
        options={InitiativeDeliveryStatusOptions}
        label="Delivery status"
        disabledAll={viewOnly}
      />

      <ProgressNotesGroup />
      <DocumentsGroup />
    </>
  );
};

export default Advanced;
