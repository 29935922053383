import { useEffect, useRef } from 'react';
import useStore from '@components/MainStage/store';
import {
  InitiativesSubLayerTypes,
  InitiativeTypes,
  Modes,
  PhysicalSubLayerTypes,
} from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useCurrentAsset from '@hooks/useCurrentAsset';

const useDeleteEdgeCheck = () => {
  const { showToast } = useToast();

  const {
    toolbox: { mode },
  } = useProject();

  const { dto: currentAsset } = useCurrentAsset();

  const remediationEdgeRef = useRef(false);
  const capabilityFunctionEdgeRef = useRef(false);

  const { getActiveEdge, nodes, unselectActiveEdge } = useStore();
  const activeEdge = getActiveEdge();

  const parent = nodes.find((node) => node.id === activeEdge?.source);
  const child = nodes.find((node) => node.id === activeEdge?.target);

  useEffect(() => {
    if (mode !== Modes.RiskManagement) {
      remediationEdgeRef.current = false;
      return;
    }

    const isParentPhysicalSubLayer = [
      PhysicalSubLayerTypes.Capabilities,
      PhysicalSubLayerTypes.Functions,
    ].includes(parent?.parentNode as PhysicalSubLayerTypes);

    const isParentAsset =
      child?.data.dto?.parent_asset &&
      child?.data.dto?.parent_asset === currentAsset?.id;

    const isChildrenImportedRemediation =
      child?.parentNode === InitiativesSubLayerTypes.ChangeInitiatives &&
      child?.data.dto?.meta_data?.type === InitiativeTypes.RemediationAction &&
      !isParentAsset;

    remediationEdgeRef.current =
      isParentPhysicalSubLayer && isChildrenImportedRemediation;
  }, [parent, child, mode, activeEdge]);

  useEffect(() => {
    if (mode !== Modes.RiskManagement) {
      capabilityFunctionEdgeRef.current = false;
      return;
    }

    capabilityFunctionEdgeRef.current =
      parent?.parentNode === PhysicalSubLayerTypes.Capabilities &&
      child?.parentNode === PhysicalSubLayerTypes.Functions;
  }, [parent, child, mode, activeEdge]);

  const checkIsExistedRemediationEdge = () => {
    if (remediationEdgeRef.current) {
      showToast(
        'Relationship between remediation action and control cannot be deleted as it was established in controls mode \n' +
          'or other assets.',
        'error',
        { verticalCenter: true },
      );

      unselectActiveEdge();
    }

    return remediationEdgeRef.current;
  };

  const checkIsCapabilityFunctionEdge = () => {
    if (capabilityFunctionEdgeRef.current) {
      showToast(
        'Relationship between capability and function cannot be deleted as it was established in controls mode or other assets.',
        'error',
        { verticalCenter: true },
      );

      unselectActiveEdge();
    }

    return capabilityFunctionEdgeRef.current;
  };

  return {
    checkIsExistedRemediationEdge,
    checkIsCapabilityFunctionEdge,
  };
};

export default useDeleteEdgeCheck;
