import React, { FC } from 'react';
import CreatedReviewDates from '@components/EntityDrawers/components/inputs/CreatedReviewDates';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import NameInput from '@components/EntityDrawers/components/inputs/NameInput';
import OwnerInput from '@components/EntityDrawers/components/inputs/OwnerInput';
import AssessorInput from '@components/EntityDrawers/drawers/Risk/inputs/AssessorInput';
import DateIdentifiedInput from '@components/EntityDrawers/drawers/Risk/inputs/DateIdentifiedInput';
import StewardInput from '@components/EntityDrawers/drawers/Risk/inputs/StewardInput';
import StewardJustificationNoteInput from '@components/EntityDrawers/drawers/Risk/inputs/StewardJustificationNoteInput';
import TaxonomyDropdown from '@components/EntityDrawers/drawers/Risk/inputs/TaxonomyDropdown';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import { WideDomainKey } from '@constants/entities/ui';
import useCurrentAsset from '@hooks/useCurrentAsset';
import EntityAlerts from '@views/Project/components/EntityAlerts';

import StewardsOpinionDropdown from '../inputs/StewardsOpinionDropdown';

const General: FC = () => {
  const { editMode } = useDrawerContext();

  const {
    dto: { domain_id },
  } = useCurrentAsset();

  return (
    <>
      <EntityAlerts />

      <NameInput
        label="Risk name"
        placeholder="Risk name"
        editMode={editMode}
        multiline
      />

      <DescriptionInput
        label="Risk description"
        placeholder="Risk description"
      />

      <TaxonomyDropdown />
      <DomainDropdown disabled value={domain_id ?? WideDomainKey} />
      <OwnerInput label="Risk owner" placeholder="Risk owner" />
      <AssessorInput />
      <StewardInput />
      <StewardsOpinionDropdown />
      <StewardJustificationNoteInput />
      <DateIdentifiedInput />

      {editMode && (
        <CreatedReviewDates reviewValidateOnBlur dateReviewedClearable />
      )}
    </>
  );
};

export default General;
