import { Edge, Node } from 'reactflow';
import useSourceNode from '@components/EntityDrawers/hooks/useSourceNode';
import useStore from '@components/MainStage/store';
import { EntityData } from '@components/MainStage/types';
import { SubLayerTypes } from '@constants/canvas/layers';
import useCurrentAsset from '@hooks/useCurrentAsset';
import useProjectSettings from '@hooks/useProjectSettings';
import useReferenceMaps from '@hooks/useReferenceMaps';
import { AssessmentMetaData, NodeDTO } from '@store/services/nodes/types';
import { ISettings } from '@store/services/projects/types';

export type TransformContext = {
  subLayer: SubLayerTypes | undefined;
  qnousMap: Map<string, string>;
  nistMap: Map<string, string>;
  settings: Partial<ISettings>;
  nodes: Node[];
  edges: Edge[];
  activeNode: Node<EntityData<NodeDTO<any>>> | undefined;
  asset: NodeDTO<AssessmentMetaData>;
};

const useTransformContext = () => {
  const activeNode = useSourceNode() as Node<EntityData<NodeDTO<any>>>;
  const [{ qnousMap, nistMap }] = useReferenceMaps();
  const { settings } = useProjectSettings();
  const { nodes, edges } = useStore();
  const { dto: asset } = useCurrentAsset();

  const context: TransformContext = {
    activeNode,
    subLayer: activeNode?.parentNode as SubLayerTypes,
    qnousMap,
    nistMap,
    settings,
    nodes,
    edges,
    asset,
  };

  return { context };
};

export default useTransformContext;
