import React, { FC, forwardRef, ReactNode } from 'react';
import { ButtonBase, ButtonBaseProps, Stack, Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DropdownIcon } from '@utils/iconsDefs';

export type SelectorButtonProps = ButtonBaseProps & {
  icon?: ReactNode;
  title: string;
  open?: boolean;
  count?: number;
};

// eslint-disable-next-line react/display-name
const SelectorButton: FC<SelectorButtonProps> = forwardRef<any, any>(
  ({ title, icon, open, sx, count, disabled, ...props }, ref) => {
    let borderColorShade: keyof (typeof themePalette)['grey'] = disabled
      ? 500
      : 700;

    if (open) {
      borderColorShade = 800;
    }

    return (
      <ButtonBase
        ref={ref}
        disabled={disabled}
        sx={{
          p: '8px 14px',
          borderRadius: 3,
          border: `1px solid ${themePalette.grey[borderColorShade]}`,
          ...sx,
        }}
        {...props}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          width="100%"
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            flex={1}
            overflow="hidden"
          >
            {icon}

            <Typography
              variant="h3"
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Typography>

            {count !== undefined && (
              <Stack
                ml={1}
                alignItems="center"
                justifyContent="center"
                sx={{
                  bgcolor: themePalette.primary.main,
                  width: '20px',
                  minWidth: '20px',
                  height: '20px',
                  borderRadius: '50px',
                }}
              >
                <Typography variant="body2" color="grey.1000">
                  {count}
                </Typography>
              </Stack>
            )}
          </Stack>

          <DropdownIcon
            fill={themePalette.grey[disabled ? 700 : 900]}
            style={{ transform: open ? 'rotate(180deg)' : '', minWidth: 20 }}
          />
        </Stack>
      </ButtonBase>
    );
  },
);

export default SelectorButton;
