import React, { FC, useRef, useState } from 'react';
import { LinkWrapper } from '@components/Inputs/LinkInput/LinkINput.styled';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { EditIcon } from '@utils/iconsDefs';

const LinkInput: FC<TextFieldProps & { value: string }> = ({
  value,
  onBlur,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [editMode, setEditMode] = useState(!value?.trim());

  if (editMode) {
    return (
      <TextField
        inputRef={inputRef}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length,
          )
        }
        fullWidth
        value={value}
        onBlur={(e) => {
          if (value?.trim()) {
            setEditMode(false);
          }

          onBlur?.(e);
        }}
        inputProps={{
          sx: {
            '&::placeholder': {
              color: themePalette.grey[800],
              opacity: 1,
            },
          },
        }}
        {...props}
      />
    );
  }

  const isDisabled = !!props.disabled || !!props.InputProps?.readOnly;

  const handleEdit = () => {
    setEditMode(true);
    setTimeout(() => inputRef.current?.focus(), 10);
  };

  return (
    <LinkWrapper $error={props.error} $disabled={isDisabled}>
      <TypographyWithElipsis
        href={value}
        component="a"
        target="_blank"
        sx={{
          textDecoration: 'underline',
          pl: 3,
          color: isDisabled ? 'rgba(0, 0, 0, 0.38)' : themePalette.grey[1000],
        }}
      >
        {value}
      </TypographyWithElipsis>

      {!isDisabled && (
        <IconButton onClick={handleEdit} sx={{ mr: 1 }}>
          <EditIcon
            width={18}
            height={18}
            style={{ minWidth: 18 }}
            fill={themePalette.grey[800]}
          />
        </IconButton>
      )}
    </LinkWrapper>
  );
};

export default LinkInput;
