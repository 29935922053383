import React, { FC, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { CustomTooltip } from '@components/CustomTooltip';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import useQnousRefOptions from '@components/EntityDrawers/hooks/useQnousRefOptions';
import ListItemWithInfo from '@components/Inputs/Autocomplete/items/ListItemWithInfo';
import ControlledAutocomplete from '@components/Inputs/controllers/ControlledAutocomplete';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useReferenceMaps from '@hooks/useReferenceMaps';
import useUser from '@hooks/useUser';
import { Chip, Stack, Typography } from '@mui/material';
import { useChangeNodeReferencesMutation } from '@store/services/nodes';
import { NodeReferencesActions } from '@store/services/nodes/types';
import { parseErrorResponse } from '@utils/helpers';

const MAX_VISIBLE_CHIP_SYMBOLS = 40;

const ReferencesInputsGroup: FC = () => {
  const { viewOnly } = useProject();
  const { showToast } = useToast();
  const { watch, setValue } = useFormContext();

  const [{ isQmsUser }] = useUser();

  const [{ qnousOptions, nistOptions }, isRefsLoading] = useQnousRefOptions();
  const [{ qnousMap, nistMap }] = useReferenceMaps();

  const [changeNodeReference, { isLoading: isChangingReference }] =
    useChangeNodeReferencesMutation();

  const handleChangeReference =
    (type: CommonKeys.QnousRef | CommonKeys.NistRef) =>
    async (_: SyntheticEvent, newReferences: ISelectOption[]) => {
      const currentReferences = watch(type);

      const revertChanges = () => {
        setValue(type, currentReferences);
      };

      try {
        setValue(type, newReferences);

        const addedReference = newReferences.find(
          (newReference) =>
            !currentReferences.some(
              (currentReference: ISelectOption) =>
                currentReference.value === newReference.value,
            ),
        );

        const removedReference = addedReference
          ? null
          : currentReferences.find(
              (currentReference: ISelectOption) =>
                !newReferences.some(
                  (newReference) =>
                    currentReference.value === newReference.value,
                ),
            );

        const { nist, qnous_ref } = await changeNodeReference({
          nist:
            watch(CommonKeys.NistRef).map(
              (ref: ISelectOption) => ref.value as string,
            ) ?? [],

          qnous_ref:
            watch(CommonKeys.QnousRef).map(
              (ref: ISelectOption) => ref.value as string,
            ) ?? [],

          type,

          ...(addedReference
            ? {
                action: NodeReferencesActions.Add,
                value: addedReference.value as string,
              }
            : {
                action: NodeReferencesActions.Rem,
                value: (removedReference?.value as string) ?? '',
              }),
        }).unwrap();

        setValue(
          CommonKeys.NistRef,
          nist.map((ref) => ({ value: ref, label: nistMap.get(ref) ?? ref })),
          { shouldDirty: true },
        );

        setValue(
          CommonKeys.QnousRef,
          qnous_ref.map((ref) => ({
            value: ref,
            label: qnousMap.get(ref) ?? ref,
          })),
          { shouldDirty: true },
        );
      } catch (error) {
        revertChanges();
        showToast(parseErrorResponse(error), 'error');
      }
    };

  return (
    <Stack gap={4}>
      <Typography variant="h4">References</Typography>

      {isQmsUser && (
        <ControlledAutocomplete
          name={CommonKeys.QnousRef}
          label="Qnous reference"
          placeholder="Select reference"
          options={qnousOptions}
          loading={isRefsLoading}
          multiple
          required
          readOnly={viewOnly}
          itemComponent={ListItemWithInfo}
          {...(isQmsUser
            ? { onChange: handleChangeReference(CommonKeys.QnousRef) }
            : {})}
          isLoading={isChangingReference || isRefsLoading}
          renderTags={(value, getTagProps) => {
            return value.map(({ value, label }, index) => (
              <CustomTooltip
                key={value}
                placement="left"
                disableHoverListener={label.length < MAX_VISIBLE_CHIP_SYMBOLS}
                title={label || qnousMap.get(value)}
              >
                <Chip
                  label={label || qnousMap.get(value)}
                  size="small"
                  variant="grey"
                  sx={{ cursor: 'pointer' }}
                  {...getTagProps({ index })}
                />
              </CustomTooltip>
            ));
          }}
        />
      )}

      <ControlledAutocomplete
        name={CommonKeys.NistRef}
        label="NIST reference"
        placeholder="Select reference"
        options={nistOptions}
        loading={isRefsLoading}
        multiple
        required
        readOnly={viewOnly}
        itemComponent={ListItemWithInfo}
        itemProps={() => ({
          renderTooltipTitle: ({
            label,
            value,
          }: {
            label: string;
            value: string;
          }) => {
            const normalLabel = label || nistMap.get(value);

            const [id, title] = normalLabel?.split(':') ?? ['', ''];

            return (
              <>
                <Typography variant="body1" color="white">
                  {id}:
                </Typography>

                <Typography variant="body1" color="grey.500">
                  {title}
                </Typography>
              </>
            );
          },
        })}
        {...(isQmsUser
          ? { onChange: handleChangeReference(CommonKeys.NistRef) }
          : {})}
        isLoading={isChangingReference || isRefsLoading}
        renderTags={(value, getTagProps) => {
          return value.map(({ value, label }, index) => {
            const normalLabel = label || nistMap.get(value);
            const [id, title] = normalLabel?.split(':') ?? ['', ''];

            return (
              <CustomTooltip
                key={value}
                placement="left"
                disableHoverListener={
                  normalLabel?.length < MAX_VISIBLE_CHIP_SYMBOLS
                }
                title={
                  <>
                    <Typography variant="body1" color="white">
                      {id}:
                    </Typography>

                    <Typography variant="body1" color="grey.600">
                      {title}
                    </Typography>
                  </>
                }
              >
                <Chip
                  label={normalLabel}
                  size="small"
                  variant="grey"
                  sx={{ cursor: 'pointer' }}
                  {...getTagProps({ index })}
                />
              </CustomTooltip>
            );
          });
        }}
      />
    </Stack>
  );
};

export default ReferencesInputsGroup;
