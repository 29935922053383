import { ContextualContent } from '@components/EntityDrawers/drawers/Contextual/content';
import { ContextualFormValues } from '@components/EntityDrawers/drawers/Contextual/form';
import { ContextualSettings } from '@components/EntityDrawers/drawers/Contextual/settings';
import withDrawer from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { ContextualMetaData } from '@store/services/nodes/types';

type Type =
  | ProjectDrawerTypes.AddContextualEntity
  | ProjectDrawerTypes.EditContextualEntity;

export const ContextualDrawer = withDrawer<
  ContextualFormValues,
  ContextualMetaData,
  Type
>(ContextualContent, ContextualSettings);
