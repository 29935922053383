import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRecentProject } from '@store/services/auth/types';
import { RootState } from '@store/store';

interface RecentlyProjectsState {
  projects: IRecentProject[];
}

const initialState: RecentlyProjectsState = {
  projects: [],
};

const MAX_RECENTLY_PROJECTS = 5;

const recentlyProjectsSlice = createSlice({
  name: 'recentlyProjects',
  initialState,
  reducers: {
    initProjects: (state, { payload }: PayloadAction<IRecentProject[]>) => {
      state.projects = payload ?? [];
    },

    resetProjects: () => initialState,

    shiftProject: (
      state,
      { payload: project }: PayloadAction<IRecentProject>,
    ) => {
      const { projects } = state;

      const filteredProjects = projects.filter(({ id }) => id !== project.id);
      const newProjects = [project, ...filteredProjects].slice(
        0,
        MAX_RECENTLY_PROJECTS,
      );

      state.projects = newProjects;
    },
  },
});
export const selectRecently = (state: RootState) =>
  state.recentlyProjects.projects;

export const { shiftProject, initProjects, resetProjects } =
  recentlyProjectsSlice.actions;
export default recentlyProjectsSlice;
