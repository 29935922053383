import React from 'react';
import { Box, Typography } from '@mui/material';
import { IVersion } from '@store/services/versions/types';
import { format } from 'date-fns';

type Props = {
  version: IVersion;
};

export const VersionCardTopPart = ({ version }: Props) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Typography variant="h4" color="grey.900">
        {version.name}
      </Typography>
      <Typography variant="body1" color="grey.700" sx={{ ml: 3 }}>
        {format(new Date(version.created_at), 'yyyy-MM-dd HH:mm')}
      </Typography>
    </Box>
  );
};
