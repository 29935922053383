import React, { FC } from 'react';
import DescriptionInput from '@components/EntityDrawers/components/inputs/DescriptionInput';
import DomainDropdown from '@components/EntityDrawers/components/inputs/DomainDropdown';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ElementTypeDetailsInput from '@components/EntityDrawers/drawers/Characteristic/inputs/ElementTypeDetailsInput';
import ElementTypeDropdown from '@components/EntityDrawers/drawers/Characteristic/inputs/ElementTypeDropdown';
import { useDrawerContext } from '@components/EntityDrawers/providers/DrawerProvider';
import ControlledDatePicker from '@components/Inputs/controllers/ControlledDatePicker';
import { DatePicker } from '@components/Inputs/DatePicker';
import { WideDomainKey } from '@constants/entities/ui';
import useCurrentAsset from '@hooks/useCurrentAsset';
import EntityAlerts from '@views/Project/components/EntityAlerts';
import dayjs from 'dayjs';

export const CharacteristicContent: FC = () => {
  const { editMode } = useDrawerContext();

  const {
    dto: { reviewed_at, domain_id },
  } = useCurrentAsset();

  return (
    <>
      <EntityAlerts />

      <ElementTypeDropdown />
      <ElementTypeDetailsInput />
      <DescriptionInput
        label="Sensitive element description"
        placeholder="Sensitive element description"
      />
      <DomainDropdown disabled value={domain_id ?? WideDomainKey} />

      {editMode && (
        <>
          <ControlledDatePicker
            name={CommonKeys.DateCreated}
            label="Date created"
            readOnly
          />

          <DatePicker
            value={reviewed_at ? dayjs(reviewed_at) : null}
            label="Date reviewed"
            readOnly
          />
        </>
      )}
    </>
  );
};
