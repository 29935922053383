import React, { FC } from 'react';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledDatePicker from '@components/Inputs/controllers/ControlledDatePicker';
import { useProject } from '@context/Project/ProjectProvider';

type Props = {
  reviewValidateOnBlur?: boolean;
  dateReviewedClearable?: boolean;
};

const CreatedReviewDates: FC<Props> = ({
  reviewValidateOnBlur = false,
  dateReviewedClearable = false,
}) => {
  const { viewOnly } = useProject();

  return (
    <>
      <ControlledDatePicker
        name={CommonKeys.DateCreated}
        label="Date created"
        readOnly
      />

      <ControlledDatePicker
        name={CommonKeys.DateReviewed}
        label="Date reviewed"
        readOnly={viewOnly}
        validateOnBlur={reviewValidateOnBlur}
        clearable={dateReviewedClearable}
      />
    </>
  );
};

export default CreatedReviewDates;
