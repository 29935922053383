import { IUser } from '@store/services/admin/types';
import { PaginationParams } from '@store/services/types';

export enum VersionType {
  Autosave = 'autosave',
  Promote = 'promote',
  Restore = 'restore',
}
export interface IVersion {
  id: string;
  name: string;
  project_id: string;
  type: VersionType;
  author: IUser;
  promote_scenario_id: string;
  promote_scenario_name: string;
  restore_version_id: string;
  restore_version_name: string;
  created_at: string;
}

export interface IGetVersionsResponse {
  page: number;
  totalCount: number;
  versions: IVersion[];
}
export interface IGetVersionsParams extends PaginationParams {
  project_id: string;
  owner?: boolean;
}
