import { MouseEventHandler } from 'react';
import {
  useCollapsedActions,
  useIsCollapsed,
} from '@components/MainStage/collapsedLayers.store';
import useAbleCollapseLayer from '@components/MainStage/hooks/useAbleCollapseLayer';
import useStore from '@components/MainStage/store';
import { LayerTypes, SubLayerTypes } from '@constants/canvas/layers';
import { LoadingKeys } from '@constants/entities/ui';
import { useProject } from '@context/Project';
import useLoading from '@hooks/useLoading';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import { collapseRelationController } from '@utils/canvas/CollapseRelationController';
import LayerView from '@utils/canvas/LayerView';
import { toggleCollapsedNodes } from '@utils/canvasHelpers';
import { filterConnectedNodes } from '@views/Insights/Stage/helpers';
import { Tabs } from '@views/Insights/types';

const useCollapseController = (id: LayerTypes | SubLayerTypes | string) => {
  const collapsed = useIsCollapsed(id as LayerTypes);
  const { collapse, expand } = useCollapsedActions();
  const { beginLoading } = useLoading();
  const { insightsTab } = useProject();

  const { isAbleToCollapse, handleUnableCollapse } = useAbleCollapseLayer(
    id as any,
  );

  const {
    toolbox: { mode },
  } = useProject();

  const { nodes, edges, setNodes, setEdges, getActiveNode } = useStore();

  const hideLayer = () => {
    if (!mode) return;

    const hiddenNodes = toggleCollapsedNodes({
      collapseIds: [id] as LayerTypes[],
      isCollapsed: true,
      nodes,
    });

    setNodes(hiddenNodes);

    CanvasModelRenderer.redrawFullCanvasLayers(mode!);
  };

  const expandLayer = async (): Promise<void> => {
    if (!mode) return Promise.resolve();

    return new Promise((resolve) => {
      const shownNodes = toggleCollapsedNodes({
        collapseIds: [id] as LayerTypes[],
        isCollapsed: false,
        nodes,
      });

      const selectedNode = getActiveNode();

      if (insightsTab === Tabs.FocusOn && selectedNode) {
        const { connectedNodes, connectedEdges } = filterConnectedNodes(
          shownNodes,
          edges,
          selectedNode.id,
        );

        setNodes(connectedNodes);
        setEdges(connectedEdges);
      } else {
        setNodes(shownNodes);
      }

      if (LayerView.isLayer(id)) {
        setTimeout(() => {
          CanvasModelRenderer.redrawFullCanvasLayers(mode!);
          resolve();
        }, 0);
      } else {
        CanvasModelRenderer.redrawFullCanvasLayers(mode!);
        resolve();
      }
    });
  };

  const handleToggleLayer: MouseEventHandler<HTMLDivElement> = async (e) => {
    e.stopPropagation();

    if (!mode) return;

    const endLoading = beginLoading(LoadingKeys.Collapse);

    try {
      if (collapsed) {
        expand(id as LayerTypes);
        await expandLayer();

        await collapseRelationController.renderPhantoms();

        return;
      }

      if (isAbleToCollapse()) {
        collapse(id as LayerTypes);
        hideLayer();
        await collapseRelationController.renderPhantoms();

        return;
      }

      handleUnableCollapse();
    } finally {
      endLoading();
    }
  };

  return { collapsed, handleToggleLayer };
};

export default useCollapseController;
