import { useEffect } from 'react';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useAssets from '@hooks/useAssets';
import useProjectId from '@hooks/useProjectId';
import { skipToken } from '@reduxjs/toolkit/query';
import {
  useGetProjectAssetNodesQuery,
  useGetProjectCanvasNodesQuery,
} from '@store/services/nodes';
import { useGetVersionsNodesQuery } from '@store/services/versions';

const EmptyNodesResponse = { nodes: [], relations: [] };

const useFetchNodes = () => {
  const {
    activeVersionId,
    isVersionsShown,
    toolbox: { mode },
  } = useProject();

  const id = useProjectId(true);
  const { selectedAssetId, isNewAssetMode } = useAssets();

  const shouldFetchCanvasNodes = id && mode && mode !== Modes.RiskManagement;

  const shouldFetchAssetNodes =
    id && mode && mode === Modes.RiskManagement && selectedAssetId;

  const shouldFetchVersionNodes =
    activeVersionId && mode && mode !== Modes.RiskManagement;

  const projectNodesQuery = useGetProjectCanvasNodesQuery(
    shouldFetchCanvasNodes ? { projectId: id, mode } : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const assetNodesQuery = useGetProjectAssetNodesQuery(
    shouldFetchAssetNodes
      ? {
          projectId: id,
          assetNodeId: selectedAssetId ?? '',
          versionId: activeVersionId,
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  const { data: versionsData, isFetching: isFetchingVersionNodes } =
    useGetVersionsNodesQuery(
      shouldFetchVersionNodes
        ? { versionId: activeVersionId ?? '', mode }
        : skipToken,
    );

  const isProjectNodesLoading =
    projectNodesQuery.isLoading || projectNodesQuery.isFetching;

  const isAssetNodesLoading =
    assetNodesQuery.isLoading || assetNodesQuery.isFetching;

  useEffect(() => {
    if (mode === Modes.RiskManagement) {
      if (!assetNodesQuery.isUninitialized) assetNodesQuery.refetch();
    }
  }, [mode]);

  useEffect(() => {
    if (
      isVersionsShown &&
      mode === Modes.RiskManagement &&
      !assetNodesQuery.isUninitialized
    ) {
      assetNodesQuery.refetch();
    }
  }, [mode, isVersionsShown]);

  useEffect(() => {
    if (
      isVersionsShown &&
      !activeVersionId &&
      !projectNodesQuery.isUninitialized
    ) {
      projectNodesQuery.refetch();
    }
  }, [isVersionsShown, activeVersionId]);

  const getData = () => {
    const isLoadingProcess =
      isProjectNodesLoading || isAssetNodesLoading || isFetchingVersionNodes;

    // Canvas shouldn`t display any nodes while loading
    if (isLoadingProcess) return;

    // Return version nodes
    if (activeVersionId) {
      if (mode === Modes.RiskManagement) {
        // Return empty nodes if there is no assets in version at all
        if (!selectedAssetId) return;

        // Return asset nodes for current Version
        return assetNodesQuery.data;
      }

      return versionsData;
    }

    // Return current modal Asset nodes
    if (mode === Modes.RiskManagement) {
      // Return empty nodes if new asset mode is active OR there is no assets at all
      if (isNewAssetMode || !selectedAssetId) return;

      return assetNodesQuery.data;
    }

    // Return current modal nodes
    return projectNodesQuery.data;
  };

  return {
    data: getData() || EmptyNodesResponse,
    isFetching:
      projectNodesQuery.isFetching ||
      assetNodesQuery.isFetching ||
      isFetchingVersionNodes,
  };
};

export default useFetchNodes;
