import { Node } from 'reactflow';
import useStore from '@components/MainStage/store';
import { COLLAPSED_LAYER_HEIGHT } from '@constants/canvas/general';

class LayerViewBase<IdType extends string> {
  protected readonly id: IdType;

  element: HTMLElement | null;

  node: Node | null;

  private unsubscribeStore: (() => void) | null = null;

  private readonly observer: MutationObserver;

  constructor(id: IdType) {
    this.id = id;
    this.element = document.getElementById(id);
    this.node = this.getNodeFromStore(id);

    this.subscribeToStore();

    this.observer = new MutationObserver(this.handleDomChanges.bind(this));
    this.observeDocument();
  }

  get height(): number {
    return this.element?.clientHeight ?? 0;
  }

  private getNodeFromStore(id: IdType): Node | null {
    const node = useStore.getState().nodes.find((node) => node.id === id);

    if (!node) {
      return null;
    }

    return node;
  }

  private subscribeToStore() {
    this.unsubscribeStore = useStore.subscribe((state) => {
      this.node = state.nodes.find((node) => node.id === this.id) ?? null;
    });
  }

  private observeDocument() {
    this.observer.observe(document, { childList: true, subtree: true });
  }

  private handleDomChanges(mutations: MutationRecord[]) {
    for (const mutation of mutations) {
      if (mutation.type === 'childList') {
        const newElement = document.getElementById(this.id);
        if (newElement !== this.element) {
          this.element = newElement;
          break;
        }
      }
    }
  }

  setHeight(newHeight: number): void {
    if (!this.element) return;

    this.element.style.height = `${newHeight}px`;
  }

  collapse(): void {
    if (!this.element) return;

    this.element.style.height = `${COLLAPSED_LAYER_HEIGHT}px`;
  }

  setYPosition(y: number): void {
    useStore.getState().updateNodePosition(this.id, { y });
  }

  shiftYPosition(offset: number): void {
    if (!this.node) return;

    if (offset !== 0) {
      this.setYPosition(this.node.position.y + offset);
    }
  }

  dispose(): void {
    if (this.unsubscribeStore) {
      this.unsubscribeStore();
      this.unsubscribeStore = null;
    }
  }
}

export default LayerViewBase;
