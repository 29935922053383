import React, { FC } from 'react';
import { Node } from 'reactflow';
import ConfirmDialog from '@components/Dialogs/ConfirmDialog';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { AssessmentFormValues } from '@components/EntityDrawers/drawers/Assessment/form';
import { DrawerWrapperProps } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import useCurrentAsset from '@hooks/useCurrentAsset';
import usePromisedConfirmModal from '@hooks/usePromisedConfirmModal';
import { useCheckDomainChangeImpactModelMutation } from '@store/services/nodes';
import { getDomainIdValue, transformValueToDomainId } from '@utils/helpers';

type Type =
  | ProjectDrawerTypes.AddAssessmentScopeEntity
  | ProjectDrawerTypes.EditAssessmentScopeEntity;

const withAssetDomainChange = (
  AssetDrawer: FC<DrawerWrapperProps<Type, AssessmentFormValues>>,
) => {
  return ({ type }: { type: Type }) => {
    const [isDomainModalOpen, { openModal, closeModal, confirmAction }] =
      usePromisedConfirmModal();

    const [checkDomainImpact] = useCheckDomainChangeImpactModelMutation();

    const {
      dto: { domain_id },
    } = useCurrentAsset();

    const checkDomainUnchanged = async (
      values: AssessmentFormValues,
      editMode: boolean,
      node: Node | undefined,
    ) => {
      if (!editMode) return true;

      if (getDomainIdValue(domain_id) === values[CommonKeys.Domain])
        return true;

      const { success: willNotImpact } = await checkDomainImpact({
        nodeId: node?.id ?? '',
        domainId: transformValueToDomainId(values[CommonKeys.Domain]),
      }).unwrap();

      try {
        if (!willNotImpact) await openModal();
        return true;
      } catch (error) {
        return false;
      }
    };

    return (
      <>
        <ConfirmDialog
          open={isDomainModalOpen}
          onClose={closeModal}
          onConfirm={confirmAction}
          title="Domain changes"
          text="Changing the domain will impact the relationship(s) asset has within the model. Are you sure you want to proceed?"
          confirmName="Proceed"
        />

        <AssetDrawer type={type} canSave={checkDomainUnchanged} />
      </>
    );
  };
};

export default withAssetDomainChange;
