import React, { FC, forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import ControlledTextField, {
  ControlledTextFieldProps,
} from '@components/Inputs/controllers/ControlledTextField';
import { Currency, CurrencyNames } from '@constants/entities/currency';
import { InputAdornment, Typography } from '@mui/material';

const NumericFormatCustom = forwardRef<NumericFormatProps, any>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
  },
);

type Props = ControlledTextFieldProps & {
  currency?: Currency;
};

const ControlledAmountInput: FC<Props> = ({ currency, ...props }) => {
  return (
    <ControlledTextField
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        startAdornment: currency ? (
          <InputAdornment position="start">
            <Typography variant="body1" color="grey.800">
              {currency}
            </Typography>
          </InputAdornment>
        ) : null,
        endAdornment: currency ? (
          <InputAdornment position="start">
            <Typography
              variant="body1"
              color="grey.1000"
              sx={{ textTransform: 'uppercase' }}
            >
              {CurrencyNames[currency]}
            </Typography>
          </InputAdornment>
        ) : null,
      }}
      {...props}
    />
  );
};

export default ControlledAmountInput;
