import React from 'react';
import { KeyControlsImpactColor } from '@components/MainStage/CustomNode/KeyControlsImpact/helpers';
import {
  KeyControlsImpactValueBlock,
  KeyControlsImpactWrapper,
} from '@components/MainStage/CustomNode/KeyControlsImpact/KeyControlsImpact.styled';
import { ImpactType } from '@store/services/projects/types';

type Props = {
  impact: ImpactType;
};

export const KeyControlsImpact = ({ impact }: Props) => {
  return (
    <KeyControlsImpactWrapper>
      <KeyControlsImpactValueBlock
        $borderColor={KeyControlsImpactColor[impact]}
      >
        {impact}
      </KeyControlsImpactValueBlock>
    </KeyControlsImpactWrapper>
  );
};
