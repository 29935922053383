import React, { FC } from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import useCurrentANDRelations from '@components/EntityDrawers/hooks/useCurrentANDRelations';
import useStore from '@components/MainStage/store';
import { DependencyTypes } from '@constants/entities/relationship';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useAssets from '@hooks/useAssets';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import { useDeleteRelationshipMutation } from '@store/services/nodes';
import {
  IAvailableRelationship,
  ICanvasNodesResponse,
} from '@store/services/nodes/types';
import { ResponseCodes } from '@store/services/types';
import { parseErrorResponse } from '@utils/helpers';

type Props = Pick<ConfirmDialogProps, 'open'> & {
  id: string;
  onOpenSelectReplacement: (
    availableRelations: IAvailableRelationship[],
  ) => void;
  onFullClose: () => void;
  onModalClose: () => void;
};

const ConfirmDeleteModal: FC<Props> = ({
  id,
  open,
  onModalClose,
  onFullClose,
  onOpenSelectReplacement,
}) => {
  const { showToast } = useToast();
  const saveResponse = useSaveEntitiesResponse();
  const {
    hideDrawer,
    toolbox: { mode },
  } = useProject();
  const { getActiveEdge } = useStore();
  const [deleteRelation] = useDeleteRelationshipMutation();

  const { selectedAssetId } = useAssets();

  const { data } = getActiveEdge() ?? {};

  const { autoCount, isLastANDAuto } = useCurrentANDRelations(
    data?.dto?.dependency,
    true,
  );

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteRelation({
        id,
        mode,
        assetId: selectedAssetId || undefined,
      }).unwrap();

      saveResponse(response as ICanvasNodesResponse);

      hideDrawer();
      onFullClose();

      showToast('The relationship has been successfully deleted.', 'success');
    } catch (e: any) {
      const { code, blockers } = e.data ?? {};

      if (code === ResponseCodes.ITEM_BLOCKED && blockers?.length) {
        onOpenSelectReplacement(blockers[0]);
        onModalClose();
        return;
      }

      showToast(parseErrorResponse(e), 'error');
      onFullClose();
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={onFullClose}
      title="Delete relationship"
      text={
        (data?.dto?.dependency === DependencyTypes.AND && autoCount) ||
        isLastANDAuto
          ? 'Are you sure that you want to delete the relationship between entities? The weight value will be equally distributed among other AND relationships.'
          : `Are you sure that you want to delete the relationship between entities?\nThis action cannot be undone.`
      }
      onConfirm={handleConfirmDelete}
    />
  );
};

export default ConfirmDeleteModal;
