import React, { FC } from 'react';
import BasicSelectDropdown from '@components/selectors/BasicSelectDropdown';
import { IconButton, Stack } from '@mui/material';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import { getYearsOptions, MonthOptions, shortMonths } from '@utils/datePicker';
import { ControlRightIcon } from '@utils/iconsDefs';
import { Dayjs } from 'dayjs';

const selectorPopover: any = {
  width: 110,
  minWidth: 110,
  maxHeight: 208,
  horizontalAlign: 'left',
};

const CalendarHeader: FC<PickersCalendarHeaderProps<Dayjs | null>> = ({
  currentMonth,
  onMonthChange,
  minDate,
  maxDate,
}) => {
  const handleChangeMonth = (value: string) => {
    const monthIndex = shortMonths.indexOf(value);

    if (currentMonth) {
      const newMonthDate = currentMonth.month(monthIndex);

      onMonthChange(
        newMonthDate,
        currentMonth.month() < monthIndex ? 'left' : 'right',
      );
    }
  };

  const handleChangeYear = (value: string) => {
    if (currentMonth) onMonthChange(currentMonth.year(+value), 'left');
  };

  const handleSlideLeft = () => {
    if (currentMonth) onMonthChange(currentMonth.add(-1, 'month'), 'right');
  };

  const handleSlideRight = () => {
    if (currentMonth) onMonthChange(currentMonth.add(1, 'month'), 'left');
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p="16px 16px 0"
    >
      <IconButton
        sx={{ p: '10px', width: 40, height: 40 }}
        onClick={handleSlideLeft}
      >
        <ControlRightIcon
          style={{ transform: 'rotate(180deg)', minWidth: 24, height: 24 }}
        />
      </IconButton>

      <Stack direction="row" gap={2}>
        <BasicSelectDropdown
          value={currentMonth?.format('MMM') ?? ''}
          onChange={handleChangeMonth}
          options={MonthOptions}
          popover={selectorPopover}
          displayValue={(_, value) => value}
          button={{ sx: { width: 91 } }}
        />

        <BasicSelectDropdown
          displayValue={() => currentMonth?.format('YYYY') ?? ''}
          value={currentMonth?.format('YYYY') ?? ''}
          onChange={handleChangeYear}
          options={getYearsOptions(minDate, maxDate)}
          popover={selectorPopover}
          button={{ sx: { width: 91 } }}
        />
      </Stack>

      <IconButton
        sx={{ p: '10px', width: 40, height: 40 }}
        onClick={handleSlideRight}
      >
        <ControlRightIcon style={{ minWidth: 24, height: 24 }} />
      </IconButton>
    </Stack>
  );
};

export default CalendarHeader;
