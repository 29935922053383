import dayjs, { Dayjs } from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(localeData);

export const DefaultDatePickerYearsGap = 30;
export const MinimalDatePickerYear = 1993;

export const shortMonths = dayjs.monthsShort();
const currentMonthIndex = dayjs().month();

const months = shortMonths.map((month, index) => {
  return {
    value: month,
    label: dayjs.months()[index],
    monthIndex: index,
  };
});

export const MonthOptions = months
  .slice(currentMonthIndex)
  .concat(months.slice(0, currentMonthIndex));

export const getYearsOptions = (
  minDate: Dayjs | null,
  maxDate: Dayjs | null,
  yearsGap = DefaultDatePickerYearsGap,
) => {
  const currentYear = dayjs().year();
  const startYear = minDate?.year() ?? currentYear;
  const endYear = maxDate?.year() ?? currentYear + yearsGap;

  return Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i,
  ).map((year) => ({ value: year.toString(), label: year.toString() }));
};

export const withRelativeTime = (dayjs: any) => {
  dayjs.extend(updateLocale);

  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: 'a while',
      MM: 'a while',
      y: 'a while',
      yy: 'a while',
    },
  });
};
