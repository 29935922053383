import { useReactFlow, XYPosition } from 'reactflow';
import { VIEWPORT_TRANSITION_TIME } from '@constants/canvas/general';
import { LayerTypes, SubLayerTypes } from '@constants/canvas/layers';
import { calculateViewportPosition } from '@utils/canvasHelpers';

const useCenterNodeViewport = () => {
  const { setViewport } = useReactFlow();

  return (
    nodePosition: XYPosition,
    nodeLayerId: LayerTypes | SubLayerTypes,
  ) => {
    setTimeout(() => {
      const viewportPosition = calculateViewportPosition(
        nodePosition,
        nodeLayerId,
      );

      setViewport(viewportPosition, { duration: VIEWPORT_TRANSITION_TIME });
    }, 10);
  };
};

export default useCenterNodeViewport;
