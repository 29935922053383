import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Node } from 'reactflow';
import { Modes } from '@constants/canvas/layers';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { Tabs } from '@views/Insights/types';

export interface ProjectContextProps {
  setIsUILoading: Dispatch<SetStateAction<boolean>>;

  drawer: ProjectDrawerProps | null;
  setDrawer: (drawer: ProjectDrawerProps) => void;
  hideDrawer: () => void;
  handleCloseDrawer: (force?: boolean, unselect?: boolean) => Promise<void>;

  isUnsavedModalOpen: boolean;

  hasChanges: boolean;
  setHasChanges: Dispatch<SetStateAction<boolean>>;

  toolbox: ProjectToolBox;
  handleViewChange: (view: ProjectViews) => void;
  handleModeChange: (mode: Modes | null) => void;
  handleDomainsChange: (domains: string[]) => void;

  isVersionsShown: boolean;
  setIsVersionsShown: Dispatch<SetStateAction<boolean>>;
  activeVersionId: string;
  setActiveVersionId: Dispatch<SetStateAction<string>>;

  isInsightsMode: boolean;
  insightsTab: Tabs;
  handleInsightsTabChange: Dispatch<SetStateAction<Tabs>>;
  isInsightExpanded: boolean;
  setInsightsExpanded: Dispatch<SetStateAction<boolean>>;

  viewOnly: boolean;
  isDashboardMode: boolean;
  isRiskRegisterMode: boolean;

  isMainMenuExpanded: boolean;
  setIsMainMenuExpanded: Dispatch<SetStateAction<boolean>>;

  isModelMode: boolean;
}

export interface ToastProviderProps {
  children: ReactNode;
}

export interface ProjectDrawerProps {
  type: ProjectDrawerTypes;
  payload?: Record<string, any> & {
    defaultValues?: any;
    node?: Node;
  };
}

export enum ProjectViews {
  Dashboard = 'dashboard',
  Model = 'model',
  Insights = 'insights',
}

export interface ProjectToolBox {
  mode: Modes | null;
  domains: string[];
  view: ProjectViews;
}
