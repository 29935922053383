import React, { useEffect } from 'react';
import { useProject } from '@context/Project/ProjectProvider';
import { useAppDispatch } from '@hooks/store';
import { Box, Typography } from '@mui/material';
import { nodesApi } from '@store/services/nodes';
import { QueryTags } from '@store/services/tags';
import { IVersion } from '@store/services/versions/types';
import VersionActionsMenu from '@views/Project/components/Versions/VersionActionsMenu';
import { VersionCardBottomPart } from '@views/Project/components/Versions/VersionCardBottomPart';
import { VersionCardTopPart } from '@views/Project/components/Versions/VersionCardTopPart';
import {
  StyledMenu,
  StyledVersionCard,
  StyledVersionCardContent,
  StyledVersionDot,
  StyledVersionLine,
} from '@views/Project/components/Versions/Versions.styled';

type Props = {
  versions: IVersion[];
  totalCount: number;
};

export const VersionsStepper = ({ versions, totalCount }: Props) => {
  const { activeVersionId, setActiveVersionId } = useProject();
  const dispatch = useAppDispatch();

  const handleCurrentVersionClick = () => {
    if (activeVersionId) {
      setActiveVersionId('');
      dispatch(nodesApi.util.invalidateTags([QueryTags.ProjectNodes]));
    }
  };

  useEffect(() => {
    dispatch(nodesApi.util.invalidateTags([QueryTags.ProjectNodes]));
  }, []);

  return (
    <Box sx={{ maxWidth: 400 }}>
      <StyledVersionCard>
        <StyledVersionDot $isActive={!activeVersionId} />
        <StyledVersionLine $isFirstItem />
        <StyledVersionCardContent
          sx={{ cursor: 'pointer' }}
          onClick={handleCurrentVersionClick}
        >
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h4" color="grey.900">
              Current version
            </Typography>
          </Box>
        </StyledVersionCardContent>
      </StyledVersionCard>
      <Box sx={{ position: 'relative' }}>
        <StyledVersionDot />
        <StyledVersionLine />
        <StyledVersionCardContent>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="h4" color="grey.900">
              {`${totalCount} versions`}
            </Typography>
          </Box>
        </StyledVersionCardContent>
      </Box>

      {versions.map((version: IVersion, index: number) => (
        <StyledVersionCard key={version.id}>
          <StyledVersionDot $isActive={version.id === activeVersionId} />
          <StyledVersionLine $isLastItem={index === versions.length - 1} />
          <StyledVersionCardContent>
            <Box sx={{ display: 'flex ', justifyContent: 'space-between' }}>
              <VersionCardTopPart version={version} />
              <StyledMenu>
                <VersionActionsMenu version={version} />
              </StyledMenu>
            </Box>
            <VersionCardBottomPart version={version} />
          </StyledVersionCardContent>
        </StyledVersionCard>
      ))}
    </Box>
  );
};
