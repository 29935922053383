import { NameLinkObject } from '@components/EntityDrawers/constants/empty-objects';
import {
  CommonKeys,
  CoreRequiredKeys,
} from '@components/EntityDrawers/constants/keys';
import {
  ComponentFormValues,
  ComponentsFields,
  defaultValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Component/form';
import { ComponentKeys } from '@components/EntityDrawers/drawers/Component/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import { ComponentMetaData, NameLink } from '@store/services/nodes/types';
import {
  checkValuesIncomplete,
  prepareDate,
  transformCommonKeys,
} from '@utils/drawers';
import {
  filterEmptyAndOmitId,
  getEntitiesArray,
  omitCertainEmptyKeys,
} from '@utils/helpers';

export const ComponentSettings: DrawerSettings<
  ComponentFormValues,
  ComponentMetaData
> = {
  editType: ProjectDrawerTypes.EditComponentEntity,
  defaultValues,
  validationSchema,
  checkIncompleteFields: checkValuesIncomplete<ComponentFormValues>(
    ...CoreRequiredKeys,
    CommonKeys.Description,
  ),

  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<ComponentFormValues, ComponentMetaData>(
      ...ComponentsFields,
    )(nodeDto, context),

    [ComponentKeys.AuditFinding]: getEntitiesArray<NameLink>(
      nodeDto.meta_data.audit_finding?.map(
        ({ description, link }) => ({ name: description, link }) as NameLink,
      ),
      NameLinkObject,
    ),
  }),

  transformMetaDataToRequestBody: (values) =>
    ({
      ...omitCertainEmptyKeys(values, [
        CommonKeys.OperationalStatus,
        CommonKeys.OperationalModel,
        CommonKeys.OperationalEffectiveness,
        CommonKeys.DesignEffectiveness,
      ]),

      ...prepareDate(values[CommonKeys.TimelineStartDate], 'start_date'),
      ...prepareDate(values[CommonKeys.TimelineEndDate], 'end_date'),

      audit_finding: filterEmptyAndOmitId<NameLink>(
        values[ComponentKeys.AuditFinding],
      ).map(({ name, link }) => ({ description: name, link })),
    }) as ComponentMetaData,
};
