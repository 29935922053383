import { useMemo } from 'react';
import useProjectSettings from '@hooks/useProjectSettings';

const useImpactOptions = () => {
  const {
    settings: { risk_assessment },
  } = useProjectSettings();

  return useMemo(() => {
    return (risk_assessment?.impact ?? []).map((item, index) => ({
      label: item,
      value: index.toString(),
    }));
  }, [risk_assessment]);
};

export default useImpactOptions;
