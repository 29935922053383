import {
  NameLinkObject,
  NameObject,
} from '@components/EntityDrawers/constants/empty-objects';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import {
  defaultValues,
  InitiativeFields,
  InitiativeFormValues,
  validationSchema,
} from '@components/EntityDrawers/drawers/Initiative/form';
import { InitiativeKeys } from '@components/EntityDrawers/drawers/Initiative/keys';
import { DrawerSettings } from '@components/EntityDrawers/hocs/withDrawer';
import { InitiativeTypes } from '@constants/canvas/layers';
import { Currency } from '@constants/entities/currency';
import { ProjectDrawerTypes } from '@constants/entities/drawers';
import {
  InitiativeMetaData,
  Name,
  NameLink,
} from '@store/services/nodes/types';
import {
  checkValuesIncomplete,
  prepareDate,
  transformCommonKeys,
} from '@utils/drawers';
import {
  filterEmptyAndOmitId,
  getEntitiesArray,
  omitCertainEmptyKeys,
} from '@utils/helpers';

export const InitiativeSettings: DrawerSettings<
  InitiativeFormValues,
  InitiativeMetaData
> = {
  editType: ProjectDrawerTypes.EditInitiativesEntity,
  defaultValues,
  validationSchema,
  checkIncompleteFields: checkValuesIncomplete<InitiativeFormValues>(
    CommonKeys.Name,
    CommonKeys.Domain,
    InitiativeKeys.Type,
  ),
  transformNodeDtoToFormValues: (nodeDto, context) => ({
    ...transformCommonKeys<InitiativeFormValues, InitiativeMetaData>(
      ...InitiativeFields,
    )(nodeDto, context),

    [InitiativeKeys.Type]: nodeDto.meta_data.type,
    [InitiativeKeys.ProgressStatus]: nodeDto.meta_data.progress_status ?? '',
    [InitiativeKeys.BusinessSponsor]: nodeDto.meta_data.business_sponsor ?? '',
    [InitiativeKeys.ProgramManager]: nodeDto.meta_data.program_manager ?? '',
    [InitiativeKeys.FundingMechanism]: nodeDto.meta_data.funding_mechanism,

    [InitiativeKeys.AllocatedBudget]:
      nodeDto.meta_data.allocated_budget?.toString() ?? '',

    [InitiativeKeys.DeliveryStatus]: nodeDto.meta_data.delivery_status,

    [InitiativeKeys.ProgressNotes]: getEntitiesArray<Name>(
      nodeDto.meta_data.progress_notes?.map((name) => ({ name })) ?? [],
      NameObject,
    ),
    [InitiativeKeys.Document]: getEntitiesArray<NameLink>(
      nodeDto.meta_data.document ?? [],
      NameLinkObject,
    ),
  }),

  transformMetaDataToRequestBody: (values, { asset }) =>
    ({
      ...omitCertainEmptyKeys(
        {
          ...values,
          [InitiativeKeys.AllocatedBudget]: parseFloat(
            values[InitiativeKeys.AllocatedBudget]
              ?.toString()
              ?.replaceAll(',', '') ?? '',
          ),
        },
        [
          InitiativeKeys.BusinessSponsor,
          InitiativeKeys.ProgramManager,
          InitiativeKeys.ProgressStatus,
          InitiativeKeys.FundingMechanism,
          InitiativeKeys.AllocatedBudget,
        ],
      ),

      type: asset?.id
        ? InitiativeTypes.RemediationAction
        : values[InitiativeKeys.Type],

      currency: Currency.USD,

      ...prepareDate(values[CommonKeys.TimelineStartDate], 'start_date'),
      ...prepareDate(values[CommonKeys.TimelineEndDate], 'end_date'),

      progress_notes: filterEmptyAndOmitId<Name>(
        values[InitiativeKeys.ProgressNotes],
      ).map(({ name }) => name),

      document: filterEmptyAndOmitId<NameLink>(values[InitiativeKeys.Document]),
      delivery_status: values[InitiativeKeys.DeliveryStatus],
    }) as InitiativeMetaData,
};
