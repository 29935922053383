import React from 'react';
import ActionList from '@components/ActionsMenu/ActionList';
import {
  Content,
  ContextMenuStyled,
} from '@components/MainStage/ContextMenu/ContextMenu.styled';
import { MenuStateWithItems } from '@components/MainStage/types';

export function ContextMenu({
  menuItems,
  top,
  left,
  position,
}: Readonly<Omit<MenuStateWithItems, 'id' | 'type'>>) {
  return (
    <ContextMenuStyled $top={top} $left={left} $position={position}>
      <Content>
        <ActionList
          items={menuItems}
          popoverSx={{ '& .MuiPopover-paper': { borderRadius: '4px' } }}
        />
      </Content>
    </ContextMenuStyled>
  );
}
