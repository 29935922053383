import React, { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { ConceptualSourceTypes } from '@constants/canvas/layers';
import { Typography } from '@mui/material';
import { ConceptualSource } from '@store/services/nodes/types';
import { themePalette } from '@theme/muiTheme';
import {
  ArrowConnectorLeftIcon,
  ArrowConnectorRightIcon,
} from '@utils/iconsDefs';
import {
  ContentWrapper,
  DoughnutTitle,
  DoughnutWrapper,
  ExportViewWrapper,
  ListItemStyled,
  ListItemStyledWrapper,
  ListWrapper,
  Wrapper,
} from '@views/ReportsModel/components/JustificationReport/components/JustificationSlide/JustificationSlide.styled';
import NodeInfo from '@views/ReportsModel/components/JustificationReport/components/NodeInfo/NodeInfo';
import { StatementSlideData } from '@views/ReportsModel/constants/types';
import { ArcElement, Chart as ChartJS } from 'chart.js';

ChartJS.register(ArcElement);

type Props = StatementSlideData & {
  label: string;
  type: string;
  definition: string;
};

const JustificationSlide: FC<Props> = ({
  type,
  label,
  definition,
  leftStatements,
  rightStatements,
}) => {
  return (
    <Wrapper>
      <ExportViewWrapper className="statement-report">
        <NodeInfo type={type} label={label} definition={definition} />

        <ContentWrapper>
          <ListWrapper>
            {leftStatements.map(({ id, justification_statement, type }) => {
              return (
                justification_statement && (
                  <ListItemStyledWrapper key={id}>
                    <ListItemStyled>
                      <TypographyWithElipsis variant="body1" linesCount={2}>
                        {justification_statement}
                      </TypographyWithElipsis>
                    </ListItemStyled>

                    <ArrowConnectorRightIcon
                      fill={
                        type === ConceptualSourceTypes.Documentation
                          ? themePalette.red[1000]
                          : themePalette.green[900]
                      }
                    />
                  </ListItemStyledWrapper>
                )
              );
            })}
          </ListWrapper>

          <DoughnutWrapper>
            <DoughnutTitle>
              <TypographyWithElipsis
                variant="h4"
                color="grey.1000"
                maxWidth="220px"
                linesCount={2}
              >
                {label}
              </TypographyWithElipsis>
            </DoughnutTitle>

            <Doughnut
              data={{
                labels: [],
                datasets: [
                  {
                    data: [100],
                    backgroundColor: themePalette.primary.main,
                    borderWidth: 0,
                  },
                ],
              }}
              options={{
                cutout: 95,
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
              }}
            />
          </DoughnutWrapper>

          <ListWrapper>
            {rightStatements.map(
              ({ id, justification_statement, type }: ConceptualSource) => {
                return (
                  justification_statement && (
                    <ListItemStyledWrapper key={id}>
                      <ArrowConnectorLeftIcon
                        fill={
                          type === ConceptualSourceTypes.Documentation
                            ? themePalette.red[1000]
                            : themePalette.green[900]
                        }
                      />

                      <ListItemStyled>
                        <TypographyWithElipsis variant="body1" linesCount={2}>
                          {justification_statement}
                        </TypographyWithElipsis>
                      </ListItemStyled>
                    </ListItemStyledWrapper>
                  )
                );
              },
            )}
          </ListWrapper>
        </ContentWrapper>

        <Typography variant="body1" color="grey.800">
          *Statements provided during interviews are represented by green
          arrows, whereas with red arrows all those <br /> identified in
          corporate documents
        </Typography>
      </ExportViewWrapper>
    </Wrapper>
  );
};

export default JustificationSlide;
