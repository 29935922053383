import React, { FC } from 'react';
import {
  CustomDialog,
  CustomDialogProps,
} from '@components/Dialogs/CustomDialog';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  SxProps,
} from '@mui/material';

export type ConfirmDialogProps = CustomDialogProps & {
  text?: React.ReactNode;
  backName?: string;
  onBack?: () => void;
  confirmName?: string;
  onConfirm?: () => void;
  disabled?: boolean;
  paperSx?: SxProps;
};

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  backName = 'Back',
  onBack,
  confirmName = 'Confirm',
  onConfirm,
  children,
  disabled,
  text,
  ...props
}) => {
  return (
    <CustomDialog
      {...props}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: 598,
          ...(props.paperSx as Record<string, any>),
        },
      }}
    >
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}

        {children}
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          variant="outlined"
          onClick={onBack || props.onClose}
        >
          {backName}
        </Button>

        <Button
          size="large"
          variant="contained"
          disabled={disabled}
          onClick={onConfirm}
        >
          {confirmName}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default ConfirmDialog;
