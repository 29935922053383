import React, { useEffect, useState } from 'react';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import usePagination from '@hooks/usePagination';
import useProjectId from '@hooks/useProjectId';
import { Box, Divider, Popover, Typography } from '@mui/material';
import { useGetVersionsQuery } from '@store/services/versions';
import { IVersion } from '@store/services/versions/types';
import { themePalette } from '@theme/muiTheme';
import { CloseIcon, FilterIcon, NoRecordsIcon } from '@utils/iconsDefs';
import { VersionsStepper } from '@views/Project/components/Versions/VersionsStepper';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

import {
  CloseIconWrapper,
  FilterWrapper,
  ShowMoreStyled,
  StyledFilterOption,
  VersionsStyled,
} from './Versions.styled';

const OWNER_VERSIONS_KEY_VALUE = 'owner';
const ALL_VERSIONS_KEY_VALUE = 'all';
const PAGINATION_STEP = 10;

const filterOptions = [
  {
    label: 'All versions',
    value: ALL_VERSIONS_KEY_VALUE,
  },
  {
    label: 'Only my versions',
    value: OWNER_VERSIONS_KEY_VALUE,
  },
];

export function Versions() {
  const projectId = useProjectId();
  const { drawer, setIsUILoading, setIsVersionsShown, setActiveVersionId } =
    useProject();
  const { saveCurrentZoom } = useCanvasZoom();
  const [allVersions, setAllVersions] = useState<IVersion[]>([]);
  const [filterValue, setFilterValue] = useState(ALL_VERSIONS_KEY_VALUE);
  const [{ page, pageSize }, { handleChangePageSize }] = usePagination();
  const [
    { page: ownerPage, pageSize: ownerPageSize },
    { handleChangePageSize: handleChangeOwnerPageSize },
  ] = usePagination();

  const { data, isLoading } = useGetVersionsQuery(
    {
      project_id: projectId,
      page: filterValue === OWNER_VERSIONS_KEY_VALUE ? ownerPage : page,
      pageSize:
        filterValue === OWNER_VERSIONS_KEY_VALUE ? ownerPageSize : pageSize,
      owner: filterValue === OWNER_VERSIONS_KEY_VALUE,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { versions, totalCount = 0 } = data ?? {};

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'versions-filter',
  });

  useEffect(() => {
    if (versions) {
      setAllVersions(versions);
    }
  }, [versions]);

  const handleFilterClick = (value: string) => {
    setFilterValue(value);
    popupState.close();
  };

  const handleClose = () => {
    saveCurrentZoom();

    setIsUILoading(true);
    setActiveVersionId('');
    setIsVersionsShown(false);

    setTimeout(() => {
      setIsUILoading(false);
    }, 500);
  };

  return (
    <VersionsStyled $isHidden={!!drawer}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 6 }}>
        <Typography variant="h3" color="grey.1000">
          Model versions
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FilterWrapper {...bindTrigger(popupState)}>
            <FilterIcon fill={themePalette.grey[1000]} />
          </FilterWrapper>

          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            slotProps={{ paper: { sx: { width: 220, mt: 3 } } }}
          >
            {filterOptions.map(({ label, value }) => {
              return (
                <StyledFilterOption
                  key={value}
                  onClick={() => handleFilterClick(value)}
                >
                  <Typography
                    variant="body1"
                    color={value === filterValue ? 'grey.1000' : 'grey.900'}
                  >
                    {label}
                  </Typography>
                </StyledFilterOption>
              );
            })}
          </Popover>
          <Divider orientation="vertical" sx={{ mx: 2 }} />
          <CloseIconWrapper onClick={handleClose}>
            <CloseIcon />
          </CloseIconWrapper>
        </Box>
      </Box>
      {allVersions.length > 0 && !isLoading ? (
        <Box sx={{ overflow: 'auto' }}>
          <VersionsStepper versions={allVersions} totalCount={totalCount} />
          {allVersions.length < totalCount && (
            <ShowMoreStyled
              onClick={() =>
                filterValue === OWNER_VERSIONS_KEY_VALUE
                  ? handleChangeOwnerPageSize(pageSize + PAGINATION_STEP)
                  : handleChangePageSize(pageSize + PAGINATION_STEP)
              }
            >
              <Typography variant="h4" color="grey.1000">
                Show more
              </Typography>
            </ShowMoreStyled>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <NoRecordsIcon width="124px" height="140px" />
          <Typography variant="h3" color="grey.1000" sx={{ my: 3 }}>
            {filterValue === OWNER_VERSIONS_KEY_VALUE
              ? 'No matches found'
              : 'This list is empty'}
          </Typography>
          <Typography
            variant="body1"
            color="grey.900"
            sx={{ maxWidth: '220px', textAlign: 'center' }}
          >
            {`No versions are currently found for your ${
              filterValue === OWNER_VERSIONS_KEY_VALUE
                ? 'filter parameter.'
                : 'model.'
            }`}
          </Typography>
        </Box>
      )}
    </VersionsStyled>
  );
}
