import React from 'react';
import { useFormContext } from 'react-hook-form';
import RiskScoreChip from '@components/EntityDrawers/drawers/Risk/components/RiskScoreChip';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import Label from '@components/Inputs/Label';
import { Stack } from '@mui/material';

const InherentScore = () => {
  const { watch } = useFormContext();

  if (!watch(RiskKeys.InherentLikelihood) || !watch(RiskKeys.InherentImpact))
    return null;

  return (
    <Stack gap={2}>
      <Label>Inherent risk score</Label>
      <RiskScoreChip fieldKey={RiskKeys.InherentScore} />
    </Stack>
  );
};

export default InherentScore;
