import { usePureProject } from '@context/Project/ProjectProvider';
import { Tabs } from '@views/Insights/types';

const useIsNodeDisabled = () => {
  const { insightsTab } = usePureProject();

  return (canvasNodeData: any) => {
    const { selectedInInsight, connectedToSelectedNode, disabled } =
      canvasNodeData;

    return (
      (insightsTab === Tabs.Dependency &&
        !selectedInInsight &&
        !connectedToSelectedNode) ||
      disabled
    );
  };
};

export default useIsNodeDisabled;
