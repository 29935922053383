import { NavLink } from 'react-router-dom';
import { themePalette } from '@theme/muiTheme';
import styled from 'styled-components';

interface NavLinkContentProps {
  $isActive?: boolean;
}

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: inherit;
`;

export const NavLinkContent = styled('div')<NavLinkContentProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: ${(p) =>
    p.$isActive ? themePalette.grey[500] : 'inherit'};
`;

export const LinksWrapper = styled('div')<{
  $disabled: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 434px;
  height: 40px;
  padding: 1px;
  border: 1px solid ${themePalette.grey[700]};
  border-radius: 12px;
  background-color: ${themePalette.grey[400]};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};
`;
