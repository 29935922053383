import { RolesPermissionsOrElements } from '@constants/entities/user';
import { useAppSelector } from '@hooks/store';
import useRolesCheck from '@hooks/useRolesCheck';
import { selectScenarioMode } from '@store/slices/projectSlice';

const useCanEditModel = () => {
  const isScenarioMode = useAppSelector(selectScenarioMode);

  const canEditModel = useRolesCheck(RolesPermissionsOrElements.EditMainModel);
  const canEditScenarios = useRolesCheck(
    RolesPermissionsOrElements.EditScenarios,
  );

  return isScenarioMode ? canEditScenarios : canEditModel;
};

export default useCanEditModel;
