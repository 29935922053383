import { createContext, useContext } from 'react';
import { StepperState } from '@hooks/useSteppr/types';

type SliderContextType = StepperState[1] & {
  currentSlide: number;
  totalSlides: number;
};

const SliderContext = createContext<SliderContextType>({
  totalSlides: 1,
  currentSlide: 0,
  nextStep: () => {},
  prevStep: () => {},
  resetStepper: () => {},
  goToStep: () => {},
});

export const useSliderContext = () => {
  const context = useContext(SliderContext);

  if (!context) {
    throw new Error('useSliderContext must be used within a SliderProvider');
  }

  return context;
};

const SliderProvider = SliderContext.Provider;

export default SliderProvider;
