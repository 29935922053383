import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { CharacteristicKeys } from '@components/EntityDrawers/drawers/Characteristic/keys';
import { ISelectOption } from '@constants/entities/ui';
import { ShapeType } from '@constants/types';
import { generateSchema, getDefs, getYups } from '@utils/drawers';
import * as yup from 'yup';

export type CharacteristicFormValues = yup.InferType<
  typeof validationSchema
> & {
  [CharacteristicKeys.ElementType]: string | null;
  [CharacteristicKeys.ElementDetail]: ISelectOption[];
};

export const CharacteristicFields = [
  CommonKeys.Name,
  CommonKeys.Description,
  CommonKeys.DateCreated,
] as const;

export const validationSchema = generateSchema<
  ShapeType<typeof CharacteristicFields>
>({
  ...getYups(...CharacteristicFields),
  [CharacteristicKeys.ElementDetail]: yup.array().of(yup.object()),
});

export const defaultValues: CharacteristicFormValues = {
  ...getDefs(...CharacteristicFields),
  [CharacteristicKeys.ElementType]: null,
  [CharacteristicKeys.ElementDetail]: [],
};
