import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Modes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectData from '@hooks/useProjectData';

const useSetupInitialMode = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [{ subscription }] = useProjectData(undefined, true);

  const {
    toolbox: { mode },
    handleModeChange,
  } = useProject();

  useEffect(() => {
    if (!mode) {
      if (!subscription) return;

      const paramsMode = searchParams.get('mode') as Modes;
      const isParamsModeValid = subscription.modes.includes(paramsMode);

      handleModeChange(isParamsModeValid ? paramsMode : subscription.modes[0]);
    } else {
      setSearchParams({ mode }, { replace: true });
    }
  }, [mode, searchParams, subscription?.modes.join(',')]);
};

export default useSetupInitialMode;
