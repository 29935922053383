import { useCallback } from 'react';
import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project';
import useResetViewport from '@hooks/canvas/useResetViewport';

const usePanelClick = ({ closeMenu }: { closeMenu: () => void }) => {
  const { unselectActiveEdge, unselectActiveNode } = useStore();
  const { handleCloseDrawer, drawer } = useProject();
  const resetViewPort = useResetViewport();

  return useCallback(() => {
    document.dispatchEvent(new Event('mousedown'));

    unselectActiveEdge();
    unselectActiveNode();
    closeMenu();
    handleCloseDrawer();

    if (drawer) {
      resetViewPort();
    }
  }, [drawer, handleCloseDrawer]);
};

export default usePanelClick;
