import { useEffect, useState } from 'react';
import { useCallbackRef } from 'use-callback-ref';

export const useTruncatedText = <T extends HTMLElement = HTMLButtonElement>(
  ...deps: any[]
) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const textRef = useCallbackRef<T>(null, (ref) => {
    setIsTruncated(Number(ref?.scrollWidth) > Number(ref?.clientWidth));
  });

  useEffect(() => {
    if (!textRef.current) return;

    const { scrollWidth, clientWidth, scrollHeight, clientHeight } =
      textRef.current;

    setIsTruncated(
      scrollWidth > clientWidth || scrollHeight > clientHeight, // comparing height in case of multiline truncated text
    );
  }, [...deps]);

  return { isTruncated, textRef };
};
