import { LayerTypes } from '@constants/canvas/layers';
import LayerView from '@utils/canvas/LayerView';

// Manager class for creating and managing LayerViewBase instances
class LayerViewManager {
  private static readonly instances = new Map<string, LayerView>();

  static getInstance(id: LayerTypes): LayerView {
    if (!this.instances.has(id)) {
      const instance = new LayerView(id);
      this.instances.set(id, instance);
    }

    return this.instances.get(id) as LayerView;
  }

  static disposeInstance(id: string): void {
    const instance = this.instances.get(id);
    if (instance) {
      instance.dispose();
      this.instances.delete(id);
    }
  }

  static disposeAll(): void {
    this.instances.forEach((instance) => instance.dispose());
    this.instances.clear();
  }
}

export default LayerViewManager;
