import React, { useMemo } from 'react';
import { IActionItem } from '@components/ActionsMenu/types';
import { Modes } from '@constants/canvas/layers';
import { ImageExport } from '@constants/entities/reports';
import { useDashboard } from '@context/DashboardContext';
import { useProject } from '@context/Project/ProjectProvider';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';
import useScenarioMode from '@hooks/useScenarioMode';
import { EyeOpenIcon, ReportExportIcon, ReportIcon } from '@utils/iconsDefs';

type Options = {
  onOpenExportAsImage: () => void;
  onOpenExportLayersReport: () => void;
  onOpenExportRiskRegisterReport: () => void;
  onOpenExportAssetReport: () => void;
};

const useAdditionalMenuItems = ({
  onOpenExportAsImage,
  onOpenExportLayersReport,
  onOpenExportRiskRegisterReport,
  onOpenExportAssetReport,
}: Options) => {
  const dashboard = useDashboard();
  const { scenarioMode } = useScenarioMode();
  const { saveCurrentZoom } = useCanvasZoom();

  const {
    toolbox: { mode },
    setIsUILoading,
    setIsVersionsShown,
    isDashboardMode,
    handleCloseDrawer,
    isModelMode,
    isRiskRegisterMode,
  } = useProject();

  const handleViewVersionClick = async () => {
    await handleCloseDrawer();
    saveCurrentZoom();
    setIsUILoading(true);
    setIsVersionsShown(true);

    setTimeout(() => {
      setIsUILoading(false);
    }, 500);
  };

  const getReportOpenFn = () => {
    if (isRiskRegisterMode) {
      return onOpenExportRiskRegisterReport;
    }

    if (mode === Modes.RiskManagement) {
      return onOpenExportAssetReport;
    }

    return onOpenExportLayersReport;
  };

  const dashboardExportItems = [
    ImageExport(() => dashboard.openExportModal(undefined)),
  ];

  const modelExportItems = [
    ...(isRiskRegisterMode
      ? []
      : [
          ImageExport(async () => {
            await handleCloseDrawer();
            onOpenExportAsImage();
          }),
        ]),

    {
      text: 'Report (.xls)',
      icon: <ReportIcon fill="currentColor" width={20} height={20} />,
      onClick: getReportOpenFn(),
    },
  ];

  return useMemo<IActionItem[]>(
    () => [
      ...(isModelMode && !scenarioMode && !isRiskRegisterMode
        ? [
            {
              text: 'Versions history',
              icon: <EyeOpenIcon fill="currentColor" />,
              onClick: handleViewVersionClick,
            },
          ]
        : []),

      {
        text: 'Export as',
        icon: <ReportExportIcon fill="currentColor" />,
        subItems: [
          ...(isDashboardMode ? dashboardExportItems : modelExportItems),
        ],
      },
    ],
    [
      handleCloseDrawer,
      isDashboardMode,
      isModelMode,
      isRiskRegisterMode,
      scenarioMode,
    ],
  );
};

export default useAdditionalMenuItems;
