import { useEffect } from 'react';
import { LoadingKeys } from '@constants/entities/ui';
import { filtersSubject } from '@context/ProjectFilters/FiltersSubject';
import useLoading from '@hooks/useLoading';
import { collapseRelationController } from '@utils/canvas/CollapseRelationController';

const useRedrawPhantomsOnFilters = () => {
  const { beginLoading } = useLoading();

  useEffect(() => {
    const subscription = filtersSubject.subscribe(() => {
      const endLoading = beginLoading(LoadingKeys.Collapse);

      setTimeout(async () => {
        await collapseRelationController.renderPhantoms();
        endLoading();
      }, 4);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
};

export default useRedrawPhantomsOnFilters;
