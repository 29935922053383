export enum Currency {
  GBP = '£',
  EUR = '€',
  USD = '$',
  AUD = 'A$',
}

export const CurrencyNames: Record<Currency, string> = {
  [Currency.GBP]: 'GBP',
  [Currency.EUR]: 'EUR',
  [Currency.USD]: 'USD',
  [Currency.AUD]: 'AUD',
} as const;
