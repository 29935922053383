import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project/ProjectProvider';

export const useSourceNode = () => {
  const { getActiveNode } = useStore();
  const activeNode = getActiveNode();
  const { drawer } = useProject();

  return drawer?.payload?.node ?? activeNode;
};

export default useSourceNode;
