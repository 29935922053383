import { useMemo } from 'react';
import useStore from '@components/MainStage/store';
import { NodesTypes } from '@constants/canvas/general';
import {
  SubLayersWithoutValidStatus,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { WideDomainKey } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import { useProjectFilters } from '@context/ProjectFilters/ProjectFiltersProvider';
import { Tabs } from '@views/Insights/types';

const useFilteredCanvas = () => {
  const { edges, nodes, phantoms } = useStore();
  const { insightsTab } = useProject();

  const {
    toolbox: { domains },
  } = useProject();

  const { reviewStatus, validationStatus } = useProjectFilters();

  return useMemo(() => {
    const filteredCanvas = { nodes, edges };

    const isTabFocusedOnOrModelEvolution = [
      Tabs.FocusOn,
      Tabs.ModelEvolution,
    ].includes(insightsTab);

    filteredCanvas.nodes = filteredCanvas.nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        canvas: {
          ...node.data.canvas,
          hiddenByFilters: false,
        },
      },
    }));

    if (!isTabFocusedOnOrModelEvolution) {
      filteredCanvas.nodes = filteredCanvas.nodes.map((node) => ({
        ...node,
        hidden: node.data.canvas.collapsed ?? false,
      }));
    }

    // Domain Scope Filter
    if (domains.length) {
      filteredCanvas.nodes = filteredCanvas.nodes.map((node) => {
        const isCollapsed = node.data?.canvas?.collapsed === true;

        if (isCollapsed) return node;

        const isCustomNode = node.type === NodesTypes.CustomNode;
        const isDomainIncluded = domains.includes(
          node.data?.dto?.domain_id ?? WideDomainKey,
        );

        const isDifferentDomain = isCustomNode && !isDomainIncluded;

        const shouldHideNode =
          (node.hidden && isTabFocusedOnOrModelEvolution) || isDifferentDomain;

        return {
          ...node,
          hidden: shouldHideNode,
          data: {
            ...node.data,
            canvas: {
              ...node.data.canvas,
              hiddenByFilters: isDifferentDomain,
            },
          },
        };
      });
    }

    // Review Required Filter
    if (reviewStatus.length) {
      filteredCanvas.nodes = filteredCanvas.nodes.map((node) => ({
        ...node,
        hidden:
          node.hidden ||
          !(
            node.type !== NodesTypes.CustomNode ||
            node.data?.dto?.review_required
          ),
        data: {
          ...node.data,
          canvas: {
            ...node.data.canvas,
            hiddenByFilters: !(
              node.type !== NodesTypes.CustomNode ||
              node.data?.dto?.review_required
            ),
          },
        },
      }));
    }

    // Validation Status Filter
    if (validationStatus.length) {
      filteredCanvas.nodes = filteredCanvas.nodes.map((node) => {
        const nodeWithoutValidStatus = SubLayersWithoutValidStatus.includes(
          node.parentNode as SubLayerTypes,
        );

        const isVisible =
          node.type !== NodesTypes.CustomNode ||
          validationStatus.includes(node.data?.dto?.valid_status);

        return {
          ...node,
          hidden: node.hidden || nodeWithoutValidStatus || !isVisible,
          data: {
            ...node.data,
            canvas: {
              ...node.data.canvas,
              hiddenByFilters: nodeWithoutValidStatus || !isVisible,
            },
          },
        };
      });
    }

    filteredCanvas.edges = filteredCanvas.edges
      .concat(phantoms)
      .map((edge) => ({
        ...edge,
        hidden: filteredCanvas.nodes.some(
          ({ id, hidden }) =>
            hidden && (id === edge.source || id === edge.target),
        ),
      }));

    return filteredCanvas;
  }, [
    nodes,
    edges,
    domains,
    reviewStatus,
    validationStatus,
    phantoms,
    insightsTab,
  ]);
};

export default useFilteredCanvas;
