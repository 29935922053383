import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import { CharacteristicFormValues } from '@components/EntityDrawers/drawers/Characteristic/form';
import { CharacteristicKeys } from '@components/EntityDrawers/drawers/Characteristic/keys';
import ControlledTextFieldSelect from '@components/Inputs/controllers/ControlledTextFieldSelect';
import { ISelectOption } from '@constants/entities/ui';
import { useProject } from '@context/Project/ProjectProvider';
import useProjectSettings from '@hooks/useProjectSettings';
import { ISensitive } from '@store/services/projects/types';
import { transformArrayToOptions } from '@utils/helpers';

const ElementTypeDropdown = () => {
  const { viewOnly } = useProject();

  const { setValue } = useFormContext<CharacteristicFormValues>();

  const {
    settings: { sensitive },
  } = useProjectSettings();

  const options: ISelectOption[] = useMemo(() => {
    return transformArrayToOptions<ISensitive>(sensitive ?? [], {
      label: 'name',
      value: 'name',
    });
  }, [sensitive]);

  return (
    <ControlledTextFieldSelect
      name={CharacteristicKeys.ElementType}
      changeHandler={(value) => {
        setValue(CommonKeys.Name, value);
        setValue(CharacteristicKeys.ElementDetail, []);
      }}
      label="Sensitive element type"
      placeholder="Choose type"
      disabled={viewOnly}
      options={options}
      required
      maxMenuWidth={367}
    />
  );
};

export default ElementTypeDropdown;
