import React, { useEffect, useState } from 'react';
import { LinkProps } from 'react-router-dom';
import { CustomTooltip } from '@components/CustomTooltip';
import { useTruncatedText } from '@hooks/useTruncatedText';
import { TooltipProps } from '@mui/material/Tooltip';
import Typography, { TypographyProps } from '@mui/material/Typography';

export interface TypographyWithElipsisProps
  extends TypographyProps,
    Partial<Pick<LinkProps, 'to' | 'target'>> {
  maxWidth?: string;
  linesCount?: number; // number of lines to show before ellipsis
  tooltipPlacement?: TooltipProps['placement'];
  tooltipProps?: Partial<TooltipProps>;
  href?: string;
}

export const TypographyWithElipsis: React.FC<TypographyWithElipsisProps> = ({
  children,
  maxWidth,
  tooltipPlacement,
  linesCount = 1,
  sx,
  tooltipProps,
  ...typographyProps
}) => {
  const { isTruncated, textRef } = useTruncatedText(children);
  const [open, setOpen] = useState(false);

  const handleHideTooltip = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('hideTooltips', handleHideTooltip);

    return () => {
      window.removeEventListener('hideTooltips', handleHideTooltip);
    };
  }, []);

  return (
    <CustomTooltip
      placement={tooltipPlacement || 'top'}
      title={children}
      disableHoverListener={!isTruncated}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...tooltipProps}
    >
      <Typography
        ref={textRef}
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          textDecoration: 'none',
          maxWidth,
          ...(linesCount > 1
            ? {
                whiteSpace: 'normal',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                lineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }
            : {}),
          ...sx,
        }}
        {...typographyProps}
      >
        {children}
      </Typography>
    </CustomTooltip>
  );
};
