import { Edge, Node, XYPosition } from 'reactflow';
import { getCollapsedState } from '@components/MainStage/collapsedLayers.store';
import {
  CANVAS_LEFT_MARGIN,
  CANVAS_WIDTH,
  CUSTOM_NODE_WIDTH,
  INITIAL_VIEWPORT,
  MAX_X_ALLOWED_FOR_NODE_PLACEMENT,
  NodesTypes,
  NodeYMargin,
  ONE_ROW_LIMIT_NODES,
  X_MARGIN_FOR_NEW_ROW,
} from '@constants/canvas/general';
import {
  AssessmentScopeSubLayerTypes,
  InitiativesSubLayerTypes,
  LayerSubLayers,
  LayerTypes,
  Modes,
  PhysicalSubLayerTypes,
  SubLayerTypes,
} from '@constants/canvas/layers';
import { ReactFlowId } from '@constants/entities/reports';
import { store } from '@store/store';
import LayerViewManager from '@utils/canvas/LayerViewManager';
import NodesRow from '@utils/canvas/NodesRow';
import NodeView from '@utils/canvas/NodeView';
import SubLayerViewManager from '@utils/canvas/SubLayerViewManager';

const getSortedCoordinatesByAxis = (nodes: Node[], axis: 'x' | 'y') => {
  return nodes.map((node) => node.position[axis]).sort((a, b) => a - b);
};

const filterNodesByRow = (nodes: Node[], rowNumber: number) => {
  return nodes.filter(
    (node: Node) =>
      (node.position.y - NodeYMargin) / NodesRow.Height === rowNumber,
  );
};

const getMinX = (nodes: Node[]) => {
  const xCoordinates = getSortedCoordinatesByAxis(nodes, 'x');
  return xCoordinates[0];
};

export const getMaxX = (nodes: Node[]) => {
  const xCoordinates = getSortedCoordinatesByAxis(nodes, 'x');

  return xCoordinates[xCoordinates.length - 1];
};

const getYPosition = (rowNumber: number) => {
  return NodeYMargin + rowNumber * NodesRow.Height;
};

const findGapBetweenNodes = (nodesForIteratedRow: Node[]) => {
  const xCoordinates = getSortedCoordinatesByAxis(nodesForIteratedRow, 'x');

  for (let i = 0; i < xCoordinates.length - 1; i += 1) {
    const difference = Math.abs(xCoordinates[i] - xCoordinates[i + 1]);

    if (difference >= CUSTOM_NODE_WIDTH * 2) {
      return xCoordinates[i] + CUSTOM_NODE_WIDTH;
    }
  }

  return false;
};

const findGapPosition = (nodes: Node[], rowNumber: number) => {
  for (
    let iteratedRowNumber = 0;
    iteratedRowNumber <= rowNumber;
    iteratedRowNumber += 1
  ) {
    const nodesForIteratedRow = filterNodesByRow(nodes, iteratedRowNumber);

    if (nodesForIteratedRow.length < ONE_ROW_LIMIT_NODES) {
      const minX = getMinX(nodesForIteratedRow);
      const maxX = getMaxX(nodesForIteratedRow);

      const isEven = (iteratedRowNumber + 1) % 2 === 0;

      if (
        isEven
          ? minX >= CUSTOM_NODE_WIDTH + X_MARGIN_FOR_NEW_ROW
          : minX >= CUSTOM_NODE_WIDTH
      ) {
        return {
          x: isEven ? X_MARGIN_FOR_NEW_ROW : 0,
          y: getYPosition(iteratedRowNumber),
        };
      }

      const gapPosition = findGapBetweenNodes(nodesForIteratedRow);

      if (gapPosition) {
        return { x: gapPosition, y: getYPosition(iteratedRowNumber) };
      }

      if (maxX < MAX_X_ALLOWED_FOR_NODE_PLACEMENT) {
        return {
          x: maxX + CUSTOM_NODE_WIDTH,
          y: getYPosition(iteratedRowNumber),
        };
      }
    }
  }

  return false;
};

export const calculateViewportPosition = (
  nodePosition: XYPosition,
  layerId: SubLayerTypes | LayerTypes,
) => {
  try {
    const { canvasHeight, canvasZoom: zoom } = store.getState().ui;

    const layerView = Object.hasOwn(LayerSubLayers, layerId)
      ? LayerViewManager.getInstance(layerId as LayerTypes)
      : SubLayerViewManager.getInstance(layerId as SubLayerTypes);

    const reactFlowContainer = document.getElementById(ReactFlowId)!;

    const containerYOffset = reactFlowContainer.clientHeight / 2;
    const containerXOffset = reactFlowContainer.clientWidth / 2;

    const centeredInViewX =
      containerXOffset - nodePosition.x * zoom - (CUSTOM_NODE_WIDTH * zoom) / 2;

    const maxPossibleX =
      (CANVAS_LEFT_MARGIN -
        (CANVAS_WIDTH + CANVAS_LEFT_MARGIN) +
        reactFlowContainer.clientWidth / zoom) *
      zoom;

    const x = Math.max(centeredInViewX, maxPossibleX);

    const centeredInViewY =
      containerYOffset -
      NodeView.Height * zoom -
      nodePosition.y * zoom -
      (layerView.node?.position.y ?? 0) * zoom;

    const maxPossibleY =
      -(canvasHeight - reactFlowContainer.clientHeight / zoom) * zoom;

    const notAboveTopLineY = Math.min(0, centeredInViewY);

    return {
      x: Math.min(CANVAS_LEFT_MARGIN * zoom, x),
      y: Math.max(notAboveTopLineY, Math.min(0, maxPossibleY)),
      zoom,
    };
  } catch (e) {
    return INITIAL_VIEWPORT();
  }
};

export const calculatePositionForNewNode = (
  subLayerId: SubLayerTypes,
  nodes: Node[],
) => {
  const subLayerNodes = nodes.filter(
    (node: Node) => node.parentNode === subLayerId,
  );
  const yCoordinates = getSortedCoordinatesByAxis(subLayerNodes, 'y');

  const rowNumber =
    (yCoordinates[yCoordinates.length - 1] - NodeYMargin) / NodesRow.Height ||
    0;

  const lastRowNodes = subLayerNodes.filter(
    (node) => (node.position.y - NodeYMargin) / NodesRow.Height === rowNumber,
  );

  const gapPosition = findGapPosition(subLayerNodes, rowNumber);

  // add node into gap position
  if (gapPosition) {
    return {
      x: gapPosition.x,
      y: gapPosition.y,
    };
  }

  const xCoordinatesForLastRow = getSortedCoordinatesByAxis(lastRowNodes, 'x');
  const maxXForLastRow =
    xCoordinatesForLastRow[xCoordinatesForLastRow.length - 1];

  // add node to new row
  if (
    (maxXForLastRow > MAX_X_ALLOWED_FOR_NODE_PLACEMENT ||
      subLayerNodes.length % ONE_ROW_LIMIT_NODES === 0) &&
    subLayerNodes.length > 0
  ) {
    return {
      x: rowNumber % 2 === 0 ? X_MARGIN_FOR_NEW_ROW : 0,
      y: NodesRow.Height * (rowNumber + 1) + NodeYMargin,
    };
  }

  return {
    x: subLayerNodes.length !== 0 ? maxXForLastRow + CUSTOM_NODE_WIDTH : 0,
    y: NodesRow.Height * rowNumber + NodeYMargin,
  };
};

export const isLayerDisabled = (
  layerId: string,
  mode: Modes,
  assetId: string | null,
) => {
  if (mode === Modes.RiskManagement) {
    return !assetId
      ? layerId !== AssessmentScopeSubLayerTypes.Asset
      : layerId === AssessmentScopeSubLayerTypes.Asset;
  }

  return false;
};

export const isAddExistingDisabled = ({
  subLayerId,
  nodes,
}: {
  subLayerId: SubLayerTypes;
  nodes: Node[];
}) => {
  const AddFunctionLayersRequired: SubLayerTypes[] = [
    PhysicalSubLayerTypes.Capabilities,
  ];

  const AddRemediationActionLayersRequired: SubLayerTypes[] = [
    PhysicalSubLayerTypes.Capabilities,
    PhysicalSubLayerTypes.Functions,
  ];

  switch (subLayerId) {
    case PhysicalSubLayerTypes.Functions: {
      return !nodes.some(
        ({ parentNode }) =>
          parentNode &&
          AddFunctionLayersRequired.includes(parentNode as SubLayerTypes),
      );
    }
    case InitiativesSubLayerTypes.ChangeInitiatives: {
      return !nodes.some(
        ({ parentNode }) =>
          parentNode &&
          AddRemediationActionLayersRequired.includes(
            parentNode as SubLayerTypes,
          ),
      );
    }
    default: {
      return false;
    }
  }
};

export const hasRiskCapabilityRelation = (
  activeNodeId: string | undefined,
  edges: Edge[],
  nodes: Node[],
) => {
  return edges.some((edge) => {
    const isSource = edge.source === activeNodeId;
    const hasCapability =
      nodes.find((node) => node.id === edge.target)?.parentNode ===
      PhysicalSubLayerTypes.Capabilities;

    return isSource && hasCapability;
  });
};

export const getCanvasHeight = () => {
  const { canvasHeight, canvasZoom } = store.getState().ui;
  const canvas = document.getElementById('canvas');

  if (!canvas) return canvasHeight;

  return canvasHeight * canvasZoom < canvas.clientHeight
    ? canvas.clientHeight / canvasZoom
    : canvasHeight;
};

export const resetNodeState = (node: Node) => ({
  ...node,
  hidden: node.data.canvas?.collapsed || false,
  selected: false,
  data: {
    ...node.data,
    canvas: {
      collapsed: node.data.canvas?.collapsed,
      connectedToSelectedNode: false,
      selectedInInsight: false,
      hiddenByFilters: false,
      selectedWithEdge: false,
      keyControlImpact: false,
      disabled: false,
      selectedInMaturity: false,
      maturityBackground: false,
      unconnected: false,
    },
  },
});

type ToggleCollapsedOptions = {
  collapseIds: (LayerTypes | SubLayerTypes)[];
  isCollapsed: boolean;
  nodes: Node[];
};

export const toggleCollapsedNodes = ({
  collapseIds,
  isCollapsed,
  nodes,
}: ToggleCollapsedOptions) => {
  return nodes.map((node) => {
    const isCustomNode = node.type === NodesTypes.CustomNode;
    const isNodeSubLayerCollapsed = getCollapsedState().isCollapsed(
      node.parentNode as SubLayerTypes,
    );

    const isNodeInCollapsedSubLayer = collapseIds.includes(
      node.parentNode as SubLayerTypes,
    );

    const isNodeInCollapsedLayer = collapseIds.some(
      (id) =>
        LayerSubLayers[id as LayerTypes]?.includes(
          node.parentNode as SubLayerTypes,
        ),
    );

    const isNode =
      isCustomNode &&
      (isNodeInCollapsedSubLayer || isNodeInCollapsedLayer) &&
      (isCollapsed ? true : !isNodeSubLayerCollapsed);

    const isSublayer =
      node.type === NodesTypes.SubLayerNode &&
      collapseIds.some(
        (id) =>
          LayerSubLayers[id as LayerTypes]?.includes(node.id as SubLayerTypes),
      );

    if (isNode || isSublayer) {
      return {
        ...node,
        hidden: isCollapsed,
        data: {
          ...node.data,
          canvas: { ...node.data.canvas, collapsed: isCollapsed },
        },
      };
    }

    return node;
  });
};

export const checkAllCollapsedNodes = (nodes: Node[]) => {
  const { collapsed } = getCollapsedState();

  return nodes.map((node) => {
    const isCustomNode = node.type === NodesTypes.CustomNode;

    const isNodeInCollapsedSubLayer = collapsed.includes(
      node.parentNode as SubLayerTypes,
    );

    const isNodeInCollapsedLayer = collapsed.some(
      (id) =>
        LayerSubLayers[id as LayerTypes]?.includes(
          node.parentNode as SubLayerTypes,
        ),
    );

    const isNode =
      isCustomNode && (isNodeInCollapsedSubLayer || isNodeInCollapsedLayer);

    const isSublayer =
      node.type === NodesTypes.SubLayerNode &&
      collapsed.some(
        (id) =>
          LayerSubLayers[id as LayerTypes]?.includes(node.id as SubLayerTypes),
      );

    return {
      ...node,
      hidden: isNode || isSublayer,
      data: {
        ...node.data,
        canvas: { ...node.data.canvas, collapsed: isNode || isSublayer },
      },
    };
  });
};

export const initCollapsedNodes = (nodes: Node[]) => {
  const { collapsed } = getCollapsedState();

  if (collapsed.length) {
    return toggleCollapsedNodes({
      collapseIds: collapsed,
      isCollapsed: true,
      nodes,
    });
  }

  return nodes;
};
