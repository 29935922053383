import { useEffect } from 'react';
import useCanvasZoom from '@hooks/canvas/useCanvasZoom';

let observer: MutationObserver;

const useCanvasZoomController = (): void => {
  const { saveCurrentZoom } = useCanvasZoom();
  const targetNode = document.querySelector(
    '.react-flow__viewport.react-flow__container',
  );

  useEffect(() => {
    observer?.disconnect();

    if (!targetNode) {
      return;
    }

    const callback = (mutationsList: any[]) => {
      const styleChanged = mutationsList.some(
        (mutation) =>
          mutation.type === 'attributes' && mutation.attributeName === 'style',
      );

      if (styleChanged) {
        saveCurrentZoom();
      }
    };

    // Create an observer instance linked to the callback function
    observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, [targetNode]);
};

export default useCanvasZoomController;
