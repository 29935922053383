import { UserItem } from '@constants/forms/users';

export const filterFilledUsers = <
  T extends Pick<UserItem, 'full_name' | 'email' | 'business_unit' | 'role'>,
>(
  users?: T[],
): T[] => {
  return (
    users?.filter(
      ({ full_name, email, business_unit, role }) =>
        full_name.trim() || email.trim() || business_unit.trim() || role,
    ) ?? []
  );
};
