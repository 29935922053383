import {
  NODE_VIEW_HEIGHT,
  NODE_VIEW_PADDING_Y,
} from '@constants/canvas/general';

class NodesRow {
  static readonly PaddingY = NODE_VIEW_PADDING_Y;

  static readonly Height = NODE_VIEW_HEIGHT + NodesRow.PaddingY * 2;
}

export default NodesRow;
