import { useEffect } from 'react';

const useBeforeUnload = (
  shouldShowMessage: boolean,
  message = 'You have unsaved changes. Do you want to discard your changes?',
): void => {
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = message;
    };

    if (shouldShowMessage) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }

    return () => {
      if (shouldShowMessage) {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    };
  }, [message, shouldShowMessage]);
};

export default useBeforeUnload;
