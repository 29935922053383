import React from 'react';
import { useFormContext } from 'react-hook-form';
import RiskScoreChip from '@components/EntityDrawers/drawers/Risk/components/RiskScoreChip';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import Label from '@components/Inputs/Label';
import { FormHelperText, Stack } from '@mui/material';

const ResidualScore = () => {
  const {
    watch,
    formState: { errors },
  } = useFormContext();

  if (!watch(RiskKeys.ResidualLikelihood) || !watch(RiskKeys.ResidualImpact))
    return null;

  return (
    <Stack gap={2}>
      <Label>Residual risk score</Label>
      <RiskScoreChip fieldKey={RiskKeys.ResidualScore} />

      {errors[RiskKeys.ResidualScore] && (
        <FormHelperText error>
          {errors[RiskKeys.ResidualScore]?.message as string}
        </FormHelperText>
      )}
    </Stack>
  );
};

export default ResidualScore;
