import { themePalette } from '@theme/muiTheme';
import { styled } from 'styled-components';

export const NodeInfoWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid ${themePalette.grey[500]};
  border-radius: 12px;
  background-color: ${themePalette.grey[400]};
  flex: 1;
  overflow: hidden;
`;

export const NodeInfoContent = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  overflow: hidden;
`;

export const IconWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 12px;
`;
