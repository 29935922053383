import { useMemo } from 'react';
import useStore from '@components/MainStage/store';
import { DependencyTypes, WeightTypes } from '@constants/entities/relationship';

const useCurrentANDRelations = (
  selectedDependency: DependencyTypes | undefined,
  editMode: boolean,
) => {
  const { getActiveEdge, edges } = useStore();
  const activeEdge = getActiveEdge();

  const { dependency, weight_type } = activeEdge?.data?.dto ?? {};

  const relationsAND = useMemo(() => {
    return edges.filter(
      (edge) =>
        !edge.id.startsWith('local') &&
        edge.data?.dto?.dependency === DependencyTypes.AND &&
        edge.source === activeEdge?.source,
    );
  }, [edges, activeEdge?.source, activeEdge?.id]);

  const { autoCount, manualSum } = useMemo(() => {
    return relationsAND.reduce(
      (acc, edge) => {
        const isWeightAuto = edge.data?.dto?.weight_type === WeightTypes.Auto;

        if (isWeightAuto && edge.id !== activeEdge?.id) {
          acc.autoCount += 1;
        } else {
          acc.manualSum += edge.data?.dto?.weight || 0;
        }

        return acc;
      },
      { autoCount: 0, manualSum: 0 },
    );
  }, [relationsAND, editMode, activeEdge, activeEdge?.id]);

  const isLastANDAuto =
    editMode &&
    dependency === DependencyTypes.AND &&
    weight_type === WeightTypes.Auto &&
    relationsAND.length > 1 &&
    !autoCount;

  const isFirstANDAuto =
    selectedDependency === DependencyTypes.AND && !autoCount;

  return {
    relations: relationsAND,
    autoCount,
    manualSum,
    isFirstANDAuto,
    isLastANDAuto,
  };
};

export default useCurrentANDRelations;
