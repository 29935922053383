import React from 'react';
import { TypographyWithElipsis } from '@components/TypographyElipsis';
import { Typography } from '@mui/material';
import { IVersion, VersionType } from '@store/services/versions/types';
import { AutoSaveIcon, PromoteIcon, RestoreIcon } from '@utils/iconsDefs';

export const useVersionStatusInfo = ({
  type,
  promote_scenario_name,
  restore_version_name,
}: IVersion) => {
  if (type === VersionType.Autosave) {
    return (
      <>
        <AutoSaveIcon />
        <Typography variant="body1" color="grey.800" sx={{ ml: 1 }}>
          Autosaved
        </Typography>
      </>
    );
  }
  if (type === VersionType.Promote) {
    return (
      <>
        <PromoteIcon style={{ flexShrink: 0 }} />
        <TypographyWithElipsis
          variant="body1"
          color="grey.800"
          tooltipPlacement="left"
          sx={{ ml: 1 }}
        >
          {`Promote scenario: ${promote_scenario_name}`}
        </TypographyWithElipsis>
      </>
    );
  }

  if (type === VersionType.Restore) {
    return (
      <>
        <RestoreIcon style={{ flexShrink: 0 }} />
        <TypographyWithElipsis
          variant="body1"
          color="grey.800"
          tooltipPlacement="left"
          sx={{ ml: 1 }}
        >
          {`Restored: ${restore_version_name}`}
        </TypographyWithElipsis>
      </>
    );
  }
};
