import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  accessToken: null,
  refreshToken: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (
      state,
      {
        payload: { accessToken, refreshToken },
      }: PayloadAction<Partial<AuthState>>,
    ) => {
      if (accessToken) state.accessToken = accessToken;
      if (refreshToken) state.refreshToken = refreshToken;
    },

    loggedOut: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectTokens = (state: RootState) => state.auth;

export const { setTokens, loggedOut } = authSlice.actions;
export default authSlice.reducer;
