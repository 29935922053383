import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

const ResidualJustification = () => {
  const { viewOnly } = useProject();
  const { watch } = useFormContext();

  const hasCapability = watch(RiskKeys.HasCapability);

  return (
    <ControlledTextField
      name={RiskKeys.ResidualJustification}
      label="Residual risk justification"
      placeholder="Residual risk justification"
      disabled={viewOnly || !hasCapability}
      value={watch(RiskKeys.ResidualJustification)}
      multiline
      required
    />
  );
};

export default ResidualJustification;
