import { SyntheticEvent, useEffect, useState } from 'react';

const useTabs = <T extends number = number>(resetDeps: any[] = []) => {
  const [tab, setTab] = useState<T>(0 as T);

  const changeTab = (_: SyntheticEvent, newTabIndex: T) => {
    setTab(newTabIndex);
  };

  useEffect(() => {
    setTab(0 as T);
  }, resetDeps);

  return { tab, changeTab };
};

export default useTabs;
