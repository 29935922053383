import React, { FC, useEffect, useState } from 'react';
import {
  CustomTooltip,
  CustomTooltipProps,
} from '@components/CustomTooltip/CustomTooltip';
import SelectorButton, {
  SelectorButtonProps,
} from '@components/selectors/core/SelectorButton';
import { Popover, SxProps } from '@mui/material';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

export type BodyProps = {
  close: () => void;
  isOpen: boolean;
};

export type CoreSelectorDropdownProps = {
  children: FC<BodyProps>;
  button: SelectorButtonProps;
  tooltip?: Omit<CustomTooltipProps, 'children'>;
  disabled?: boolean;

  popover?: SxProps & {
    width?: number;
    horizontalAlign?: number | 'center' | 'left' | 'right';
  };
};

const CoreSelectorDropdown: FC<CoreSelectorDropdownProps> = ({
  button: { title, id, sx: buttonSx = {}, ...props },
  popover,
  children: Body,
  tooltip,
  disabled,
}) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `selector-${title}`,
  });

  const [popoverWidth, setPopoverWidth] = useState<string | number>(
    popover?.width || 220,
  );

  useEffect(() => {
    const btn = document.querySelector(`#${id}`);
    const btnWidth = btn?.clientWidth || 220;
    setPopoverWidth(btnWidth);
  }, [id, title]);

  return (
    <>
      <CustomTooltip {...tooltip} skip={!tooltip}>
        <div>
          <SelectorButton
            id={id}
            title={title}
            open={popupState.isOpen}
            disabled={disabled}
            sx={{ ...buttonSx }}
            {...props}
            {...bindTrigger(popupState)}
          />
        </div>
      </CustomTooltip>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: popover?.horizontalAlign || 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: popover?.horizontalAlign || 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: popoverWidth, mt: 3, ...popover } },
        }}
      >
        <Body close={popupState.close} isOpen={popupState.isOpen} />
      </Popover>
    </>
  );
};

export default CoreSelectorDropdown;
