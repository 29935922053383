import React, { FC, useMemo } from 'react';
import ActionList from '@components/ActionsMenu/ActionList';
import { RolesPermissionsOrElements } from '@constants/entities/user';
import { useProject } from '@context/Project/ProjectProvider';
import useRolesCheck from '@hooks/useRolesCheck';
import useToggle from '@hooks/useToggle';
import { Popover, PopoverProps } from '@mui/material';
import { IVersion } from '@store/services/versions/types';
import { EyeOpenIcon, RestoreIcon } from '@utils/iconsDefs';
import { RestoreVersionModal } from '@views/Project/components/Versions/RestoreVersionModal';
import { bindPopover } from 'material-ui-popup-state/hooks';

type Props = {
  version: IVersion;
  popoverProps: ReturnType<typeof bindPopover> & PopoverProps;
};

const VersionMenuPopover: FC<Props> = ({ version, popoverProps }) => {
  const { setActiveVersionId } = useProject();

  const [
    isRestoreVersionOpen,
    { on: openRestoreVersion, off: closeRestoreVersion },
  ] = useToggle();
  const withPopoverClose = (fn: () => void) => () => {
    fn();
    popoverProps.onClose();
  };

  const showRestoreBtn = useRolesCheck(
    RolesPermissionsOrElements.RestoreVersion,
  );

  const items = useMemo(() => {
    return [
      {
        text: 'View version',
        icon: <EyeOpenIcon />,
        onClick: withPopoverClose(() => {
          setActiveVersionId(version.id);
        }),
      },
      ...(showRestoreBtn
        ? [
            {
              text: 'Restore version',
              icon: <RestoreIcon />,
              onClick: withPopoverClose(openRestoreVersion),
            },
          ]
        : []),
    ];
  }, []);

  return (
    <>
      <RestoreVersionModal
        version={version}
        open={isRestoreVersionOpen}
        onClose={closeRestoreVersion}
      />

      <Popover {...popoverProps}>
        <ActionList items={items} />
      </Popover>
    </>
  );
};

export default VersionMenuPopover;
