import React, { FC, MouseEventHandler } from 'react';
import { IconButton, IconButtonProps, Stack } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { CalendarIcon } from '@utils/iconsDefs';

const OpenPickerButton: FC<IconButtonProps> = ({ onClick, ...props }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      sx={{
        pr: 3,
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 1,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
      }}
      onClick={
        props.disabled
          ? undefined
          : (onClick as unknown as MouseEventHandler<HTMLDivElement>)
      }
    >
      <IconButton {...props} sx={{ p: '12px', mr: -2.5 }}>
        <CalendarIcon fill={themePalette.grey[800]} style={{ minWidth: 20 }} />
      </IconButton>
    </Stack>
  );
};

export default OpenPickerButton;
