import React from 'react';
import { Stack } from '@mui/material';
import { IVersion } from '@store/services/versions/types';
import { themePalette } from '@theme/muiTheme';
import { MoreIcon } from '@utils/iconsDefs';
import VersionMenuPopover from '@views/Project/components/Versions/VersionMenuPopover';
import { IconWrapper } from '@views/Project/components/Versions/Versions.styled';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';

type Props = {
  version: IVersion;
};

const VersionActionsMenu = ({ version }: Props) => {
  const popupState = usePopupState({
    variant: 'popper',
    popupId: `action-${version.id}`,
  });

  return (
    <Stack direction="row" justifyContent="center" position="relative">
      <IconWrapper {...bindTrigger(popupState)}>
        <MoreIcon width={16} height={16} fill={themePalette.grey[800]} />
      </IconWrapper>

      <VersionMenuPopover
        version={version}
        popoverProps={{
          ...bindPopover(popupState),
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          transformOrigin: { vertical: 'top', horizontal: 'right' },
          slotProps: {
            paper: {
              sx: {
                width: 220,
                mt: 3,
                boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.10)',
              },
            },
          },
        }}
      />
    </Stack>
  );
};

export default VersionActionsMenu;
