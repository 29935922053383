import { useMemo } from 'react';
import { useGetQnousRefsQuery } from '@store/services/qnous-ref';

const useReferenceMaps = (): [
  { qnousMap: Map<string, string>; nistMap: Map<string, string> },
  boolean,
] => {
  const { data, isLoading, isFetching } = useGetQnousRefsQuery();
  const { qnous_ref, nist } = data ?? {};

  const qnousMap = useMemo(() => {
    return new Map(qnous_ref?.map(({ id, name }) => [id, name]) ?? []);
  }, [data]);

  const nistMap = useMemo(() => {
    return new Map(nist?.map(({ id, name }) => [id, name]) ?? []);
  }, [data]);

  return [{ qnousMap, nistMap }, isLoading || isFetching];
};

export default useReferenceMaps;
