import React from 'react';
import { NodeProps } from 'reactflow';
import useCollapseController from '@components/MainStage/hooks/useCollapseController';
import { CollapseIcon } from '@components/MainStage/MainStage.styled';
import useCanvasWidth from '@hooks/canvas/useCanvasWidth';
import { Typography } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { SubLayerIcon } from '@utils/iconsDefs';

import { SubLayerName, SubLayerNodeStyled } from './SubLayerNode.styled';

export type NodeData = {
  label: string;
  withBottomBorder?: boolean;
};

export function SubLayerNode({ id, data }: NodeProps<NodeData>) {
  const canvasWidth = useCanvasWidth();

  const { collapsed, handleToggleLayer } = useCollapseController(id);

  return (
    <SubLayerNodeStyled
      $withBottomBorder={!!data.withBottomBorder}
      id={id}
      $canvasWidth={canvasWidth}
    >
      <SubLayerName onClick={handleToggleLayer}>
        <SubLayerIcon fill={themePalette.grey[collapsed ? 700 : 800]} />

        <Typography
          variant="body1"
          color={collapsed ? 'grey.700' : 'grey.900'}
          sx={{ ml: '10px' }}
        >
          {data.label}
        </Typography>

        <CollapseIcon collapsed={collapsed} />
      </SubLayerName>
    </SubLayerNodeStyled>
  );
}
