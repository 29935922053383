import React from 'react';
import { useFormContext } from 'react-hook-form';
import { RiskFormValues } from '@components/EntityDrawers/drawers/Risk/form';
import { RiskKeys } from '@components/EntityDrawers/drawers/Risk/keys';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

const InherentJustification = () => {
  const { viewOnly } = useProject();

  const { setValue, watch } = useFormContext<RiskFormValues>();

  return (
    <ControlledTextField
      name={RiskKeys.InherentJustification}
      label="Inherent risk justification"
      placeholder="Inherent risk justification"
      disabled={viewOnly}
      multiline
      required
      changeWrapper={(onChange) => (e) => {
        onChange(e);

        if (!watch(RiskKeys.HasCapability)) {
          setValue(RiskKeys.ResidualJustification, e.target.value);
        }
      }}
    />
  );
};

export default InherentJustification;
