import React, { FC } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { UploadBoxStyled } from '@components/UploadModal/core/DropZoneBlock/DropZoneBlock.styled';
import { Button, Typography } from '@mui/material';
import {
  CloudDisabledIcon,
  CloudIcon,
  DocumentDisabledIcon,
  DocumentIcon,
} from '@utils/iconsDefs';

type Props = {
  isDisabled: boolean;
  options?: Partial<DropzoneOptions>;
  title?: string;
  description?: string;
};

const DefaultAcceptFormats = {
  'application/vnd.ms-exce': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
};

const DropZoneBlock: FC<Props> = ({
  isDisabled,
  options,
  title = 'Choose a file or drag and drop it here',
  description = 'Xlsx and xls formats, up to 2 MB',
}) => {
  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
  } = useDropzone({
    noClick: true,
    multiple: false,
    disabled: isDisabled,
    accept: DefaultAcceptFormats,
    ...options,
  });

  return (
    <UploadBoxStyled {...getRootProps()}>
      <input {...getInputProps()} />

      {isDisabled ? <CloudDisabledIcon /> : <CloudIcon />}

      <Typography
        variant="h4"
        sx={{ mt: 2 }}
        color={isDisabled ? 'grey.700' : 'grey.1000'}
      >
        {title}
      </Typography>

      <Typography
        variant="body1"
        sx={{ mt: 2 }}
        color={isDisabled ? 'grey.700' : 'grey.800'}
      >
        {description}
      </Typography>

      <Button
        onClick={openFileDialog}
        startIcon={isDisabled ? <DocumentDisabledIcon /> : <DocumentIcon />}
        variant="outlined"
        disabled={isDisabled}
        sx={{ mt: 6, width: 230, height: 40 }}
      >
        Choose a file
      </Button>
    </UploadBoxStyled>
  );
};

export default DropZoneBlock;
