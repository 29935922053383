import React, { FC } from 'react';
import { OperationalStatusOptions } from '@components/EntityDrawers/constants/common';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledRadioGroup from '@components/Inputs/controllers/ControlledRadioGroup';
import { useProject } from '@context/Project/ProjectProvider';

const OperationalStatusRadioGroup: FC = () => {
  const { viewOnly } = useProject();

  return (
    <ControlledRadioGroup
      name={CommonKeys.OperationalStatus}
      options={OperationalStatusOptions}
      label="Operational status"
      triggerFields={['timelineStartDate']}
      disabledAll={viewOnly}
    />
  );
};

export default OperationalStatusRadioGroup;
