import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { themePalette } from '@theme/muiTheme';
import { DoubleArrowIcon } from '@utils/iconsDefs';

import { StyledIconButton } from './ControlsBar.styled';

type Props = {
  isListMenuOpen: boolean;
  onToggleListMenu: () => void;
};

const ControlsBar: FC<Props> = ({ isListMenuOpen, onToggleListMenu }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      position="absolute"
      sx={{ p: 6, bottom: 0 }}
    >
      <StyledIconButton
        onClick={onToggleListMenu}
        variant="shadow"
        size="large"
        sx={{
          '&, &:hover': {
            bgcolor: 'white',
          },
        }}
      >
        <DoubleArrowIcon
          style={{ transform: isListMenuOpen ? '' : 'rotate(180deg)' }}
        />
      </StyledIconButton>
    </Stack>
  );
};

export default ControlsBar;
