import { useCallback } from 'react';
import { Connection } from 'reactflow';
import useStore from '@components/MainStage/store';
import { useProject } from '@context/Project';
import useProjectId from '@hooks/useProjectId';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetProjectDomainsQuery } from '@store/services/projects';
import { isEdgeConnectable } from '@utils/canvasEdgeHelpers';

const useIsValidConnection = () => {
  const { getNodeById } = useStore();
  const projectId = useProjectId(true);
  const { data: domains } = useGetProjectDomainsQuery(projectId || skipToken);
  const {
    toolbox: { mode },
  } = useProject();

  return useCallback(
    (connection: Connection) => {
      if (connection.target && connection.source) {
        const targetNode = getNodeById(connection.target);
        const sourceNode = getNodeById(connection.source);

        if (sourceNode && targetNode && domains && mode) {
          return isEdgeConnectable({
            sourceNode,
            targetNode,
            domains,
            getNodeById,
            mode,
          });
        }
      }

      return false;
    },
    [domains, mode],
  );
};

export default useIsValidConnection;
