import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import ConfirmDialog, {
  ConfirmDialogProps,
} from '@components/Dialogs/ConfirmDialog';
import TextFieldSelect, {
  DEFAULT_PLACEHOLDER_VALUE,
} from '@components/Inputs/TextFieldSelect';
import DeleteAssetModal from '@components/MainStage/ContextMenu/NodeContextMenu/DeleteAssetModal';
import RemoveNodeModal from '@components/MainStage/ContextMenu/NodeContextMenu/RemoveNodeModal';
import useDeleteAsset from '@components/MainStage/hooks/useDeleteAsset';
import useIsNodeAddedToAsset from '@components/MainStage/hooks/useIsNodeAddedToAsset';
import useStore from '@components/MainStage/store';
import { Modes, SubLayerTypes } from '@constants/canvas/layers';
import { useProject } from '@context/Project/ProjectProvider';
import { useToast } from '@context/Toast/ToastProvider';
import useAssets from '@hooks/useAssets';
import useSaveEntitiesResponse from '@hooks/useSaveEntitiesResponse';
import useToggle from '@hooks/useToggle';
import { Typography } from '@mui/material';
import {
  useAssignNodesToAssetMutation,
  useDeleteNodeMutation,
} from '@store/services/nodes';
import {
  IAvailableRelationship,
  ICanvasNodesResponse,
  NodeDTO,
  NodeDTOTypes,
} from '@store/services/nodes/types';
import { ResponseCodes } from '@store/services/types';
import CanvasModelRenderer from '@utils/canvas/CanvasModelRenderer';
import { parseErrorResponse } from '@utils/helpers';

type Props = Pick<ConfirmDialogProps, 'open' | 'onClose'> & {
  nodeId: string;
};

export const DeleteNodeModal: FC<Props> = memo(({ nodeId, open, onClose }) => {
  const { showToast } = useToast();
  const { nodes, getNodeById } = useStore();
  const {
    hideDrawer,
    toolbox: { mode },
  } = useProject();
  const { selectedAssetId } = useAssets();
  const saveResponseToRedux = useSaveEntitiesResponse();

  const { name, type } = (getNodeById(nodeId)?.data?.dto ?? {}) as NodeDTO;

  const isNodeAdded = useIsNodeAddedToAsset(nodeId);

  const [deleteNodeRequest] = useDeleteNodeMutation();
  const [assignNodesToAsset] = useAssignNodesToAssetMutation();

  const [isConfirmDeleteOpen, confirmDeleteControls] = useToggle();
  const [isConfirmRemoveOpen, confirmRemoveControls] = useToggle();
  const {
    isConfirmDeleteAssetOpen,
    confirmDeleteAssetControls,
    handleDeleteAsset,
  } = useDeleteAsset();

  const [isBlockedOpen, blockedControls, relationOptions] = useToggle<
    IAvailableRelationship[] | null
  >();

  const [replaceRelationId, setReplaceRelationId] = useState(
    DEFAULT_PLACEHOLDER_VALUE,
  );

  useEffect(() => {
    if (open) {
      if (type === NodeDTOTypes.Asset) {
        return confirmDeleteAssetControls.on(nodeId);
      }

      if (isNodeAdded) {
        return confirmRemoveControls.on();
      }

      confirmDeleteControls.on();
    } else {
      confirmDeleteControls.off();
      blockedControls.off();
      confirmDeleteAssetControls.off();
    }
  }, [open]);

  const handleRemoveNode = async () => {
    const res = await assignNodesToAsset({
      action: 'rem',
      assetId: selectedAssetId ?? '',
      nodes: [{ id: nodeId, x: 0, y: 0 }],
    }).unwrap();

    showToast(
      `The entity has been successfully ${
        isNodeAdded ? 'removed' : 'deleted'
      }.`,
      'success',
    );
    return res;
  };

  const handleDeleteNode = async () => {
    const res = await deleteNodeRequest({
      id: nodeId,
      mode: mode ?? Modes.BusinessAlignment,
      assetId: selectedAssetId || undefined,
    }).unwrap();

    showToast(`The entity has been successfully deleted.`, 'success');
    return res;
  };

  const withNodeClear =
    (requestFn: () => PromiseLike<ICanvasNodesResponse>) => async () => {
      confirmRemoveControls.off();
      confirmDeleteControls.off();

      try {
        const response = await requestFn();
        saveResponseToRedux(response as ICanvasNodesResponse);

        const deletedNode = nodes.find((node) => node.id === nodeId);

        if (deletedNode) {
          CanvasModelRenderer.redrawAfterSubLayerChanges(
            mode!,
            deletedNode.parentNode as SubLayerTypes,
          );
        }

        hideDrawer();
        onClose?.();
      } catch (error: any) {
        const { code, blockers } = error.data ?? {};

        if (code === ResponseCodes.ITEM_BLOCKED && blockers?.length) {
          if (blockers.length === 1) {
            confirmDeleteControls.off();
            blockedControls.on(blockers[0]);
            return;
          }

          showToast(
            'Entity has multiple AND relationships with the last auto-assigned weight value. Delete these relationships first to delete the entity.',
            'error',
          );
          onClose?.();
          return;
        }

        showToast(parseErrorResponse(error), 'error');
        onClose?.();
      }
    };

  const handleDeleteWithReplacement = async () => {
    try {
      const response = await deleteNodeRequest({
        id: nodeId,
        allocateWeightRelationsId: [replaceRelationId],
        mode: mode ?? Modes.BusinessAlignment,
        assetId: selectedAssetId || undefined,
      }).unwrap();

      saveResponseToRedux(response as ICanvasNodesResponse);
      onClose?.();

      hideDrawer();

      showToast(
        'The entity has been successfully deleted. The weight value is allocated.',
        'success',
      );
    } catch (e: any) {
      showToast(parseErrorResponse(e), 'error');
    } finally {
      setReplaceRelationId(DEFAULT_PLACEHOLDER_VALUE);
    }
  };

  const options = useMemo(
    () => relationOptions?.map(({ id, name }) => ({ value: id, label: name })),
    [relationOptions],
  );

  const withClose = (cb: () => void) => () => {
    cb();
    onClose?.();
  };

  return (
    <>
      <RemoveNodeModal
        nodeId={nodeId}
        open={isConfirmRemoveOpen}
        onClose={withClose(confirmRemoveControls.off)}
        onConfirm={withNodeClear(handleRemoveNode)}
      />

      <DeleteAssetModal
        open={isConfirmDeleteAssetOpen}
        onClose={withClose(confirmDeleteAssetControls.off)}
        assetName={name}
        onConfirm={withClose(handleDeleteAsset)}
      />

      <ConfirmDialog
        open={isConfirmDeleteOpen}
        onClose={withClose(confirmDeleteControls.off)}
        title="Delete entity"
        text={
          <Typography variant="body1" color="grey.900">
            Are you sure that you want to delete the entity{' '}
            <Typography variant="body1" color="grey.1000" component="span">
              {name}
            </Typography>
            ?
            <br />
            This action cannot be undone.
          </Typography>
        }
        onConfirm={withNodeClear(
          mode === Modes.RiskManagement ? handleRemoveNode : handleDeleteNode,
        )}
      />

      <ConfirmDialog
        open={isBlockedOpen}
        onClose={withClose(blockedControls.off)}
        title="Delete entity"
        text={
          <Typography variant="body1" color="grey.900">
            Are you sure that you want to delete the entity{' '}
            <Typography variant="body1" color="grey.1000" component="span">
              {name}
            </Typography>
            ?
            <br />
            Please, select one relationship to allocate the weight value to.
          </Typography>
        }
        onConfirm={handleDeleteWithReplacement}
        disabled={replaceRelationId === DEFAULT_PLACEHOLDER_VALUE}
      >
        <TextFieldSelect
          label="Allocation relationship"
          value={replaceRelationId}
          onChange={(e) => setReplaceRelationId(e.target.value)}
          placeholder="Select allocation relationship"
          options={options}
        />
      </ConfirmDialog>
    </>
  );
});
