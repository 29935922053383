import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { CommonKeys } from '@components/EntityDrawers/constants/keys';
import ControlledTextField from '@components/Inputs/controllers/ControlledTextField';
import { useProject } from '@context/Project/ProjectProvider';

const CurrentMaturity: FC = () => {
  const { viewOnly } = useProject();
  const { trigger } = useFormContext();

  return (
    <ControlledTextField
      name={CommonKeys.CurrentMaturity}
      label="Current maturity"
      placeholder="Current maturity"
      disabled={viewOnly}
      changeWrapper={(onChange) => (e) => {
        onChange(e);
        trigger([CommonKeys.TargetMaturity]);
      }}
    />
  );
};

export default CurrentMaturity;
