import React, { FC } from 'react';
import CalendarHeader from '@components/Inputs/DatePicker/CalendarHeader';
import OpenPickerButton from '@components/Inputs/DatePicker/OpenPickerButton';
import Label from '@components/Inputs/Label';
import { Box } from '@mui/material';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers';
import { themePalette } from '@theme/muiTheme';
import {
  DefaultDatePickerYearsGap,
  MinimalDatePickerYear,
} from '@utils/datePicker';
import { CloseCircleIcon } from '@utils/iconsDefs';
import dayjs, { Dayjs } from 'dayjs';

type Props = DatePickerProps<Dayjs> & {
  label?: string;
  required?: boolean;
  placeholder?: string;
  clearable?: boolean;
};

const ClearIcon = () => <CloseCircleIcon fill={themePalette.system.error} />;

export const DatePicker: FC<Props> = ({
  format = 'YYYY-MM-DD',
  label,
  required,
  sx,
  placeholder = 'Choose date',
  slotProps: { textField, ...slotProps } = {},
  minDate = dayjs().set('year', MinimalDatePickerYear),
  maxDate = dayjs().add(DefaultDatePickerYearsGap, 'years'),
  clearable = false,
  ...props
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      {label && <Label required={required}>{label}</Label>}

      <MuiDatePicker
        minDate={minDate}
        maxDate={maxDate}
        dayOfWeekFormatter={(_day, weekday) => weekday.format('dd')}
        format={format}
        slots={{
          calendarHeader: CalendarHeader as FC<any>,
          openPickerButton: OpenPickerButton as FC<any>,
          clearIcon: ClearIcon,
        }}
        {...props}
        sx={{ width: '100%', ...sx }}
        slotProps={{
          field: { clearable },
          clearButton: {
            color: 'error',
            sx: {
              transform: 'translateX(-20px)',
              padding: 0,
              zIndex: 2,
              '&:hover': { background: 'transparent' },
              '& svg': { width: 20, height: 20 },
            },
          },
          textField: {
            placeholder,
            inputProps: { disabled: true },
            ...textField,
          },
          day: {
            disableRipple: true,
            disableTouchRipple: true,
            focusRipple: false,

            sx: {
              width: 40,
              height: 40,
              margin: 0,
              borderRadius: 3,
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              color: themePalette.grey[1000],

              '&:hover': {
                backgroundColor: themePalette.grey[500],
              },

              '&.MuiPickersDay-today': {
                border: 'none',
                backgroundColor: themePalette.grey[500],
                color: themePalette.grey[900],
              },

              '&.Mui-selected': {
                backgroundColor: `${themePalette.primary.main} !important`,
                fontWeight: 600,
              },
            },
          },
          desktopPaper: {
            sx: {
              width: 312,
              boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.10)',
              borderRadius: 3,

              '& .MuiDateCalendar-root': {
                height: 366,
                maxHeight: 366,

                '& .MuiPickersSlideTransition-root': {
                  minHeight: 255,
                },
              },

              '& .MuiDayCalendar-header': {
                marginTop: 2,
              },

              '& .MuiDayCalendar-weekDayLabel': {
                width: 40,
                margin: 0,
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                color: themePalette.grey[1000],
              },

              '& .MuiDayCalendar-weekContainer, .MuiDayCalendar-header': {
                gap: '2px',
              },
            },
          },
          ...slotProps,
        }}
      />
    </Box>
  );
};
