import { ButtonBase } from '@mui/material';
import { styled } from '@mui/material/styles';
import { themePalette } from '@theme/muiTheme';

export const AdditionalSettingsStyled = styled(ButtonBase)<{
  $isActive?: boolean;
  $isDisabled?: boolean;
}>`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid
    ${({ $isActive, $isDisabled }) => {
      if ($isDisabled) {
        return themePalette.grey[500];
      }
      if ($isActive) {
        return themePalette.grey[900];
      }
      return themePalette.grey[700];
    }};

  &:hover {
    border: 1px solid ${themePalette.grey[800]};
  }
`;

export const StyledSettingsOption = styled('div')`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: ${themePalette.grey[400]};

    p {
      color: ${themePalette.grey[1000]};
    }
  }
`;
